let marks = [];
let tempMarks = [];
let totalMarks;
const changeHAndler = (value, index) => {
  tempMarks[index] = value;
  marks = [...tempMarks];
  totalMarks = marks.reduce((a, b) => a + b, 0);
  return totalMarks;
};
export default changeHAndler;
