import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import QuizData from "./QuizData";
import Container from "react-bootstrap/Container";
import Footer from "./Footer";
import LoadingSpinner from "./LoadingSpinner";
import Arrow from "../images/orange.png";
import Image from "react-bootstrap/esm/Image";
import Time from "./Time";
import changeHAndler from "./Qresult";
import { doc, setDoc, db, getDoc } from "./Firebase";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Quiz = ({ currentUserEmail }) => {
  const [nextQues, setNextQues] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [blur, setBlur] = useState("0px");
  let index = localStorage.getItem("readIndex");
  const visit = localStorage.getItem("visit");
  const navigate = useNavigate();

  let score = 0;
  let shuffleArray = [];
  const shuffle = async () => {
    let i = 0;
    let useIndex = [];
    while (i < QuizData[index].length) {
      let randomNumber = Math.floor(Math.random() * QuizData[index].length);
      if (!useIndex.includes(randomNumber)) {
        shuffleArray.push(QuizData[index][randomNumber]);
        useIndex.push(randomNumber);
        i++;
      }
    }
    shuffleArray = shuffleArray.slice(0, 10);
    return shuffleArray;
  };
  shuffle();
  if (nextQues === 0) {
    localStorage.setItem("notChangeArray", JSON.stringify(shuffleArray));
  }
  shuffleArray = JSON.parse(localStorage.getItem("notChangeArray"));
  let Sr = nextQues + 1;

  let quizType = localStorage.getItem("quizType");
  let quizname = "";
  if (quizType === "CardData") {
    quizname = QuizData[index][0].quizname + " Internship";
  }
  if (quizType === "JCardData") {
    quizname = QuizData[index][0].quizname + " position";
  }
  let imgLink = QuizData[index][0].imgLink;

  const changeHandler = (e) => {
    const temp = shuffleArray[nextQues].ans === e.target.value ? 1 : 0;
    let sr = changeHAndler(temp, nextQues);
    localStorage.setItem("nSr", sr);
  };

  return (
    <>
      {visit === "val" ? (
        <>
          {isLoading ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <></>
          )}
          <Container
            fluid
            style={{ filter: `blur(${blur})`, userSelect: "none" }}
          >
            <div className="heading bg-white">
              {QuizData[index][0].quizname} :
            </div>
            <div className="quizStyle bg-white">
              <div className="mb-2 quesStlyle">
                {Sr}.{shuffleArray[nextQues].ques}
              </div>
              <Form onChange={changeHandler}>
                <div className="row flex-column">
                  <div className="col-sm-8 col-10 sndAns">
                    <Form.Check
                      className="ansStyle"
                      inline
                      label={"(A) " + shuffleArray[nextQues].options[0]}
                      name="group1"
                      type="radio"
                      value="A"
                    />
                    <br />
                    <Form.Check
                      className="ansStyle"
                      inline
                      label={"(B) " + shuffleArray[nextQues].options[1]}
                      name="group1"
                      type="radio"
                      value="B"
                    />
                  </div>
                  <div className="col-sm-8 sndAns">
                    <Form.Check
                      className="ansStyle"
                      inline
                      label={"(C) " + shuffleArray[nextQues].options[2]}
                      name="group1"
                      type="radio"
                      value="C"
                    />
                    <br />
                    <Form.Check
                      className="ansStyle"
                      inline
                      label={"(D) " + shuffleArray[nextQues].options[3]}
                      name="group1"
                      type="radio"
                      value="D"
                    />
                  </div>
                </div>
              </Form>
              <div className="txt">
                <Time />
                {Sr < 10 ? (
                  <>
                    <Image
                      fluid
                      src={Arrow}
                      className="xlsButton resBtn"
                      onClick={() => {
                        let radios = document.getElementsByName("group1");
                        let val = "";
                        let i = 0;
                        while (i < radios.length) {
                          if (radios[i].checked) {
                            val = radios[i].value;
                            radios[i].checked = false;
                            i++;
                          }
                          i++;
                        }
                        setNextQues(nextQues + 1);
                      }}
                    ></Image>
                  </>
                ) : (
                  <div className="QuizBtn">
                    <Button
                      className="quizButton mb-3 mt-3"
                      type="submit"
                      disabled={isLoading}
                      onClick={async () => {
                        setIsLoading(true);
                        setBlur("4px");
                        const docRef = doc(db, "users", currentUserEmail);
                        const docSnap = await getDoc(docRef);

                        const scoreDocRef = doc(
                          db,
                          "Qualified-score",
                          QuizData[index][0].quizname
                        );
                        const scoreDocSnap = await getDoc(scoreDocRef);
                        const qualifiedScore = scoreDocSnap.data().score;
                        {
                          localStorage.removeItem("visit");
                        }
                        localStorage.setItem("QualifiedScore", qualifiedScore);
                        let {
                          name,
                          email,
                          phNumber,
                          experience,
                          link,
                          companyName,
                          ctc,
                        } = docSnap.data();

                        const attemptDocRef = doc(db, quizname, email);
                        const attemptDocSnap = await getDoc(attemptDocRef);

                        const failedAttemptDocRef = doc(
                          db,
                          `Failed ${quizname}`,
                          email
                        );

                        const failedAttemptDocSnap = await getDoc(
                          failedAttemptDocRef
                        );

                        let attempts = 0;
                        let failedAttempts = 0;

                        if (attemptDocSnap.exists()) {
                          attempts = attemptDocSnap.data().attempts;
                          attempts = attempts + 1;
                        } else if (failedAttemptDocSnap.exists()) {
                          failedAttempts = failedAttemptDocSnap.data().attempts;
                          attempts = failedAttempts + 1;
                        } else {
                          attempts = 1;
                        }

                        score = localStorage.getItem("nSr");
                        if (score >= qualifiedScore) {
                          let date = new Date();
                          await setDoc(doc(db, quizname, email), {
                            name,
                            email,
                            phNumber,
                            experience,
                            companyName,
                            ctc,
                            link,
                            score,
                            quizname,
                            imgLink,
                            attempts,
                            submissionDate: date,
                          });

                          fetch(
                            "https://us-central1-alphanumeric-career.cloudfunctions.net/server",
                            {
                              method: "POST",
                              withCredentials: false,
                              headers: {
                                "Content-Type": "application/json",
                              },
                              body: JSON.stringify({
                                name,
                                quizname,
                                currentUserEmail,
                                imgLink,
                              }),
                            }
                          ).then((res) => {
                            setIsLoading(true);
                            setBlur("4px");
                            navigate("/quiz-result");
                          });
                        }

                        if (score < qualifiedScore) {
                          let date = new Date();
                          await setDoc(doc(db, `Failed ${quizname}`, email), {
                            name,
                            email,
                            phNumber,
                            experience,
                            companyName,
                            ctc,
                            link,
                            score,
                            quizname,
                            imgLink,
                            attempts,
                            submissionDate: date,
                          });

                          fetch(
                            "https://us-central1-alphanumeric-career.cloudfunctions.net/server/notQualify",
                            {
                              method: "POST",
                              mode: "cors",
                              headers: {
                                "access-control-allow-origin": "*",
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                              body: JSON.stringify({
                                name,
                                quizname,
                                currentUserEmail,
                                imgLink,
                              }),
                            }
                          ).then((res) => {
                            setIsLoading(true);
                            setBlur("4px");
                            navigate("/quiz-result");
                          });
                        }
                      }}
                    >
                      Submit Quiz
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Container>
          <Footer />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Quiz;
