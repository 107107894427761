import React from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/esm/Image";
import Form from "react-bootstrap/Form";
import footer from "../images/strips.png";
import gogleLogo from "../images/google-logo.png";
import { auth, provider, signInWithPopup } from "./Firebase";
import { useNavigate } from "react-router-dom";

const Sign = () => {
  const navigate = useNavigate();
  const signingoogle = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        navigate("/");
      })
      .catch((error) => {
        alert(error.message);
      });
  };
  return (
    <>
      <Container fluid className="">
        <div className="row heigh">
          <div className="col-lg-5 col-md-6 col-sm-6 col-9">
            <div className="signBox text-center">
              <Form className="">
                <span className="signText">Let's move forward!</span> <br />
                <Button className="signBtn" onClick={signingoogle}>
                  <div className="d-inline gLogo">
                    <Image fluid src={gogleLogo} className="Lheight" />
                  </div>
                  Sign up with Google
                </Button>
              </Form>
            </div>
            {/* <Image src={mobile} className="mobileImg d-none" /> */}
          </div>
          <Image src={footer} className="ftimg" />
        </div>
      </Container>
    </>
  );
};

export default Sign;
