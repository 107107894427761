const _accounts = [
    {
        quizname: "Accounts",
        imgLink :"https://alphanumericideas.com/images/careers/accounts.png",
        ques: " Real account records",
        options: [
          "Gains and losses",
          "Dealings in commodities",
          "Dealings with creditors or debtors",
          "All of the above",
        ],
        ans: "B",
      },
      {
        ques: " Balance sheets are prepared",
        options: [
          "Annually",
          "Daily",
          "Monthly",
          "Weekly",
        ],
        ans: "A",
      },
      {
        ques: " ____ is the internal user of financial statements",
        options: [
          "Shareholder of the business",
          "Government agency",
          "Manager of the business",
          "Creditor of the business",
        ],
        ans: "C",
      },
      {
        ques: " Auditing refers to",
        options: [
          "Reporting the financial information",
          "Examination of financial information",
          "Maintaining the ledger accounts",
          "Preparation of financial statements",
        ],
        ans: "B",
      },
      {
        ques: " A low Return on Investment Ratio (ROI) indicates",
        options: [
          "Over investment in assets",
          "Improper utilization of resources",
          "Both A and B",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " Discount allowed is",
        options: [
          "Expense of business",
          "Loss of business",
          "Income of business",
          "Abnormal loss of business",
        ],
        ans: "A",
      },
      {
        ques: " Journals are also referred to as",
        options: [
          "Book of economic events",
          "T account",
          "Book of original entries",
          "Book of entries",
        ],
        ans: "C",
      },
      {
        ques: " Current ratio =",
        options: [
          "Current assets/Current liabilities",
          "Debt/Equity",
          "Quick assets/Current liabilities",
          "Current assets/Equity",
        ],
        ans: "A",
      },
      {
        ques: " What is equity?",
        options: [
          "Owner’s claim on total assets",
          "Owner’s claim on total liabilities",
          "Liability of a business",
          "Cash from the business",
        ],
        ans: "A",
      },
      {
        ques: " _____ is the first phase of accounting cycle",
        options: [
          "Posting entries to Ledger accounts",
          "Preparing Journal",
          "Identifying an economic event or transaction",
          "Making decisions about business",
        ],
        ans: "C",
      },
      {
        ques: " A Master Budget consists of",
        options: [
          "Production budget",
          "Material budget",
          "Sales budget",
          "All of the above",
        ],
        ans: "D",
      },
      {
        ques: " Which of the following is liabilities?",
        options: [
          "Obligations",
          "Future benefits",
          "Resources",
          "Expenses",
        ],
        ans: "A",
      },
      {
        ques: " The accounting process involves recording",
        options: [
          "Posting",
          "Non Quantifiable economic event",
          "Quantifiable economic event",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " ____is the gross inflow of economic benefits.",
        options: [
          "Assets",
          "Liabilities",
          "Expenses",
          "Income",
        ],
        ans: "D",
      },
      {
        ques: " Accounting provides information on",
        options: [
          "Financial conditions of an institutions",
          "Company’s tax liability for a particular year",
          "Cost and income for managers",
          "All of the above",
        ],
        ans: "C",
      },
      {
        ques: " In accounting, an economic event is referred to as",
        options: [
          "Transaction",
          "Bank statement",
          "Cash",
          "Exchange of money",
        ],
        ans: "A",
      },
      {
        ques: " In Journal, the business transaction is recorded",
        options: [
          "Once in a month",
          "Once in a week",
          "Next day",
          "Same day",
        ],
        ans: "D",
      },
      {
        ques: " The process of entering all transactions from the Journal to Ledger is called",
        options: [
          "Accounting",
          "Posting",
          "Entry",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " ____is the most important characteristic that all assets of a business have",
        options: [
          "Future economic benefits",
          "Intangible nature of assets",
          "Value of assets",
          "Long life of asset",
        ],
        ans: "A",
      },
      {
        ques: " Balance sheet is a statement of",
        options: [
          "Capital",
          "Liabilities",
          "Assets",
          "All of the above",
        ],
        ans: "D",
      },
      {
        ques: " Capital increases if____",
        options: [
          "Drawings",
          "Interest on capital",
          "Revenue",
          "Expenses are greater than income",
        ],
        ans: "C",
      },
      {
        ques: " Every business transaction affects at least ______ accounts.",
        options: [
          "Two",
          "Five",
          "One",
          "Three",
        ],
        ans: "A",
      },
      {
        ques: " The other name of Journal is_____",
        options: [
          "Cash Book",
          "Day Book",
          "T account",
          "Ledger",
        ],
        ans: "B",
      },
      {
        ques: " Payment of expenses will______ the assets",
        options: [
          "Increase",
          "Apportion",
          "Reduce",
          "Overstate",
        ],
        ans: "C",
      },
      {
        ques: " ______is a statement showing the financial status of the company at any given time.",
        options: [
          "Profit & Loss statement",
          "Cash Book",
          "Balance Sheet",
          "Trading account",
        ],
        ans: "C",
      },
      {
        ques: " The long term assets that have no physical existence but are rights that have value is known as",
        options: [
          "Intangible assets",
          "Current assets",
          "Investments",
          "Fixed assets",
        ],
        ans: "A",
      },
      {
        ques: " The debts which are to be repaid within a short period (year or less) are known as",
        options: [
          "Fixed liabilities",
          "Contingent liabilities",
          "Current liabilities",
          "All of the above",
        ],
        ans: "C",
      },
      {
        ques: " Discount allowed is a kind of deduction from ______",
        options: [
          "Discount account",
          "Cash account",
          "Account Receivable",
          "Account Payable",
        ],
        ans: "C",
      },
      {
        ques: " The account that records expenses, gains and losses is",
        options: [
          "Real account",
          "Nominal account",
          "Personal account",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " Any written evidence in support of a business transaction is",
        options: [
          "Journal",
          "Ledger",
          "Ledger posting",
          "Voucher",
        ],
        ans: "D",
      },
      {
        ques: " The revenues and expenses of a company are displayed in which statement?",
        options: [
          "Balance Sheet",
          "Cash Flow Statement",
          "Income Statement",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " The main Purpose of Financial Accounting is?",
        options: [
          "To Provide financial information to shareholders",
          "To maintain balance sheet",
          "To minimize taxes",
          "To keep track of liabilities",
        ],
        ans: "A",
      },
      {
        ques: " The expanded accounting equation is used by which statement?",
        options: [
          "Cash Flow Statement",
          "Balance Sheet",
          "Income Statement",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " What type of balance do asset accounts have?",
        options: [
          "Contra",
          "Credit",
          "Debit",
          "All of the above",
        ],
        ans: "C",
      },
      {
        ques: " The kind of debts which are needed to be repaid in a short term is known as?",
        options: [
          "Fixed Liabilities",
          "Current Liabilities",
          "Depreciating Assets",
          "Intangible Assets",
        ],
        ans: "B",
      },
      {
        ques: " The account which increases equity is known as?",
        options: [
          "Debit Account",
          "Credit Account",
          "Revenue",
          "Treasury Stock",
        ],
        ans: "C",
      },
      {
        ques: " What are the long-term assets which do not have any physical existence?",
        options: [
          "Intangible Assets",
          "Tangible Assets",
          "Current Liabilities",
          "Current Assets",
        ],
        ans: "A",
      },
      {
        ques: " What is the supporting evidence in a business transaction called?",
        options: [
          "Journal",
          "Ledger",
          "Voucher",
          "Contra Voucher",
        ],
        ans: "C",
      },
      {
        ques: " The Expenses, Profit & Loss of an organization are recorded in which account?",
        options: [
          "Current Account",
          "Personal Account",
          "Nominal Account",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " Which person owes an amount to a business organization for buying goods and services on a credit basis?",
        options: [
          "Creditors",
          "Debtors",
          "Owner",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " When are balance sheets prepared?",
        options: [
          "Quarterly",
          "Yearly",
          "Monthly",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " What is Activity Ratio?",
        options: [
          "Financial Ratios that measure a firm's ability to convert different accounts into a balance sheet.",
          "Ratios that measure a company's ability to pay debt obligations and its margin of safety.",
          "Financial measurements that assess the ability of a company to meet its financial obligations",
          "The ratio that evaluates the company's ability to generate income as compared to its expenses",
        ],
        ans: "A",
      },
      {
        ques: " What is Current Liability?",
        options: [
          "Assets of a company that are expected to be sold or used as a result of standard business operations over the next year.",
          "A potential liability that may occur in the future",
          "Company's short-term financial obligations that are due within one year or within a normal operating cycle.",
          "Obligations listed on the balance sheet not due for more than a year.",
        ],
        ans: "C",
      },
      {
        ques: " What is an operating cycle?",
        options: [
          "A metric that expresses the time (measured in days) it takes for a company to convert its investments in inventory and other resources into cash flows from sales.",
          "The time it takes a company to buy goods, sell them and receive cash from the sale of said goods.",
          "The process of hiring personnel to conduct the daily operations of the business.",
          "Collective process of identifying, analysing, and recording the accounting events of a company.",
        ],
        ans: "B",
      },
      {
        ques: " What are Outstanding Expenses?",
        options: [
          "Expenses which are not paid off in the current balance sheet",
          "The necessary purchases that keep a business going from day-to-day",
          "Type of expense that is due but has not been paid",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " What is Bank Overdraft?",
        options: [
          "A document used by a company's accounts payable department containing the supporting documents for an invoice.",
          "A negotiable instrument where payment is guaranteed by the issuing bank",
          "A negotiable instrument similar to a bill of exchange",
          "A line of credit that covers your transactions if your bank account balance drops below zero.",
        ],
        ans: "D",
      },
      {
        ques: " Which Ratio protects the Creditors?",
        options: [
          "Lower Debt Equity Ratio",
          "Liquidity Assets",
          "Higher Inventory Ratio",
          "Return on Investment Ratio",
        ],
        ans: "A",
      },
      {
        ques: " Return on Investment =?",
        options: [
          "Net Sales x 100",
          "Fixed Assets x 100",
          "Total Assets x 100",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " What does improper utilization of resources and over investment in assets indicate?",
        options: [
          "Low Return on Investment",
          "Low Sales",
          "High Sales",
          "Depreciation of Assets",
        ],
        ans: "A",
      },
      {
        ques: " Which budget is prepared for Advertising, Salary, and Market Analysis?",
        options: [
          "Operating Budget",
          "Sales Expenditure Budget",
          "Static Budget",
          "Labor Budget",
        ],
        ans: "B",
      },
      {
        ques: " What does a master budget include?",
        options: [
          "Material Budget",
          "Production Budget",
          "Sales Budget",
          "All of the above",
        ],
        ans: "D",
      },
      {
        ques: " Transaction is referred to as which event in accounting?",
        options: [
          "Political Event",
          "Economic Event",
          "Dividend",
          "Cash Transaction",
        ],
        ans: "B",
      },
      {
        ques: " Recording financial transactions is part of?",
        options: [
          "Accounting",
          "Bookkeeping",
          "Data Entry",
          "Journal",
        ],
        ans: "B",
      },
      {
        ques: " Examining of financial information refers to?",
        options: [
          "Analysis",
          "Auditing",
          "Recording",
          "Balance Sheet",
        ],
        ans: "B",
      },
      {
        ques: " Who is an external user of financial statements?",
        options: [
          "Shareholders",
          "CEO",
          "Manager",
          "Creditor",
        ],
        ans: "D",
      },
      {
        ques: " Identifying an economic transaction is which phase of the accounting cycle?",
        options: [
          "First",
          "Second",
          "Third",
          "Last",
        ],
        ans: "A",
      },
      {
        ques: " What is a Company?",
        options: [
          "Legal entity organized and operated for a collective, public or social benefit, in contrast with an entity that operates as a business aiming to generate a profit for its owners.",
          "Business organization which is owned and carried on jointly by the members of the Hindu Undivided Family.",
          "Unincorporated business with only one owner who pays personal income tax on profits earned.",
          "A legal entity formed by a group of individuals to engage in and operate a business.",
        ],
        ans: "D",
      },
      {
        ques: " What are Liabilities?",
        options: [
          "Resources of a Company",
          "Expenses of a Company",
          "Obligations of a Company",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " What is an Income?",
        options: [
          "A resource with economic value that an individual, corporation, or country owns or controls with the expectation that it will provide a future benefit.",
          "Obligation between one party and another not yet completed or paid for.",
          "Earning from investments and other sources unrelated to employment.",
          "The revenue a business earns from selling its goods and services",
        ],
        ans: "D",
      },
      {
        ques: " An Asset possessed by the business should be shown in which part of balance sheet?",
        options: [
          "Liabilities",
          "Assets",
          "Revenues",
          "Fixed Assets",
        ],
        ans: "B",
      },
      {
        ques: " What is the common characteristic of all the assets owned by a company?",
        options: [
          "Intangible",
          "Long Life",
          "Future Economic Benefits",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " Capital + Liabilities = ?",
        options: [
          "Revenue",
          "Assets",
          "Unearned Income",
          "Voucher",
        ],
        ans: "B",
      },
      {
        ques: " Owner's claim on total assets is?",
        options: [
          "Liability",
          "Assets",
          "Equity",
          "Cash",
        ],
        ans: "C",
      },
      {
        ques: " What are Drawings?",
        options: [
          "Accounting method used to allocate the cost of a tangible or physical asset over its useful life or life expectancy.",
          "The amount of cash that a business disburses",
          "An increase in the value of an asset over time",
          "Money that is taken from the business account for personal use",
        ],
        ans: "D",
      },
      {
        ques: " What occurs when expenses are greater than income?",
        options: [
          "Net Loss",
          "Net Profit",
          "Debts",
          "Decrease in Assets",
        ],
        ans: "A",
      },
      {
        ques: " What is a nominal account?",
        options: [
          "An interest-bearing deposit account held at a bank or other financial institution",
          "The account that represents a country's imports and exports of goods and services.",
          "The account in which accounting transactions are stored for one fiscal year.",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " What is a Real Account?",
        options: [
          "An interest-bearing deposit account held at a bank or other financial institution",
          "The account that represents a country's imports and exports of goods and services.",
          "The account in which accounting transactions are stored for one fiscal year.",
          "An account that retains and rolls forward its ending balance at the end of the year.",
        ],
        ans: "D",
      },
      {
        ques: " When does the capital of a company increases?",
        options: [
          "Drawings Decrease",
          "Liabilities Decrease",
          "Revenue Increases",
          "Interest on capital increases",
        ],
        ans: "C",
      },
      {
        ques: " Revenue - Expenses =?",
        options: [
          "Net Income",
          "Net Loss",
          "Depreciation",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " What causes the decrease in Assets?",
        options: [
          "Cash Purchases",
          "Liabilities",
          "Payment of Expenses",
          "Retained Earnings",
        ],
        ans: "C",
      },
      {
        ques: " Assets - Liabilities = ?",
        options: [
          "Income",
          "Gains",
          "Earnings",
          "Capital",
        ],
        ans: "D",
      },
      {
        ques: " What increases the Assets & Equity?",
        options: [
          "Fresh Capital",
          "Debts paid off",
          "Revenues",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " What is the book of original entries?",
        options: [
          "Voucher Book",
          "General Journal",
          "Day Book",
          "Account Statement",
        ],
        ans: "B",
      },
      {
        ques: " How many accounts are affected by a business transaction?",
        options: [
          "One",
          "Two",
          "Three",
          "Several",
        ],
        ans: "B",
      },
      {
        ques: " Day book is also known as?",
        options: [
          "Journal",
          "Voucher",
          "Ledger",
          "Book Keeping",
        ],
        ans: "A",
      },
      {
        ques: " What is a compound entry?",
        options: [
          "An entry involving more than two accounts.",
          "Accounting entry in which just one account is debited and one is credited.",
          "An accounting entry in which there is more than one debit, more than one credit, or more than one of both debits and credits.",
          "Entry that is used to record a business transaction in the accounting records of a business.",
        ],
        ans: "C",
      },
      {
        ques: " Purchases are Debited when?",
        options: [
          "Goods are purchased on cash",
          "Goods are sold at a discount",
          "Goods are purchased on credit",
          "Goods are lost",
        ],
        ans: "C",
      },
      {
        ques: " When are Drawing Debited?",
        options: [
          "Cash withdrawn for personal use",
          "Cash withdrawn for payment of goods",
          "Cash withdrawn for salary",
          "Octroi",
        ],
        ans: "A",
      },
      {
        ques: " In what order data is entered into the Journal?",
        options: [
          "Chronological Order",
          "Numeric Order",
          "Alphabetically",
          "Bullets",
        ],
        ans: "A",
      },
      {
        ques: " Cash Account is credited when?",
        options: [
          "Debts paid off",
          "Building bought in cash",
          "Asset sold for cash",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " What is the main objective of financial accounting?",
        options: [
          "Profit and loss account only",
          "Balance sheet only",
          "Both P & L account and balance sheet",
          "Trial balance only",
        ],
        ans: "C",
      },
      {
        ques: " According to which concept the owners are considered to be different from the business",
        options: [
          "Business entity concept",
          "Going concern concept",
          "Money measurement concept",
          "Accounting period concept",
        ],
        ans: "A",
      },
      {
        ques: " According to which concept all the transactions are measurable in the terms of money are recorded in accounts?",
        options: [
          "Business entity concept",
          "Going concern concept",
          "Money measurement concept",
          "Accounting period concept",
        ],
        ans: "C",
      },
      {
        ques: " While putting the value or price of an entity in financial records the lowest price is recorded not the current price or current market value. This is known as",
        options: [
          "Business Entity Concept",
          "Conservatism",
          "Expenditure Concept",
          "Money Measurement Concept",
        ],
        ans: "B",
      },
      {
        ques: " Which of the following is related to Scientific system of maintaining account?",
        options: [
          "Single entry",
          "Double entry",
          "Contra entry",
          "Book entry",
        ],
        ans: "B",
      },
      {
        ques: " Which of the following defines outstanding salary account?",
        options: [
          "Natural personal account",
          "Artificial personal account",
          "Representative personal account",
          "Real account",
        ],
        ans: "C",
      },
      {
        ques: " Debit the receiver, credit the giver is rule for",
        options: [
          "Personal account",
          "Tangible real account",
          "Nominal account",
          "Representative personal account",
        ],
        ans: "A",
      },
      {
        ques: " Interest earned is a",
        options: [
          "Personal account",
          "Real account",
          "Nominal account",
          "Intangible real account",
        ],
        ans: "C",
      },
      {
        ques: " Investment is a",
        options: [
          "Personal account",
          "Real account",
          "Nominal account",
          "Representative personal account",
        ],
        ans: "B",
      },
      {
        ques: " Trading account is a",
        options: [
          "Personal account",
          "Real account",
          "Nominal account",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " Profit and loss account starts with",
        options: [
          "Net profit",
          "Net loss",
          "Gross profit",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " All revenue incomes are credited to",
        options: [
          "Manufacturing account",
          "Trading account",
          "Profit and loss account",
          "None of the above",
        ],
        ans: "C",
      },
      {
        ques: " In profit and loss account, if debit is more than the credit, the difference is",
        options: [
          "Net profit",
          "Net loss",
          "Gross profit",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " Assets which are acquired and held permanently and used in the business with the objective of making profits is known as",
        options: [
          "Fixed assets",
          "Current assets",
          "Intangible assets",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " Which of the following assets have definite physical share or identity and existence?",
        options: [
          "Fictitious assets",
          "Current assets",
          "Tangible assets",
          "Intangible assets",
        ],
        ans: "C",
      },
      {
        ques: " Outstanding expenses is a",
        options: [
          "Liability",
          "Income",
          "Asset",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " Interest on capital is a business can be defined as",
        options: [
          "Expense",
          "Income",
          "Liability",
          "Asset",
        ],
        ans: "A",
      },
      {
        ques: " The ratios which reflect managerial efficiency in handling the assets is",
        options: [
          "Turnover ratios",
          "Profitability ratios",
          "Short term solvency ratio",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " Interest on capital is added with",
        options: [
          "Capital",
          "Profit",
          "Loss",
          "Donation",
        ],
        ans: "A",
      },
      {
        ques: " The ratios which reveal the final result of the managerial policies and performance is",
        options: [
          "Turnover ratios",
          "Profitability ratios",
          "Long term solvency ratio",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " Which of the following matches with fixed assets ratio?",
        options: [
          "Short-term solvency ratio",
          "Long-term solvency ratio",
          "Profitability ratio",
          "Turnover ratio",
        ],
        ans: "B",
      },
      {
        ques: " The ratio which determines the profitability from the shareholders point of view is",
        options: [
          "Return on investment",
          "Gross profit ratio",
          "Return on shareholders funds",
          "Operating profit ratio",
        ],
        ans: "C",
      },
      {
        ques: " The ratio which shows the proportion of profits retained in the business out of the current year profits is",
        options: [
          "Retained earnings ratio",
          "Pay out ratio",
          "Earnings per share",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " How the dividend is related to the market value of shares?",
        options: [
          "Interest coverage ratio",
          "Fixed dividend coverage ratio",
          "Debt service coverage ratio",
          "Dividend yield ratio",
        ],
        ans: "D",
      },
      {
        ques: " Which ratio is calculated to ascertain the efficiency of inventory management in terms of capital investment?",
        options: [
          "Stock velocity ratio",
          "Debtors velocity ratio",
          "Creditors velocity ratio",
          "Working capital turnover ratio",
        ],
        ans: "A",
      },
      {
        ques: " Which ratio measures the number of times the receivables are rotated in a year in terms of sales?",
        options: [
          "Stock turnover ratio",
          "Debtors turnover ratio",
          "Creditors velocity ratio",
          "Working capital turnover ratio",
        ],
        ans: "B",
      },
      {
        ques: " Current assets - current liabilities =",
        options: [
          "Fixed capital",
          "Working capital",
          "Opening capital",
          "Closing capital",
        ],
        ans: "B",
      },
      {
        ques: " The ratio establishes the relationship between fixed assets and long-terms funds is",
        options: [
          "Current ratio",
          "Fixed assets ratio",
          "Fixed assets turnover ratio",
          "Debt equity ratio",
        ],
        ans: "B",
      },
      {
        ques: " A high capital gearing ratio indicates",
        options: [
          "Under capitalization",
          "Over capitalization",
          "Borrowed capital",
          "Long term funds",
        ],
        ans: "A",
      },
      {
        ques: " Low turnover of stock ratio indicates",
        options: [
          "Solvency position",
          "Monopoly situation",
          "Overinvestment in inventory",
          "Liquidity position",
        ],
        ans: "C",
      },
      {
        ques: " All those assets which are converted into cash in the normal course of business within one year are known as",
        options: [
          "Fixed assets",
          "Current assets",
          "Fictitious assets",
          "Wasting assets",
        ],
        ans: "B",
      },
      {
        ques: " Any transaction between a current account and another current account does not affect",
        options: [
          "Profit",
          "Funds",
          "Working capital",
          "Capital",
        ],
        ans: "B",
      },
      {
        ques: " Decrease in current asset",
        options: [
          "Increases working capital",
          "Decreases working capital",
          "Decrease fixed capital",
          "Increase fixed capital",
        ],
        ans: "B",
      },
      {
        ques: " Decrease in current liability",
        options: [
          "Increases working capital",
          "Decreases working capital",
          "Decreases fixed capital",
          "Increases fixed capital",
        ],
        ans: "A",
      },
      {
        ques: " Profit on sale of fixed assets is",
        options: [
          "Non trading income",
          "Operating income",
          "Non trading gains",
          "Long term gain",
        ],
        ans: "C",
      },
      {
        ques: " In funds flow statement, funds from operations is",
        options: [
          "Application of fund",
          "Source of cash",
          "Application of cash",
          "Source of fund",
        ],
        ans: "D",
      },
      {
        ques: " In funds flow statement, outflow of funds on account of operations is",
        options: [
          "Application of fund",
          "Source of cash",
          "Application of cash",
          "Source of fund",
        ],
        ans: "A",
      },
      {
        ques: " In funds flow statement, repayment of long-term loans is",
        options: [
          "Application of fund",
          "Source of cash",
          "Application of cash",
          "Source of fund",
        ],
        ans: "A",
      },
      {
        ques: " In adjusted P & L account, depreciation on fixed assets will be",
        options: [
          "Debited",
          "Credited",
          "Ignored",
          "Deducted",
        ],
        ans: "A",
      },
      {
        ques: " Cash flow analysis is based on the",
        options: [
          "Capital",
          "Fixed assets",
          "Cash concept of funds",
          "Working capital",
        ],
        ans: "C",
      },
      {
        ques: " In cash flow statement, increase in current asset",
        options: [
          "Increases cash",
          "Decreases cash",
          "Increases working capital",
          "Decreases working capital",
        ],
        ans: "B",
      },
      {
        ques: " In cash flow statement, opening balances of bank balance is posted in which side of the statement",
        options: [
          "Sources of cash",
          "Application of cash",
          "Sources of funds",
          "Application of funds",
        ],
        ans: "A",
      },
      {
        ques: " In cash flow statement, closing balances of bank balance is posted in which side of the statement",
        options: [
          "Sources of cash",
          "Application of cash",
          "Sources of funds",
          "Application of funds",
        ],
        ans: "B",
      },
      {
        ques: " Production cost under marginal costing includes",
        options: [
          "Prime cost only",
          "Prime cost and fixed overhead",
          "Prime cost and variable overhead",
          "Prime cost, variable overhead and fixed overhead",
        ],
        ans: "C",
      },
      {
        ques: " Contribution margin is equal to",
        options: [
          "Fixed cost - loss",
          "Profit + variable cost",
          "Sales - fixed cost - profit",
          "Sales - profit",
        ],
        ans: "A",
      },
      {
        ques: " P/V Ratio is an indicator of",
        options: [
          "The rate at which goods are sold",
          "The volume of sales",
          "The volume of profit",
          "The rate of profit",
        ],
        ans: "D",
      },
      {
        ques: " An increase in variable costs",
        options: [
          "Increases p/v ratio",
          "Increases the profit",
          "Reduces contribution",
          "Increases margin of safety",
        ],
        ans: "C",
      },
      {
        ques: " CVP analysis is most important for the determination of",
        options: [
          "Sales revenue necessary to equal fixed costs",
          "Relationship between revenues and costs at various levels of operations",
          "Variable revenues necessary to equal fixed costs",
          "Volume of operations necessary to Break-even",
        ],
        ans: "A",
      },
      {
        ques: " __is the art of recording, classifying, and summarizing in a significant manner, and in terms of money transactions and events which are in part at least, of a financial character and interpreting the results thereof.",
        options: [
          "Journal Entry",
          "Ledger",
          "Accounting",
          "None",
        ],
        ans: "C",
      },
      {
        ques: " Any form of accounting which enables a business to be conducted more efficiently can be regarded as ___ accounting.",
        options: [
          "Financial",
          "Management",
          "Cost",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " Inflation accounting is also called __",
        options: [
          "Revaluation",
          "Replacement",
          "None",
          "All",
        ],
        ans: "B",
      },
      {
        ques: " A process of accounting that recognizes the impact of transactions on the financial statements in the time periods when revenues and expenses occur instead of when cash is received or disbursed is called ___ basis",
        options: [
          "Accrual",
          "Cash",
          "None",
          "A & b",
        ],
        ans: "A",
      },
      {
        ques: " A process of accounting where revenue and expense recognition would occur when cash is received and disbursed is called __",
        options: [
          "Cash",
          "Accrual",
          "None",
          "A & b",
        ],
        ans: "A",
      },
      {
        ques: " ___ they are the present obligations arising from past events. It also arises when an asset is created or acquired",
        options: [
          "Asset",
          "Liabilities",
          "Equity",
          "All",
        ],
        ans: "B",
      },
      {
        ques: " ___ is an increase in economic benefits during the accounting period in the form of inflows or enhancements of assets or a decrease in liabilities, thereby increasing equity and net worth.",
        options: [
          "Income",
          "Equity",
          "Expenses",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " __ is a residual interest in the assets after deducting liabilities",
        options: [
          "Income",
          "Equity",
          "Expenses",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " ___ concept implies that each transaction and event must be expressible in monetary terms",
        options: [
          "Money measurement",
          "Business Entity",
          "Going Concerned",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " ___ implies that a business unit is separate and distinct from the person who owns or controls it",
        options: [
          "Money measurement",
          "Business Entity",
          "Going Concerned",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " __concept tells that to recognize revenue it has to be realized.",
        options: [
          "Accrual concept",
          "Matching concept",
          "Realization concept",
          "None",
        ],
        ans: "C",
      },
      {
        ques: " The conventions, concepts, rules, and procedures that together make up accepted accounting practice at any given time are called ___",
        options: [
          "AICPA",
          "GAAP",
          "GAPA",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " ___ is the after-tax cash flow generated by a business minus the cost of the capital it has deployed to generate that cash flow.",
        options: [
          "EVA",
          "GAAP",
          "AICPA",
          "None of the above",
        ],
        ans: "A",
      },
      {
        ques: " Prepaid Insurance A/cis ___ A/c",
        options: [
          "Real",
          "Personal",
          "Nominal",
          "None",
        ],
        ans: "C",
      },
      {
        ques: " Outstanding wages A/c is ___ A/c",
        options: [
          "Personal",
          "Real",
          "Nominal",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " Bad Debts A/c is ___ A/c",
        options: [
          "Real",
          "Personal",
          "Nominal",
          "None",
        ],
        ans: "C",
      },
      {
        ques: " The___ accounting system provides a system of checks and balances",
        options: [
          "Single Entry",
          "Financial Entry",
          "Double Entry",
          "Triple Entry",
        ],
        ans: "C",
      },
      {
        ques: " It means that cash is received by the business from the proprietor. It results in the immediate receipt of cash",
        options: [
          "Antony commenced business with Rs 10,000",
          "Bought goods for cash rs 2,000",
          "Charged commission to Chander rs 100",
          "Sold goods for cashrs1000",
        ],
        ans: "A",
      },
      {
        ques: " Debt may be of ___ types",
        options: [
          "3",
          "2",
          "5",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " Creditor may be ___ types",
        options: [
          "3",
          "2",
          "4",
          "5",
        ],
        ans: "C",
      },
      {
        ques: " ___ is a brief explanation to a journal entry, given below the journal entry, within brackets",
        options: [
          "Narration",
          "Ledger",
          "Credit",
          "Debit",
        ],
        ans: "A",
      },
      {
        ques: " ___ is written in a ledger A/c at the time of its closing to indicate that the balance in that A/c has been carried down to the next period.",
        options: [
          "c/f",
          "c/d",
          "b/d",
          "b/f",
        ],
        ans: "B",
      },
      {
        ques: " A cash book, which is used to record both cash and bank transactions, is referred to as a ___ column cash book.",
        options: [
          "1",
          "2",
          "3",
          "4",
        ],
        ans: "B",
      },
      {
        ques: " ___ provides internet technology to navigate the vast resources available in the market.",
        options: [
          "WWW",
          "Internet",
          "Intranet",
          "None",
        ],
        ans: "A",
      },
      {
        ques: " ___ are devices that allow direct data entry into the computer without doing any manual",
        options: [
          "Standard Programmes",
          "Scanner",
          "Flow Charts",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " ___ helps in conducting business electronically with the help of internet technology.",
        options: [
          "Parity Bit",
          "E-Commerce",
          "Computer Checks",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " Mines quarries is the example of ___ assets",
        options: [
          "Fictitious assets",
          "Liquid assets",
          "Intangible assets",
          "Wasting assets",
        ],
        ans: "D",
      },
      {
        ques: " Which of the following accounts is prepared to find out the cost of production?",
        options: [
          "Manufacturing account",
          "Trading account",
          "Profit and Loss account",
          "Balance Sheet",
        ],
        ans: "A",
      },
      {
        ques: " Sale proceeds of fixed assets are a",
        options: [
          "Capital profit",
          "Revenue profit",
          "Capital receipt",
          "Revenue receipt",
        ],
        ans: "C",
      },
      {
        ques: " Preliminary expenses are an example of",
        options: [
          "Capital expenditure",
          "Revenue expenditure",
          "Deferred Revenue expenditure",
          "Capital loss",
        ],
        ans: "C",
      },
      {
        ques: " Petty Cash Book is prepared to",
        options: [
          "Meet the requirements of small business",
          "Meet the small expenses paid in cash",
          "Cover small and miscellaneous incomes",
          "All of the above",
        ],
        ans: "D",
      },
      {
        ques: " Who draws B/E?",
        options: [
          "Debtor",
          "Bank",
          "Fixed asset",
          "Creditor",
        ],
        ans: "A",
      },
      {
        ques: " Commerce is __",
        options: [
          "Industry and trade",
          "Trade and aids to trade",
          "Industry and business",
          "Industry and aids to trade",
        ],
        ans: "B",
      },
      {
        ques: " In the double-entry system, every business transaction affects",
        options: [
          "Two sides of the same account",
          "The same side of two accounts",
          "Two accounts only",
          "Two or more accounts",
        ],
        ans: "C",
      },
      {
        ques: " The concept of conservatism takes into account",
        options: [
          "All expected income and losses",
          "All expected income but no losses",
          "All expected losses but no expected income",
          "None",
        ],
        ans: "C",
      },
      {
        ques: " Financial position of the business means",
        options: [
          "Position about profits and losses",
          "Position about incomes and expenses",
          "Position about liquid funds",
          "What the business owes to others and what it owns at a given point in time",
        ],
        ans: "D",
      },
      {
        ques: " A Bank Reconciliation Statement is a",
        options: [
          "Part of Pass Book",
          "Part of Cash Book",
          "Statement prepared by the bank",
          "Statement prepared by the customer",
        ],
        ans: "D",
      },
      {
        ques: " Income from the sale of scrap is usually shown in",
        options: [
          "Profit and Loss account",
          "Trading account",
          "Manufacturing account",
          "A11 of the above",
        ],
        ans: "A",
      },
      {
        ques: " Sales to Ram Rs. 450 posted to his account as Rs. 550 would affect",
        options: [
          "Sales account",
          "Ram’s account",
          "Cash account",
          "None",
        ],
        ans: "B",
      },
      {
        ques: " Trial Balance contains the balances of",
        options: [
          "All real and personal accounts",
          "All personal and nominal accounts",
          "All nominal and real accounts",
          "All accounts",
        ],
        ans: "A",
      },
      {
        ques: " The book value of old furniture was Rs. 1,500. It was sold for Rs. 500. The difference is a ___",
        options: [
          "Revenue expenditure",
          "Revenue loss",
          "Capital expenditure",
          "Capital loss",
        ],
        ans: "D",
      },
      {
        ques: " Which of the following expenses will be shown in the Profit and Loss Account?",
        options: [
          "Wages",
          "Carriage inwards",
          "None of the above",
          "Both (a) and (b) above",
        ],
        ans: "B",
      },
      {
        ques: " Overcasting of Purchases Journal would affect",
        options: [
          "Sales Account only",
          "Purchases Account only",
          "Supplier’s Account and Purchases Account",
          "Sales Account and Purchases Account",
        ],
        ans: "C",
      },
      {
        ques: " Salary paid to Rehman was recorded in the Cashbook as payment to Rehman. This is an error of",
        options: [
          "Omission",
          "Commission",
          "Compensating error",
          "Principle",
        ],
        ans: "D",
      },
      {
        ques: " According to the money measurement concept, which transaction will be recorded in the books of the account?",
        options: [
          "Health of manager",
          "Quality of goods",
          "Value of building",
          "Qualifications of employees",
        ],
        ans: "C",
      },
      {
        ques: " Revenue is said to be realized when",
        options: [
          "The sale is made",
          "Goods are manufactured",
          "Cash is received",
          "Sale is made and cash is received",
        ],
        ans: "C",
      },
      {
        ques: " Discount allowed on the issue of shares is an example of",
        options: [
          "Capital loss",
          "Capital expenditure",
          "Revenue expenditure",
          "Deferred revenue expenditure",
        ],
        ans: "B",
      },
      {
        ques: " Repair incurred for using a second-hand car purchased recently",
        options: [
          "Capital expenditure",
          "Revenue expenditure",
          "Capital loss",
          "Deferred revenue expenditure",
        ],
        ans: "A",
      },
      {
        ques: " Sales are equal to",
        options: [
          "Cost of Goods Sold + Gross Profit",
          "Cost of Goods Sold – Gross Profit",
          "Gross Profit – Cost of Goods Sold",
          "Cost of Goods Sold – Stock",
        ],
        ans: "A",
      },
      {
        ques: " Interest in drawings is regarded as",
        options: [
          "Expenditure for the business",
          "Loss for the business",
          "Gain for the business",
          "Liability for the business",
        ],
        ans: "C",
      },
      {
        ques: " Accounting provides data or information on",
        options: [
          "Income and cost for the managers",
          "Financial conditions of the institutions",
          "Company’s tax liability for a particular year",
          "All the above",
        ],
        ans: "D",
      },
      {
        ques: " Long term assets without any physical existence but, possessing a value are called",
        options: [
          "Intangible assets",
          "Fixed assets",
          "Current assets",
          "Investments",
        ],
        ans: "A",
      },
      {
        ques: " The assets that can be easily converted into cash within a short period, i.e., 1 year or less are known as",
        options: [
          "Current assets",
          "Fixed assets",
          "Intangible assets",
          "Investments",
        ],
        ans: "A",
      },
      {
        ques: " Copyrights, Patents and Trademarks are examples of",
        options: [
          "Current assets",
          "Fixed assets",
          "Intangible assets",
          "Investments",
        ],
        ans: "C",
      },
      {
        ques: " The debts which are to be repaid within a short period (a year or less) are referred to as",
        options: [
          "Current Liabilities",
          "Fixed liabilities",
          "Contingent liabilities",
          "All the above",
        ],
        ans: "A",
      },
      {
        ques: " Gross profit is",
        options: [
          "Cost of goods sold + Opening stock",
          "Sales – cost of goods sold",
          "Sales – Purchases",
          "Net profit – expenses",
        ],
        ans: "B",
      },
      {
        ques: " Net profit is calculated in which of the following accounts?",
        options: [
          "Profit and loss account",
          "Balance sheet",
          "Trial balance",
          "Trading account",
        ],
        ans: "A",
      },
      {
        ques: " In order to find out the value of the closing stock during the end of the financial year we,",
        options: [
          "Do this by stocktaking",
          "Deduct the cost of goods sold from sales",
          "Deduct opening stock from the cost of goods sold",
          "Look in the stock account",
        ],
        ans: "A",
      },
      {
        ques: " Which of these best explains fixed assets?",
        options: [
          "Are bought to be used in the business",
          "Are expensive items bought for the business",
          "Are items which will not wear out quickly",
          "Are of long life and are not purchased specifically for resale",
        ],
        ans: "D",
      },
      {
        ques: " The charges of placing commodities into a saleable condition should be charged to",
        options: [
          "Trading account",
          "P & L a/c",
          "Balance Sheet",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " Suppliers personal a/c are seen in the",
        options: [
          "Sales Ledger",
          "Nominal ledger",
          "Purchases Ledger",
          "General Ledger",
        ],
        ans: "C",
      },
      {
        ques: " If you want to ensure that your money will be secured if cheques sent are wasted in the post, you should",
        options: [
          "Always pay by cash",
          "Cross your Cheques ‘Account Payee only, Not Negotiable.",
          "Always get the money in person",
          "Not use the postal service in future",
        ],
        ans: "B",
      },
      {
        ques: " Discounts received are",
        options: [
          "Buyer of goods granted discount by seller",
          "Deducted when we receive cash",
          "Given by us when we sell goods on credit",
          "None of these",
        ],
        ans: "A",
      },
      {
        ques: " Sales invoices are first entered in",
        options: [
          "The Cash Book",
          "The Purchases Journal",
          "The Sales Journal",
          "The Sales Account",
        ],
        ans: "C",
      },
      {
        ques: " Entered in the Purchases Journal are",
        options: [
          "Discounts received",
          "Purchases invoices",
          "Payments to suppliers",
          "Trade discounts",
        ],
        ans: "B",
      },
      {
        ques: " At the balance sheet date, the balance on the Accumulated Provision for Depreciation Account is",
        options: [
          "Simply deducted from the asset in the Balance Sheet",
          "Transferred to Profit and Loss Account",
          "Transferred to the Asset Account",
          "Transferred to Depreciation Account",
        ],
        ans: "A",
      },
      {
        ques: " If we take goods for own use we should",
        options: [
          "Debit Drawings Account, Credit Purchases Account",
          "Debit Drawings Account: Credit Stock Account",
          "Debit Sales Account: Credit Stock Account",
          "Debit Purchases Account: Credit Drawings Account",
        ],
        ans: "A",
      },
      {
        ques: " When a petty cash book is kept there will be",
        options: [
          "No entries made at all in the general ledger for items paid by petty cash",
          "The same number of entries in the general ledger",
          "Fewer entries made in the general ledger",
          "More entries made in the general ledger",
        ],
        ans: "C",
      },
      {
        ques: " If a trial balance totals do not agree, the difference must be entered in",
        options: [
          "The Profit and Loss Account",
          "A Nominal Account",
          "The Capital Account",
          "A Suspense Account",
        ],
        ans: "D",
      },
      {
        ques: " If it is required to maintain fixed capitals then the partners’ shares of profits must be",
        options: [
          "Credited to capital accounts",
          "Debited to capital accounts",
          "Debited to partners’ current accounts",
          "Credited to partners’ current accounts",
        ],
        ans: "D",
      },
      {
        ques: " The primary qualities that make accounting information useful for decision making are _______",
        options: [
          "Relevance and freedom from bias",
          "Reliability and comparability",
          "Comparability and consistency",
          "None of the above",
        ],
        ans: "B",
      },
      {
        ques: " The amount drawn by a businessman for his personal use is ____",
        options: [
          "Capital",
          "Drawing",
          "Expenditure",
          "Loss",
        ],
        ans: "B",
      },

]

export default _accounts