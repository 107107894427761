import { useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "./LoadingSpinner";
import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../images/Microsoft_Office_Excel_(2019–present).svg.png";
import {
  db,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteDoc,
  storage,
  ref,
  getDownloadURL,
  query,
  where,
} from "./Firebase";

import { useNavigate } from "react-router-dom";

const UserData = ({ currentUserEmail }) => {
  let [userData, setUserData] = useState([]);
  const [display, setDisplay] = useState(0);
  const [blur, setBlur] = useState("0px");
  const [loadingBlur, setLoadingBlur] = useState("0px");
  const [scale, setScale] = useState(1);
  const [SeprateData, setSeprateData] = useState({});
  const [err, Seterr] = useState("");
  const [zIndex, setZindex] = useState("-2");
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState("");
  const [rejectButton, setRejectButton] = useState(true);
  const [allUsers, setAllUsers] = useState();
  const [scoreDisplay, setScoreDisplay] = useState(0);
  const [scoreZIndex, setscoreZIndex] = useState("-2");
  const [databaseScore, setDatabaseScore] = useState([]);
  const [userCounter, setuserCounter] = useState("0");
  const [rejectAllAuth, setRejectAllAuth] = useState(false);
  const [formData, SetFormData] = useState({
    reason: "",
    type: "All",
    field: "Accounts",
    filterName: "",
    applyMarks: "",
    customReason: "",
    sort: "sort",
  });

  let red = "";

  const now = new Date();
  const dayOfWeek = now.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6

  const daysUntilNextMonday = 8 - 3;
  console.log(daysUntilNextMonday);
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  const millisecondsUntilNextMonday = daysUntilNextMonday * millisecondsPerDay;
  console.log(millisecondsUntilNextMonday);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let tStamp = Date.parse(formData.date);
    let date = new Date(tStamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    // let d = new Date();
    // let dateEligibility = Date.parse(d);
    // let futureDateEligibility = dateEligibility + 259200000;
    if (hours === 17 && minutes > 0) {
      hours = hours + 1;
    }

    let day = date.getDay();
    const element = [];
    let count = 0;
    if (currentUserEmail === "gurvinder@alphanumericideas.com") {
      if (day !== 0) {
        if (hours <= 17 && hours > 9) {
          const docRef = doc(db, "interview-Timings", "All-Timings");
          const docSnap = await getDoc(docRef);
          for (let index = 0; index < docSnap.data().time.length; index++) {
            let getTimeStamp = docSnap.data().time[index];
            element.push(getTimeStamp);
          }
          for (let index = 0; index < element.length; index++) {
            let eligibility = element[index] + 1800000;
            let eligibility2 = element[index] - 1800000;
            if (tStamp >= eligibility || tStamp <= eligibility2) {
              count = count + 1;
            }
          }
          if (element.length === count) {
            let json = "";
            setLoadingBlur("4px");
            setIsLoading(true);
            const attendeeEmail = SeprateData.email;
            try {
              const response = await fetch(
                "https://us-central1-alphanumeric-career.cloudfunctions.net/server/event",
                {
                  method: "POST",
                  withCredentials: false,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    calendarDate,
                    date,
                    attendeeEmail,
                  }),
                }
              );
              json = await response.json();
            } catch (error) {
              console.log(error);
            }
            if (json.msg === "Schedule successfully") {
              await updateDoc(doc(db, "interview-Timings", "All-Timings"), {
                time: arrayUnion(tStamp),
              });
              alert(json.msg);
              setIsLoading(false);
              setLoadingBlur("0px");
              const {
                name,
                email,
                experience,
                companyName,
                ctc,
                quizname,
                link,
                imgLink,
                submissionDate,
              } = SeprateData;
              await setDoc(doc(db, `Interview-Scheduled ${quizname}`, email), {
                name,
                email,
                experience,
                companyName,
                ctc,
                quizname,
                link,
                imgLink,
                submissionDate,
              });
              await deleteDoc(doc(db, quizname, email));
              if (formData.type === "All") {
                let document = `${formData.field} Internship`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                });
                document = `${formData.field} position`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                  setUserData(data);
                });
              } else {
                const document = `${formData.field} ${formData.type}`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                  setUserData(data);
                });
              }
            } else {
              alert(json.msg);
            }
            Seterr("");
          } else {
            Seterr("Try different time slot");
          }
        } else {
          Seterr("Please select time between 10 am - 5 pm");
        }
      } else {
        Seterr("Don't select sunday");
      }
    } else {
      Seterr("Invalid Credientials");
    }
  };

  let calendarDate = formData.date;
  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkData = () => {
    if (!rejectButton) {
      const filteredData = allUsers.filter((user) => {
        return user.name
          .toLowerCase()
          .includes(formData.filterName.toLowerCase());
      });
      console.log(filteredData);
      setUserData(filteredData);
    } else {
      alert("No Data");
    }
  };

  let sr = 0;
  let scoreSr = 0;
  let data = [];
  const tableRef = useRef(null);

  const allData = () => {
    setRejectButton(false);
    if (formData.type === "All") {
      let document = `${formData.field} Internship`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `${formData.field} position`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    } else {
      const document = `${formData.field} ${formData.type}`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    }
    setAllUsers(data);
  };

  const rejectAll = async (e) => {
    e.preventDefault();
    setRejectAllAuth(false);
    let { reason, customReason } = formData;
    if (reason === "") reason = customReason;
    // if (reason !== "") {
    if (formData.type === "All") {
      let document = `${formData.field} Internship`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach(async (docu) => {
          const {
            name,
            email,
            phNumber,
            experience,
            companyName,
            score,
            ctc,
            quizname,
            link,
            imgLink,
            attempts,
            submissionDate,
          } = docu.data();
          let d = submissionDate.toDate().toDateString();
          let onlyDate = new Date(d);
          onlyDate.setDate(onlyDate.getDate() + 21);
          onlyDate = onlyDate.toDateString();

          await setDoc(doc(db, `RejectedUsers ${document}`, email), {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            score,
            quizname,
            link,
            imgLink,
            attempts,
            submissionDate,
          });

          await deleteDoc(doc(db, document, email));
          data.push(docu.data());
          fetch(
            "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
            {
              method: "POST",
              mode: "cors",
              headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
              },
              body: JSON.stringify({
                name,
                email,
                quizname,
                imgLink,
                onlyDate,
                reason,
              }),
            }
          ).then((res) => {
            // setIsLoading(true);
            // setBlur("4px");
            // navigate("/quiz-result");
          });
        });
        setUserData(data);
      });
    }
    if (formData.type === "All") {
      let document = `${formData.field} position`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach(async (docu) => {
          const {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            quizname,
            link,
            score,
            imgLink,
            attempts,
            submissionDate,
          } = docu.data();
          let d = submissionDate.toDate().toDateString();
          let onlyDate = new Date(d);
          onlyDate.setDate(onlyDate.getDate() + 21);
          onlyDate = onlyDate.toDateString();

          await setDoc(doc(db, `RejectedUsers ${document}`, email), {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            quizname,
            score,
            link,
            imgLink,
            attempts,
            submissionDate,
          });

          await deleteDoc(doc(db, document, email));
          data.push(docu.data());
          fetch(
            "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
            {
              method: "POST",
              mode: "cors",
              headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
              },
              body: JSON.stringify({
                name,
                email,
                quizname,
                imgLink,
                onlyDate,
                reason,
              }),
            }
          ).then((res) => {
            // setIsLoading(true);
            // setBlur("4px");
            // navigate("/quiz-result");
          });
        });
        setUserData(data);
      });
    } else {
      let document = `${formData.field} ${formData.type}`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach(async (docu) => {
          const {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            score,
            quizname,
            link,
            imgLink,
            attempts,
            submissionDate,
          } = docu.data();
          let d = submissionDate.toDate().toDateString();
          let onlyDate = new Date(d);
          onlyDate.setDate(onlyDate.getDate() + 21);
          onlyDate = onlyDate.toDateString();

          await setDoc(doc(db, `RejectedUsers ${document}`, email), {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            score,
            quizname,
            link,
            imgLink,
            attempts,
            submissionDate,
          });

          await deleteDoc(doc(db, document, email));
          data.push(docu.data());

          fetch(
            "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
            {
              method: "POST",
              mode: "cors",
              headers: {
                "access-control-allow-origin": "*",
                "Content-type": "application/json; charset=UTF-8",
              },
              body: JSON.stringify({
                name,
                email,
                quizname,
                imgLink,
                onlyDate,
                reason,
              }),
            }
          ).then((res) => {
            // setIsLoading(true);
            // setBlur("4px");
            // navigate("/quiz-result");
          });
        });
        setUserData(data);
      });
    }
    setDisplay(0);
    setBlur("0px");
    setZindex("-2");
    setScale(1);
    // } else {
    //   alert("Select Reason");
    // }
  };

  const rejectOne = async (e) => {
    e.preventDefault();
    const rejectedIndex = localStorage.getItem("rejectedIndex");
    let index = parseInt(rejectedIndex);
    let { reason, customReason } = formData;
    if (reason === "") reason = customReason;
    // if (reason !== "") {
    const ele = userData[index];

    const {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      link,
      quizname,
      imgLink,
      attempts,
      submissionDate,
    } = ele;

    let d = submissionDate.toDate().toDateString();
    let onlyDate = new Date(d);
    onlyDate.setDate(onlyDate.getDate() + 21);
    onlyDate = onlyDate.toDateString();
    let clName = `RejectedUsers ${quizname}`;
    await setDoc(doc(db, clName, email), {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      link,
      quizname,
      imgLink,
      attempts,
      submissionDate,
    });
    await deleteDoc(doc(db, quizname, email));
    if (formData.type === "All") {
      let document = `${formData.field} Internship`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `${formData.field} position`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    } else {
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    }

    fetch(
      "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          name,
          email,
          quizname,
          imgLink,
          onlyDate,
          reason,
        }),
      }
    );
    setDisplay(0);
    setBlur("0px");
    setZindex("-2");
    setScale(1);
    alert("Rejected");
    // }

    // else {
    //   alert("Select Reason");
    // }
  };

  const applyMarks = async () => {
    if (formData.applyMarks !== "") {
      await setDoc(doc(db, "Qualified-score", formData.field), {
        field: formData.field,
        score: parseInt(formData.applyMarks),
      });
      alert("Marks Applied");
    } else {
      alert("Enter Marks");
    }
  };

  const { sort } = formData;
  if (sort === "alpha") {
    const alphaSort = userData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    userData = alphaSort;
  }

  if (sort === "score") {
    const alphaSort = userData.sort((a, b) => {
      return b.score - a.score;
    });
    userData = alphaSort;
  }

  if (sort === "date") {
    const dateSort = userData.sort((a, b) => {
      const timestampA = a.submissionDate.toMillis();
      const timestampB = b.submissionDate.toMillis();
      return timestampB - timestampA;
    });
    userData = dateSort;
  }

  const chec = (index) => {
    console.log(userData[index]);
  };

  return (
    <>
      {isLoading ? <LoadingSpinner /> : <></>}

      {/******* CUSTOM REASON ******* */}

      {/******* END CUSTOM REASON ******* */}
      <div
        className="date-container"
        style={{ zIndex: zIndex, filter: `blur(${loadingBlur})` }}
      >
        <Form
          className="back mmm reasonW"
          style={{ opacity: display, transform: `scale(${scale})` }}
        >
          <div
            className="text-center mb-3"
            style={{ fontWeight: "700", letterSpacing: "1px" }}
          >
            Are You Sure ? <br />
          </div>
          {/* <Form.Select
            aria-label="Default select example"
            name="reason"
            onChange={handleinput}
          >
            <option selected value="">
              Select Reason
            </option>
            <option value="We were unable to reach you by phone or email after several attempts, so we have decided to move forward with other candidates.">
              Unable to Contact You
            </option>

            <option value="We received many applications for this position and could only select a few candidates who best met the requirements.">
              Limited Openings
            </option>

            <option value="customReason">Custom Reason</option>
          </Form.Select> */}

          {formData.reason === "customReason" ? (
            <>
              <Form.Group className="mb-3 mt-3" controlId="formBasicText">
                <Form.Control
                  type="text"
                  placeholder="Type Reason"
                  name="customReason"
                  value={formData.customReason}
                  onChange={handleinput}
                />
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <div className="d-flex justify-content-around mt-4">
            <Button
              className="scheduleButton"
              variant="success"
              type="submit"
              onClick={(e) => (rejectAllAuth ? rejectAll(e) : rejectOne(e))}
            >
              Reject
            </Button>
            <Button
              className="cancelButton"
              variant="danger"
              onClick={(e) => {
                setDisplay(0);
                setBlur("0px");
                setZindex("-2");
                setScale(1);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="scheduleError text-center mt-3"> {err}</div>
        </Form>
      </div>

      {/* USER SCOORE START ***************** */}

      <div
        className="date-container"
        style={{
          zIndex: scoreZIndex,
          filter: `blur(${loadingBlur})`,
          width: "95vw",
          position: "absolute",
          top: "20rem",
        }}
      >
        <div
          className="mmm text-center"
          style={{
            opacity: scoreDisplay,
            transform: `scale(${scale})`,
            width: "80vw",
          }}
        >
          <Table
            striped
            bordered
            hover
            className="text-center mb-5"
            style={{ height: "50vh" }}
          >
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Field</th>
                <th>Score</th>
              </tr>
            </thead>
            {databaseScore.map((ele) => {
              scoreSr = scoreSr + 1;
              return (
                <>
                  <tbody>
                    <tr>
                      <td>{scoreSr}</td>
                      <td>{ele.field}</td>
                      <td>{ele.score}</td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </Table>

          <Button
            className="navButton"
            variant="danger"
            onClick={(e) => {
              setScoreDisplay(0);
              setBlur("0px");
              setscoreZIndex("-2");
              setScale(1);
            }}
          >
            GO back
          </Button>
        </div>
      </div>

      {/* USER SCORE END  ****************** */}

      <div style={{ filter: `blur(${blur})` }}>
        <h1 className="heading">Not Contacted : {userCounter}</h1>
        <div className="m-3" style={{ filter: `blur(${blur})` }}>
          <DownloadTableExcel
            filename={document}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className="mb-2 xlsButton">
              <img className="xls" src={xls} alt="" />{" "}
            </button>
          </DownloadTableExcel>
          <div className="row justify-content-center mb-4">
            <div className="col-md-3 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="type"
                onChange={handleinput}
              >
                <option value="All">All</option>
                <option value="Internship">Internships</option>
                <option value="position">Jobs</option>
              </Form.Select>
            </div>
            <div className="col-md-3 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="field"
                onChange={handleinput}
              >
                <option value="Accounts">Accounts</option>
                <option value="Business Developement">
                  Business Development
                </option>
                <option value="Content Writing">Content Writing</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Data Entry">Data Entry</option>
                <option value="Data Research">Data Research</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Marketing">Marketing</option>
                <option value="Online Bidding">Online Bidding</option>
                <option value="Web Development">Web Development</option>
                <option value="Check">Check</option>
              </Form.Select>
            </div>
            <div className="col-md-1 col-8 mt-2 text-center">
              <Button className="navButton" onClick={allData}>
                Search
              </Button>
            </div>

            <div className="col-md-3 col-8 mt-2 text-center">
              <Button
                disabled={rejectButton}
                className="navButton"
                onClick={() => {
                  setDisplay(1);
                  setBlur("4px");
                  setZindex("2");
                  setScale(1.1);
                  setRejectAllAuth(true);
                }}
              >
                Reject All
              </Button>
            </div>

            <div className="row justify-content-around mt-4">
              <div className="mb-3 col-md-3 col-8">
                <Form>
                  <Form.Group controlId="text">
                    <Form.Control
                      className="text-center"
                      type="text"
                      placeholder="Search By Name"
                      value={formData.filterName}
                      name="filterName"
                      onChange={handleinput}
                      onKeyUp={checkData}
                    />
                  </Form.Group>
                </Form>
              </div>

              <div className="mb-3 col-md-2 col-8">
                <Form>
                  <Form.Group controlId="validationCustom01">
                    <Form.Control
                      className="text-center"
                      required
                      type="number"
                      placeholder="Enter Marks"
                      name="applyMarks"
                      onChange={handleinput}
                      value={formData.applyMarks}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-2 col-8 text-center">
                <Form.Select
                  aria-label="Default select example"
                  name="sort"
                  onChange={handleinput}
                >
                  <option value="sort">Sort By</option>
                  <option value="date">Latest</option>
                  <option value="alpha">Name [ A - Z ]</option>
                  <option value="score">Score [ 10 - 0 ]</option>
                </Form.Select>
              </div>

              <div className="col-md-2 col-8 text-center">
                <Button className="navButton" onClick={applyMarks}>
                  Apply Marks
                </Button>
              </div>

              <div className="col-md-2 col-8 text-center">
                <Button
                  className="navButton"
                  onClick={async () => {
                    const userData = [];
                    const querySnapshot = await getDocs(
                      collection(db, "Qualified-score")
                    );
                    querySnapshot.forEach((doc) => {
                      userData.push(doc.data());
                    });
                    setDatabaseScore(userData);

                    setScoreDisplay(1);
                    setBlur("4px");
                    setscoreZIndex("2");
                    setScale(1.1);
                  }}
                >
                  Check Marks
                </Button>
              </div>
            </div>

            {/* ****** DELETE DATA ******** */}
            <div>
              <Button
                className="hdrBtn"
                onClick={async () => {
                  // ********** ONE SECTION *************
                  // getDocs(
                  //   collection(db, "RejectedUsers Human Resources  Internship")
                  // ).then((querySnapshot) => {
                  //   querySnapshot.forEach(async (docu) => {
                  //     console.log(docu.data());
                  //     await setDoc(
                  //       doc(
                  //         db,
                  //         `RejectedUsers Human Resources Internship`,`${docu.data().email}`
                  //       ),
                  //       {
                  //         name: docu.data().name,
                  //         attempts: 1,
                  //         companyName: docu.data().companyName,
                  //         ctc: docu.data().ctc,
                  //         email: docu.data().email,
                  //         experience: docu.data().experience,
                  //         imgLink:
                  //           "https://alphanumericideas.com/images/careers/human-resources.png",
                  //         link: docu.data().link,
                  //         phNumber: docu.data().phNumber,
                  //         quizname: docu.data().quizname,
                  //         score: docu.data().score,
                  //         submissionDate: docu.data().submissionDate,
                  //       }
                  //     );

                  // await deleteDoc(
                  //   doc(
                  //     db,
                  //     "RejectedUsers Human Resources  Internship",
                  //     docu.data().email
                  //   )
                  // );
                  //   });
                  // });

                  // ********** END ONE SECTION *************

                  // const upRef = doc(db, "users",
                  //   "dhruvmittal1403@gmail.com");
                  // await updateDoc(upRef, {
                  //   ["Data Research"]: false,
                  // });

                  const upRef = doc(db, "users",
                    "gurvinder@alphanumericideas.com");
                  await updateDoc(upRef, {
                    "Business Developement Internship": false,
                  });

                  // await deleteDoc(
                  //   doc(db, "users",
                  //   "mohit@alphanumericideas.com")
                  // );

                  // await setDoc(doc(db, "testCollection",
                  // "Web Development Rejected",
                  // "Position",
                  // "email@example.com"), {
                  //   name: "Dummy",
                  //   age : 29,
                  //   score : 8
                  // });
                  // const attemptDocRef = doc(db, "Online Bidding Internship", "mohit@alphanumericideas.com");

                  // dhruvmittal1403@gmail.com

                  // const docRef = doc(db, "users", "dhruvmittal1403@gmail.com");
                  // const docSnap = await getDoc(docRef);
                  // docSnap.data();
                  // console.log(docSnap.data());

                  // const q = query(
                  //   collection(db, "usersTest"),
                  //   where("link", "==", null)
                  // );

                  // const querySnapshot = await getDocs(
                  //   collection(db, "usersTest")
                  // );
                  // const querySnapshot = await getDocs(q);

                  // querySnapshot.forEach(async (docu) => {
                  //   if ("link" in docu.data()) {
                  //     console.log("exist");
                  //   } else {
                  //     console.log("Not exist");
                  //   }
                  //   const updatedDocref = doc(
                  //     db,
                  //     "RejectedUsers Web Development position",
                  //     docu.data().email
                  //   );
                  //   if (docu.data().attempts >= 1 ) {
                  //     console.log("Nothing Happen");
                  //   } else {
                  //     await updateDoc(updatedDocref, {
                  //       attempts: 1,
                  //     });
                  //   }
                  // });

                  // const updatedDocref = doc(db, "Interview-Scheduled Graphic Design Internship","anshugupta3020@gmail.com")
                  // await updateDoc(updatedDocref, {
                  //   phNumber: docSnap.data().phNumber
                  // });

                  // fetch("/monday-email", {
                  //   method: "POST",
                  //   mode: "cors",
                  //   headers: {
                  //     "access-control-allow-origin": "*",
                  //     "Content-type": "application/json; charset=UTF-8",
                  //   },
                  //   body: JSON.stringify({
                  //     name: "Run",
                  //   }),
                  // })
                  //   .then((res) => {
                  //     return res.json();
                  //   })
                  //   .then((data) => {
                  //     alert(data.msg);
                  //   });
                }}
              >
                Add User
              </Button>
              {/* <button onClick={()=>{
                fetch("/get-user", {
                  method: "POST",
                  mode: "cors",
                  headers: {
                    "access-control-allow-origin": "*",
                    "Content-type": "application/json; charset=UTF-8",
                  },
                  body: JSON.stringify({
                    name: "Run",
                  }),
                })
                  .then((res) => {
                    return res.json();
                  })
                  .then((data) => {
                    alert(data.msg);
                  });
              }}>get user</button> */}
            </div>
            {/* ********************* */}
          </div>
          <Table
            responsive
            striped
            bordered
            hover
            variant="light"
            ref={tableRef}
          >
            <thead className="QuizBtn">
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Experience</th>
                <th>Company Name</th>
                <th>CTC</th>
                <th>Score</th>
                <th>Category</th>
                <th>Submission Date</th>
                <th>Attempts</th>
                <th>Suggested Time</th>
                <th>Resume Link</th>
                <th className="text-center" colSpan="2">
                  Action
                </th>
              </tr>
            </thead>

            {userData.map((ele, index) => {
              sr++;
              {
                ele.unRejected ? (red = "red") : (red = "black");
              }
              console.log(red);
              return (
                <>
                  <tbody className="QuizBtn">
                    <tr>
                      <td style={{ color: red }}>{sr}</td>
                      <td style={{ color: red }}>{ele.name}</td>
                      <td style={{ color: red }}>{ele.email}</td>
                      <td style={{ color: red }}>{ele.phNumber}</td>
                      <td style={{ color: red }}>{ele.experience}</td>
                      <td style={{ color: red }}>{ele.companyName}</td>
                      <td style={{ color: red }}>{ele.ctc}</td>
                      <td style={{ color: red }}>{ele.score}/10</td>
                      <td style={{ color: red }}>{ele.quizname}</td>
                      <td style={{ color: red }}>
                        {ele.submissionDate.toDate().toLocaleString()}
                      </td>
                      <td style={{ color: red }}>{ele.attempts}</td>
                      <td style={{ color: red }}>{ele.suggestedInterview}</td>
                      {ele.link ? (
                        <>
                          <td style={{ cursor: "pointer" }}>
                            <a
                              style={{ color: "#139ded" }}
                              className="resumeLink"
                              href={ele.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open Resume
                            </a>
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            style={{ color: "#139ded", cursor: "pointer" }}
                            onClick={async () => {
                              let link = `gs://alphanumeric-career.appspot.com/resume/${ele.email}-resume.pdf`;
                              let nlink = link.replaceAll(" ", "");
                              const fileRef = ref(storage, nlink);
                              const downloadURL = await getDownloadURL(fileRef);
                              localStorage.setItem("url", downloadURL);
                              navigate("/resume-viewer");
                            }}
                          >
                            Open Resume
                          </td>
                        </>
                      )}

                      <td className="d-flex justify-content-around">
                        <Button
                          className="me-2"
                          variant="success"
                          onClick={async () => {
                            const {
                              name,
                              email,
                              phNumber,
                              experience,
                              companyName,
                              ctc,
                              score,
                              quizname,
                              link,
                              imgLink,
                              attempts,
                              submissionDate,
                            } = ele;
                            let d = submissionDate.toDate().toDateString();
                            let onlyDate = new Date(d);
                            onlyDate.setDate(onlyDate.getDate() + 21);
                            onlyDate = onlyDate.toDateString();
                            let clName = `Contacted ${quizname}`;
                            await setDoc(doc(db, clName, email), {
                              name,
                              email,
                              phNumber,
                              experience,
                              companyName,
                              ctc,
                              score,
                              quizname,
                              link,
                              imgLink,
                              attempts,
                              submissionDate,
                            });
                            await deleteDoc(doc(db, quizname, email));
                            if (formData.type === "All") {
                              let document = `${formData.field} Internship`;
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                }
                              );
                              document = `${formData.field} position`;
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            } else {
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            }

                            alert("Contacted");

                            // fetch(
                            //   "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
                            //   {
                            //     method: "POST",
                            //     mode: "cors",
                            //     headers: {
                            //       "access-control-allow-origin": "*",
                            //       "Content-type":
                            //         "application/json; charset=UTF-8",
                            //     },
                            //     body: JSON.stringify({
                            //       name,
                            //       email,
                            //       quizname,
                            //       imgLink,
                            //       onlyDate,
                            //     }),
                            //   }
                            // ).then((res) => {
                            // setIsLoading(true);
                            // setBlur("4px");
                            // navigate("/quiz-result");
                            // });
                          }}
                        >
                          Contacted
                        </Button>
                        <Button
                          className=""
                          variant="danger"
                          onClick={() => {
                            setDisplay(1);
                            setBlur("4px");
                            setZindex("2");
                            setScale(1.1);
                            localStorage.setItem("rejectedIndex", index);
                          }}
                        >
                          Reject
                        </Button>

                        {/* <Button
                          variant="secondary"
                          onClick={async () => {
                            const {
                              name,
                              email,
                              phNumber,
                              experience,
                              companyName,
                              ctc,
                              quizname,
                              link,
                              imgLink,
                              submissionDate,
                            } = ele;
                            await setDoc(
                              doc(db, `Selected-Users ${quizname}`, email),
                              {
                                name,
                                email,
                                experience,
                                companyName,
                                ctc,
                                quizname,
                                link,
                              }
                            );
                            await deleteDoc(doc(db, quizname, email));
                            if (formData.type === "All") {
                              let document = `${formData.field} Internship`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                }
                              );
                              document = `${formData.field} position`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            } else {
                              const document = `${formData.field} ${formData.type}`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            }
                          }}
                        >
                          Select
                        </Button> */}
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </Table>
          {!rejectButton && userData.length === 0 ? (
            <h3 className="text-center mt-4">No Data Found</h3>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default UserData;
