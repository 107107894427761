import React, { useState, useEffect } from "react";
import InternshipCard from "./InternshipCard";
import JobCard from "./JobCard";
import Form from "react-bootstrap/Form";
import { db, doc, getDoc } from "./Firebase";
import Footer from "./Footer";
import ScrollTop from "./ScrollTop";

const JobProfile = ({ user, currentUserEmail }) => {
  localStorage.removeItem("nSr");
  const [update, setUpdate] = useState("");
  const [formData, SetFormData] = useState({ field: "All" });
  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData(() => {
      <ScrollTop />;
      return { [name]: value };
    });
  };

  const Fn = async () => {
    const docRef = doc(db, "users", currentUserEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUpdate("value");
    }
  };

  Fn();

  return (
    <>
      {user ? (
        <>
          {}
          {update ? (
            <>
            <div className="navFieldDiv">
              <Form.Select
                id="selectValue"
                aria-label="Default select example"
                className="navField"
                name="field"
                onChange={handleinput}
                required
              >
                <option value="All">Job Types</option>
                <option value="All">All</option>
                <option value="Internship">Internships</option>
                <option value="Job">Jobs</option>
              </Form.Select>
              </div>
              {formData.field === "Internship" ? (
                <>
                  <div className="main">
                    <InternshipCard currentUserEmail={currentUserEmail} />
                  </div>
                </>
              ) : (
                <></>
              )}
              {formData.field === "Job" ? (
                <>
                  <div className="main">
                    <JobCard currentUserEmail={currentUserEmail} />
                  </div>
                </>
              ) : (
                <></>
              )}
              {formData.field === "All" ? (
                <>
                  <div className="main">
                    <InternshipCard currentUserEmail={currentUserEmail} />
                  </div>
                  <div className="main">
                    <JobCard currentUserEmail={currentUserEmail} />
                  </div>
                </>
              ) : (
                <></>
              )}
              <Footer />;
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default JobProfile;
