import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const SendEmails = () => {
  const [formData, SetFormData] = useState({
    subject: "",
    content: "",
  });

  const handleInput = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const { subject, content } = formData;
    if (subject && content) {
      alert("process start")
      fetch("/sendAllEmials", {
        method: "POST",
        mode: "cors",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          subject,
          content,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => alert(data.msg));
    } else {
      alert("All Fields Required");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <Form className="text-center w-50 mt-4" onSubmit={handleSubmit}>
          <Form.Group className="mb-3" controlId="formBasicText">
            <Form.Control
              type="text"
              placeholder="Enter Subject"
              name="subject"
              onChange={handleInput}
              value={formData.subject}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Control
              as="textarea"
              placeholder="Enter Content"
              style={{ height: "150px" }}
              name="content"
              onChange={handleInput}
              value={formData.content}
            />
          </Form.Group>

          <Button className="navButton" variant="primary" type="submit" onClick={handleSubmit}>
            Send to All
          </Button>
        </Form>
      </div>
    </>
  );
};

export default SendEmails;
