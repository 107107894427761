import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import Container from "react-bootstrap/Container";
import { doc, setDoc, db, storage, ref, uploadBytes } from "./Firebase";
import Footer from "./Footer";
import FormLabel from "react-bootstrap/esm/FormLabel";
import check from "../images/checkmark.png";
import { useNavigate } from "react-router-dom";

const Register = ({ currentUserEmail, currentUserName }) => {
  const [formData, SetFormData] = useState({
    fname: "",
    lname: "",
    email: null,
    phNumber: "",
    experience: "Fresher",
    companyName: null,
    ctc: null,
    link: null,
    country: null,
  });

  const [selectedFile, setSelectedFile] = useState(null);
  const [firstDisplay, setFirstDisplay] = useState(1);
  const [secondDisplay, setSecondDisplay] = useState(0);
  const [thirdDisplay, setThirdDisplay] = useState(0);
  let [phError, setPhError] = useState("none");
  const mobileNumberRegex = /^\d{10}$/;

  formData.email = currentUserEmail;

  const navigate = useNavigate();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
    if (name === "phNumber") {
      let checkNumber = mobileNumberRegex.test(value);
      if (!checkNumber) {
        setPhError("block");
        phError = "block";
      } else {
        setPhError("none");
      }
    }
  };

  let fDisplay = "block";
  let zIndex = -4;
  let firstScale = 1;
  let scale = 0.1;
  let thirdScale = 0.1;
  let thirdZIndex = -4;

  const toggle = () => {
    const { fname, lname, email, phNumber } = formData;
    if (fname && lname && email && phNumber && selectedFile) {
      setThirdDisplay(0);
      setSecondDisplay(1);
      setFirstDisplay(0);
    } else {
      alert("All Fields Required");
    }
  };

  const toggle3 = () => {
    setThirdDisplay(1);
    setSecondDisplay(0);
    setFirstDisplay(0);
  };

  if (firstDisplay === 0 && secondDisplay === 1 && thirdDisplay === 0) {
    firstScale = 0.1;
    scale = 1;
    zIndex = 4;
  }

  if (firstDisplay === 0 && secondDisplay === 0 && thirdDisplay === 1) {
    scale = 0.1;
    zIndex = -4;
    thirdScale = 1;
    thirdZIndex = 4;
  }

  const name = `${formData.fname} ${formData.lname}`;
  const handleSubmit = async (e) => {
    e.preventDefault();

    // const visited = false;
    const {
      fname,
      lname,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      link,
      country,
    } = formData;
    if (phError === "none") {
      if (experience === "Fresher") {
        if (
          fname &&
          lname &&
          phNumber &&
          email &&
          experience &&
          selectedFile &&
          country
        ) {
          let emmail = formData.email;
          await setDoc(doc(db, "users", email), {
            name,
            email,
            phNumber,
            experience,
            companyName,
            ctc,
            link,
            country
            // visited,
          });
          const folderRef = ref(storage, "resume");
          const fileRef = ref(folderRef, `${currentUserEmail}-resume.pdf`);
          uploadBytes(fileRef, selectedFile);

          toggle3();

          // .catch((error) => {
          //   alert(error.message);
          // });
        } else {
          alert("All fields are required");
        }
      } else {
        if (
          fname &&
          lname &&
          phNumber &&
          email &&
          experience &&
          companyName &&
          ctc &&
          selectedFile &&
          country
        ) {
          let emmail = formData.email;
          // let pass = formData.password;
          // createUserWithEmailAndPassword(auth, emmail, pass)
          //   .then(async (res) => {
          await setDoc(doc(db, "users", email), {
            name,
            email,
            experience,
            phNumber,
            companyName,
            ctc,
            link,
            country,
            // visited,
          });
          const folderRef = ref(storage, "resume");
          const fileRef = ref(folderRef, `${currentUserEmail}-resume.pdf`);
          uploadBytes(fileRef, selectedFile);
          toggle3();
        } else {
          alert("All fields are Required");
        }
      }
    }
  };

  return (
    <>
      <Container fluid className="mt-5 pb-5">
        <div className="row justify-content-center">
          <div
            className="text-center registaerHeading"
            style={{ opacity: `${firstDisplay}` }}
          >
            Let’s get <span className="text-danger">started</span>
          </div>
          <div
            className="col-11 col-md-7 bg-white pb-4 ps-4 pe-4 mt-3 formDiv"
            style={{
              display: `${fDisplay}`,
              opacity: `${firstDisplay}`,
              transition: "all ease-in-out .6s",
              transform: `scale(${firstScale})`,
              borderRadius: "1rem",
              boxShadow: "0 0 8px 4px rgb(236, 233, 233)",
            }}
          >
            <Form className="mt-3 registerForm">
              <div className="d-flex mb-3">
                <div className="row">
                  <div className="col-lg-4 col-11 mb-2 mt-3">
                    <div className="labelText">My Name is</div>
                  </div>
                  <div className="col-lg-4 text-center col-11 mb-2 mt-3">
                    <Form.Group className="mb-3" controlId="formBasicText">
                      <Form.Control
                        placeholder="First name*"
                        type="text"
                        value={formData.fname}
                        name="fname"
                        onChange={handleinput}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-4 text-center col-11 mb-2 mt-3">
                    <Form.Group className="mb-3" controlId="formBasicText">
                      <Form.Control
                        placeholder="Last name*"
                        type="text"
                        value={formData.lname}
                        name="lname"
                        onChange={handleinput}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className="col-lg-6 col-11 mb-2 mt-3">
                    <div className="labelText">
                      The best email to reach me is
                    </div>
                  </div>

                  <div className="col-lg-6 col-11 mb-2 mt-3">
                    <div className="labelText">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Enter email"
                          value={formData.email}
                          name="email"
                          onChange={handleinput}
                          required
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-lg-4 col-11 mb-2 mt-3">
                    <div className="labelText me-5">Contact no.</div>
                  </div>

                  <div className="col-lg-5 col-11 mb-2 mt-3">
                    <div className="labelText me-5">
                      <Form.Group className="mb-3" controlId="formBasicNumber">
                        <div
                          className="mb-2 text-danger"
                          style={{ display: `${phError}` }}
                        >
                          Number must be 10 digits
                        </div>
                        <Form.Control
                          placeholder="9876543210*"
                          type="number"
                          value={formData.phNumber}
                          name="phNumber"
                          onChange={handleinput}
                          required
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </div>
                  </div>

                  <div className="col-lg-5 col-11 mb-2 mt-3">
                    <div className="labelText">
                      Upload Resume <br />
                      <span style={{ fontSize: "small", color: "#6e6e6e" }}>
                        ( in .pdf format)
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-5 col-11 mb-2 mt-3">
                    <Form.Group className="mb-3" controlId="formFile">
                      <Form.Control
                        placeholder="Resume link*"
                        type="file"
                        accept=".pdf"
                        onChange={handleFileUpload}
                        required
                      />
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="text-center">
                <Button
                  className="registerBtn"
                  variant="primary"
                  onClick={toggle}
                >
                  Next
                </Button>
              </div>
            </Form>
          </div>

          {/* *********** SECOND FORM START ************* */}

          <div
            className="col-11 mb-2 col-md-5 bg-white pb-4 ps-5 pe-5"
            style={{
              position: "absolute",
              zIndex: `${zIndex}`,
              opacity: `${secondDisplay}`,
              transition: "all ease-in-out .6s",
              transform: `scale(${scale})`,
              borderRadius: "1rem",
              boxShadow: "0 0 8px 4px rgb(236, 233, 233)",
            }}
          >
            <div className="nameText mt-4">
              Hi,{" "}
              <span className="text-danger">{`${formData.fname} ${formData.lname}`}</span>
            </div>

            <Form className="mt-5 registerForm">
              <div className="row">
                <div className="col-lg-8 col-12 mb-4">
                  <div className="labelText me-5">
                    Choose Experience in Year
                  </div>
                </div>

                <div className="col-lg-4 col-11 mb-4">
                  <Form.Select
                    id="selectValue"
                    aria-label="Default select example"
                    className="mb-3"
                    name="experience"
                    onChange={handleinput}
                    required
                  >
                    <option value="Fresher" selected>
                      Fresher
                    </option>
                    <option value="One Year">One Year</option>
                    <option value="Two Years">Two Years</option>
                    <option value="Three Years">Three Years</option>
                    <option value="Four Years">Four Years</option>
                    <option value="Five Years">Five Years</option>
                    <option value="Above Five Years">Above Five Years</option>
                  </Form.Select>
                </div>

                {formData.experience !== "Fresher" ? (
                  <>
                    <div className="col-lg-6 col-12 mb-4">
                      <div className="labelText">Enter Company Name</div>
                    </div>
                    <div className="col-lg-5 col-11 mb-4">
                      <Form.Group className="mb-3" controlId="formBasicText">
                        <Form.Control
                          placeholder="Company name*"
                          id="companyName"
                          type="text"
                          value={formData.companyName}
                          name="companyName"
                          onChange={handleinput}
                          required
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </div>
                    <div className="col-lg-6 col-11 mb-4">
                      <div className="labelText">Enter CTC in Rupess</div>
                    </div>
                    <div className="col-lg-4 col-11 mb-4">
                      <Form.Group className="mb-3" controlId="formBasicNumber">
                        <Form.Control
                          placeholder="300000*"
                          type="number"
                          value={formData.ctc}
                          name="ctc"
                          onChange={handleinput}
                          required
                        />
                        <Form.Text className="text-muted"></Form.Text>
                      </Form.Group>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-lg-6 col-12 mb-4">
                  <div className="labelText">Enter Country Name</div>
                </div>
                <div className="col-lg-5 col-11 mb-4">
                  <Form.Group className="mb-3" controlId="formBasicNumber">
                    <Form.Control
                      placeholder="India*"
                      type="text"
                      value={formData.country}
                      name="country"
                      onChange={handleinput}
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </div>
              </div>

              <div className="d-flex justify-content-around mt-4">
                <Button
                  className="registerBtn"
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Update
                </Button>

                {/* <Button
                  className="registerBtn"
                  variant="primary"
                  onClick={toggle3}
                >
                  Cong.
                </Button> */}
              </div>
            </Form>
          </div>

          {/* *********** SECOND FORM END ************* */}

          {/* ********* THIRD CONGRATULATION PAGE ***************** */}

          <div
            className="col-11 col-md-6 bg-white  pb-4 ps-5 pe-5"
            style={{
              position: "absolute",
              zIndex: `${thirdZIndex}`,
              opacity: `${thirdDisplay}`,
              transition: "all ease-in-out .6s",
              transform: `scale(${thirdScale})`,
              borderRadius: "1rem",
              boxShadow: "0 0 8px 4px rgb(236, 233, 233)",
            }}
          >
            <div className="row justify-content-center">
              <div className="col-12 mt-4 mb-4 text-center congText">
                Congratulations!
              </div>
              <div className="col-12 mb-5 mt-4 text-center congText2">
                Your profile has been updated
              </div>
            </div>
            <div className="row justify-content-center ">
              <div className="col-12 text-center">
                <Button
                  className="registerBtn jobPrfl"
                  onClick={() => navigate("/all-jobs")}
                >
                  {" "}
                  Go to Job Profile
                </Button>
              </div>
            </div>
          </div>

          {/* ******** END THIRD CONGRATULATION PAGE ********** */}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Register;
