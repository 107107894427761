import { useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import LoadingSpinner from "./LoadingSpinner";
import { DownloadTableExcel } from "react-export-table-to-excel";
import xls from "../images/Microsoft_Office_Excel_(2019–present).svg.png";
import {
  db,
  collection,
  getDocs,
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  setDoc,
  deleteDoc,
  storage,
  ref,
  getDownloadURL,
} from "./Firebase";

import { useNavigate } from "react-router-dom";

const UserData = ({ currentUserEmail }) => {
  let [userData, setUserData] = useState([]);
  const [display, setDisplay] = useState(0);
  const [displayReject, setDisplayReject] = useState(0);
  const [blur, setBlur] = useState("0px");
  const [loadingBlur, setLoadingBlur] = useState("0px");
  const [scale, setScale] = useState(1);
  const [scaleReject, setScaleReject] = useState(1);
  const [SeprateData, setSeprateData] = useState({});
  const [err, Seterr] = useState("");
  const [zIndex, setZindex] = useState("-2");
  const [zIndexReject, setZindexReject] = useState("-2");
  const [isLoading, setIsLoading] = useState(false);
  const [document, setDocument] = useState("");
  const [rejectButton, setRejectButton] = useState(true);
  const [allUsers, setAllUsers] = useState();
  const [scoreDisplay, setScoreDisplay] = useState(0);
  const [scoreZIndex, setscoreZIndex] = useState("-2");
  const [databaseScore, setDatabaseScore] = useState([]);
  const [userCounter, setuserCounter] = useState("0");
  const [formData, SetFormData] = useState({
    date: null,
    type: "All",
    field: "Accounts",
    filterName: "",
    applyMarks: "",
    reason: "",
    customReason: "",
    sort: "sort",
  });
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    let tStamp = Date.parse(formData.date);
    let date = new Date(tStamp);
    let hours = date.getHours();
    let minutes = date.getMinutes();
    // let d = new Date();
    // let dateEligibility = Date.parse(d);
    // let futureDateEligibility = dateEligibility + 259200000;
    if (hours === 17 && minutes > 0) {
      hours = hours + 1;
    }

    let day = date.getDay();
    const element = [];
    let count = 0;
    if (currentUserEmail === "gurvinder@alphanumericideas.com") {
      if (day !== 0) {
        if (hours <= 17 && hours > 9) {
          const docRef = doc(db, "interview-Timings", "All-Timings");
          const docSnap = await getDoc(docRef);
          for (let index = 0; index < docSnap.data().time.length; index++) {
            let getTimeStamp = docSnap.data().time[index];
            element.push(getTimeStamp);
          }
          for (let index = 0; index < element.length; index++) {
            let eligibility = element[index] + 1800000;
            let eligibility2 = element[index] - 1800000;
            if (tStamp >= eligibility || tStamp <= eligibility2) {
              count = count + 1;
            }
          }
          if (element.length === count) {
            let json = "";
            setLoadingBlur("4px");
            setIsLoading(true);
            const attendeeEmail = SeprateData.email;
            try {
              const response = await fetch(
                "https://us-central1-alphanumeric-career.cloudfunctions.net/server/event",
                {
                  method: "POST",
                  withCredentials: false,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    calendarDate,
                    date,
                    attendeeEmail,
                  }),
                }
              );
              json = await response.json();
            } catch (error) {
              console.log(error);
            }
            if (json.msg === "Schedule successfully") {
              await updateDoc(doc(db, "interview-Timings", "All-Timings"), {
                time: arrayUnion(tStamp),
              });
              alert(json.msg);
              setIsLoading(false);
              setLoadingBlur("0px");
              const {
                name,
                email,
                phNumber,
                experience,
                companyName,
                ctc,
                score,
                quizname,
                link,
                imgLink,
                attempts,
                submissionDate,
              } = SeprateData;
              await setDoc(doc(db, `Interview-Scheduled ${quizname}`, email), {
                name,
                email,
                phNumber,
                experience,
                companyName,
                ctc,
                score,
                quizname,
                link,
                imgLink,
                attempts,
                submissionDate,
              });
              await deleteDoc(doc(db, `Contacted ${quizname}`, email));
              if (formData.type === "All") {
                let document = `Contacted ${formData.field} Internship`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                });
                document = `Contacted ${formData.field} position`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                  setUserData(data);
                });
              } else {
                const document = `Contacted ${formData.field} ${formData.type}`;
                setDocument(document);
                getDocs(collection(db, document)).then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    data.push(doc.data());
                  });
                  setUserData(data);
                });
              }
            } else {
              alert(json.msg);
            }
            Seterr("");
          } else {
            Seterr("Try different time slot");
          }
        } else {
          Seterr("Please select time between 10 am - 5 pm");
        }
      } else {
        Seterr("Don't select sunday");
      }
    } else {
      Seterr("Invalid Credientials");
    }
  };

  let calendarDate = formData.date;
  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkData = () => {
    if (!rejectButton) {
      const filteredData = allUsers.filter((user) => {
        return user.name
          .toLowerCase()
          .includes(formData.filterName.toLowerCase());
      });
      console.log(filteredData);
      setUserData(filteredData);
    } else {
      alert("No Data");
    }
  };

  let sr = 0;
  let scoreSr = 0;
  let data = [];
  const tableRef = useRef(null);

  const allData = () => {
    setRejectButton(false);
    if (formData.type === "All") {
      let document = `Contacted ${formData.field} Internship`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `Contacted ${formData.field} position`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    } else {
      const document = `Contacted ${formData.field} ${formData.type}`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    }
    setAllUsers(data);
  };

  const rejectOne = async (e) => {
    e.preventDefault();
    const rejectedIndex = localStorage.getItem("rejectedIndex");
    let index = parseInt(rejectedIndex);
    let { reason, customReason } = formData;
    if (reason === "") reason = customReason;
    // if (reason !== "") {
    const ele = userData[index];

    const {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      quizname,
      link,
      imgLink,
      attempts,
      submissionDate,
    } = ele;

    let d = submissionDate.toDate().toDateString();
    let onlyDate = new Date(d);
    onlyDate.setDate(onlyDate.getDate() + 21);
    onlyDate = onlyDate.toDateString();
    let clName = `RejectedUsers ${quizname}`;
    await setDoc(doc(db, clName, email), {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      quizname,
      link,
      imgLink,
      attempts,
      submissionDate,
    });
    await deleteDoc(doc(db, `Contacted ${quizname}`, email));
    if (formData.type === "All") {
      let document = `Contacted ${formData.field} Internship`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `Contacted ${formData.field} position`;
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    } else {
      const document = `Contacted ${formData.field} ${formData.type}`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    }

    fetch(
      "https://us-central1-alphanumeric-career.cloudfunctions.net/server/reject",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          name,
          email,
          quizname,
          imgLink,
          onlyDate,
          reason,
        }),
      }
    );
    setDisplayReject(0);
    setBlur("0px");
    setZindexReject("-2");
    setScaleReject(1);
    alert("Rejected");
    // } else {
    //   alert("Select Reason");
    // }
  };

  const { sort } = formData;
  if (sort === "alpha") {
    const alphaSort = userData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    userData = alphaSort;
  }

  if (sort === "score") {
    const alphaSort = userData.sort((a, b) => {
      return b.score - a.score;
    });
    userData = alphaSort;
  }

  if (sort === "date") {
    const dateSort = userData.sort((a, b) => {
      const timestampA = a.submissionDate.toMillis();
      const timestampB = b.submissionDate.toMillis();
      return timestampB - timestampA;
    });
    userData = dateSort;
  }

  return (
    <>
      {isLoading ? <LoadingSpinner /> : <></>}
      <div
        className="date-container"
        style={{ zIndex: zIndex, filter: `blur(${loadingBlur})` }}
      >
        <Form
          className="back mmm"
          style={{ opacity: display, transform: `scale(${scale})` }}
        >
          <div
            className="text-center mb-3"
            style={{ fontWeight: "700", letterSpacing: "1px" }}
          >
            Schedule Interview For <br />
            {SeprateData.name}
          </div>
          <Form.Group controlId="datetime-local mb-5">
            <Form.Control
              type="text"
              value={formData.date}
              placeholder="DD/MM/YYYY , 00:00"
              onFocus={(e) => (e.target.type = "datetime-local")}
              name="date"
              onChange={handleinput}
            />
          </Form.Group>
          <div className="d-flex justify-content-around mt-4">
            <Button
              className="scheduleButton"
              variant="success"
              type="submit"
              onClick={handleSubmit}
            >
              Schedule
            </Button>
            <Button
              className="cancelButton"
              variant="danger"
              onClick={(e) => {
                setDisplay(0);
                setBlur("0px");
                setZindex("-2");
                setScale(1);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="scheduleError text-center mt-3"> {err}</div>
        </Form>
      </div>

      {/* ************ REJECT CONTAINER ************** */}

      <div
        className="date-container"
        style={{ zIndex: zIndexReject, filter: `blur(${loadingBlur})` }}
      >
        <Form
          className="back mmm reasonW"
          style={{ opacity: displayReject, transform: `scale(${scaleReject})` }}
        >
          <div
            className="text-center mb-3"
            style={{ fontWeight: "700", letterSpacing: "1px" }}
          >
            Are You Sure ? <br />
          </div>
          {/* <Form.Select
            aria-label="Default select example"
            name="reason"
            onChange={handleinput}
          >
            <option selected value="">
              Select Reason
            </option>
            <option value="We were unable to reach you by phone or email after several attempts, so we have decided to move forward with other candidates.">
              Unable to Contact You
            </option>

            <option value="We received many applications for this position and could only select a few candidates who best met the requirements.">
              Limited Openings
            </option>
            <option value="customReason">Custom Reason</option>
          </Form.Select> */}

          {formData.reason === "customReason" ? (
            <>
              <Form.Group className="mb-3 mt-3" controlId="formBasicText">
                <Form.Control
                  type="text"
                  placeholder="Type Reason"
                  name="customReason"
                  value={formData.customReason}
                  onChange={handleinput}
                />
              </Form.Group>
            </>
          ) : (
            <></>
          )}

          <div className="d-flex justify-content-around mt-4">
            <Button
              className="scheduleButton"
              variant="success"
              type="submit"
              onClick={(e) => rejectOne(e)}
            >
              Reject
            </Button>
            <Button
              className="cancelButton"
              variant="danger"
              onClick={(e) => {
                setDisplayReject(0);
                setBlur("0px");
                setZindexReject("-2");
                setScaleReject(1);
              }}
            >
              Cancel
            </Button>
          </div>
          <div className="scheduleError text-center mt-3"> {err}</div>
        </Form>
      </div>

      {/* ******** END REJECT CONTAINER *************** */}

      {/* USER SCOORE START ***************** */}

      <div
        className="date-container"
        style={{
          zIndex: scoreZIndex,
          filter: `blur(${loadingBlur})`,
          width: "95vw",
          position: "absolute",
          top: "20rem",
        }}
      >
        <div
          className="mmm text-center"
          style={{
            opacity: scoreDisplay,
            transform: `scale(${scale})`,
            width: "80vw",
          }}
        >
          <Table
            striped
            bordered
            hover
            className="text-center mb-5"
            style={{ height: "50vh" }}
          >
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Field</th>
                <th>Score</th>
              </tr>
            </thead>
            {databaseScore.map((ele) => {
              scoreSr = scoreSr + 1;
              return (
                <>
                  <tbody>
                    <tr>
                      <td>{scoreSr}</td>
                      <td>{ele.field}</td>
                      <td>{ele.score}</td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </Table>

          <Button
            className="navButton"
            variant="danger"
            onClick={(e) => {
              setScoreDisplay(0);
              setBlur("0px");
              setscoreZIndex("-2");
              setScale(1);
            }}
          >
            GO back
          </Button>
        </div>
      </div>

      {/* USER SCORE END  ****************** */}

      <div style={{ filter: `blur(${blur})` }}>
        <h1 className="heading">Contacted Users : {userCounter}</h1>
        <div className="m-3" style={{ filter: `blur(${blur})` }}>
          <DownloadTableExcel
            filename={document}
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className="mb-2 xlsButton">
              <img className="xls" src={xls} alt="" />{" "}
            </button>
          </DownloadTableExcel>
          <div className="row justify-content-center mb-4">
            <div className="col-md-3 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="type"
                onChange={handleinput}
              >
                <option value="All">All</option>
                <option value="Internship">Internships</option>
                <option value="position">Jobs</option>
              </Form.Select>
            </div>
            <div className="col-md-3 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="field"
                onChange={handleinput}
              >
                <option value="Accounts">Accounts</option>
                <option value="Business Development">
                  Business Development
                </option>
                <option value="Content Writing">Content Writing</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Data Entry">Data Entry</option>
                <option value="Data Research">Data Research</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Marketing">Marketing</option>
                <option value="Online Bidding">Online Bidding</option>
                <option value="Web Development">Web Development</option>
                <option value="Check">Check</option>
              </Form.Select>
            </div>
            <div className="col-md-2 col-8 mt-2 text-center">
              <Button className="navButton" onClick={allData}>
                Search
              </Button>
            </div>

            <div className="col-md-2 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="sort"
                onChange={handleinput}
              >
                <option value="sort">Sort By</option>
                <option value="date">Latest</option>
                <option value="alpha">Name [ A - Z ]</option>
                <option value="score">Score [ 10 - 0 ]</option>
              </Form.Select>
            </div>

            <div className="col-md-2 col-8 mt-2 text-center">
              <Form>
                <Form.Group controlId="text">
                  <Form.Control
                    className="text-center"
                    type="text"
                    placeholder="Search By Name"
                    value={formData.filterName}
                    name="filterName"
                    onChange={handleinput}
                    onKeyUp={checkData}
                  />
                </Form.Group>
              </Form>
            </div>

            {/* ****** DELETE DATA ******** */}
            {/* <div>
              <Button className="hdrBtn" onClick={async() => {
                await deleteDoc(doc(db, "users", "mohit@alphanumericideas.com"));
                alert("User Deleted")
              }}>
                Delete User
              </Button>
            </div> */}
            {/* ********************* */}
          </div>
          <Table
            responsive
            striped
            bordered
            hover
            variant="light"
            ref={tableRef}
          >
            <thead className="QuizBtn">
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Experience</th>
                <th>Company Name</th>
                <th>CTC</th>
                <th>Score</th>
                <th>Category</th>
                <th>Submission Date</th>
                <th>Attempts</th>
                <th>Suggested Time</th>
                <th>Resume Link</th>
                <th className="text-center" colSpan="2">
                  Action
                </th>
              </tr>
            </thead>

            {userData.map((ele, index) => {
              sr++;
              return (
                <>
                  <tbody className="QuizBtn">
                    <tr>
                      <td>{sr}</td>
                      <td>{ele.name}</td>
                      <td>{ele.email}</td>
                      <td>{ele.phNumber}</td>
                      <td>{ele.experience}</td>
                      <td>{ele.companyName}</td>
                      <td>{ele.ctc}</td>
                      <td>{ele.score}/10</td>
                      <td>{ele.quizname}</td>
                      <td>{ele.submissionDate.toDate().toLocaleString()}</td>
                      <td>{ele.attempts}</td>
                      <td>{ele.suggestedInterview}</td>
                      {ele.link ? (
                        <>
                          <td style={{ cursor: "pointer" }}>
                            <a
                              style={{ color: "#139ded" }}
                              className="resumeLink"
                              href={ele.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open Resume
                            </a>
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            style={{ color: "#139ded", cursor: "pointer" }}
                            onClick={async () => {
                              let link = `gs://alphanumeric-career.appspot.com/resume/${ele.email}-resume.pdf`;
                              let nlink = link.replaceAll(" ", "");
                              const fileRef = ref(storage, nlink);
                              const downloadURL = await getDownloadURL(fileRef);
                              localStorage.setItem("url", downloadURL);
                              navigate("/resume-viewer");
                            }}
                          >
                            Open Resume
                          </td>
                        </>
                      )}
                      <td className="d-flex justify-content-around">
                        <Button
                          className="me-2"
                          style={{ fontSize: "0.8rem" }}
                          variant="success"
                          onClick={async () => {
                            setDisplay(1);
                            setBlur("4px");
                            setSeprateData(ele);
                            setZindex("2");
                            setScale(1.1);
                          }}
                        >
                          Schedule Interview
                        </Button>

                        <Button
                          variant="secondary"
                          className="me-2"
                          onClick={async () => {
                            const {
                              name,
                              email,
                              phNumber,
                              experience,
                              companyName,
                              ctc,
                              score,
                              quizname,
                              link,
                              imgLink,
                              attempts,
                              submissionDate,
                            } = ele;
                            await setDoc(
                              doc(db, `Selected-Users ${quizname}`, email),
                              {
                                name,
                                email,
                                phNumber,
                                experience,
                                companyName,
                                ctc,
                                score,
                                quizname,
                                link,
                                imgLink,
                                attempts,
                                submissionDate,
                              }
                            );
                            await deleteDoc(
                              doc(db, `Contacted ${quizname}`, email)
                            );
                            if (formData.type === "All") {
                              let document = `Contacted ${formData.field} Internship`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                }
                              );
                              document = `Contacted ${formData.field} position`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            } else {
                              const document = `Contacted ${formData.field} ${formData.type}`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            }

                            fetch(
                              "https://us-central1-alphanumeric-career.cloudfunctions.net/server/select",
                              {
                                method: "POST",
                                mode: "cors",
                                headers: {
                                  "access-control-allow-origin": "*",
                                  "Content-type":
                                    "application/json; charset=UTF-8",
                                },
                                body: JSON.stringify({
                                  name,
                                  email,
                                  quizname,
                                  imgLink,
                                }),
                              }
                            ).then((res) => {
                              // setIsLoading(true);
                              // setBlur("4px");
                              // navigate("/quiz-result");
                            });
                            alert("Selected");
                          }}
                        >
                          Select
                        </Button>

                        <Button
                          className=""
                          variant="danger"
                          onClick={() => {
                            setDisplayReject(1);
                            setBlur("4px");
                            setZindexReject("2");
                            setScaleReject(1.1);
                            localStorage.setItem("rejectedIndex", index);
                          }}
                        >
                          Reject
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </Table>
          {!rejectButton && userData.length === 0 ? (
            <h3 className="text-center mt-4">No Data Found</h3>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default UserData;
