const data_research = [
  {
    quizname: "Data Research",
    imgLink: "https://alphanumericideas.com/images/careers/data-research.png",
    ques: " What is a common statistical method used to measure the relationship between two variables?",
    options: [
      "Regression analysis",
      "Correlation analysis",
      "Factor analysis",
      "Cluster analysis",
    ],
    ans: "B",
  },
  {
    ques: " What type of data refers to data that has been collected by someone else and is publicly available?",
    options: [
      "Primary data",
      "Secondary data",
      "Tertiary data",
      "Quaternary data",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of transforming raw data into a format that is easier to analyze?",
    options: [
      "Data mining",
      "Data modeling",
      "Data transformation",
      "Data cleaning",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of combining two or more datasets into one?",
    options: [
      "Data integration",
      "Data mining",
      "Data cleansing",
      "Data visualization",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a measure of central tendency?",
    options: ["Mean", "Median", "Mode", "Variance"],
    ans: "D",
  },
  {
    ques: " What is the process of identifying and removing outliers from a dataset?",
    options: [
      "Data cleaning",
      "Data transformation",
      "Data normalization",
      "Data filtering",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle missing data?",
    options: [
      "Listwise deletion",
      "Pairwise deletion",
      "Imputation",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What type of data refers to data that can take on any value within a range?",
    options: [
      "Categorical data",
      "Ordinal data",
      "Interval data",
      "Nominal data",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a statistical method used to test for significant differences between two or more groups?",
    options: [
      "ANOVA",
      "Regression analysis",
      "Correlation analysis",
      "Factor analysis",
    ],
    ans: "A",
  },
  {
    ques: " What type of data refers to data that can be organized into categories?",
    options: [
      "Categorical data",
      "Ordinal data",
      "Interval data",
      "Nominal data",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of analyzing data to extract insights and patterns?",
    options: [
      "Data mining",
      "Data modeling",
      "Data transformation",
      "Data cleaning",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to reduce the number of variables in a dataset?",
    options: [
      "Principal component analysis",
      "Cluster analysis",
      "Factor analysis",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of visualizing data in order to gain insights and communicate findings?",
    options: [
      "Data mining",
      "Data modeling",
      "Data transformation",
      "Data visualization",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a method used to handle skewed data?",
    options: [
      "Data transformation",
      "Data normalization",
      "Data filtering",
      "Data cleaning",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of identifying patterns in data that can be used to make predictions?",
    options: [
      "Data mining",
      "Data modeling",
      "Data transformation",
      "Data cleaning",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a measure of the spread of a dataset?",
    options: ["Mean", "Median", "Mode", "Standard deviation"],
    ans: "D",
  },
  {
    ques: " What is the process of selecting a sample from a larger population for analysis?",
    options: [
      "Sampling",
      "Data cleaning",
      "Data transformation",
      "Data modeling",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle multicollinearity in regression analysis?",
    options: [
      "Ridge regression",
      "Lasso regression",
      "Elastic net regression",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a measure of how well a regression model fits the data?",
    options: [
      "R-squared",
      "Standard deviation",
      "Correlation coefficient",
      "Mean squared error",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of testing a hypothesis using data?",
    options: [
      "Inferential statistics",
      "Descriptive statistics",
      "Data mining",
      "Data modeling",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle overfitting in machine learning models?",
    options: [
      "Regularization",
      "Cross-validation",
      "Feature selection",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of transforming categorical variables into numerical variables?",
    options: [
      "One-hot encoding",
      "Label encoding",
      "Target encoding",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for classification problems?",
    options: [
      "Decision tree",
      "Linear regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of splitting a dataset into training and testing sets?",
    options: [
      "Cross-validation",
      "Feature selection",
      "Sampling",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle imbalanced data in machine learning?",
    options: [
      "Oversampling",
      "Undersampling",
      "Synthetic data generation",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for regression problems?",
    options: [
      "Decision tree",
      "Logistic regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of neural network that is used for image recognition?",
    options: [
      "Convolutional neural network",
      "Recurrent neural network",
      "Deep belief network",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of measuring the performance of a machine learning model?",
    options: ["Evaluation", "ValidationValidation", "Training", "Testing"],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle missing values in machine learning?",
    options: ["Imputation", "Deletion", "Regression", "All of the above"],
    ans: "D",
  },
  {
    ques: " Which of the following is a measure of how well a classification model predicts positive cases?",
    options: ["Precision", "Recall", "F1 score", "All of the above"],
    ans: "D",
  },
  {
    ques: " What is the process of selecting the most important features for a machine learning model?",
    options: [
      "Feature selection",
      "Feature engineering",
      "Feature extraction",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of unsupervised learning algorithm?",
    options: [
      "K-nearest neighbors",
      "Linear regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a method used to handle noisy data in machine learning?",
    options: [
      "Outlier detection",
      "Data cleaning",
      "Feature engineering",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of ensemble learning algorithm?",
    options: [
      "Random forest",
      "Support vector machine",
      "Naive Bayes",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle continuous variables in machine learning?",
    options: [
      "Binning",
      "One-hot encoding",
      "Label encoding",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of improving the performance of a machine learning model by adjusting the model's hyperparameters?",
    options: [
      "Hyperparameter tuning",
      "Feature selection",
      "Data cleaning",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to reduce the dimensionality of a dataset?",
    options: [
      "Principal component analysis",
      "Linear regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for both regression and classification problems?",
    options: [
      "Support vector machine",
      "K-nearest neighbors",
      "Naive Bayes",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a measure of how well a classification model correctly identifies negative cases?",
    options: ["Specificity", "Sensitivity", "Accuracy", "All of the above"],
    ans: "A",
  },
  {
    ques: " What is the process of using multiple models to make a prediction?",
    options: [
      "Ensemble learning",
      "Deep learning",
      "Reinforcement learning",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle skewed data in machine learning?",
    options: [
      "Log transformation",
      "Box-Cox transformation",
      "Square root transformation",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of neural network that is used for natural language processing?",
    options: [
      "Convolutional neural network",
      "Recurrent neural network",
      "Deep belief network",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of finding patterns in large datasets?",
    options: [
      "Data mining",
      "Data cleaning",
      "Data modeling",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle multicollinearity in regression models?",
    options: [
      "Dropping one of the correlated variables",
      "Ridge regression",
      "Lasso regression",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for clustering problems?",
    options: [
      "Decision tree",
      "Linear regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of how well a classification model predicts positive cases while penalizing false positives?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "A",
  },
  {
    ques: " What is the process of transforming numerical variables into categorical variables?",
    options: [
      "Binning",
      "One-hot encoding",
      "Label encoding",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for anomaly detection?",
    options: [
      "Decision tree",
      "Linear regression",
      "K-means clustering",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a method used to handle non-linear relationships in regression models?",
    options: [
      "Polynomial regression",
      "Ridge regression",
      "Lasso regression",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a measure of how well a classification model predicts positive cases while penalizing false negatives?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "B",
  },
  {
    ques: " What is the process of analyzing and interpreting data to gain insights and knowledge?",
    options: [
      "Data visualization",
      "Data analysis",
      "Data modeling",
      "Data mining",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of cleaning and preparing data for analysis?",
    options: ["Data mining", "Data analysis", "Data cleaning", "Data modeling"],
    ans: "C",
  },
  {
    ques: " Which of the following is a programming language commonly used for data analysis?",
    options: ["Python", "C++", "Java", "All of the above"],
    ans: "A",
  },
  {
    ques: " What is the process of converting data from one format to another?",
    options: [
      "Data cleaning",
      "Data transformation",
      "Data modeling",
      "Data mining",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a type of data analysis that focuses on describing and summarizing data?",
    options: [
      "Inferential statistics",
      "Descriptive statistics",
      "Predictive analytics",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a graphical representation of the relationship between two variables?",
    options: ["Histogram", "Scatter plot", "Bar chart", "Line graph"],
    ans: "B",
  },
  {
    ques: " What is the process of using statistical methods to make predictions about future events?",
    options: [
      "Inferential statistics",
      "Descriptive statistics",
      "Predictive analytics",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of how spread out a distribution is?",
    options: ["Mean", "Median", "Mode", "Standard deviation"],
    ans: "D",
  },
  {
    ques: " Which of the following is a statistical technique used to test hypotheses about population parameters?",
    options: [
      "Regression analysis",
      "Hypothesis testing",
      "ANOVA",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a measure of how well a model fits the data?",
    options: ["Accuracy", "Precision", "Recall", "R-squared"],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for classification problems?",
    options: [
      "Linear regression",
      "Decision tree",
      "K-means clustering",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a measure of the strength of the relationship between two variables?",
    options: [
      "Correlation coefficient",
      "Regression coefficient",
      "Standard deviation",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of data analysis that involves testing the significance of relationships between variables?",
    options: [
      "Descriptive statistics",
      "Inferential statistics",
      "Predictive analytics",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of identifying and removing outliers from a dataset?",
    options: [
      "Data cleaning",
      "Data transformation",
      "Data modeling",
      "Data mining",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a measure of how well a classification model predicts positive cases while penalizing false positives?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "A",
  },
  {
    ques: " What is the process of using multiple models to make a prediction?",
    options: [
      "Ensemble learning",
      "Deep learning",
      "Reinforcement learning",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a method used to handle missing data in a dataset?",
    options: [
      "Dropping missing values",
      "Imputing missing values",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  
  {
    ques: " Which of the following is a measure of the goodness of fit of a regression model?",
    options: [
      "Mean squared error",
      "Root mean squared error",
      "R-squared",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a type of data analysis that involves identifying patterns in large datasets?",
    options: [
      "Data mining",
      "Data cleaning",
      "Data transformation",
      "Data modeling",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a technique used to reduce the number of features in a dataset?",
    options: [
      "Feature scaling",
      "Feature engineering",
      "Feature selection",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of using historical data to make predictions about future events?",
    options: [
      "Predictive modeling",
      "Descriptive modeling",
      "Inferential modeling",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a technique used to group similar observations together?",
    options: [
      "Regression analysis",
      "Cluster analysis",
      "Association analysis",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a measure of the effectiveness of a binary classification model?",
    options: ["Accuracy", "Precision", "Recall", "All of the above"],
    ans: "D",
  },
  {
    ques: " Which of the following is a method used to validate the performance of a machine learning model?",
    options: [
      "Cross-validation",
      "Train-test split",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of data analysis that involves identifying relationships between variables?",
    options: [
      "Descriptive statistics",
      "Inferential statistics",
      "Predictive analytics",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a technique used to reduce the dimensionality of a dataset?",
    options: [
      "Principal component analysis",
      "Factor analysis",
      "Both a and b",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for unsupervised learning?",
    options: [
      "Linear regression",
      "Decision tree",
      "K-means clustering",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of how well a binary classification model identifies true positive cases?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "B",
  },
  {
    ques: " Which of the following is a type of data analysis that involves making predictions about future events?",
    options: [
      "Descriptive modeling",
      "Inferential modeling",
      "Predictive modeling",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a technique used to handle imbalanced classes in a binary classification problem?",
    options: [
      "Oversampling",
      "Undersampling",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of how well a binary classification model identifies true positive cases while penalizing false negatives?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for reinforcement learning?",
    options: [
      "Linear regression",
      "Decision tree",
      "Q-learning",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of how well a regression model fits the data?",
    options: [
      "Mean squared error",
      "Root mean squared error",
      "R-squared",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a technique used to handle missing data in a dataset?",
    options: ["Imputation", "Deletion", "Both a and b", "None of the above"],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of data analysis that involves summarizing and visualizing data?",
    options: [
      "Exploratory data analysis",
      "Inferential data analysis",
      "Predictive data analysis",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a measure of how well a binary classification model identifies true positive cases while minimizing false positives?",
    options: ["Precision", "Recall", "F1 score", "None of the above"],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for anomaly detection?",
    options: [
      "Linear regression",
      "Decision tree",
      "Isolation forest",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a technique used to handle multicollinearity in a regression model?",
    options: [
      "Ridge regression",
      "Lasso regression",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of data analysis that involves testing hypotheses about a population based on sample data?",
    options: [
      "Descriptive statistics",
      "Inferential statistics",
      "Predictive analytics",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a measure of the effectiveness of a multi-class classification model?",
    options: ["Accuracy", "Precision", "Recall", "All of the above"],
    ans: "D",
  },
  {
    ques: " Which of the following is a technique used to handle class imbalance in a multi-class classification problem?",
    options: [
      "Oversampling",
      "Undersampling",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for time series forecasting?",
    options: [
      "Linear regression",
      "Decision tree",
      "ARIMA",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of the effectiveness of a clustering algorithm?",
    options: [
      "Silhouette score",
      "Adjusted R-squared",
      "F1 score",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a technique used to handle outliers in a dataset?",
    options: ["Winsorization", "Trimming", "Both a and b", "None of the above"],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for natural language processing?",
    options: [
      "Linear regression",
      "Decision tree",
      "Naive Bayes",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a technique used to handle categorical variables in a machine learning model?",
    options: [
      "One-hot encoding",
      "Label encoding",
      "Both a and b",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of data analysis that involves identifying the most important variables in a dataset?",
    options: [
      "Feature scaling",
      "Feature engineering",
      "Feature selection",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a measure of the effectiveness of a regression model that penalizes large coefficients?",
    options: [
      "Ridge regression",
      "Lasso regression",
      "Both a and b",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of machine learning algorithm that is used for recommendation systems?",
    options: [
      "Linear regression",
      "Decision tree",
      "Collaborative filtering",
      "None of the above",
    ],
    ans: "C",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
];

export default data_research;
