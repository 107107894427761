import React, { useState, useEffect, useRef } from "react";
import Table from "react-bootstrap/Table";
import xls from "../images/Microsoft_Office_Excel_(2019–present).svg.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  db,
  collection,
  getDocs,
  doc,
  setDoc,
  deleteDoc,
  storage,
  ref,
  getDownloadURL,
} from "./Firebase";

import { useNavigate } from "react-router-dom";

const Interviews = () => {
  let [userData, setUserData] = useState([]);
  const [document, setDocument] = useState("");
  const [showOrNot, setShowOrNot] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const [click, setClick] = useState(false);
  const [display, setDisplay] = useState(0);
  const [blur, setBlur] = useState("0px");
  const [zIndex, setZindex] = useState("-2");
  const [scale, setScale] = useState(1);
  const [userCounter, setuserCounter] = useState("0");
  const [formData, SetFormData] = useState({
    type: "All",
    field: "Accounts",
    sort: "",
  });

  const navigate = useNavigate();

  let sr = 0;
  let data = [];
  const tableRef = useRef(null);

  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkData = () => {
    if (click) {
      console.log(allUsers[1]);
      const filteredData = allUsers.filter((user) => {
        return user.name
          .toLowerCase()
          .includes(formData.filterName.toLowerCase());
      });
      setUserData(filteredData);
    } else {
      alert("No Data");
    }
  };

  const allData = () => {
    setShowOrNot(true);
    if (formData.type === "All") {
      let document = `Interview-Scheduled ${formData.field} Internship`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `Interview-Scheduled ${formData.field} position`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    }
    const document = `Interview-Scheduled ${formData.field} ${formData.type}`;
    setDocument(document);
    getDocs(collection(db, document)).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setUserData(data);
      const userLength = data.length;
      setuserCounter(userLength);
    });

    setAllUsers(data);
    setClick(true);
  };

  const rejectOne = async (e) => {
    e.preventDefault();
    let index = parseInt(userCounter);
    index--;
    console.log(index);
    const ele = userData[index];

    const {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      quizname,
      link,
      imgLink,
      attempts,
      submissionDate,
    } = ele;
    await setDoc(doc(db, `RejectedUsers ${quizname}`, email), {
      name,
      email,
      phNumber,
      experience,
      companyName,
      ctc,
      score,
      quizname,
      link,
      imgLink,
      attempts,
      submissionDate,
    });
    await deleteDoc(doc(db, `Interview-Scheduled ${quizname}`, email));
    if (formData.type === "All") {
      let document = `Interview-Scheduled ${formData.field} Internship`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `Interview-Scheduled ${formData.field} position`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    } else {
      const document = `Interview-Scheduled ${formData.field} ${formData.type}`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
      });
    }

    fetch(
      "https://us-central1-alphanumeric-career.cloudfunctions.net/server/interview-reject",
      {
        method: "POST",
        mode: "cors",
        headers: {
          "access-control-allow-origin": "*",
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          name,
          email,
          quizname,
          imgLink,
        }),
      }
    );
    alert("Rejected");
  };

  const { sort } = formData;
  if (sort === "alpha") {
    const alphaSort = userData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    userData = alphaSort;
  }

  if (sort === "date") {
    const dateSort = userData.sort((a, b) => {
      const timestampA = a.submissionDate.toMillis();
      const timestampB = b.submissionDate.toMillis();
      return timestampB - timestampA;
    });
    userData = dateSort;
  }

  return (
    <>
      <div className="date-container" style={{ zIndex: zIndex }}>
        <Form
          className="back mmm reasonW"
          style={{ opacity: display, transform: `scale(${scale})` }}
        >
          <div
            className="text-center mb-3"
            style={{ fontWeight: "700", letterSpacing: "1px" }}
          >
            Rejected Reason <br />
          </div>
          <Form.Select
            aria-label="Default select example"
            name="reason"
            onChange={handleinput}
          >
            <option selected value="">
              Select Reason
            </option>
            <option value="Unable to Contact You">Unable to Contact You</option>
            <option value="Lack of relevant experience">
              Lack of Relevant Experience
            </option>
            <option value="Weak or unimpressive application materials">
              Weak or Unimpressive Application Materials
            </option>
            <option value="Limited openings">Limited Openings</option>
            <option value="Specific requirements not met">
              Specific Requirements Not Met
            </option>
          </Form.Select>
          <div className="d-flex justify-content-around mt-4">
            <Button
              className="scheduleButton"
              variant="success"
              type="submit"
              onClick={rejectOne}
            >
              Reject
            </Button>
            <Button
              className="cancelButton"
              variant="danger"
              onClick={(e) => {
                setDisplay(0);
                setBlur("0px");
                setZindex("-2");
                setScale(1);
              }}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </div>

      <div style={{ filter: `blur(${blur})` }}>
        <h1 className="heading">Interview Scheduled Users : {userCounter}</h1>
        <div className="m-3">
          <DownloadTableExcel
            filename="interview-scheduled"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <button className="mb-2 xlsButton">
              <img className="xls" src={xls} alt="" />{" "}
            </button>
          </DownloadTableExcel>

          <div className="row justify-content-center mb-4">
            <div className="col-md-3 col-8 mt-2">
              <Form.Select
                aria-label="Default select example"
                name="type"
                onChange={handleinput}
              >
                <option value="All">All</option>
                <option value="Internship">Internships</option>
                <option value="position">Jobs</option>
              </Form.Select>
            </div>
            <div className="col-md-3 col-8 mt-2">
              <Form.Select
                aria-label="Default select example"
                name="field"
                onChange={handleinput}
              >
                <option value="Accounts">Accounts</option>
                <option value="Business Development">
                  Business Development
                </option>
                <option value="Content Writing">Content Writing</option>
                <option value="Data Analyst">Data Analyst</option>
                <option value="Data Entry">Data Entry</option>
                <option value="Data Research">Data Research</option>
                <option value="Digital Marketing">Digital Marketing</option>
                <option value="Email Marketing">Email Marketing</option>
                <option value="Graphic Design">Graphic Design</option>
                <option value="Human Resources">Human Resources</option>
                <option value="Marketing">Marketing</option>
                <option value="Online Bidding">Online Bidding</option>
                <option value="Web Development">Web Development</option>
              </Form.Select>
            </div>
            <div className="col-md-2 col-8 mt-2 text-center">
              <Button className="navButton" onClick={allData}>
                Search
              </Button>
            </div>

            <div className="col-md-2 col-8 mt-2 text-center">
              <Form.Select
                aria-label="Default select example"
                name="sort"
                onChange={handleinput}
              >
                <option value="sort">Sort By</option>
                <option value="date">Latest</option>
                <option value="alpha">Name [ A - Z ]</option>
              </Form.Select>
            </div>
            <div className="col-md-2 col-8 mt-2">
              <Form>
                <Form.Group controlId="text">
                  <Form.Control
                    className="text-center"
                    type="text"
                    placeholder="Search By Name"
                    value={formData.filterName}
                    name="filterName"
                    onChange={handleinput}
                    onKeyUp={checkData}
                  />
                </Form.Group>
              </Form>
            </div>
          </div>

          <Table
            responsive
            striped
            bordered
            hover
            variant="light"
            ref={tableRef}
          >
            <thead className="QuizBtn">
              <tr>
                <th>Sr. No.</th>
                <th>Name</th>
                <th>Email</th>
                <th>Experience</th>
                <th>Company Name</th>
                <th>CTC</th>
                <th>Category</th>
                <th>Submission Date</th>
                <th>Attempts</th>
                <th>Resume Link</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            {userData.map((ele) => {
              sr++;
              return (
                <>
                  <tbody className="QuizBtn">
                    <tr>
                      <td>{sr}</td>
                      <td>{ele.name}</td>
                      <td>{ele.email}</td>
                      <td>{ele.experience}</td>
                      <td>{ele.companyName}</td>
                      <td>{ele.ctc}</td>
                      <td>{ele.quizname}</td>
                      <td>{ele.submissionDate.toDate().toLocaleString()}</td>
                      <td>{ele.attempts}</td>
                      {ele.link ? (
                        <>
                          <td style={{ cursor: "pointer" }}>
                            <a
                              style={{ color: "#139ded" }}
                              className="resumeLink"
                              href={ele.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Open Resume
                            </a>
                          </td>
                        </>
                      ) : (
                        <>
                          <td
                            style={{ color: "#139ded", cursor: "pointer" }}
                            onClick={async () => {
                              let link = `gs://alphanumeric-career.appspot.com/resume/${ele.email}-resume.pdf`;
                              let nlink = link.replaceAll(" ", "");
                              const fileRef = ref(storage, nlink);
                              const downloadURL = await getDownloadURL(fileRef);
                              localStorage.setItem("url", downloadURL);
                              navigate("/resume-viewer");
                            }}
                          >
                            Open Resume
                          </td>
                        </>
                      )}
                      <td className="d-flex justify-content-around">
                        <Button
                          variant="success"
                          className="me-1"
                          onClick={async () => {
                            const {
                              name,
                              email,
                              phNumber,
                              experience,
                              companyName,
                              ctc,
                              score,
                              quizname,
                              link,
                              imgLink,
                              attempts,
                              submissionDate,
                            } = ele;
                            await setDoc(
                              doc(db, `Selected-Users ${quizname}`, email),
                              {
                                name,
                                email,
                                phNumber,
                                experience,
                                companyName,
                                ctc,
                                score,
                                quizname,
                                link,
                                imgLink,
                                attempts,
                                submissionDate,
                              }
                            );
                            await deleteDoc(
                              doc(db, `Interview-Scheduled ${quizname}`, email)
                            );
                            if (formData.type === "All") {
                              let document = `Interview-Scheduled ${formData.field} Internship`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                }
                              );
                              document = `Interview-Scheduled ${formData.field} position`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            } else {
                              const document = `Interview-Scheduled ${formData.field} ${formData.type}`;
                              setDocument(document);
                              getDocs(collection(db, document)).then(
                                (querySnapshot) => {
                                  querySnapshot.forEach((doc) => {
                                    data.push(doc.data());
                                  });
                                  setUserData(data);
                                }
                              );
                            }

                            fetch(
                              "https://us-central1-alphanumeric-career.cloudfunctions.net/server/select",
                              {
                                method: "POST",
                                mode: "cors",
                                headers: {
                                  "access-control-allow-origin": "*",
                                  "Content-type":
                                    "application/json; charset=UTF-8",
                                },
                                body: JSON.stringify({
                                  name,
                                  email,
                                  quizname,
                                  imgLink,
                                }),
                              }
                            ).then((res) => {
                              // setIsLoading(true);
                              // setBlur("4px");
                              // navigate("/quiz-result");
                            });
                            alert("Selected");
                          }}
                        >
                          Select
                        </Button>

                        <Button variant="danger" onClick={rejectOne}>
                          Reject
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            })}
          </Table>
          {showOrNot && userData.length === 0 ? (
            <h3 className="text-center mt-4">No Data Found</h3>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default Interviews;
