import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Time = () => {
  const duration = 10 * 60 * 1000;
  const [time, setTime] = useState(duration);
  const navigate = useNavigate();
  useEffect(() => {
    if (time !== 0) {
      setTimeout(() => {
        let f = time - 1000;
        setTime(f);
      }, 1000);
    } else {
      alert("Time is Up");
      navigate("/home");
    }
  }, [time]);

  const getFormatedTime = (milliSeconds) => {
    let totalSeconds = milliSeconds / 1000;
    let seconds = totalSeconds % 60;
    let minutes = Math.floor(totalSeconds / 60);
    return `${minutes} min : ${seconds} sec`;
  };

  return (
    <>
      <span className="timeStyle fs-5">Time : {getFormatedTime(time)}</span>
    </>
  );
};

export default Time;
