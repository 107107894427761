const online_bidding = [
  {
    quizname: "Online Bidding",
    imgLink :"https://alphanumericideas.com/images/careers/online-bidding.png",
    ques: " What is the process of submitting a monetary offer for a good or service in an auction called?",
    options: [
      "Bidding",
      "Tender",
      "Negotiation",
      "Offer",
    ],
    ans: "A",
  },
  {
    ques: " What type of auction allows for multiple rounds of bidding, with the price increasing incrementally each round?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " In a sealed bid auction, what is the term used to describe the practice of submitting a bid that is intentionally lower than the true value of the item?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "A",
  },
  {
    ques: " What type of auction is typically used for goods or services that are difficult to value, such as real estate or intellectual property?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common type of online auction platform?",
    options: [
      "Amazon",
      "eBay",
      "Alibaba",
      "Craigslist",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction allows for multiple participants to submit sealed bids, with the highest bid winning the auction?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "D",
  },
  {
    ques: " In a Dutch auction, what is the initial price set at?",
    options: [
      "The reserve price",
      "The estimated value",
      "The highest bid",
      "The lowest possible price",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction is characterized by a single bid and a single buyer, typically used for government procurement or natural resource extraction?",
    options: [
      "English auction",
      "Dutch auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe a situation in which two or more participants in an auction collude to drive up prices and decrease competition?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of placing a bid on an item at the last possible moment before the auction ends, with the goal of preventing other bidders from having time to respond?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "D",
  },
  {
    ques: " Which type of auction uses a bidding process in which the price starts at a high level and is gradually lowered until a participant is willing to accept the current price?",
    options: [
      "English auction",
      "Dutch auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "B",
  },
  {
    ques: " In a first-price sealed bid auction, what is the highest bid paying?",
    options: [
      "The Reserve Price",
      "The Second highest bid",
      "The Bid it self",
      "The Minimum Price",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe an auction where the participants are anonymous?",
    options: [
      "Open auction",
      "Blind auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe the practice of placing fake bids on an item to drive up the price of the item?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe the practice of allowing a seller to bid on their own items in an auction?",
    options: [
      "Underbidding",
      "Self-bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "B",
  },
  {
    ques: " What type of auction is typically used for goods or services that are difficult to value, such as real estate or intellectual property?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "D",
  },
  {
    ques: " In a Vickrey auction, what is the winning bid paying?",
    options: [
      "The Reserve Price",
      "The Second highest bid",
      "The Bid it self",
      "The Minimum Price",
    ],
    ans: "B",
  },
  {
    ques: " What type of auction is characterized by a single bid and a single buyer, typically used for procurement of goods and services?",
    options: [
      "English auction",
      "Dutch auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe a situation in which an auction participant is acting on behalf of the seller to artificially inflate the price of an item?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Sniping",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of placing a bid on an item with the intention of winning the auction, but then not completing the purchase?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Non-performing bid",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction is typically used for goods or services that have a fixed price, such as stock or currency?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " In a second-price sealed bid auction, what is the highest bid paying?",
    options: [
      "The Reserve Price",
      "The Second highest bid",
      "The Bid it self",
      "The Minimum Price",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe an auction where the participants are known to each other?",
    options: [
      "Open auction",
      "Blind auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the practice of placing a bid with the intention of blocking other bidders from winning the auction?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Blocking bid",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of placing a bid on an item with the intention of selling it to another party at a higher price?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Scalping",
    ],
    ans: "A",
  },
  {
    ques: " What type of auction is typically used for selling large volumes of goods or services, such as commodities or government contracts?",
    options: [
      "English auction",
      "Reverse auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "B",
  },
  {
    ques: " In a proxy bidding, what is the system doing on behalf of the bidder?",
    options: [
      "Place bids automatically",
      "Place bids manually",
      "Place bids only when the price is lower than the maximum bid",
      "Place bids only when the price is higher than the maximum bid",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe an auction where the participants are not physically present, but participate via the internet or other electronic means?",
    options: [
      "Online auction",
      "Offline auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the practice of using multiple identities to bid on an item in an auction?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Bid shielding",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of buying goods or services through an auction with the intention of reselling them at a higher price?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Scalping",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction is characterized by a single bid and multiple buyers, typically used for procurement of goods and services?",
    options: [
      "English auction",
      "Dutch auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "C",
  },
  {
    ques: " In a multi-unit auction, what is the auction selling?",
    options: [
      "A single item",
      "Multiple identical items",
      "Multiple unique items",
      "A bundle of items",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe an auction where the winner is determined by a random selection, rather than by the highest bid?",
    options: [
      "Open auction",
      "Blind auction",
      "Reverse auction",
      "Lottery auction",
    ],
    ans: "D",
  },
  {
    ques: " What is the term used to describe the practice of using automated software to place bids on an item in an auction?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Bot bidding",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of purchasing goods or services through an auction with the intention of using them for personal or business use?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Consumption",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction is typically used for selling luxury or high-end goods, such as artwork or collectibles?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " In a silent auction, what is the way of bidding?",
    options: [
      "Oral bidding",
      "Written bidding",
      "Online bidding",
      "Phone bidding",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe an auction where the participants are allowed to change their bids during the course of the auction?",
    options: [
      "Open auction",
      "Blind auction",
      "Dynamic auction",
      "Sealed bid auction",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe the practice of placing a bid on an item in an auction with the intention of driving up the price of the item for the benefit of another bidder?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Shill bidding",
      "Padding",
    ],
    ans: "D",
  },
  {
    ques: " What is the process of buying goods or services through an auction with the intention of reselling them at a profit, while taking advantage of price differences between different markets?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Scalping",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of submitting a monetary offer for a good or service in an auction called?",
    options: [
      "Tender",
      "Quotation",
      "Negotiation",
      "Bidding",
    ],
    ans: "D",
  },
  {
    ques: " What type of auction is used for selling goods or services that have a fixed quantity, such as commodities or raw materials?",
    options: [
      "English auction",
      "Dutch auction",
      "Reverse auction",
      "Sealed bid auction",
    ],
    ans: "C",
  },
  {
    ques: " In a forward auction, what is the process of the auction?",
    options: [
      "The price starts low and increases",
      "The price starts high and decreases",
      "The price is fixed and bidders compete to buy",
      "The price is based on the last sale",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the practice of placing a bid on an item in an auction with the intention of driving down the price of the item for the benefit of another bidder?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Dumping",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of buying goods or services through an auction with the intention of reselling them at a profit, while taking advantage of seasonal or market fluctuations?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Scalping",
    ],
    ans: "B",
  },
  {
    ques: " What type of auction is typically used for selling goods or services that have a unique value, such as real estate or art?",
    options: [
      "English auction",
      "Dutch auction",
      "Vickrey auction",
      "Sealed bid auction",
    ],
    ans: "A",
  },
  {
    ques: " In a descending clock auction, what is the process of the auction?",
    options: [
      "The price starts high and decreases at regular intervals until a bid is placed",
      "The price starts low and increases at regular intervals until a bid is placed",
      "The price is fixed and bidders compete to buy",
      "The price is based on the last sale",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the practice of using multiple identities to bid on an item in an auction, in order to inflate the price?",
    options: [
      "Underbidding",
      "Shill bidding",
      "Bid rigging",
      "Bid pumping",
    ],
    ans: "B",
  },
  {
    ques: " What is the process of buying goods or services through an auction with the intention of using them for personal or business use, but at a reduced price compared to the market value?",
    options: [
      "Flipping",
      "Speculation",
      "Arbitrage",
      "Bargain hunting",
    ],
    ans: "D",
  },
  {
    ques: " What is the term used to describe an auction where the participants are allowed to bid on multiple items in a single auction?",
    options: [
      "Multi-unit auction",
      "Package auction",
      "Bundle auction",
      "Combinatorial auction",
    ],
    ans: "A",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  ]

  export default online_bidding