import React from "react";
import time from "../images/time.png";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import { useNavigate } from "react-router-dom";

const TryAfter = () => {
  const navigate = useNavigate();
  return (
    <Container className="d-flex justify-content-center mt-5">
      <div className="row mt-5">
        <div className="col mt-2 text">
          <img
            className="try"
            src={time}
            alt="Try after seven days from submission date"
          />
          <br></br>
          <Button
            className="mt-5 goBckBtn"
            onClick={() => {
              navigate("/all-jobs");
            }}
          >
            Go back
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default TryAfter;
