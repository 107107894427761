const data_analyst = [
  {
    quizname: "Data Analyst",
    imgLink :"https://alphanumericideas.com/images/careers/data-analyst.png",
    ques: " What is the most commonly used programming language for data analysis?",
    options: [
      "Python",
      "R",
      "Java",
      "C++",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for data visualization in Python?",
    options: [
      "Matplotlib",
      "Seaborn",
      "ggplot",
      "Plotly",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for data manipulation in Python?",
    options: [
      "Pandas",
      "NumPy",
      "SciPy",
      "Scikit-learn",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for machine learning in Python?",
    options: [
      "Pandas",
      "NumPy",
      "SciPy",
      "Scikit-learn",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for natural language processing in Python?",
    options: [
      "NLTK",
      "Gensim",
      "Spacy",
      "CoreNLP",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for deep learning in Python?",
    options: [
      "TensorFlow",
      "Keras",
      "PyTorch",
      "Theano",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for data visualization in R?",
    options: [
      "ggplot2",
      "Matplotlib",
      "Seaborn",
      "Plotly",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for data manipulation in R?",
    options: [
      "dplyr",
      "tidyr",
      "data",
      "Plotly",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the library used for machine learning in R?",
    options: [
      "Caret",
      "randomForest",
      "e1071",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for natural language processing in R?",
    options: [
      "tm",
      "SnowballC",
      "quanteda",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for deep learning in R?",
    options: [
      "Keras",
      "Tensorflow",
      "Mxnet",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for data visualization in SQL?",
    options: [
      "SQLPlot",
      "MySQLPlot",
      "SQLGraph",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for data manipulation in SQL?",
    options: [
      "SQLData",
      "MySQLData",
      "SQLTable",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for machine learning in SQL?",
    options: [
      "SQLMachine",
      "MySQLMachine",
      "SQLModel",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the library used for natural language processing in SQL?",
    options: [
      "SQL NLP",
      "MySQL NLP",
      "SQLText",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the file format used for data analysis?",
    options: [
      "CSV",
      "Excel",
      "JSON",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data structure used for data analysis?",
    options: [
      "List",
      "Array",
      "Dataframe",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is the statistical method used for measuring the association between two variables?",
    options: [
      "Correlation",
      "Regression",
      "ANOVA",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the statistical method used for predicting the value of a dependent variable based on the value of one or more independent variables?",
    options: [
      "Correlation",
      "Regression",
      "ANOVA",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the name of the statistical method used for comparing the means of multiple groups?",
    options: [
      "Correlation",
      "Regression",
      "ANOVA",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is the name of the statistical method used for testing the significance of the difference between two means?",
    options: [
      "t-test",
      "z-test",
      "chi-squared test",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the statistical method used for testing the significance of the difference between two proportions?",
    options: [
      "t-test",
      "z-test",
      "chi-squared test",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the name of the statistical method used for testing the significance of the association between two categorical variables?",
    options: [
      "t-test",
      "z-test",
      "chi-squared test",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is the name of the data model used for prediction?",
    options: [
      "Linear regression",
      "Logistic regression",
      "Decision tree",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data model used for classification?",
    options: [
      "Linear regression",
      "Logistic regression",
      "Decision tree",
      "Both B and C",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data model used for clustering?",
    options: [
      "K-means",
      "Hierarchical",
      "DBSCAN",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data model used for dimensionality reduction?",
    options: [
      "PCA",
      "LDA",
      "t-SNE",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data model used for natural language processing?",
    options: [
      "N-gram",
      "LDA",
      "Word2Vec",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the data model used for the recommendation system?",
    options: [
      "Content-based",
      "Collaborative filtering",
      "Hybrid",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for supervised learning?",
    options: [
      "KNN",
      "SVM",
      "Random Forest",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for unsupervised learning?",
    options: [
      "K-means",
      "SVM",
      "Random Forest",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the algorithm used for semi-supervised learning?",
    options: [
      "Self-training",
      "Co-training",
      "Multi-view learning",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for reinforcement learning?",
    options: [
      "Q-learning",
      "SARSA",
      "DQN",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for deep learning?",
    options: [
      "CNN",
      "RNN",
      "LSTM",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for feature selection?",
    options: [
      "Filter",
      "Wrapper",
      "Embedded",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for feature extraction?",
    options: [
      "PCA",
      "LDA",
      "t-SNE",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for feature engineering?",
    options: [
      "PCA",
      "LDA",
      "t-SNE",
      "None of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for anomaly detection?",
    options: [
      "Isolation Forest",
      "Local Outlier Factor",
      "One-class SVM",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for image processing?",
    options: [
      "OpenCV",
      "ImageKit",
      "Pillow",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for text processing?",
    options: [
      "NLTK",
      "Gensim",
      "Spacy",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for time series analysis?",
    options: [
      "ARIMA",
      "SARIMA",
      "Prophet",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for survival analysis?",
    options: [
      "Kaplan-Meier",
      "Cox proportional hazards",
      "Aalen's additive model",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for the decision tree?",
    options: [
      "C4.5",
      "ID3",
      "CART",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for random forest?",
    options: [
      "C4.5",
      "ID3",
      "CART",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for gradient boosting?",
    options: [
      "XGBoost",
      "LightGBM",
      "CatBoost",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for natural language generation?",
    options: [
      "GPT-2",
      "BERT",
      "T5",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the name of the algorithm used for natural language understanding?",
    options: [
      "GPT-2",
      "BERT",
      "T5",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for natural language dialogue?",
    options: [
      "GPT-2",
      "BERT",
      "T5",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for natural language sentiment analysis?",
    options: [
      "TextBlob",
      "VaderSentiment",
      "AFINN",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the name of the algorithm used for natural language summarization?",
    options: [
      "Gensim",
      "PyTextRank",
      "Sumy",
      "All of the above",
    ],
    ans: "D",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  ]

  export default data_analyst