const JCardData = [
  {
    title: "Accounts",
    link: "/quiz",
    description:
      "As an accounts professional at Alphanumeric Ideas, you will be responsible for managing the financial operations of the company and ensuring compliance with financial regulations and standards. Your job will involve working closely with other departments, such as sales and marketing, to ensure that financial information is accurate and up-to-date.",
    description2:
      "One of the primary responsibilities of the accounts job is to manage the company's accounts receivable and payable. This involves preparing and sending invoices to clients, following up on overdue payments, and managing vendor payments. You will also be responsible for reconciling accounts, tracking expenses, and preparing financial reports.",
    enable: true,
    imgLink: "https://alphanumericideas.com/images/careers/icons/accounts.png",
    minimum: [
      "Bachelor's degree in Accounting, Finance, or a related field.",
      "2-3 years of experience in accounts payable and receivable, preferably in a digital marketing or creative industry.",
      "Strong understanding of financial regulations and standards, such as tax filings, budget constraints, and financial reporting requirements.",
      "Proficiency in financial software and systems, such as QuickBooks or Xero.",
      "Excellent communication skills and ability to work collaboratively with other departments and stakeholders.",
    ],

    responsibilities: [
      "Managing the company's accounts receivable and payable, including preparing and sending invoices to clients, following up on overdue payments, and managing vendor payments.",
      "Reconciling accounts, tracking expenses, and preparing financial reports.",
      "Ensuring compliance with financial regulations and standards, such as tax filings, budget constraints, and financial reporting requirements.",
      "Working closely with other departments, such as sales and marketing, to ensure that financial information is accurate and up-to-date.",
      "Maintaining accurate records and ensuring data integrity in financial systems and software.",
    ],
  },
  {
    title: "Digital Marketing",
    link: "/quiz",
    description:
      "The role of a Digital Marketer at Alphanumeric Ideas involves working closely with clients to understand their business goals and objectives. They then develop and execute customized digital marketing campaigns that include various tactics such as search engine optimization (SEO), pay-per-click (PPC) advertising, social media marketing, email marketing, and content marketing. Digital Marketers at Alphanumeric Ideas must have a deep understanding of digital marketing channels and be proficient in using various digital marketing tools and software. They should also have excellent communication skills and the ability to collaborate with clients and team members to achieve desired results.",
    description2:
      "One of the key responsibilities of a Digital Marketer at Alphanumeric Ideas is to identify target audiences and develop strategies to reach them effectively. They must be able to analyze market trends, consumer behavior, and competitor activity to gain insights into the target audience's needs and preferences.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/digital-marketing.png",
    minimum: [
      "Education: A bachelor's degree in marketing, communications, business, or a related field. A master's degree may also be preferred.",
      "Technical skills: Proficiency in digital marketing tools and software, including Google Analytics, Google Ads, Facebook Ads Manager, Hootsuite, and email marketing platforms. Experience with graphic design tools such as Adobe Photoshop or Canva may also be preferred.",
      "Analytical skills: Strong analytical skills, with the ability to measure and analyze campaign performance metrics and use data to make informed decisions.",
      "Communication skills: Excellent communication skills, with the ability to present ideas and strategies in a clear and concise manner to clients and team members.",
      "Interpersonal skills: Strong interpersonal skills, with the ability to collaborate effectively with clients and team members and work in a fast-paced, deadline-driven environment.",
    ],
    responsibilities: [
      "Developing digital marketing strategies: Collaborating with clients to develop customized digital marketing strategies that align with their business goals and objectives.",
      "Conducting market research: Conducting market research to gain insights into consumer behavior, market trends, and competitor activity, and using this information to inform digital marketing strategies.",
      "Managing campaigns: Planning and executing digital marketing campaigns across various channels, including SEO, PPC advertising, social media, email marketing, and content marketing.",
      "Creating content: Creating and optimizing digital marketing content, including website copy, social media posts, email campaigns, and digital ads.",
      "Measuring and analyzing performance: Tracking and analyzing key performance metrics such as website traffic, lead generation, and conversion rates, and using this data to optimize campaigns and improve results.",
    ],
  },
  {
    title: "Marketing",
    link: "/quiz",
    description:
      "One of the key areas of focus for marketing at Alphanumeric Ideas is lead generation. As a digital marketing agency, the company relies on a steady flow of new business to sustain its growth and success. Your job will involve developing and executing marketing campaigns that target potential customers and generate leads through a variety of channels, such as email marketing, social media, content marketing, and paid advertising.",
    description2:
      "Another critical responsibility for marketing at Alphanumeric Ideas is brand building and awareness. As a digital marketing agency, the company operates in a highly competitive and dynamic industry, which requires a strong and distinctive brand identity. Your job will involve developing and executing brand-building initiatives that differentiate the company from its competitors and position it as a thought leader in the industry.Another critical responsibility for marketing at Alphanumeric Ideas is brand building and awareness. As a digital marketing agency, the company operates in a highly competitive and dynamic industry, which requires a strong and distinctive brand identity. Your job will involve developing and executing brand-building initiatives that differentiate the company from its competitors and position it as a thought leader in the industry.",
    enable: true,
    imgLink: "https://alphanumericideas.com/images/careers/icons/marketing.png",
    minimum: [
      "Bachelor's degree in Marketing, Business Administration, or a related field.",
      "2-3 years of experience in marketing, preferably in a digital marketing or creative industry.",
      "Strong understanding of digital marketing channels and tactics, including email marketing, social media, content marketing, and paid advertising.",
      "Excellent communication, project management, and problem-solving skills.",
      "Familiarity with marketing software and tools, such as marketing automation, social media management, and analytics platforms.",
    ],
    responsibilities: [
      "Lead Generation: Developing and executing marketing campaigns that generate leads through email marketing, social media, content marketing, and paid advertising.",
      "Brand Building: Developing and executing brand-building initiatives that differentiate the company from competitors and position it as a thought leader in the industry.",
      "Client Relationship Management: Understanding client business goals and developing marketing strategies that align with their objectives. Ensuring customer satisfaction and maintaining positive relationships with clients.",
      "Content Marketing: Developing and executing content marketing strategies that engage target audiences, drive traffic to the company's website, and generate leads.",
      "Social Media Marketing: Developing and executing social media marketing strategies that increase brand awareness, engage target audiences, and drive traffic to the company's website.",
    ],
  },
  {
    title: "Human Resources",
    link: "/quiz",
    description:
      "One of the key areas of focus for HR at Alphanumeric Ideas is talent acquisition. As a digital marketing agency, the company relies heavily on skilled and knowledgeable professionals in areas such as social media management, content creation, and search engine optimization. Your job will involve developing and implementing strategies to attract, hire, and retain the best talent in the industry.",
    description2:
      "Another critical responsibility for HR at Alphanumeric Ideas is employee engagement and retention. As a digital marketing agency, the company operates in a fast-paced and dynamic industry, which can be demanding and stressful. As an HR professional, you will need to work closely with managers and employees to identify opportunities for growth and development, and to implement programs and initiatives that promote a positive work environment and foster employee engagement.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/human-resources.png",
    minimum: [
      "Bachelor's degree in Human Resources, Business Administration, or a related field.",
      "2-3 years of experience in human resources, preferably in a digital marketing or creative industry.",
      "Strong understanding of HR principles and practices, including recruitment, compensation and benefits, employee engagement, and performance management.",
      "Excellent communication, negotiation, and problem-solving skills.",
      "Knowledge of labor laws and regulations, including FMLA, ADA, and EEOC.",
    ],
    responsibilities: [
      "Talent Acquisition: Developing and implementing strategies to attract, hire, and retain the best talent in the industry. This includes job posting, candidate screening, interviewing, and onboarding.",
      "Employee Engagement: Developing programs and initiatives that promote a positive work environment, foster employee engagement, and support professional growth and development.",
      "Compensation and Benefits: Managing employee compensation and benefits, including salary negotiations, payroll processing, and administering employee benefit programs.",
      "Policy and Procedure Administration: Developing and implementing employee policies and procedures, ensuring compliance with labor laws and regulations, and communicating HR policies and procedures to employees.",
      "Performance Management: Developing and implementing employee performance management processes, including goal setting, performance evaluations, and career development plans.",
    ],
  },
  {
    title: "Business Development",
    link: "/quiz",
    enable: true,
    description:
      "The role of a business development professional at Alphanumeric Ideas involves identifying new business opportunities, researching potential clients, and building relationships with key decision-makers. You would need to have a deep understanding of the digital marketing landscape, including the latest trends and technologies, and be able to identify opportunities for the agency to offer its services to potential clients.",
    description2:
      "Your responsibilities would also include creating and delivering presentations and proposals to potential clients, negotiating contracts and pricing, and working closely with the agency's internal teams to meet client needs. You would be expected to develop a deep understanding of the client's business, industry, and competition to create effective marketing strategies that deliver results.",
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/business-development.png",
    minimum: [
      "Bachelor's degree in Business Administration, Marketing, Communications or related field.",
      "2-3 years of experience in sales or business development in the digital marketing industry.",
      "Strong communication and interpersonal skills to build relationships with potential clients.",
      "Knowledge of digital marketing strategies, technologies, and trends.",
      "Proven track record of generating new business and closing deals.",
    ],
    responsibilities: [
      "Identifying new business opportunities and potential clients in the digital marketing industry.",
      "Conducting market research and staying up-to-date with industry trends and developments.",
      "Developing and executing sales strategies to achieve revenue and growth targets.",
      "Building and maintaining relationships with key decision-makers and stakeholders.",
      "Creating and delivering compelling presentations and proposals to potential clients.",
    ],
  },
  {
    title: "Content Writing",
    link: "/quiz",
    description:
      "The role of a content writer at Alphanumeric Ideas involves researching and writing various types of content, such as blog posts, articles, social media posts, email newsletters, product descriptions, and more. You would need to deeply understand the client's target audience, industry, and competitors to create content that resonates with their customers and achieves their marketing goals.",
    description2:
      "Your responsibilities would also include collaborating with the agency's internal teams, such as designers and digital marketers, to ensure the content aligns with the client's overall marketing strategy. You would need to be able to work independently and manage multiple projects simultaneously while adhering to deadlines and quality standards.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/content-writing.png",
    minimum: [
      "Bachelor's degree in English, Journalism, Marketing, Communications, or a related field.",
      "Proven experience as a content writer in a digital marketing, advertising agency, or related field.",
      "Strong writing and editing skills with the ability to create compelling and engaging content for various digital channels.",
      "Ability to conduct research and synthesize information from various sources to create high-quality content.",
      "Knowledge of SEO best practices and the ability to optimize content for search engines.",
    ],
    responsibilities: [
      "Conducting research to deeply understand the client's target audience, industry, and competitors.",
      "Creating high-quality and engaging content, including blog posts, articles, social media posts, email newsletters, product descriptions, and more, aligns with the client's marketing goals.",
      "Adhering to established style guidelines and brand voice for each client.",
      "Optimizing content for search engines and ensuring that it meets SEO best practices.",
      "Collaborating with designers, digital marketers, and other internal teams to ensure that the content aligns with the client's overall marketing strategy.",
    ],
  },
  {
    title: "Data Analyst",
    link: "/quiz",
    description:
      "As a Data Analyst intern at Alphanumeric Ideas, a digital marketing agency, you will have the opportunity to gain hands-on experience in the field of data analysis and marketing analytics. During your internship, you will work closely with the Analytics team to support their various initiatives, including data collection, data analysis, and reporting.",
    description2:
      "Throughout your internship, you will gain exposure to various aspects of marketing analytics, including web analytics, social media analytics, and digital marketing analytics. You will work alongside experienced professionals and learn best practices for using data to inform marketing decisions and drive business outcomes.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-analyst.png",
    minimum: [
      "Currently pursuing a Bachelor's or Master's degree in Statistics, Mathematics, Computer Science, Information Technology, Marketing, or a related field.",
      "Strong analytical and critical thinking skills, with the ability to analyze complex data sets and extract meaningful insights.",
      "Proficiency in data analysis tools such as Microsoft Excel, SQL, R, Python, or other statistical analysis tools.",
      "Familiarity with web analytics platforms such as Google Analytics and social media analytics tools such as Facebook Insights.",
      "Experience with data visualization tools such as Tableau, Power BI, or D3.js.",
    ],
    responsibilities: [
      "Data collection and management: You will assist with collecting data from various sources, cleaning and organizing data, and ensuring its accuracy and completeness.",
      "Data analysis: You will work with the Analytics team to develop and execute data analysis strategies, analyze complex data sets, and extract meaningful insights.",
      "Reporting: You will assist with creating reports and visualizations to communicate insights to both technical and non-technical stakeholders.",
      "Supporting data-driven decision-making: You will provide data-driven insights to inform marketing and business decisions.",
      "Research: You will conduct research on industry trends and competitors to support the Analytics team's initiatives.",
    ],
  },
  {
    title: "Data Entry",
    link: "/quiz",
    description:
      "The Data Entry role at Alphanumeric Ideas is critical for inputting and maintaining data accuracy for the agency and its clients. The Data Entry professional is responsible for data entry, verification, and quality control.",
    description2:
      "In this role, attention to detail and accuracy is crucial, as well as the ability to work collaboratively with other teams. The Data Entry professional will be responsible for entering data accurately and efficiently into various systems, verifying the accuracy of data, conducting data quality control checks, and maintaining data confidentiality and security. The Data Entry professional will also be expected to identify opportunities to improve data entry processes and provide recommendations. Overall, the Data Entry position is critical in ensuring that Alphanumeric Ideas and its clients have accurate and reliable data for their marketing strategies.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-entry.png",
    minimum: [
      "High school diploma or equivalent.",
      "1-2 years of experience in data entry, preferably in a digital marketing or advertising agency.",
      "Excellent typing speed and accuracy.",
      "Proficiency in Microsoft Office applications, especially Excel.",
      "Familiarity with data entry software systems.",
    ],
    responsibilities: [
      "Enter data accurately and efficiently into a variety of systems.",
      "Verify the accuracy of data in the systems.",
      "Ensure data quality by performing data quality control checks.",
      "Conduct regular data audits to identify errors or inconsistencies.",
      "Work collaboratively with other internal teams to ensure the accuracy of data.",
    ],
  },
  {
    title: "Data Research",
    link: "/quiz",
    description:
      "The role of a Data Researcher at Alphanumeric Ideas involves working closely with clients to understand their business goals and objectives. They then design research studies that gather relevant data and analyze the results to provide actionable insights. These insights can be used to improve a client's marketing strategy, optimize advertising campaigns, and ultimately increase revenue.",
    description2:
      "One of the key responsibilities of a Data Researcher at Alphanumeric Ideas is to identify trends and patterns in large data sets. They must be able to understand and manipulate data from various sources, including web analytics, customer databases, and social media platforms, to gain a comprehensive understanding of a client's target audience.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-research.png",
    minimum: [
      "Education: A bachelor's degree in a related field such as statistics, mathematics, computer science, data analytics, or marketing research. A master's degree may also be preferred.",
      "Technical skills: Proficiency in data analysis tools and software such as Excel, SPSS, SAS, R, and SQL. Experience with data visualization tools such as Tableau or Power BI may also be preferred.",
      "Analytical skills: Strong analytical and problem-solving skills, with the ability to manipulate and analyze large datasets and identify trends and patterns.",
      "Communication skills: Excellent communication skills, with the ability to present complex data analysis results in a clear and concise manner to clients and team members.",
      "Interpersonal skills: Strong interpersonal skills, with the ability to collaborate effectively with clients and team members and work in a fast-paced, deadline-driven environment.",
    ],
    responsibilities: [
      "Conducting research studies: Designing and conducting research studies to gather relevant data and insights that help clients make informed marketing decisions.",
      "Analyzing data: Analyzing data collected from various sources such as web analytics, customer databases, and social media platforms to identify trends, patterns, and insights.",
      "Creating reports: Creating reports and presentations that present data analysis results in a clear and concise manner to clients and team members.",
      "Developing data models: Developing data models that help identify and predict trends and patterns, and assist in making informed business decisions.",
      "Collaborating with clients: Collaborating with clients to understand their business goals, objectives, and requirements, and developing research strategies that address their needs.",
    ],
  },
  {
    title: "Email Marketing",
    link: "/quiz",
    description:
      "The role of an Email Marketing Specialist at Alphanumeric Ideas involves creating, managing, and optimizing email campaigns that align with clients' business objectives. This may include developing email lists, creating compelling email content, designing email templates, scheduling and sending emails, and tracking and analyzing campaign performance.",
    description2:
      "To be successful as an Email Marketing Specialist at Alphanumeric Ideas, you must possess a deep understanding of email marketing best practices and be proficient in using email marketing tools and software. You should also have excellent communication and collaboration skills to work with clients and team members effectively.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/email-marketing.png",
    minimum: [
      "Education: A bachelor's degree in marketing, communications, business, or a related field. A master's degree may also be preferred.",
      "Technical skills: Proficiency in email marketing tools and software, including Mailchimp, HubSpot, or similar email marketing software. Experience with graphic design tools such as Adobe Photoshop or Canva may also be preferred.",
      "Analytical skills: Strong analytical skills, with the ability to measure and analyze campaign performance metrics and use data to make informed decisions.",
      "Communication skills: Excellent communication skills, with the ability to write compelling email copy and present ideas and strategies in a clear and concise manner to clients and team members.",
      "Interpersonal skills: Strong interpersonal skills, with the ability to collaborate effectively with clients and team members and work in a fast-paced, deadline-driven environment.",
    ],
    responsibilities: [
      "Developing email marketing strategies: Collaborating with clients to develop customized email marketing strategies that align with their business goals and objectives.",
      "Creating email campaigns: Creating and managing email campaigns that include compelling email content, targeted segmentation, visually appealing email templates, and calls-to-action that align with clients' brand guidelines.",
      "Managing email lists: Managing email lists and segmenting them based on customer behavior and preferences to personalize email content to drive engagement.",
      "Analyzing email performance: Measuring and analyzing email campaign performance metrics such as open rates, click-through rates, and conversion rates, and using this data to optimize campaigns and improve results.",
      "Staying up-to-date with industry trends: Staying up-to-date with email marketing industry trends and best practices to ensure clients' email campaigns are effective and current.",
    ],
  },
  {
    title: "Graphic Design",
    link: "/quiz",
    description:
      "The primary responsibility of a Graphic Designer at Alphanumeric Ideas is to create high-quality design assets that align with clients' brand guidelines and marketing goals. This may include developing designs for social media, email marketing, display advertising, and other digital marketing channels.",
    description2:
      "In addition to creating designs, Graphic Designers at Alphanumeric Ideas must also be proficient in using design tools and software such as Adobe Creative Suite to produce high-quality visual assets efficiently. They should also be knowledgeable about design principles such as color theory, typography, and layout to create visually appealing and effective designs.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/graphic-design.png",
    minimum: [
      "Education: A bachelor's degree in graphic design, visual communication, or a related field. Equivalent work experience may also be considered.",
      "Technical skills: Proficiency in design software such as Adobe Creative Suite, including Photoshop, Illustrator, and InDesign. Knowledge of web design tools such as HTML and CSS may also be preferred.",
      "Portfolio: A strong portfolio of design work that demonstrates creativity, technical ability, and a solid understanding of design principles.",
      "Communication skills: Excellent communication skills, with the ability to collaborate effectively with clients and team members and work in a fast-paced, deadline-driven environment.",
      "Attention to detail: Strong attention to detail, with the ability to identify errors and inconsistencies in design projects and ensure accuracy.",
    ],
    responsibilities: [
      "Creating design assets: Creating high-quality designs for various digital marketing channels, including social media, email marketing, display advertising, and more. This may include designing logos, banners, infographics, and other visual assets.",
      "Adhering to brand guidelines: Ensuring that all design assets adhere to clients' brand guidelines and are consistent with their overall marketing strategy.",
      "Using design software: Using design tools and software such as Adobe Creative Suite to produce high-quality designs efficiently.",
      "Collaborating with the creative team: Collaborating with copywriters, digital marketers, and other members of the creative team to develop cohesive and effective marketing campaigns.",
      "Staying up-to-date with design trends: Staying up-to-date with design trends and best practices to ensure that Alphanumeric Ideas' designs are fresh, innovative, and effective.",
    ],
  },
  {
    title: "Online Bidding",
    link: "/quiz",
    description:
      "As an online bidding specialist at Alphanumeric Ideas, a digital marketing agency, your primary responsibility will be to manage the company's bidding process for various online advertising platforms, such as Google Ads, Bing Ads, and Facebook Ads. Your job will involve analyzing data, developing bidding strategies, and optimizing campaigns to ensure maximum return on investment (ROI) for the company and its clients.",
    description2:
      "One of the key areas of focus for online bidding at Alphanumeric Ideas is cost per acquisition (CPA) optimization. As an online bidding specialist, your job will be to drive down the cost per acquisition of online advertising campaigns while maintaining or increasing the quality of leads generated. This will require a deep understanding of bidding algorithms, campaign optimization techniques, and conversion rate optimization (CRO).",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/online-bidding.png",
    minimum: [
      "Bachelor's degree in Marketing, Business Administration, or a related field.",
      "2-3 years of experience in online advertising, particularly in the area of bidding and campaign optimization.",
      "Strong understanding of online advertising platforms, such as Google Ads, Bing Ads, and Facebook Ads, and their bidding algorithms.",
      "Proficiency in data analysis and reporting using analytics tools and software.",
      "Professional certifications in online advertising and analytics, such as Google Ads and Analytics certifications, would be an added advantage.",
    ],
    responsibilities: [
      "Bidding strategy development: Developing bidding strategies for various online advertising platforms, such as Google Ads, Bing Ads, and Facebook Ads, based on client goals and budget constraints.",
      "Campaign optimization: Continuously optimizing online advertising campaigns to improve key performance indicators (KPIs) such as cost per click (CPC), click-through rate (CTR), and conversion rate (CR).",
      "Cost per acquisition (CPA) optimization: Driving down the cost per acquisition of online advertising campaigns while maintaining or increasing the quality of leads generated.",
      "Data analysis: Analyzing data using analytics tools and software to identify trends, make data-driven decisions, and report on campaign performance to clients and internal stakeholders.",
      "Reporting: Providing regular reporting on campaign performance to clients and internal stakeholders, including analysis of KPIs and recommendations for improvement.",
    ],
  },
  {
    title: "Web Development",
    link: "/quiz",
    description:
      "One of the key areas of focus for web development at Alphanumeric Ideas is user experience (UX) and user interface (UI) design. As a web developer, your job will be to create websites that are visually appealing, easy to navigate, and optimized for conversion. This will require a deep understanding of UX/UI best practices, as well as knowledge of coding languages and frameworks such as HTML, CSS, JavaScript, and jQuery.",
    description2:
      "Another critical responsibility for web development at Alphanumeric Ideas is website optimization. Your job will involve optimizing websites for search engine optimization (SEO), page speed, and mobile responsiveness, to ensure that they are easily discoverable and accessible to users on all devices. This will require knowledge of SEO best practices, as well as experience with website optimization tools and techniques.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/web-development.png",
    minimum: [
      "Bachelor's degree in Computer Science, Web Development, or a related field.",
      "2-3 years of experience in web development, preferably in a digital marketing or creative industry.",
      "Strong understanding of coding languages and frameworks such as HTML, CSS, JavaScript, Expressjs, ReactJs and AngularJs.",
      "Familiarity with content management systems (CMS) such as WordPress and Drupal.",
      "Experience with website optimization tools and techniques, such as SEO, page speed optimization, and mobile responsiveness.",
    ],
    responsibilities: [
      "Coding, designing, and modifying websites according to client specifications and industry best practices.",
      "Creating websites that are visually appealing, easy to navigate, and optimized for conversion.",
      "Optimizing websites for SEO, page speed, and mobile responsiveness.",
      "Troubleshooting issues, performing updates and backups, and ensuring the security and integrity of the company's and clients' websites.",
      "Staying up to date with industry trends and best practices in web development, UX/UI design, and website optimization.",
    ],
  },

  // {
  //   title: "Flutter",
  //   link: "/quiz",
  //   description:
  //     "Are you a highly skilled Flutter Developer looking for an exciting opportunity to showcase your talent and creativity? Look no further! Alphanumeric Ideas, a renowned digital marketing agency, is thrilled to offer an exceptional job opening for a Flutter Developer to join our dynamic team.",
  //   description2:
  //     "Alphanumeric Ideas is a leading digital marketing agency known for its innovative and result-oriented approach. We specialize in providing top-notch digital marketing solutions to clients across various industries, helping them thrive in the ever-evolving digital landscape. Our team is comprised of dedicated professionals who are passionate about their work, fostering a collaborative and inspiring work environment.",
  //   enable: true,
  //   imgLink: "https://alphanumericideas.com/images/careers/icons/marketing.png",
  //   minimum: [
  //     "Proven experience as a Flutter Developer with a strong portfolio showcasing successful mobile app projects.",
  //     "Proficiency in Dart programming language and solid knowledge of mobile app development frameworks and tools.",
  //     "In-depth understanding of Flutter SDK and its capabilities for building cross-platform applications.",
  //     "Strong problem-solving skills and the ability to troubleshoot and resolve technical challenges efficiently.",
  //     "A passion for staying up-to-date with the latest trends and advancements in mobile app development.",
  //   ],
  //   responsibilities: [
  //     "Collaborating with our design and development teams to understand client requirements and translate them into functional and visually appealing app interfaces.",
  //     "Conducting thorough testing and debugging of applications to ensure a seamless and bug-free user experience.",
  //     "Staying updated with the latest advancements in Flutter and mobile app development to incorporate cutting-edge features and best practices into our projects.",
  //     "Assisting in code reviews, offering constructive feedback, and implementing improvements to maintain code quality and consistency.",
  //     "Collaborating with cross-functional teams, including designers, backend developers, and project managers, to ensure seamless app integration and project success.",
  //   ],
  // },
];

export default JCardData;
