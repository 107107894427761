import business_developement from "./AllFieldsQuestions/Business-development-Quiz";
import content_writing from "./AllFieldsQuestions/Content-Writing-Quiz";
import data_analyst from "./AllFieldsQuestions/Data-Analyst-Quiz";
import data_entry from "./AllFieldsQuestions/Data-Entry-Quiz";
import data_research from "./AllFieldsQuestions/Data-Research-Quiz";
import digital_marketing from "./AllFieldsQuestions/Digital-Marketing-Quiz";
import email_markrting from "./AllFieldsQuestions/Email-Marketing-Quiz";
import graphic_design from "./AllFieldsQuestions/Graphic-Design-Quiz";
import human_resources from "./AllFieldsQuestions/Human-resources-Quiz";
import _marketing from "./AllFieldsQuestions/MarketingQuiz";
import online_bidding from "./AllFieldsQuestions/Online-Bidding-Quiz";
import web_development from "./AllFieldsQuestions/Web-development-Quiz";
import _accounts from "./AllFieldsQuestions/AccountsQuiz";

const QuizData = [
  // ********************** ACCOUNTS *************************

  _accounts,
  // ********************** DIGITAL MARKETING *************************

  digital_marketing,
  // ********************** MARKETING *************************

  _marketing,
  // ********************** HUMAN RESOURCES *************************

  human_resources,
  // ********************** BUSINNESS DEVELOPMENT *************************

  business_developement,
  // ********************** CONTENT WRITING *************************

  content_writing,
  // ********************** DATA ANALYST *************************

  data_analyst,
  // ********************** DATA ENTRY *************************

  data_entry,
  // ********************** DATA RESEARCH *************************

  data_research,
  // ********************** EMAIL MARKETING *************************

  email_markrting,
  // ********************** GRAPHIC DESIGNER *************************

  graphic_design,
  // ********************** ONLINE BIDDING *************************

  online_bidding,
  // ********************** WEB DEVELOPMENT *************************

  web_development,
];

export default QuizData;
