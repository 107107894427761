import React from "react";
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/Button";
import CardData from "./CardData";
import OfficeBuilding from "../images/office-building.png";
import Location from "../images/location.png";
import Footer from "./Footer";
import { doc, updateDoc, db, getDoc } from "./Firebase";
import { useNavigate } from "react-router-dom";
import JCardData from "./JCardData";

const LaunchQuiz = ({ currentUserEmail }) => {
  const readIndex = localStorage.getItem("readIndex");
  const quizType = localStorage.getItem("quizType");
  let quizName = "";
  const navigate = useNavigate();
  let obj = "";

  if (quizType === "CardData") obj = CardData[readIndex];

  if (quizType === "JCardData") obj = JCardData[readIndex];

  const handleError = async () => {
    const docRef = doc(db, "users", currentUserEmail);
    const docSnap = await getDoc(docRef);
    const { title } = obj;
    const check = docSnap.data()
    let index = CardData.findIndex((ele) => ele.title === title);
    localStorage.setItem("index", index);
    console.log(check[title]);
    if (check[title]) {
      obj.link = "resubmit-error";
    } else {
      localStorage.setItem("visit", "val");
      await updateDoc(doc(db, "users", currentUserEmail), {
        [title] : true
      });
      {
        quizType === "CardData" ? (
          (quizName = `${obj.title} Internship`)
        ) : (
          <></>
        );
        localStorage.setItem("documentRef", quizName);
      }
      {
        quizType === "JCardData" ? (quizName = `${obj.title} position`) : <></>;
        localStorage.setItem("documentRef", quizName);
      }

      // fetch(
      //   "https://us-central1-alphanumeric-career.cloudfunctions.net/server/resubmit",
      //   {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({
      //       currentUserEmail,
      //       quizName,
      //     }),
      //   }
      // ).then((res) => {});
    }
    navigate(obj.link);
  };

  return (
    <>
      <Container fluid>
        <div className="bg-white mt-5">
          <div className="row justify-content-center">
            <div className="col-md-5 col-10 pt-3 pb-3">
              <div className="quizDesHeading">{obj.title}</div> <br />
              <div className="d-flex">
                <Image src={OfficeBuilding} className="building me-3" />
                <span className="quizSpan">Alphanumeric Ideas</span>
              </div>
              <div className="d-flex mb-2">
                <Image src={Location} className="location resLocation" />
                <span className="quizSpan">
                  Mundi Kharar, Kharar, nr. Sunny Enclave, Sahibzada Ajit Singh
                  Nagar, Mohali Punjab
                </span>
              </div>
            </div>

            <div className="col-md-5 col-10 resCenter">
              <Image src={obj.imgLink} className="quizImg ms-5 ps-5" />
            </div>
          </div>
        </div>

        <div className="bg-white mt-3">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="quizSecondHeading">Minimum Qualifications</div>
              <ul>
                <li className="minQuali">{obj.minimum[0]}</li>
                <li className="minQuali">{obj.minimum[1]}</li>
                <li className="minQuali">{obj.minimum[2]}</li>
                <li className="minQuali">{obj.minimum[3]}</li>
                <li className="minQuali">{obj.minimum[4]}</li>
              </ul>
              <div className="quizSecondHeading mt-4">About the job</div>
              <div className="aboutJob">{obj.description}</div>
              <div className="aboutJob">{obj.description2}</div>
              <div className="quizSecondHeading mt-4">Responsibilities</div>
              <ul>
                <li className="minQuali">{obj.responsibilities[0]}</li>
                <li className="minQuali">{obj.responsibilities[1]}</li>
                <li className="minQuali">{obj.responsibilities[2]}</li>
                <li className="minQuali">{obj.responsibilities[3]}</li>
                <li className="minQuali">{obj.responsibilities[4]}</li>
              </ul>
              <div className="quizSecondHeading mt-4">Quiz</div>
              <div className="quizIns">
                Take this 10-minute multiple choice quiz as a crucial step in
                the internship/job selection process. Demonstrate your aptitude
                and knowledge by answering the questions within the allotted
                time frame. Make sure to give it your best shot!
              </div>
            </div>
          </div>
          <div className="text-center mt-5 pb-5">
            <Button
              className="quizButton"
              // disabled={obj.enable !== true}
              variant="primary"
              onClick={handleError}
            >
              Launch Quiz
            </Button>
          </div>
        </div>
        <Footer />
      </Container>
    </>
  );
};

export default LaunchQuiz;
