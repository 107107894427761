import React, { useState, useRef } from "react";
import Table from "react-bootstrap/Table";
import xls from "../images/Microsoft_Office_Excel_(2019–present).svg.png";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  db,
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  updateDoc,
  storage,
  ref,
  getDownloadURL,
} from "./Firebase";
import { useNavigate } from "react-router-dom";

import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";

const RejectedUser = () => {
  let [userData, setUserData] = useState([]);
  const [document, setDocument] = useState("");
  const [showOrNot, setShowOrNot] = useState(false);
  const [allUsers, setAllUsers] = useState();
  const [click, setClick] = useState(false);
  const [userCounter, setuserCounter] = useState("0");
  const [formData, SetFormData] = useState({
    type: "All",
    field: "Accounts",
    sort: "",
  });

  const navigate = useNavigate();
  let sr = 0;
  let data = [];
  const tableRef = useRef(null);

  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const checkData = () => {
    if (click) {
      console.log(allUsers[1]);
      const filteredData = allUsers.filter((user) => {
        return user.name
          .toLowerCase()
          .includes(formData.filterName.toLowerCase());
      });
      setUserData(filteredData);
    } else {
      alert("No Data");
    }
  };

  const allData = () => {
    setShowOrNot(true);
    if (formData.type === "All") {
      let document = `RejectedUsers ${formData.field} Internship`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
      });
      document = `RejectedUsers ${formData.field} position`;
      setDocument(document);
      getDocs(collection(db, document)).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          data.push(doc.data());
        });
        setUserData(data);
        const userLength = data.length;
        setuserCounter(userLength);
      });
    }
    const document = `RejectedUsers ${formData.field} ${formData.type}`;
    let name = document.split(" ");
    name.shift();
    let fieldName = name.toString();

    setDocument(document);
    getDocs(collection(db, document)).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
      });
      setUserData(data);
      const userLength = data.length;
      setuserCounter(userLength);
    });

    setAllUsers(data);
    setClick(true);
  };

  const { sort } = formData;
  if (sort === "alpha") {
    const alphaSort = userData.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    userData = alphaSort;
  }

  if (sort === "score") {
    const alphaSort = userData.sort((a, b) => {
      return b.score - a.score;
    });
    userData = alphaSort;
  }

  if (sort === "date") {
    const dateSort = userData.sort((a, b) => {
      const timestampA = a.submissionDate.toMillis();
      const timestampB = b.submissionDate.toMillis();
      return timestampB - timestampA;
    });
    userData = dateSort;
  }

  return (
    <>
      <h1 className="heading">Rejected Users : {userCounter}</h1>
      <div className="m-3">
        <DownloadTableExcel
          filename="Rejected_users"
          sheet="users"
          currentTableRef={tableRef.current}
        >
          <button className="mb-2 xlsButton">
            <img className="xls" src={xls} alt="" />{" "}
          </button>
        </DownloadTableExcel>

        <div className="row justify-content-center mb-4">
          <div className="col-md-3 col-8 mt-2">
            <Form.Select
              aria-label="Default select example"
              name="type"
              onChange={handleinput}
            >
              <option value="All">All</option>
              <option value="Internship">Internships</option>
              <option value="position">Jobs</option>
            </Form.Select>
          </div>
          <div className="col-md-3 col-8 mt-2">
            <Form.Select
              aria-label="Default select example"
              name="field"
              onChange={handleinput}
            >
              <option value="Accounts">Accounts</option>
              <option value="Business Development">Business Development</option>
              <option value="Content Writing">Content Writing</option>
              <option value="Data Analyst">Data Analyst</option>
              <option value="Data Entry">Data Entry</option>
              <option value="Data Research">Data Research</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Email Marketing">Email Marketing</option>
              <option value="Graphic Design">Graphic Design</option>
              <option value="Human Resources">Human Resources</option>
              <option value="Marketing">Marketing</option>
              <option value="Online Bidding">Online Bidding</option>
              <option value="Web Development">Web Development</option>
              <option value="Check">Check</option>
            </Form.Select>
          </div>
          <div className="col-md-2 col-8 mt-2 text-center">
            <Button className="navButton" onClick={allData}>
              Search
            </Button>
          </div>

          <div className="col-md-2 col-8 mt-2 text-center">
            <Form.Select
              aria-label="Default select example"
              name="sort"
              onChange={handleinput}
            >
              <option value="sort">Sort By</option>
              <option value="date">Latest</option>
              <option value="alpha">Name [ A - Z ]</option>
              <option value="score">Score [ 10 - 0 ]</option>
            </Form.Select>
          </div>

          <div className="col-md-2 col-8 mt-2">
            <Form>
              <Form.Group controlId="text">
                <Form.Control
                  className="text-center"
                  type="text"
                  placeholder="Search By Name"
                  value={formData.filterName}
                  name="filterName"
                  onChange={handleinput}
                  onKeyUp={checkData}
                />
              </Form.Group>
            </Form>
          </div>
        </div>

        <Table responsive striped bordered hover variant="light" ref={tableRef}>
          <thead className="QuizBtn">
            <tr>
              <th>Sr. No.</th>
              <th>Name</th>
              <th>Email</th>
              <th>Experience</th>
              <th>Company Name</th>
              <th>CTC</th>
              <th>Category</th>
              <th>Submission Date</th>
              <th>Attempts</th>
              <th>Score</th>
              <th>Resume Link</th>
              <th className="text-center">Status</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          {userData.map((ele) => {
            sr++;
            return (
              <>
                <tbody className="QuizBtn">
                  <tr>
                    <td>{sr}</td>
                    <td>{ele.name}</td>
                    <td>{ele.email}</td>
                    <td>{ele.experience}</td>
                    <td>{ele.companyName}</td>
                    <td>{ele.ctc}</td>
                    <td>{ele.quizname}</td>
                    <td>{ele.submissionDate.toDate().toLocaleString()}</td>
                    <td>{ele.attempts}</td>
                    <td>{ele.score}</td>
                    {ele.link ? (
                      <>
                        <td style={{ cursor: "pointer" }}>
                          <a
                            style={{ color: "#139ded" }}
                            className="resumeLink"
                            href={ele.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open Resume
                          </a>
                        </td>
                      </>
                    ) : (
                      <>
                        <td
                          style={{ color: "#139ded", cursor: "pointer" }}
                          onClick={async () => {
                            let link = `gs://alphanumeric-career.appspot.com/resume/${ele.email}-resume.pdf`;
                            let nlink = link.replaceAll(" ", "");
                            const fileRef = ref(storage, nlink);
                            const downloadURL = await getDownloadURL(fileRef);
                            localStorage.setItem("url", downloadURL);
                            navigate("/resume-viewer");
                          }}
                        >
                          Open Resume
                        </td>
                      </>
                    )}
                    <td className="rejected">Rejected</td>
                    <td className="">
                      <Button
                        className="me-2"
                        variant="danger"
                        onClick={async () => {
                          const {
                            name,
                            email,
                            phNumber,
                            experience,
                            companyName,
                            ctc,
                            score,
                            quizname,
                            link,
                            imgLink,
                            attempts,
                            submissionDate,
                          } = ele;
                          let d = submissionDate.toDate().toDateString();
                          let onlyDate = new Date(d);
                          onlyDate.setDate(onlyDate.getDate() + 21);
                          onlyDate = onlyDate.toDateString();
                          let clName = `${quizname}`;
                          await setDoc(doc(db, clName, email), {
                            name,
                            email,
                            phNumber,
                            experience,
                            companyName,
                            ctc,
                            score,
                            quizname,
                            link,
                            imgLink,
                            attempts,
                            unRejected: true,
                            submissionDate,
                          });

                          const updatedDocref = doc(db, "users", email);
                          await updateDoc(updatedDocref, {
                            visited: false,
                          });

                          let clName2 = `RejectedUsers ${quizname}`;
                          await deleteDoc(doc(db, clName2, email));
                          if (formData.type === "All") {
                            let document = `${formData.field} Internship`;
                            getDocs(collection(db, document)).then(
                              (querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                  data.push(doc.data());
                                });
                              }
                            );
                            document = `${formData.field} position`;
                            getDocs(collection(db, document)).then(
                              (querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                  data.push(doc.data());
                                });
                                setUserData(data);
                              }
                            );
                          } else {
                            getDocs(collection(db, document)).then(
                              (querySnapshot) => {
                                querySnapshot.forEach((doc) => {
                                  data.push(doc.data());
                                });
                                setUserData(data);
                              }
                            );
                          }

                          fetch(
                            "https://us-central1-alphanumeric-career.cloudfunctions.net/server/unreject",
                            {
                              method: "POST",
                              mode: "cors",
                              headers: {
                                "access-control-allow-origin": "*",
                                "Content-type":
                                  "application/json; charset=UTF-8",
                              },
                              body: JSON.stringify({
                                name,
                                email,
                                quizname,
                                imgLink,
                                onlyDate,
                              }),
                            }
                          ).then((res) => {
                            // setIsLoading(true);
                            // setBlur("4px");
                            // navigate("/quiz-result");
                          });
                          alert("Unrejected");
                        }}
                      >
                        Unreject
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </>
            );
          })}
        </Table>
        {showOrNot && userData.length === 0 ? (
          <h3 className="text-center mt-4">No Data Found</h3>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default RejectedUser;
