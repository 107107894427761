const web_development = [
  {
    quizname: "Web Development",
    imgLink :"https://alphanumericideas.com/images/careers/web-development.png",
    ques: " What does HTML stand for?",
    options: [
      "Hypertext Markup Language",
      "High-level Technical Markup Language",
      "Hyperlink Text Markup Language",
      "Hypermedia Markup Language",
    ],
    ans: "A",
  },
  {
    ques: " What is the default value of the position property in CSS?",
    options: [
      "Relative",
      "Absolute",
      "Fixed",
      "Static",
    ],
    ans: "D",
  },
  {
    ques: " What is the correct way to write a JavaScript array?",
    options: [
      "array[]",
      "array()",
      "new array()",
      "[]",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a cookie and a session?",
    options: [
      "Cookies are stored on the client-side, while sessions are stored on the server-side",
      "Cookies have an expiration date while sessions do not.",
      "Cookies are used to store user preferences, while sessions are used to store login information",
      "Both a and c",
    ],
    ans: "D",
  },
  {
    ques: " What is the correct way to include a JavaScript file in an HTML document?",
    options: [
      '<script href="filename.js"></script>',
      '<script src="filename.js"></script>',
      '<javascript src="filename.js"></javascript>',
      '<script name="filename.js"></script>',
    ],
    ans: "B",
  },
  {
    ques: " How capable in HTML5?",
    options: [
      "By using draggable attribute",
      "By using the movable attribute",
      "By using the on drag event",
      "By using the on dragstart event",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between GET and POST method in HTML forms?",
    options: [
      "GET method is used for retrieving data, while POST method is used for submitting data",
      "GET method is more secure than POST method",
      "GET method sends data in the URL, while POST method sends data in the request body",
      "Both a and c",
    ],
    ans: "D",
  },
  {
    ques: " What is the correct way to define a function in JavaScript?",
    options: [
      "function:myFunction() { }",
      "function myFunction() { }",
      "new function myFunction() { }",
      "let myFunction = function() { }",
    ],
    ans: "B",
  },
  {
    ques: " What is the correct way to define a CSS class in HTML?",
    options: [
      '<style class="myclass">',
      '<class name="myclass">',
      '<div class="myclass">',
      '<class="myclass">',
    ],
    ans: "C",
  },
  {
    ques: " How can you change the background color of an element in CSS?",
    options: [
      "background-color: #FF0000;",
      "color: #FF0000;",
      "bgcolor: #FF0000;",
      "background: #FF0000;",
    ],
    ans: "A",
  },
  {
    ques: " What does JavaScript use for types?",
    options: [
      "Classes",
      "Prototypes",
      "Interfaces",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between 'var' and 'let' in JavaScript?",
    options: [
      '"var" is used for global variables, while "let" is used for local variables.',
      '"var" is used for function-scoped variables, while "let" is used for block-scoped variables.',
      '"var" is used for constant variables, while "let" is used for mutable variables',
      '"var" and "let" are interchangeable and have the same behavior',
    ],
    ans: "B",
  },
  {
    ques: " What is the correct syntax for a CSS media query?",
    options: [
      "@media (screen and max-width: 600px) { ... }",
      "@screen media (max-width: 600px) { ... }",
      "@media screen and (max-width: 600px) { ... }",
      "@query media screen (max-width: 600px) { ... }",
    ],
    ans: "C",
  },
  {
    ques: " What is the name of the JavaScript method used to remove an element from the DOM?",
    options: [
      "remove()",
      "delete()",
      "erase()",
      "detach()",
    ],
    ans: "A",
  },
  {
    ques: " What does AJAX stand for?",
    options: [
      "Asynchronous JavaScript and eXtensible Markup Language",
      "Asynchronous JavaScript and XHTML",
      "Asynchronous JavaScript and JSON",
      "Asynchronous JavaScript and XML",
    ],
    ans: "D",
  },
  {
    ques: " What is the correct syntax for a CSS selector that targets a specific ID?",
    options: [
      "#myid",
      ".myid",
      "myid",
      "@myid",
    ],
    ans: "A",
  },
  {
    ques: ' What is the difference between "==" and "=== " in JavaScript?',
    options: [
      '"==" compares data types, while "===" compares values',
      '"==" compares values, while "===" compares values and data types',
      'Both "==" and "===" compare values only',
      'Both "==" and "=== " compare values and data types',
    ],
    ans: "B",
  },
  {
    ques: " What is the correct way to define a CSS id in HTML?",
    options: [
      '<style id="myid">',
      '<id name="myid">',
      '<div id="myid">',
      '<id="myid">',
    ],
    ans: "C",
  },
  {
    ques: " How can you make an element resizable in HTML5?",
    options: [
      "By using the resizable attribute",
      "By using the resize attribute",
      "By using the onresize event",
      "By using the onresizestart event",
    ],
    ans: "A",
  },
  {
    ques: " What is the correct way to define a JavaScript object?",
    options: [
      "object[]",
      "object()",
      "new object()",
      "{}",
    ],
    ans: "D",
  },
  {
    ques: ' What is the difference between "null" and "undefined" in JavaScript?',
    options: [
      '"null" is an assignment value, while "undefined" is a default value',
      '"undefined" is an assignment value, while "null" is a default value',
      '"null" represents an empty value, while "undefined" represents an uninitialized value',
      '"undefined" represents an empty value,',
    ],
    ans: "C",
  },
  {
    ques: " What is the preferred way for adding a background color in HTML?",
    options: [
      '<body background="yellow">',
      '<background>yellow</background>',
      '<body style="background-color:yellow">',
      '<background color="yellow">text<background>',
    ],
    ans: "C",
  },
  {
    ques: " What is the correct HTML for creating a hyperlink?",
    options: [
      '<a name="">A</a>',
      "<a>B</a>",
      '<a href="http://www.example.com">example</a>',
      '<a url="http://www.example.com">example</a>',
    ],
    ans: "C",
  },
  {
    ques: " How can you create an email link?",
    options: [
      '<mail href="a@b">',
      '<mail>a@b</mail>',
      '<a href="a@b">',
      '<a href="mailto:a@b.com">',
    ],
    ans: "D",
  },
  {
    ques: " How can you open a link in a new browser window?",
    options: [
      '<a href="url" new>',
      '<a href="url" target="new">',
      '<a href="url" target="_blank">',
      '<a href="url" target="">',
    ],
    ans: "C",
  },
  {
    ques: " Which of these tags are all <table> tags?",
    options: [
      "<table><head><tfoot>",
      "<table><tr><td>",
      "<table><tr><tt>",
      "<thead><body><tr>",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following JavaScript cannot do?",
    options: [
      "JavaScript can react to events",
      "JavaScript can manipulate HTML elements",
      "JavaScript can be use to validate data",
      "All of the Above",
    ],
    ans: "D",
  },
  {
    ques: " What's so great about XML?",
    options: [
      "Easy data exchange",
      "High speed on network",
      "Only B.is correct",
      "Both A & B",
    ],
    ans: "D",
  },
  {
    ques: " Where is the correct place to refer an external style sheet in HTML document?",
    options: [
      "In the middle of the document",
      "At the end of the document",
      "At the top of the document",
      "Both A & C",
    ],
    ans: "C",
  },
  {
    ques: " Which is the correct tag for internal style sheet in HTML?",
    options: [
      "<stylesheet>",
      "<style>",
      "<script>",
      "Both A & B",
    ],
    ans: "B",
  },
  {
    ques: " Correct syntax of CSS?",
    options: [
      "{body;color:black}",
      "{body:color=black(body}",
      "body:color=black",
      "body {color: black}",
    ],
    ans: "D",
  },
  {
    ques: " Correct way to insert a comment in a CSS file?",
    options: [
      "/* welcome to t4tutorials */",
      "// welcome to t4tutorials /",
      "// welcome to t4tutorials",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " How to add background color for all the h1 elements?",
    options: [
      "h1 {background-color:red;}",
      "h1.all {background-color:red;}",
      "all.h1 {background-color:red;}",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " How do you change the text color of an element?",
    options: [
      "fgcolor:",
      "text-color:",
      "text-color=",
      "color:",
    ],
    ans: "D",
  },
  {
    ques: " How can you make a bulleted list?",
    options: [
      "<list>",
      "<nl>",
      "<ul>",
      "<ol>",
    ],
    ans: "C",
  },
  {
    ques: " Choose the correct HTML tag to make a text bold?",
    options: [
      "<b>",
      "<bold>",
      "<bb>",
      "<bld>",
    ],
    ans: "A",
  },
  {
    ques: " Which attribute is used to name an element uniquely?",
    options: [
      "Class",
      "Id",
      "Dot",
      "All of above",
    ],
    ans: "B",
  },
  {
    ques: " The special formatting codes in HTML document used to present content are",
    options: [
      "Tags",
      "Attributes",
      "Values",
      "None of above",
    ],
    ans: "A",
  },
  {
    ques: " What should be the first tag in any HTML document?",
    options: [
      "<head>",
      "<title>",
      "<html>",
      "<document>",
    ],
    ans: "C",
  },
  {
    ques: " Choose the correct HTML tag to make a text italic",
    options: [
      "<ii>",
      "<italics>",
      "<italic>",
      "<i>",
    ],
    ans: "",
  },
  {
    ques: " Which of the following is not a valid JavaScript variable name?",
    options: [
      "MyVariable",
      "2variable",
      "my_variable",
      "$variable",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a way to create an array in JavaScript?",
    options: [
      "let myArray = new Array();",
      "let myArray = {1, 2, 3};",
      "let myArray = [1, 2, 3];",
      "let myArray = new List();",
    ],
    ans: "C",
  },
  {
    ques: " What is the correct way to add an element to the end of an array in JavaScript?",
    options: [
      "myArray.push(element);",
      "myArray.add(element);",
      "myArray[myArray.length] = element;",
      "myArray.append(element);",
    ],
    ans: "A",
  },
  {
    ques: " What is the correct way to define a function in JavaScript?",
    options: [
      "let myFunction = function() {};",
      "function myFunction() {};",
      "myFunction() {};",
      "Both a and b;",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a way to create an object in JavaScript?",
    options: [
      "let myObject = new Object();",
      "let myObject = {};",
      "both a and b);",
      "let myObject = new struct();",
    ],
    ans: "C",
  },
  {
    ques: " What is the correct syntax for creating a for loop in JavaScript?",
    options: [
      "for (i = 0; i < 10; i++)",
      "for (let i = 0; i < 10; i++)",
      "for i = 0 to 10",
      "for (let i = 0; i < 10; i++;)",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a way to declare a variable in JavaScript?",
    options: [
      "let myVar;",
      "let myVar;",
      "const myVar;",
      "All of the above;",
    ],
    ans: "D",
  },
  {
    ques: " What is the correct way to print to the console in JavaScript?",
    options: [
      "print(message);",
      "log(message);",
      "echo(message);",
      "console.log(message);",
    ],
    ans: "D",
  },
  {
    ques: " What does the typeof operator return in JavaScript?",
    options: [
      "The type of the variable or object",
      "The value of the variable or object",
      "The memory address of the variable or object",
      "The length of the variable or object",
    ],
    ans: "A",
  },
  {
    ques: " What is the correct way to add an event listener to an element in JavaScript?",
    options: [
      "element.onclick = function(){};",
      'element.addEventListener("click", function(){});',
      'element.listen("click", function(){});',
      "element.event = function(){};",
    ],
    ans: "B",
  },
  {
    ques: " How do you create a new element in JavaScript?",
    options: [
      "element = new Element();",
      'let element = document.createElement("tagName");',
      "let element = new tagName();",
      'element = document.newElement("tagName");',
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a break statement and a continue statement in JavaScript?",
    options: [
      "continue statement exits the current loop, while break statement skips the current iteration",
      "break statement and continue statement do the same thing",
      "break statement exits the current loop, while continue statement skips the current iteration",
      "break statement skips the current iteration, while continue statement exits the current loop",
    ],
    ans: "C",
  },
  {
    ques: " How can you check if a variable is an array in JavaScript?",
    options: [
      "By using the typeof operator",
      "By using the isArray() method",
      "By using the instanceof operator",
      "By using the Array.isArray() method",
    ],
    ans: "D",
  },
  {
    ques: " How can you merge two arrays in JavaScript?",
    options: [
      "By using the concat() method",
      "By using the merge() method",
      "By using the join() method",
      "By using the add() method",
    ],
    ans: "A",
  },
  {
    ques: " How can you check if a variable is an object in JavaScript?",
    options: [
      "By using the typeof operator",
      "By using the isObject() method",
      "By using the instanceof operator",
      "By using the Object.isObject() method",
    ],
    ans: "C",
  },
  {
    ques: " HTML is what type of language ?",
    options: [
      "Scripting Language",
      "Markup Language",
      "Programming Language",
      "Network Protocol",
    ],
    ans: "B",
  },
  {
    ques: " HTML uses",
    options: [
      "User defined tags",
      "Pre-specified tags",
      "Fixed tags defined by the language",
      "Tags only for linking",
    ],
    ans: "C",
  },
  {
    ques: " Fundamental HTML Block is known as _________",
    options: [
      "HTML Body",
      "HTML Tag",
      "HTML Attribute",
      "HTML Element",
    ],
    ans: "B",
  },
  {
    ques: " Apart from <b> tag, what other tag makes text bold ?",
    options: [
      "<fat>",
      "<strong>",
      "<black>",
      "<emp>",
    ],
    ans: "B",
  },
  {
    ques: " What tag is used to display a picture in a HTML page?",
    options: [
      "picture",
      "image",
      "img",
      "src",
    ],
    ans: "C",
  },
  {
    ques: " HTML web pages can be read and rendered by _____",
    options: [
      "Compiler",
      "Server",
      "Web Browser",
      "Interpreter",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a browser ?",
    options: [
      "Microsofts Bing",
      "Netscape Navigator",
      "Mozilla Firefox",
      "Opera",
    ],
    ans: "A",
  },
  {
    ques: " Which HTML tag produces the biggest heading?",
    options: [
      "<h9>",
      "<h5>",
      "<h6>",
      "<h1>",
    ],
    ans: "D",
  },
  {
    ques: " HTML tags are surrounded by which type of brackets",
    options: [
      "Curly",
      "Round",
      "Squart",
      "Angle",
    ],
    ans: "D",
  },
  {
    ques: " Tags and texts that are not directly displayed on the page are written in _____ section.",
    options: [
      "<head>",
      "<title>",
      "<body>",
      "<html>",
    ],
    ans: "A",
  },
  {
    ques: " PHP Stands for?",
    options: [
      "Hypertex Processor",
      "Hyper Markup Processor",
      "Hyper Markup Preprocessor",
      "Hypertext Preprocessor",
    ],
    ans: "D",
  },
  {
    ques: " PHP is an example of ___________ scripting language",
    options: [
      "Server-side",
      "Client-side",
      "Browser-side",
      "In-side",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not true?",
    options: [
      "PHP can be used to develop web applications",
      "PHP makes a website dynamic",
      "PHP applications can not be compile",
      "PHP can not be embedded into html",
    ],
    ans: "D",
  },
  {
    ques: " PHP scripts are enclosed within ____",
    options: [
      "<php> . . . </php>",
      "<?php . . . ?>",
      "?php . . . ?php",
      "<p> . . . </p>",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following variables is not a predefined variable?",
    options: [
      "$get",
      "$ask",
      "$request",
      "$post",
    ],
    ans: "B",
  },
  {
    ques: " When you need to obtain the ASCII value of a character which of the following function you apply in PHP?",
    options: [
      "chr( );",
      "asc( );",
      "ord( );",
      "val( );",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following method sends input to a script via a URL?",
    options: [
      "Get",
      "Post",
      "Both",
      "None",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following function returns a text in title case from a variable?",
    options: [
      "ucwords($var)",
      "upper($var)",
      "toupper($var)",
      "ucword($var)",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following function returns the number of characters in a string variable?",
    options: [
      "count($variable)",
      "len($variable)",
      "strcount($variable)",
      "strlen($variable)",
    ],
    ans: "D",
  },
  {
    ques: " The ” var” and “function” are known as _________",
    options: [
      "Data types",
      "Keywords",
      "Prototypes",
      "Declaration statements",
    ],
    ans: "D",
  },
  {
    ques: " Which of these is the correct way in which we can call the JavaScript code?",
    options: [
      "Triggering Event",
      "Preprocessor",
      "Function/Method",
      "RMI",
    ],
    ans: "C",
  },
  {
    ques: " Which of these functions of the Number Object would format a number with different numbers of digits to the decimal’s right?",
    options: [
      "toFixed()",
      "toExponential()",
      "toLocaleString()",
      "toPrecision()",
    ],
    ans: "A",
  },
  {
    ques: " Out of the following functions of the string object, which one would return the character in any string via the specified number of characters starting at a specified position?",
    options: [
      "search()",
      "substr()",
      "split()",
      "slice()",
    ],
    ans: "B",
  },
  {
    ques: " Look at the snippets given below and check the one in which the variable “a” isn’t equal to the “NULL”",
    options: [
      "if (a!)",
      "if(a!=null)",
      "if(a!==null)",
      "if(a!null)",
    ],
    ans: "C",
  },
  {
    ques: " Which of these operators are used for checking if a specific property exists?",
    options: [
      "in",
      "within",
      "exist",
      "exists",
    ],
    ans: "A",
  },
  {
    ques: " The expression that can appear legally on an assignment expression’s left side” is a common explanation for variables, elements of arrays, and properties of objects. These are known as __________:",
    options: [
      "Prototypes",
      "Properties",
      "Lvalue",
      "Definition",
    ],
    ans: "C",
  },
  {
    ques: " Which of these is a correct output for the JavaScript code given below? string X= “Hey”; string Y=”There”; alert(X+Y);",
    options: [
      "Hey There",
      "Hey_There",
      "HeyThere",
      "undefined",
    ],
    ans: "C",
  },
  {
    ques: " Which of these is known as the Equality operator used for checking whether both the values are equal?",
    options: [
      "=",
      "==",
      "===",
      "&&",
    ],
    ans: "B",
  },
  {
    ques: " In case a value of an operator is NULL, then the unary operator would return the ____________ typeof.",
    options: [
      "Object",
      "Boolean",
      "String",
      "Undefined",
    ],
    ans: "D",
  },
  {
    ques: " Which of these is not a keyword?",
    options: [
      "debugger",
      "use strict",
      "with",
      "if",
    ],
    ans: "B",
  },
  {
    ques: " Which of these symbols is used to create comments in JavaScript?",
    options: [
      "//",
      "\\\\",
      "\\* */",
      "\\* *\\",
    ],
    ans: "A",
  },
  {
    ques: " In the line of code given below, what will the “datatype” written in brackets be called?                                                                      article[datatype]=assignment_value;",
    options: [
      "An object",
      "A String",
      "Floating point",
      "An integer",
    ],
    ans: "B",
  },
  {
    ques: " Which of these methods or operators are used for identifying an array?",
    options: [
      "isarrayType()",
      "===",
      "==",
      "Typeof",
    ],
    ans: "A",
  },
  {
    ques: " A function’s execution would stop whenever a program control would encounter the _________ statement in the function’s body.",
    options: [
      "goto statement",
      "break statement",
      "continue statement",
      "return statement",
    ],
    ans: "D",
  },
  {
    ques: " Which of these keywords is used to define various functions in JavaScript?",
    options: [
      "function",
      "main",
      "init",
      "Void",
    ],
    ans: "A",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
]

export default web_development