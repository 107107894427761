import React, { useState } from "react";
import moment from "moment";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { db, doc, updateDoc } from "./Firebase";

const QuizResult = ({ currentUserName, currentUserEmail }) => {
  const [display, setDisplay] = useState(0);
  const [scale, setScale] = useState(1);
  const [zIndex, setZindex] = useState("-2");
  const [show, setShow] = useState(true);
  const [blur, setBlur] = useState("0");
  const [formData, SetFormData] = useState({
    date: null,
  });

  const QualifiedScore = localStorage.getItem("QualifiedScore");
  const integerQualified = parseInt(QualifiedScore);

  const handleinput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    SetFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let documentRef = localStorage.getItem("documentRef");
    try {
      const date = new Date(formData.date);
      const interviewDate = date.toLocaleString();
      await updateDoc(doc(db, documentRef, currentUserEmail), {
        suggestedInterview: interviewDate,
      });
      setShow(false);
    } catch (error) {}
  };

  const date = new Date();
  let txtColor = "";
  let assessmentHeading = "";
  let assessmentMessage = "";
  let visibleOrNot = "";
  let failedDisplay = "visible";
  let fdTxtAlgn = "none";
  const score = localStorage.getItem("nSr");
  const per = (score / 10) * 100;
  const txt = per + "%";
  const navigate = useNavigate();

  if (score >= integerQualified) {
    txtColor = "#6ac145";
    assessmentHeading = "Congratulations!";
    visibleOrNot = "inline";
    assessmentMessage =
      "You have successfully passed the quiz! Please suggest your preferred interview timings below.";
  } else {
    txtColor = "#ff0000";
    assessmentHeading = "Assessment Failed";
    visibleOrNot = "none";
    assessmentMessage = "You Can Try Again Next Week.";
    failedDisplay = "none";
    fdTxtAlgn = "center";
  }

  setTimeout(() => {
    localStorage.removeItem("nSr");
  }, 100000);

  return (
    <>
      <div className="date-container" style={{ zIndex: zIndex }}>
        <Form
          className="mmm"
          style={{ opacity: display, transform: `scale(${scale})` }}
        >
          {show ? (
            <>
              <Form.Group controlId="datetime-local">
                <Form.Control
                  type="text"
                  value={formData.date}
                  placeholder="DD/MM/YYYY , 00:00"
                  onFocus={(e) => (e.target.type = "datetime-local")}
                  name="date"
                  onChange={handleinput}
                />
              </Form.Group>
              <div className="d-flex justify-content-around mt-4">
                <Button
                  variant="danger"
                  className="cancelButton"
                  onClick={(e) => {
                    setDisplay(0);
                    setBlur("0px");
                    setZindex("-2");
                    setScale(0.7);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={formData.date === null}
                  type="submit"
                  variant="success"
                  className="scheduleButton"
                  onClick={handleSubmit}
                >
                  Suggest
                </Button>
              </div>
            </>
          ) : (
            <>
              <div
                className="text-center"
                style={{ fontSize: "0.8rem", lineHeight: "1.4rem" }}
              >
                We have successfully noted your suggestion. <br /> Our HR team
                will arrange an interview with you at <br /> their earliest
                convenience. <br />
                <Button
                  className="scheduleButton mt-2"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={() => {
                    localStorage.setItem("nSr", 0);
                    navigate("/all-jobs");
                  }}
                >
                  Go Back
                </Button>
              </div>
            </>
          )}
        </Form>
      </div>
      <div className="qResult">
        <span className="resultDate">
          Completed: {moment(date).format("MMM D - h:mm A")}{" "}
        </span>
        <br />
        <span className="resultName">{currentUserName}</span>
      </div>
      <Container fluid className="bg-white" style={{ filter: `blur(${blur})` }}>
        <div className="row pt-5 pb-5 justify-content-center">
          {/* <div
            className="col-md-5 col-10 align-self-center pt-5 pb-5"
            style={{ display: failedDisplay }}
          >
            <CircularProgressbar
              styles={{
                path: {
                  stroke: txtColor,
                },
                text: {
                  fill: "black",
                  fontSize: "1.5rem",
                },
              }}
              value={per}
              text={txt}
              className="progressBar"
            />
          </div> */}

          <div
            className="col-md-5 col-10 resPc pt-5 pb-5"
            style={{ textAlign: "center" }}
          >
            <div className="assesmentHeading" style={{ color: txtColor }}>
              {assessmentHeading}
            </div>
            <div className="resultMsg mb-5">{assessmentMessage}</div>
            {/* <span className="resultMsg" style={{ display: failedDisplay }}>
              Total Points: {score}/10
            </span> */}
            {/* <div className="mt-3 text-center"> */}
            {/* <Button
                className="goBckBtn"
                onClick={() => {
                  localStorage.setItem("nSr", 0)
                  navigate("/all-jobs");
                }}
              >
                Go Back
              </Button> <br /> */}
            <Button
              style={{ display: visibleOrNot }}
              className="goBckBtn"
              onClick={() => {
                setDisplay(1);
                setScale(1.2);
                setZindex("2");
                setBlur("4px");
              }}
            >
              Suggest Interview Timing
            </Button>
            {/* </div> */}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default QuizResult;
