import logo from "../images/logo.png";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { auth } from "./Firebase";

import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

const Navigation = ({ user, currentUserEmail }) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  return (
    <>
      <Navbar expand className="sticky-top navColor">
        <span>
          <Navbar.Brand
            onClick={() => {
              navigate("/");
            }}
          >
            <Image fluid src={logo} alt="AI Logo" className="me-2 logoStyle" />
          </Navbar.Brand>
        </span>

        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {user ? (
              <>
                <NavLink
                  to="/"
                  className="navButton ms-3 me-3  mt-2"
                  variant="success"
                  onClick={() => {
                    auth.signOut();
                    localStorage.setItem("loginEmail", null);
                    localStorage.removeItem("localUpdate");
                  }}
                >
                  logout
                </NavLink>
              </>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container>
        <div className="row d-flex justify-content-center">
          {(currentUserEmail === "gurvinder@alphanumericideas.com"  || currentUserEmail === "shruti@alphanumericideas.com"  ||currentUserEmail === "mohit@alphanumericideas.com"  ||  currentUserEmail === "zippinder@alphanumericideas.com") ? (
            <>
              <div className="col-md-3 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/userdata"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                  Not Contacted
                </NavLink>
              </div>

              <div className="col-md-3 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/connected"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                 Contacted
                </NavLink>
              </div>


              <div className="col-md-3 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/failed"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                  Failed Users
                </NavLink>
              </div>


              <div className="col-md-3 col-5  me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/scheduled-interviews"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                  Scheduled Interviews
                </NavLink>
              </div>


              <div className="col-md-2 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/selected-users"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                  Selected Users
                </NavLink>
              </div>


              <div className="col-md-2 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/rejected"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                  Rejected Users
                </NavLink>
              </div>

              <div className="col-md-3 col-5 me-3">
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "white" : "black",
                    border: isActive
                      ? "1px solid rgb(184, 17, 17)"
                      : "1px solid black",
                    backgroundColor: isActive ? "#ca1b00" : "transparent",
                  })}
                  to="/send-emails"
                  className="linkStyle mt-2 mb-2 nav-link"
                  variant="success"
                >
                 Send Email to All
                </NavLink>
              </div>


            </>
          ) : (
            <span></span>
          )}
        </div>
      </Container>
    </>
  );
};

export default Navigation;
