import React, { useState, useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import Button from "react-bootstrap/Button";
import headerImg from "../images/VectorSmartObject.png";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Account from "../images/accountsCr.png";
import Business from "../images/Business-developmentCr.png";
import Content from "../images/Content-writingCr.png";
import DataAnalyst from "../images/Data-analystCr.png";
import DataEntry from "../images/Data-entryCr.png";
import DigitalMarkrting from "../images/Digital-marketingCr.png";
import EmailMarketing from "../images/Email-marketingCr.png";
import GraphicDesign from "../images/Graphic-designCr.png";
import HumanResources from "../images/HRCr.png";
import Marketing from "../images/MarketingCr.png";
import OnlineBidding from "../images/Online-biddingCr.png";
import WebDevelopment from "../images/Web-developmentCr.png";
import DataResearch from "../images/Data-researchCr.png";
import Footer from "../components/Footer";
import { db, doc, getDoc } from "./Firebase";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Apply = ({ user, currentUserEmail }) => {
  localStorage.removeItem("nSr");
  const [update, setUpdate] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);

  let text = "Apply Now";
  let redirect = "/sign";
  const setDimension = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [width]);

  let cssCls = "slider-strip";
  let cssCls2 = "slider-strip2";

  useEffect(() => {
    Fn();
  }, []);

  const Fn = async () => {
    const docRef = doc(db, "users", currentUserEmail);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUpdate("value");
      localStorage.setItem("localUpdate", true);
    }
  };

  let localUpdate = localStorage.getItem("localUpdate");

  const navigate = useNavigate();
  if (user) {
    text = "Go to job profile";
    redirect = "/all-jobs";
    if (!update && !localUpdate) {
      text = "Update profile";
      redirect = "/update-profile";
    }
  }

  let carousel = 3;
  if (width <= 530) carousel = 1;
  const options = {
    items: carousel,
    nav: true,
    dots: false,
    loop: true,
    autoplay: true,
    center: true,
    autoplayTimeout: 2000,
  };
  return (
    <>
      <div className="whiteBG">
        <Container fluid className="">
          <div className="row justify-content-center">
            <div className="col-12 col-md-5 d-flex justify-content-center">
              <Image fluid src={headerImg} alt="" className="headImg" />
            </div>

            <div className="col-12 col-md-5 pt-5 headText d-flex justify-content-center btnCenter">
              <Form className="">
                <span className="">
                  Discover the <span className="txtColor">Future</span>
                  <br /> Together with <br />
                </span>
                <TypeAnimation
                  sequence={["Alphanumeric Ideas", 1000]}
                  speed={30}
                  cursor={false}
                  wrapper="span"
                  repeat={Infinity}
                />
                <br />
                <Button
                  className="hdrBtn"
                  onClick={() => {
                    navigate(redirect);
                  }}
                >
                  {text}
                </Button>
              </Form>
            </div>
          </div>
        </Container>
      </div>
      <Container fluid className="bg-white mt-5">
        <div className="row justify-content-center pt-5 pb-3 ">
          <div className="col-lg-8 col-9">
            <OwlCarousel options={options}>
              <div class="item" id="start">
                <Image fluid src={Account} alt="First slide" />
              </div>
              <div class="item">
                <Image src={Business} alt="First slide" />
              </div>
              <div class="item">
                <Image src={Content} alt="First slide" />
              </div>

              <div class="item">
                <Image src={DataAnalyst} alt="First slide" />
              </div>
              <div class="item">
                <Image src={DataEntry} alt="First slide" />
              </div>
              <div class="item">
                <Image src={DataResearch} alt="First slide" />
              </div>
              <div class="item">
                <Image src={DigitalMarkrting} alt="First slide" />
              </div>
              <div class="item">
                <Image src={EmailMarketing} alt="First slide" />
              </div>
              <div class="item">
                <Image src={GraphicDesign} alt="First slide" />
              </div>
              <div class="item">
                <Image src={HumanResources} alt="First slide" />
              </div>
              <div class="item">
                <Image src={Marketing} alt="First slide" />
              </div>
              <div class="item">
                <Image src={OnlineBidding} alt="First slide" />
              </div>
              <div class="item">
                <Image src={WebDevelopment} alt="First slide" />
              </div>
            </OwlCarousel>
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Apply;
