const human_resources = [
  {
    quizname: "Human Resources",
    imgLink :"https://alphanumericideas.com/images/careers/human-resources.png",
    ques: " Human Resource Management is the process of",
    options: [
      "Recruitment and selection",
      "Analyzing the skills",
      "Workforce utilization",
      "None of the Above",
    ],
    ans: "A",
  },
  {
    ques: " Management in HRM refers to",
    options: [
      "Labour unions",
      "Proper and maximum utilization of resources",
      "Top management",
      "Operations management",
    ],
    ans: "B",
  },
  {
    ques: " The resources by themselves",
    options: [
      "Have to be collected",
      "Must be coordinated",
      "Need to be organized",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " HRM is an",
    options: [
      "Department function",
      "Individual function",
      "Organization function",
      "Environmental function",
    ],
    ans: "C",
  },
  {
    ques: " HRM includes",
    options: [
      "Planning personnel needs",
      "Providing benefits and incentives",
      "Appraising performance",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " OPM means",
    options: [
      "Office of personnel management",
      "Office of performance management",
      "Opinion of person in management",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " The role of HR director is",
    options: [
      "Solving the disputes",
      "Extension of plan",
      "Administration",
      "Training and development",
    ],
    ans: "C",
  },
  {
    ques: " The perspective for the need of HR is",
    options: [
      "Select the legal employee",
      "Promote the skill employee",
      "Distinguishing the features of employees",
      "The right person for the right job",
    ],
    ans: "D",
  },
  {
    ques: " HRM is",
    options: [
      "Inter – related",
      "Interdependent",
      "Interacting",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " The skill and talents of HR",
    options: [
      "Need an opportunity",
      "Obtained by the management",
      "Exemplified",
      "Represented",
    ],
    ans: "C",
  },
  {
    ques: " HR management on procurement, development and cooperation targets",
    options: [
      "To accomplish social objectives",
      "To obtain organizational objectives",
      "Both A and B",
      "Neither A nor B",
    ],
    ans: "C",
  },
  {
    ques: " HRM is concerned with the ——————- discussion in management",
    options: [
      "Company",
      "People",
      "Industry",
      "Society",
    ],
    ans: "B",
  },
  {
    ques: " HRM can be understood as a process of",
    options: [
      "Processing",
      "Developing",
      "Maintaining competent HR",
      "All the above",
    ],
    ans: "C",
  },
  {
    ques: " HRM is an art of",
    options: [
      "Preparation of a task",
      "Coordinating middle management",
      "Helping top management",
      "Managing people",
    ],
    ans: "D",
  },
  {
    ques: " What should be the basic positive attitude among workers?",
    options: [
      "Do not join in labor unions",
      "Reducing wastage and maximum use of resources",
      "Educate others",
      "Effective communication",
    ],
    ans: "B",
  },
  {
    ques: " HR policy makes employees",
    options: [
      "Train for future positions",
      "A better person/workers",
      "A knowledgeable person",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " Healthy HRM practices can help the organization",
    options: [
      "To reduce the disputes/ conflicts",
      "To increase the promotional opportunities",
      "To realize the employees",
      "To maintain cordial relationship unions",
    ],
    ans: "D",
  },
  {
    ques: " ______ practices teach individuals team work and adjustment",
    options: [
      "Personnel management",
      "Effective Human Resource",
      "Employee welfare",
      "Healthy,safety and welfare",
    ],
    ans: "B",
  },
  {
    ques: " Employee training requires to meet",
    options: [
      "Job requirement",
      "Job enhancement",
      "Job Analysis",
      "Job Enrichment",
    ],
    ans: "A",
  },
  {
    ques: " ___________ can be promoted for the future for the top level job",
    options: [
      "Potential employees",
      "Legal employees",
      "Skilled employees",
      "Trained employees",
    ],
    ans: "A",
  },
  {
    ques: " When industrial development increased by HR, it also stimulates",
    options: [
      "Organizational modifications",
      "Economy",
      "Technology",
      "Socio cultural change",
    ],
    ans: "B",
  },
  {
    ques: " HR also focuses on",
    options: [
      "Physical and emotional capital",
      "The confidant of employees",
      "Communication styles of the workers",
      "Attitude of the worker",
    ],
    ans: "D",
  },
  {
    ques: " What is Harmonious relationship at workplace",
    options: [
      "Friendly in nature",
      "Pleasing and consistent",
      "Cordial and available",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " Working condition activities refers with",
    options: [
      "Decision making of top management",
      "Policy and procedure of a firm",
      "Health, safety, welfare services",
      "Culture of the organization",
    ],
    ans: "C",
  },
  {
    ques: " Employee welfare is about",
    options: [
      "Determining employees real needs",
      "Giving challengable work",
      "Showing the path by right leadership",
      "Salary benefits",
    ],
    ans: "A",
  },
  {
    ques: " ________ is the art and science",
    options: [
      "Labour legislation",
      "Union management relationship",
      "Allotment of right job to right one",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " The objective of the HRM span right from the",
    options: [
      "Manpower needs",
      "Organizational needs",
      "Social needs",
      "Industry welfare",
    ],
    ans: "A",
  },
  {
    ques: " HRM is all about developing and managing",
    options: [
      "Knowledge",
      "SKills",
      "Creativity",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " Social objective of an organization",
    options: [
      "Minimize the profit",
      "Minimize the negative impact",
      "Maximize the No of employees",
      "Minimize the risk",
    ],
    ans: "B",
  },
  {
    ques: " The exist of HRM department is to serve",
    options: [
      "Not to the labor unions",
      "The labor unions",
      "The rest of the organization",
      "Only the department",
    ],
    ans: "C",
  },
  {
    ques: " HRM assists employees",
    options: [
      "To achieve the organizational goals",
      "To achieve departmental goals",
      "To accomplish organizational goals",
      "To achieve personal goal",
    ],
    ans: "D",
  },
  {
    ques: " ________ Cannot perform their job in vacuum",
    options: [
      "The top level executives",
      "Middle level Managers",
      "The personnel managers",
      "Operation level people",
    ],
    ans: "C",
  },
  {
    ques: " The primary importance of HRM as for environmental influence",
    options: [
      "Economic conditions",
      "Labour workers",
      "Laws and regulations",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ________separately of in continuation can influence the HRM function of any organization",
    options: [
      "Personal factors",
      "External factors",
      "Internal factors",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Changes in the ___________ have profound impact on the personnel",
    options: [
      "External environment",
      "Internal environment",
      "Human factors",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Rapid _______________ stimulates the innovation",
    options: [
      "Technological changes",
      "Economic changes",
      "Political modifications",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " _______________ are necessary to match the changing job requirements",
    options: [
      "Finance experts",
      "Marketing experts",
      "Technical skill personal",
      "Legal experts",
    ],
    ans: "C",
  },
  {
    ques: " _______ influence the financial wealth of the organization",
    options: [
      "Technological conditions",
      "Social conditions",
      "Political conditions",
      "Economical conditions",
    ],
    ans: "D",
  },
  {
    ques: " ______________have strength to match the growth of industrialization",
    options: [
      "Govt organization",
      "Employees organization",
      "Private organization",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " __________ seek to organize with ___________ over the terms and conditions of employment for their members",
    options: [
      "Labour unions, Management",
      "Employer, employee",
      "Supervisor, subordinate",
      "Leader, follower",
    ],
    ans: "A",
  },
  {
    ques: " Labour market deals with",
    options: [
      "Job enrichment",
      "Job enlargement",
      "Demand and supply of labor",
      "Promotions and transfer",
    ],
    ans: "C",
  },
  {
    ques: " Organization undergoes changes consequent with",
    options: [
      "Alternation in the organizational management",
      "Changes in demand of employees",
      "Changes in the labor market",
      "Modification in the job",
    ],
    ans: "B",
  },
  {
    ques: " ________ will help to cope up with the changes",
    options: [
      "Confidence and motivations",
      "Communication styles",
      "Attitude and aptitude",
      "New skill and knowledge",
    ],
    ans: "C",
  },
  {
    ques: " _________ of Human Resource in the country influence the HRM function of any organization",
    options: [
      "Structure",
      "Values",
      "Education",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ____ is the major strategy of our country’s HR focused to face the foreign organization",
    options: [
      "Challenge the competition",
      "Focus on product",
      "Recruitment of employees from other countries",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " The candidates are having good education and communication skills are getting more chance",
    options: [
      "For the promotion and increment",
      "For the Job market",
      "For the organization",
      "For the labor market",
    ],
    ans: "B",
  },
  {
    ques: " _________ practices will help to overcome the HR promotions",
    options: [
      "Employment",
      "Economic",
      "Innovative",
      "Cultural changes",
    ],
    ans: "C",
  },
  {
    ques: " ____ is the big problem for every organization",
    options: [
      "Recruiting and selecting",
      "Attracting and retaining",
      "Performance appraisal",
      "Promotion and transfer",
    ],
    ans: "B",
  },
  {
    ques: " The company’s _____________ process ensures that it gets people edge it needs",
    options: [
      "Recruitment",
      "Motivation",
      "Training",
      "Development",
    ],
    ans: "A",
  },
  {
    ques: " Sending employees for higher studies",
    options: [
      "Process of appraisal",
      "Process of selection",
      "Motivation",
      "Learning and development",
    ],
    ans: "D",
  },
  {
    ques: " No attendance monitoring is pertinent to",
    options: [
      "Career appraisal",
      "Performance appraisal",
      "Compensation and benefits",
      "Selection of the employee",
    ],
    ans: "C",
  },
  {
    ques: " Leadership and development consists of",
    options: [
      "Flexi and part time workers",
      "Employee empowerment",
      "Feedback system",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " The name of the facilitator in the HR department",
    options: [
      "People’s champion",
      "Supervisor",
      "Chief executive",
      "Leader",
    ],
    ans: "A",
  },
  {
    ques: " _______ deals with orientation along with parents",
    options: [
      "Campus recruitment",
      "Walk in interview",
      "Employees training",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the designation of the manager who energize the workplace with focus",
    options: [
      "Employee energized executive",
      "Employee engagement manager",
      "Chief executive officer",
      "HR trainer",
    ],
    ans: "B",
  },
  {
    ques: " _________ ensures talented employees retained by reassigning them to other groups",
    options: [
      "Employees Enrolment review committee",
      "Association of job secured for employees",
      "Work allotment committee",
      "People movement management review committee",
    ],
    ans: "D",
  },
  {
    ques: " HRM policy is",
    options: [
      "Place of action",
      "A decision making",
      "Is a mission",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " HRM policies lies in",
    options: [
      "An organization value",
      "Philosophy",
      "Concepts and principles",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ____ is a well Thought-out course of action",
    options: [
      "Strategy",
      "Procedure",
      "Mission",
      "Budgetary programme",
    ],
    ans: "B",
  },
  {
    ques: " The basic purpose of a procedure is",
    options: [
      "To identify the work",
      "To understand the task",
      "To spell out clearly the way",
      "To execute the work",
    ],
    ans: "C",
  },
  {
    ques: " Human resource policies are",
    options: [
      "Mitigate the company’s risk of liability",
      "Increase the benefit",
      "Reduce the labor turnover",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What are all the three basic compensation strategies?",
    options: [
      "Retain, educate and motivate the employee",
      "Compliance, relationship, understand the unions",
      "Secure, Satisfy and mitigate the jobs for employees",
      "Market leader, competitive with market and below market",
    ],
    ans: "D",
  },
  {
    ques: " An organization structure which is composed of",
    options: [
      "Top management and middle management",
      "Middle and operation",
      "Line and staff people",
      "None of them",
    ],
    ans: "C",
  },
  {
    ques: " The clearer the line of authority will be the clearer",
    options: [
      "The organizational function",
      "The responsibility for decision making",
      "Strategic action for future",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " In line authority the superior exercises",
    options: [
      "Direct supervision",
      "Informal relationship",
      "Formal relationship",
      "Dictatorship",
    ],
    ans: "A",
  },
  {
    ques: " What is the benefit of staff?",
    options: [
      "Handling complex managerial functions",
      "Assisting in decision making",
      "Reliving an over burden of top executives",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ________ is responsible to run the organization according to a strategic plan",
    options: [
      "Staff authority",
      "Organizational Hierarchy",
      "Line authority",
      "Organizational policy",
    ],
    ans: "C",
  },
  {
    ques: " HRD will develop the",
    options: [
      "Executive development",
      "Strategic development",
      "Planning and decision making",
      "Organizational and personal skills",
    ],
    ans: "D",
  },
  {
    ques: " HRD deals with functions such as",
    options: [
      "Career development",
      "Performance development",
      "Mentoring and coaching",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " Personnel management is mainly",
    options: [
      "Reactive",
      "Proactive",
      "Negative",
      "Condition",
    ],
    ans: "A",
  },
  {
    ques: " ________emphasizes the strict observance of defined rules",
    options: [
      "Preaction management",
      "Human Resources Management",
      "Production management",
      "Personnel management",
    ],
    ans: "D",
  },
  {
    ques: " The personnel management employees get ______________ based on job evaluations",
    options: [
      "Promotion",
      "Transfer",
      "Uniform rewards",
      "Incentives",
    ],
    ans: "C",
  },
  {
    ques: " ___________ is all about the way of individual behaviors",
    options: [
      "Rewards & awards",
      "Motivation",
      "Wages & salaries",
      "Labour unions",
    ],
    ans: "B",
  },
  {
    ques: " Intrinsic factors are",
    options: [
      "Driven to excel and fear of failure",
      "Money and valuation time",
      "Maternity leave",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Example of instinct theory of motivation",
    options: [
      "Satisfaction test",
      "Seasonal migration",
      "Survivability test",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " The important role in incentive theory is",
    options: [
      "Behaviour patterns",
      "Fear of failure",
      "Design to be acknowledged",
      "Monetary reward",
    ],
    ans: "D",
  },
  {
    ques: " _______ theories of motivation are based on the cognitive reasons",
    options: [
      "Humanistic theory",
      "Arousal theory",
      "Expectancy theory",
      "Incentive theory",
    ],
    ans: "A",
  },
  {
    ques: " Love and Belongingness needs relate to the",
    options: [
      "Achievement",
      "Independence",
      "Friendship and family",
      "Status",
    ],
    ans: "C",
  },
  {
    ques: " ______ impel a person to make creative or productive effects",
    options: [
      "Existence needs",
      "Self actualization",
      "Relationship",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " According to McCelland achievement theory, achievers avoid _________",
    options: [
      "Easily accomplished goals",
      "Challenges of the firm",
      "Individual performance",
      "Low and High risk situation",
    ],
    ans: "D",
  },
  {
    ques: " TAT means",
    options: [
      "Thematic Apperception Test",
      "Teaching and training",
      "Technical aptitude test",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which one of the following is dissatisfaction factor",
    options: [
      "Advancement",
      "Growth",
      "Work conditions",
      "Responsibility",
    ],
    ans: "C",
  },
  {
    ques: " Valence refers to",
    options: [
      "Emotional orientation",
      "Physical orientation",
      "Biological orientation",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " _______ means the desires of employees are fulfilled",
    options: [
      "Company policy",
      "Valence",
      "Expectancy",
      "Instrumentality",
    ],
    ans: "D",
  },
  {
    ques: " ___________refers to the individual’s perception of the personality that different rewards depend upon different degrees of effort",
    options: [
      "Effort of employees",
      "Value of reward",
      "Perceived effort",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Ethnocentric means",
    options: [
      "Continental based",
      "Home country based",
      "Global based",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " The main changes required in labor competencies",
    options: [
      "Economical",
      "Technical",
      "Political",
      "Socio cultural",
    ],
    ans: "B",
  },
  {
    ques: " ______ is the recognition and valorization of individual differences",
    options: [
      "Diversity management",
      "Global HR management",
      "International labor management",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " Human Resource planning is the process of",
    options: [
      "Planning",
      "Organizing",
      "Forecasting",
      "Coordinating",
    ],
    ans: "C",
  },
  {
    ques: " ________Facilitates the realization of the company’s objectives",
    options: [
      "Human Resource Development",
      "Labour Management",
      "Organizational Planning",
      "Human Resource Planning",
    ],
    ans: "D",
  },
  {
    ques: " The main objective of HR planning is",
    options: [
      "Forecast future requirements",
      "Determine levels of recruitment and training",
      "Facilitate productivity bargaining",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " Surplus or deficiency in _______ is the result of the absence of effective planning",
    options: [
      "Staff strength",
      "Labour strength",
      "No. of executives",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " HR managers are important facilitators of the",
    options: [
      "HR planning process",
      "Strategic Planning process",
      "Management process",
      "HR process",
    ],
    ans: "B",
  },
  {
    ques: " Manpower planning provides essential information for _____________ Personnel function",
    options: [
      "Organization policy",
      "Strategic decision",
      "Designing and implementation",
      "Achieving the mission",
    ],
    ans: "C",
  },
  {
    ques: " From the updated HR information storage system the following can be analyzed",
    options: [
      "No of employees",
      "Employees capacity",
      "Performance and potential",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " The HR required at different positions according to their ________ to the estimated",
    options: [
      "Department profile",
      "Personal profile",
      "Company profile",
      "Job profile",
    ],
    ans: "D",
  },
  {
    ques: " ______ involves implementation of the human resources action plan",
    options: [
      "Forecasting demand and supply of HR",
      "Monitoring, control and feedback",
      "Formulating the HR active plan",
      "Estimating manpower gaps",
    ],
    ans: "B",
  },
  {
    ques: " ____________ is useful for overall management of all personal activities",
    options: [
      "Job analysis",
      "HR planning",
      "HR system",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Job analysis is",
    options: [
      "Networking",
      "Systematic exploration",
      "Project organization",
      "Staff management",
    ],
    ans: "B",
  },
  {
    ques: " _________ is depends on the end use of the information",
    options: [
      "Analysis of data",
      "Collection of data",
      "Type of data",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " ___________, implies objective listing of the job title, tasks, duties",
    options: [
      "Job description",
      "Job content",
      "Organizational description",
      "Supervisor’s instruction",
    ],
    ans: "A",
  },
  {
    ques: " _______ is concerned with the actual work place",
    options: [
      "Department structure",
      "Organization structure",
      "Working environment",
      "Organization function",
    ],
    ans: "C",
  },
  {
    ques: " What are job Hazards?",
    options: [
      "Challenges and struggles",
      "Destructions and problems",
      "Difficulties and complications",
      "Obstacles and obstructions",
    ],
    ans: "D",
  },
  {
    ques: " Content of job specification statement",
    options: [
      "Required education",
      "Health and physical fitness",
      "Appearance",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " The flow of work with organization is strongly influenced by",
    options: [
      "Nature of the task",
      "Nature of product and service",
      "Nature of the organization",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " Ergonomics concerned with",
    options: [
      "Designing and shaping jobs",
      "Creativity the jobs",
      "Guiding the employees to do the jobs",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " Efficiency consideration must be balanced against",
    options: [
      "Facilities and obtainability",
      "Talents and skills of the people",
      "Abilities and availability of the people",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " _______ insist the job to be designed to meet the expectations of workers",
    options: [
      "Social and cultural expectations",
      "Employee and their abilities",
      "Work practices",
      "Workflow",
    ],
    ans: "A",
  },
  {
    ques: " Techno stress means",
    options: [
      "Stress in task revision",
      "Ergonomic stress",
      "Stress in technology and information",
      "Alternative work pattern",
    ],
    ans: "C",
  },
  {
    ques: " The process of identification of different sources of personnel is",
    options: [
      "Training",
      "Selection",
      "Development",
      "Recruitment",
    ],
    ans: "D",
  },
  {
    ques: " ________ is involving with the shifting of an employee from one job to another",
    options: [
      "Promotion",
      "Transfer",
      "Increment",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " The main inducement of internal transfer is",
    options: [
      "Morale of the employee increased",
      "Employee will work more",
      "Low turnover",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " The important constraint of internal transfer",
    options: [
      "Disagreement in Hierarchy",
      "Lack in coordination",
      "Increase in Labour turnover",
      "Lack in spirit of competition",
    ],
    ans: "D",
  },
  {
    ques: " Direct recruitment is otherwise called",
    options: [
      "Workforce engagement",
      "Recruitment at factory gate",
      "Selection of the best",
      "Promote the talent",
    ],
    ans: "B",
  },
  {
    ques: " ______ Recruitment is very cheap",
    options: [
      "Unsolicited applications",
      "Media advertisement",
      "Direct recruitment",
      "All the above",
    ],
    ans: "C",
  },
  {
    ques: " Senior positions in industry are filled by",
    options: [
      "Employment agencies",
      "Casual caller",
      "Management consultant",
      "Media advertisement",
    ],
    ans: "D",
  },
  {
    ques: " Compulsory notification of vacancies to __________ is required by Law",
    options: [
      "Employment exchanges",
      "Causal caller",
      "Media Ads",
      "Direct recruitment",
    ],
    ans: "A",
  },
  {
    ques: " Who has offered middle level executive placements",
    options: [
      "Educational institutions",
      "Management consultants",
      "Recommendations",
      "Labour contracts",
    ],
    ans: "B",
  },
  {
    ques: " Recruitment planning outlines the major responsibility",
    options: [
      "Skills",
      "Experience",
      "Level of pay",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ____________ is an integral part of recruitment",
    options: [
      "Searching",
      "Screening",
      "Analyzing",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Sons of soil claim means",
    options: [
      "Recruitment from sister concern",
      "Selection by promotion",
      "Suitable hands in vicinity of organization",
      "Selection by recommendation",
    ],
    ans: "C",
  },
  {
    ques: " _______ is hiring the best candidates from the pool of applications",
    options: [
      "Promotion",
      "Transfer",
      "Recommendation",
      "Selection",
    ],
    ans: "D",
  },
  {
    ques: " ____________ is the device used for collecting information from candidates",
    options: [
      "Application Blank",
      "Bio -data of candidate",
      "Interview",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " ________ is the primary benefit of selecting the right candidate",
    options: [
      "Deduction of salary",
      "Avoid training",
      "Competent employees",
      "Easily handle the task",
    ],
    ans: "C",
  },
  {
    ques: " _______ is a process of assigning specific job to each of the selected candidates",
    options: [
      "Placement",
      "Training",
      "Selection practices",
      "Preliminary interview",
    ],
    ans: "A",
  },
  {
    ques: " The significant of placement",
    options: [
      "Improves employee welfare",
      "Reducing absenteeism",
      "Reducing accident rates",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " _______ will help to perform the employees’ duty effectively",
    options: [
      "Training",
      "Recruitment",
      "Induction",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the characteristic of training",
    options: [
      "Clearly define parametres",
      "Planned sequence of events",
      "Induces behavioral changes",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " The main objective of the training",
    options: [
      "Solve the conflict",
      "Improve the productivity",
      "To avoid labor union problems",
      "Increase the no. of employees",
    ],
    ans: "B",
  },
  {
    ques: " ______ is essential to reduce the training period",
    options: [
      "Performance Appraisal",
      "Decision Making",
      "Systematic Training",
      "Quality conscious",
    ],
    ans: "C",
  },
  {
    ques: " The Primary Advantage of Training is",
    options: [
      "Effective Work Flow",
      "Coordination",
      "Order of the work",
      "Technology updation",
    ],
    ans: "D",
  },
  {
    ques: " Employees Learn in Real working environment is",
    options: [
      "Subordinate training",
      "On the job training",
      "Off the job training",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " _______ is the person in an organization can offer more support",
    options: [
      "Mentor",
      "Supervisor",
      "Manager",
      "Superior",
    ],
    ans: "A",
  },
  {
    ques: " “Sitting Next to Nellie” means",
    options: [
      "Learning alongside with unknown process",
      "Learning with friend",
      "Learning with the team",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " JIT in HR is",
    options: [
      "Job In Technique",
      "Just In Time",
      "Job Instructional Technique",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " The object _____ is to make the trainees all round craftsmen",
    options: [
      "Class room training",
      "Coaching and Job rotation",
      "Mentoring",
      "Apprenticeship Training",
    ],
    ans: "D",
  },
  {
    ques: " _______ offers access to something new in learning",
    options: [
      "Vestibule training",
      "Simulation Exercises",
      "Conferences and Lectures",
      "All the above",
    ],
    ans: "A",
  },
  {
    ques: " ______ is an artificial environment exactly similar to the actual situations",
    options: [
      "Vestibule Training",
      "Simulation Exercises",
      "Management Games",
      "Case Study",
    ],
    ans: "B",
  },
  {
    ques: " Sensitive Training is also known as",
    options: [
      "In Basket Training",
      "Role Playing",
      "Case Study",
      "Laboratory / T Group Training",
    ],
    ans: "D",
  },
  {
    ques: " The need assessment is to determine priorities",
    options: [
      "Allocate Resources",
      "Relationship Building",
      "Behaviour identification",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " The First step in Need Assessment",
    options: [
      "Data Gathering and Analysis",
      "Utilisation of information",
      "Exploration and Identification",
      "Evaluation and Control",
    ],
    ans: "C",
  },
  {
    ques: " According to Knowles ______ provides the basis for the learning activities",
    options: [
      "Hard Work/Smart work",
      "Learning and Guidance",
      "Education and Knowledge",
      "Experience including mistakes",
    ],
    ans: "D",
  },
  {
    ques: " _______ initiates center on enhancing capabilities",
    options: [
      "Development",
      "Rewards",
      "Training",
      "Planning",
    ],
    ans: "A",
  },
  {
    ques: " _____ method used to knowledge and attitudinal development of trainees",
    options: [
      "Class room training method",
      "Group discussion method",
      "Simulation exercises",
      "Role playing",
    ],
    ans: "B",
  },
  {
    ques: " In Performance Appraisal",
    options: [
      "The Supervisor measure the pay of employees and compare it",
      "The supervisors analyzes the factors behind work performance",
      "Employers are in position to guide",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " ______ technique which is systematic collection of performance data from all derived groups",
    options: [
      "Field Review Method",
      "MBO",
      "360 degree Feedback",
      "Cost Accounting Method",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is the correct abbreviation of HRM?",
    options: [
      "Human Resource Management",
      "Human Resourcefulness Management",
      "Human Relation Management",
      "Humanistic Relation Management",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following correctly defines the Human Resource Department?",
    options: [
      "Functional department",
      "Service department",
      "Line department",
      "Authority department",
    ],
    ans: "B",
  },
  {
    ques: " Human factor can be defined as _______",
    options: [
      "The entire concept of human behavior",
      "Interrelated Physiological, Psychological and Socio-ethical aspects of a human being",
      "Micro and macro issues of socioeconomic factor",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Finding ways to reduce __________ is a crucial responsibility of management",
    options: [
      "Stress",
      "Dissatisfaction",
      "Uncertainty",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following components are reformed to support the strategies of human resource function?",
    options: [
      "Control systems",
      "Rewards",
      "Appointment",
      "Both (a) and (b)",
    ],
    ans: "D",
  },
  {
    ques: " What is the need for Human Resource Planning?",
    options: [
      "For undergoing an effective employee development program",
      "To represent a base for recruitment",
      "To represent a base for selection policy",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Directing, being one of the preeminent functions of Human Resource Management that falls under _________",
    options: [
      "Operative functions",
      "Technical functions",
      "Managerial functions",
      "Behavioral functions",
    ],
    ans: "C",
  },
  {
    ques: " Human relation approach refers to ________",
    options: [
      "An approach in which workers are facilitated with humanity at the workplace.",
      "A shared teamwork between the employee and the employer for solving problematic issues",
      "Forming a group of people on the work front so as to inspire them to work collectively for the company's growth in terms of social, economic, and psychological productivity.",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following fields requires a skilled HR professional?",
    options: [
      "People handling",
      "Clarifying",
      "Both (a) and (b)",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is considered as strategic activity?",
    options: [
      "Productivity",
      "Recruitment",
      "Planning",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " Who laid the foundation of Human Resource Management practices?",
    options: [
      "David C. McClelland",
      "Roethlisberger and Dickinson",
      "Peter Drucker and Douglas McGregor",
      "Elton Mayo",
    ],
    ans: "Elton Mayo",
  },
  {
    ques: " The business side of the process begins with the strategic __________ as one of the guiding frameworks.",
    options: [
      "Policy",
      "HR",
      "Plan",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " How has HRM become one of the highly focused jobs?",
    options: [
      "It focuses on obtaining as well as maintaining a satisfied workforce",
      "It results in maximum output with the increased customer satisfaction",
      "It promotes group satisfaction with individual development.",
      "Optimum utilization of manpower by motivation and improving efficiency.",
    ],
    ans: "B",
  },
  {
    ques: " What does a job specification include?",
    options: [
      "Personal characteristics",
      "Physical characteristics",
      "Psychological characteristics",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What are those aspects on which the HR professionals apply the risk management techniques?",
    options: [
      "HR Competencies",
      "HR Strategies",
      "Both (a) and (b)",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Developing characteristics of people are needed to run a business in ________",
    options: [
      "Short term",
      "Medium term",
      "Long term",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following issues tends to be well disguised?",
    options: [
      "Territorial",
      "Social",
      "Political",
      "Economical",
    ],
    ans: "A",
  },
  {
    ques: " Who suggested that Human Resource Strategy isn't as effective as it was supposed to be?",
    options: [
      "Tony Groundy",
      "Peter Ducker",
      "Anonymous",
      "John Zimmerman",
    ],
    ans: "A",
  },
  {
    ques: " How many factors were identified by Pettigrew & Whipp to manage the successful change?",
    options: [
      "Four",
      "Five",
      "Three",
      "Six",
    ],
    ans: "B",
  },
  {
    ques: " What is the scope of Human Resource Management?",
    options: [
      "Performing training and development sessions for employee growth.",
      "Maintaining good impersonal industrial relations and worker's morale for companies' productivity.",
      "Accomplishing advanced research in behavioral sciences, new ideas in man, management, and advances in the field of training and development.",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following takes a full interest in the process of strategic planning?",
    options: [
      "Training & Development",
      "Quality Control",
      "Human Resource",
      "Production",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following tells the correct importance of controlling?",
    options: [
      "Power to influence people's behavior",
      "Process of regulating the activities",
      "An important mental process on the part of the manager",
      "To ensure that all of the activities are coordinated as per the plan",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following aims to put the financial measure on an organization's intellectual capital?",
    options: [
      "Exchange of knowledge",
      "Financial knowledge",
      "The consortium",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is meant by decision in management?",
    options: [
      "To formulate a proper conclusion after considerations",
      "A decision that looks out for the alternatives",
      "A decision is the outcome of a group of people or an individual.",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What does quantitative technique refer to?",
    options: [
      "Waiting line problems and the queuing theory",
      "Gaming, Game theory and Probability theory",
      "Models, simulation, and resource allocation technique",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following evolved in staffing?",
    options: [
      "Termination",
      "Estimation of workload",
      "Personnel appointments and placements",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is the correct abbreviation of MPDQ?",
    options: [
      "Modern Positions Developed Qualitatively",
      "Management Process Descriptive Questions",
      "Management Position Description Questionnaire",
      "Methods for Personality Development Questions",
    ],
    ans: "C",
  },
  {
    ques: " What is defined as a record of outcomes resulting from a particular job or an activity at a specific time?",
    options: [
      "Evaluation",
      "Work function",
      "Performance",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " A/An __________ is a plan or a program scheme that helps to motivate an individual or a group to deliver outstanding performance.",
    options: [
      "Promotion Scheme",
      "Incentive Scheme",
      "Reward",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " A/An __________ is considered to be a vertical move in terms of rank and responsibilities.",
    options: [
      "Appraisal",
      "Reward",
      "Increment",
      "Promotion",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following provides necessary information for job evaluation?",
    options: [
      "Job Enrichment",
      "Job Description",
      "Job Ranking",
      "Job Enlargement",
    ],
    ans: "B",
  },
  {
    ques: " What is the 'Laissez Faire' viewpoint?",
    options: [
      "A view popularized by Rousseau, Bentham, and Hobbes",
      "A minimum of public intervention in economic activities",
      "The business enterprise must get the opportunity to earn more profits",
      "The change in the concept of labor from the commodity approach to the human concept.",
    ],
    ans: "B",
  },
  {
    ques: " What do you understand by the written record of duties, responsibilities, and conditions of a job?",
    options: [
      "Job enrichment",
      "Job ranking",
      "Job enlargement",
      "Job description",
    ],
    ans: "D",
  },
  {
    ques: " What are the possible factors that help in understanding the nature of a human being?",
    options: [
      "As per one's behavior at his/her workplace.",
      "Based on how successfully a management team influences an individual employee or a group",
      "With the help of human behavior's determinants",
      "By studying the human behavior of each employee in alliance with the organization",
    ],
    ans: "A",
  },
  {
    ques: " What is meant by the factual statement of the duties and responsibilities?",
    options: [
      "Job analysis",
      "Job specification",
      "Job evaluation",
      "Job description",
    ],
    ans: "D",
  },
  {
    ques: " Why is the career path in Japanese employee management non-specified?",
    options: [
      "Rotational job results in providing a benefit such skills that are necessary for top-quality executives",
      "At the time of induction, the employees within the organization get exposure to switch their careers in different job domains and get themselves trained to have hands-on trending technologies",
      "In order to keep the employee up to date, the Japanese industries offer rotational jobs",
      "The Japanese management system emphasizes creating skilled workers by making them adapt to organizational changes as and when required",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following are one kind of skills: inventory, regression, replacement charts, Markov analysis?",
    options: [
      "Training plan",
      "Retention plan",
      "Redundancy plan",
      "Forecasting methods",
    ],
    ans: "D",
  },
  {
    ques: " For closure, each worker has to compensate with __________ average pay for each preceding year of completed service",
    options: [
      "20 days",
      "15 days",
      "25 days",
      "30 days",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following refers to the term quality improvement?",
    options: [
      "A team effort",
      "Achieved by the quality inspector.",
      "Zero things went wrong",
      "Tough job; somewhat impossible task.",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following pairs in the given sequence represents the initial as well as the final step incurred in the T&D program?",
    options: [
      "Evaluating T&D program followed by implementing it",
      "Determining the need for the T&D program and then evaluating the T&D program",
      "Determining the need for the T&D program followed by designing the T&D program itself",
      "Designing the T&D program and then implementing it.",
    ],
    ans: "B",
  },
  {
    ques: " How are the union leaders elected at different federations?",
    options: [
      "Based on employer's recommendations",
      "Based on the seniority",
      "Based on political considerations",
      "Based on the democratic principle",
    ],
    ans: "D",
  },
  {
    ques: " What does job evaluation seek?",
    options: [
      "Evaluating the importance of different jobs within the organization.",
      "Evaluating employee's performance of their respective job profiles.",
      "Determining the relative worth of various jobs within the organization in monetary terms.",
      "Establishing the hierarchy of different job profiles in the organization.",
    ],
    ans: "C",
  },
  {
    ques: " What is included in the content of the job description?",
    options: [
      "Skills and educational qualification",
      "Job title and work environment",
      "Both (a) and (b)",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between the Japanese management system and other management systems?",
    options: [
      "It is a system in contrast to the American management system",
      "It is mainly concerned with high quality and performance standards",
      "It is encouraged to perform repetitively and reliably",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is enhanced by 360-degree feedback?",
    options: [
      "Managerial decisions",
      "HR decisions",
      "HRD",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Stress is laid on __________ in the grid seminars.",
    options: [
      "Professionalism",
      "Training and Development",
      "Teaching",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following involves one to one interaction amid the administrator and the participant?",
    options: [
      "Motivation",
      "Counseling",
      "Training",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " Under which of the following methods, common factors related to all jobs are identified?",
    options: [
      "Merit",
      "Ranking",
      "Factor comparison",
      "Point",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is involved in the in-basket training?",
    options: [
      "Simulation",
      "On-the-job training",
      "Vestibule training",
      "Coaching",
    ],
    ans: "A",
  },
  {
    ques: " _________ is considered the first wage incentive plan in the modern era of the industry",
    options: [
      "Barth plan",
      "Halsey plan",
      "Gantt task plan",
      "Taylor's differential piece-rate plan",
    ],
    ans: "D",
  },
  {
    ques: " The career path model in an organization, initiating career planning can importantly form the basis for ______?",
    options: [
      "Rotation",
      "Transfer",
      "Placement",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " In HR functions of management, assisting managers is known as ___________",
    options: [
      "Line manager",
      "Staff manager",
      "First line supervisor",
      "All of above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following factor does not involve in communication with employees?",
    options: [
      "Compensating",
      "Disciplining",
      "Counseling",
      "Interviewing",
    ],
    ans: "A",
  },
  {
    ques: " Who set the Performance development plan for the employee",
    options: [
      "Department Head",
      "Employer",
      "Immediate boss",
      "All of the above",
    ],
    ans: "C",
  },
  ]

  export default human_resources