import "./App.css";
import { useState, useEffect } from "react";
import Register from "./components/Register";
import Navigation from "./components/Navigation";
import Quiz from "./components/Quiz";
import Title from "./components/Title";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { auth, onAuthStateChanged } from "./components/Firebase";
import JobProfile from "./components/JobProfile";
import UserData from "./components/UserData";
import FailedUser from "./components/FailedUser";
import RejectedUser from "./components/RejectedUser";
import TryAfter from "./components/TryAfter";
import Schedule from "./components/Schedule";
import Sign from "./components/Sign";
import Apply from "./components/Apply";
import LaunchQuiz from "./components/LaunchQuiz";
import QuizResult from "./components/QuizResult";
import ScrollTop from "./components/ScrollTop";
import LoadingSpinner from "./components/LoadingSpinner";
import Interviews from "./components/Interviews";
import SelectedUsers from "./components/SelectedUsers";
import ConnectedUsers from "./components/ContactedUsers";
import ResumeViewer from "./components/ResumeViewer";
import SendEmails from "./components/SendEmails";

function App() {
  const [user, setUser] = useState(null);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");

  useEffect(async () => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const currentUser = auth.currentUser;
        setUser(user);
        setCurrentUserEmail(currentUser.email);
        setCurrentUserName(currentUser.displayName);
      } else {
        setUser(null);
        setCurrentUserEmail("");
        setCurrentUserName("");
      }
    });
  }, []);

  return (
    // <h1>Site under maintenance</h1>
    <>
      <BrowserRouter>
        <ScrollTop />
        <Navigation user={user} currentUserEmail={currentUserEmail} />
        <Routes>
          <>
            <Route path="load" element={<LoadingSpinner />} />
            <Route
              path="/"
              element={
                <>
                  <Apply user={user} currentUserEmail={currentUserEmail} />{" "}
                  <Title title="Alphanumeric Ideas Career" />
                </>
              }
            />
            <Route
              path="/all-jobs"
              element={
                <>
                  <JobProfile user={user} currentUserEmail={currentUserEmail} />
                  <Title title="All Jobs | Alphanumeric Ideas Career" />
                </>
              }
            />
            <Route
              path="/update-profile"
              element={
                <>
                  <Register
                    currentUserEmail={currentUserEmail}
                    currentUserName={currentUserName}
                  />{" "}
                  <Title title="Update Profile | Alphanumeric Ideas Career" />{" "}
                </>
              }
            />

            {/* <Route
              path="/schedule"
              element={
                <>
                  <Schedule user={user} currentUserEmail={currentUserEmail} />{" "}
                  <Title title="Schedule Interview | Alphanumeric Ideas Career" />
                </>
              }
            /> */}
            <Route
              path="/quiz"
              element={
                <>
                  <Quiz user={user} currentUserEmail={currentUserEmail} />
                  <Title title="Quiz | Alphanumeric Ideas Career" />
                </>
              }
            />
            <Route
              path="/quiz-description"
              element={
                <>
                  <LaunchQuiz user={user} currentUserEmail={currentUserEmail} />{" "}
                  <Title title="Quiz Description | Alphanumeric Ideas Career" />
                </>
              }
            />
            <Route
              path="/quiz-result"
              element={
                <>
                  <QuizResult
                    user={user}
                    currentUserName={currentUserName}
                    currentUserEmail={currentUserEmail}
                  />
                  <Title title="Quiz Result | Alphanumeric Ideas Career" />
                </>
              }
            />
            <Route
              path="/quiz-description/resubmit-error"
              element={
                <>
                  <TryAfter user={user} />
                  <Title title="Quiz Description | Alphanumeric Ideas Career" />
                </>
              }
            />
          </>

          <>
            <Route
              path="/sign"
              element={
                <>
                  <Sign user={user} />
                  <Title title="Sign In | Alphanumeric Ideas Career" />
                </>
              }
            />
          </>

          {currentUserEmail === "gurvinder@alphanumericideas.com" ||
          currentUserEmail === "mohit@alphanumericideas.com" ||
          currentUserEmail === "zippinder@alphanumericideas.com" ||
          currentUserEmail === "shruti@alphanumericideas.com" ? (
            <>
              <Route
                path="/userdata"
                element={
                  <>
                    <UserData currentUserEmail={currentUserEmail} />
                    <Title title="User Data | Alphanumeric Ideas Career" />
                  </>
                }
              />
              <Route
                path="/connected"
                element={
                  <>
                    <ConnectedUsers currentUserEmail={currentUserEmail} />
                    <Title title="Connected Users | Alphanumeric Ideas Career" />
                  </>
                }
              />
              <Route
                path="/failed"
                element={
                  <>
                    <FailedUser />
                    <Title title="Failed Users | Alphanumeric Ideas Career" />
                  </>
                }
              />
              <Route
                path="/selected-users"
                element={
                  <>
                    <SelectedUsers />
                    <Title title="Selected Users | Alphanumeric Ideas Career" />
                  </>
                }
              />
              <Route
                path="/rejected"
                element={
                  <>
                    <RejectedUser
                      user={user}
                      currentUserEmail={currentUserEmail}
                    />
                    <Title title="Rejected Users | Alphanumeric Ideas Career" />
                  </>
                }
              />

              <Route
                path="/scheduled-interviews"
                element={
                  <>
                    <Interviews
                      user={user}
                      currentUserEmail={currentUserEmail}
                    />
                    <Title title="Scheduled Interviews | Alphanumeric Ideas Career" />
                  </>
                }
              />

              <Route
                path="/resume-viewer"
                element={
                  <>
                    <ResumeViewer />
                    <Title title="Resume Viewer | Alphanumeric Ideas Career" />
                  </>
                }
              />

              <Route
                path="/send-emails"
                element={
                  <>
                    <SendEmails />
                    <Title title="Send Emails | Alphanumeric Ideas Career" />
                  </>
                }
              />
            </>
          ) : (
            <></>
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
