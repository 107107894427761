import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";
import CardData from "./CardData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import JCardData from "./JCardData";
import { useNavigate } from "react-router-dom";

const JobCard = ({ currentUserEmail }) => {
  const navigate = useNavigate();
  return (
    <>
      <div className="bg-white mt-5 mobileJobProfile">
        <div className="heading">All Available Jobs</div>
        <Container fluid>
          <div className="row justify-content-center">
            {JCardData.map((ele) => {
              return (
                <>
                  <div className="col-md-5 col-lg-4">
                    <Card
                      className="cardSt"
                      onClick={() => {
                        let readIndex = JCardData.findIndex(
                          (elem) => elem.title === ele.title
                        );
                        localStorage.setItem("readIndex", readIndex);
                        localStorage.setItem("quizType", "JCardData");
                        navigate("/quiz-description");
                      }}
                    >
                      <Card.Body className="cardStyle">
                        <div className="cardImage text-center pt-4">
                          <Image src={ele.imgLink} />
                        </div>
                        <Card.Title className="mt-3">{ele.title}</Card.Title>
                        <Card.Text>
                          {ele.description.slice(0, 120) + "..."} <br />
                          <div
                            className="readMore mt-2 mb-2 d-inline-block"
                            aria-hidden="true"
                            onClick={() => {
                              let readIndex = JCardData.findIndex(
                                (elem) => elem.title === ele.title
                              );
                              localStorage.setItem("readIndex", readIndex);
                              localStorage.setItem("quizType", "JCardData");
                              navigate("/quiz-description");
                            }}
                          >
                            Read More
                            <span className="ms-2 readMore3">
                              <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                            <span className="ms-2 readMore2">
                              <FontAwesomeIcon icon={faChevronRight} />{" "}
                            </span>
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </>
              );
            })}
          </div>
        </Container>
      </div>
    </>
  );
};

export default JobCard;
