import { initializeApp } from "firebase/app";
import Config from "./Config";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  deleteDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  query,
  where,
} from "firebase/firestore";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  onAuthStateChanged,
} from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

const app = initializeApp(Config);
const auth = getAuth(app);
const provider = new GoogleAuthProvider(app);
const db = getFirestore(app);
const functions = getFunctions(app);
const storage = getStorage(app);

export {
  auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  provider,
  onAuthStateChanged,
  db,
  collection,
  getDocs,
  addDoc,
  doc,
  setDoc,
  functions,
  httpsCallable,
  deleteDoc,
  getDoc,
  updateDoc,
  arrayUnion,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  query,
  where,
};
