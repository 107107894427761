const data_entry = [
  {
    quizname: "Data Entry",
    imgLink :"https://alphanumericideas.com/images/careers/data-entry.png",
    ques: " What is data entry?",
    options: [
      "Storing information in a database",
      "Collecting information from various sources",
      "Entering information into a computer system",
      "Analyzing information to draw conclusions",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common data entry error?",
    options: [
      "Transposition error",
      "Omission error",
      "Addition error",
      "Subtraction error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common tool used for data entry?",
    options: [
      "Keyboard",
      "Mouse",
      "Scanner",
      "Printer",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a best practice for data entry?",
    options: [
      "Use abbreviations and acronyms as much as possible",
      "Enter data as quickly as possible",
      "Double-check all data before submitting it",
      "Ignore minor errors if they do not impact the overall data",
    ],
    ans: "C",
  },
  {
    ques: " What is OCR?",
    options: [
      'An acronym network error for "Optical Character Recognition',
      "A type of computer virus",
      "A data entry software program",
      'An abbreviation for "Online Customer Reviews"',
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a common data entry software program?",
    options: [
      "Microsoft Word",
      "Adobe Photoshop",
      "QuickBooks",
      "Excel",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common type of data entry job?",
    options: [
      "Medical billing",
      "Survey data collection",
      "Retail sales",
      "Inventory management",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common data entry format?",
    options: [
      "Text",
      "Spreadsheet",
      "Database",
      "Image",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common data entry skill?",
    options: [
      "Typing speed",
      "Attention to detail",
      "Customer service",
      "Time management",
    ],
    ans: "C",
  },
  {
    ques: " What is the purpose of data entry?",
    options: [
      "To create new data",
      "To delete old data",
      "To organize existing data",
      "To make data more complicated",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a common data entry task?",
    options: [
      "Writing a research report",
      "Conducting a survey",
      "Transcribing a conversation",
      "Analyzing data trends",
    ],
    ans: "C",
  },
  {
    ques: " What is the role of a data entry clerk?",
    options: [
      "To manage a company's finances",
      "To collect data from customers",
      "To enter data into a computer system",
      "To create visual representations of data",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a common data entry output?",
    options: [
      "Report",
      "Chart",
      "Graph",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering numerical data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Capitalization error",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering text data?",
    options: [
      "Transposition error",
      "Omission error",
      "Numeric error",
      "Punctuation error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering date data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering time data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering name data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering address data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering email data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering phone number data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Format error",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering currency data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Decimal point error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering percentage data?",
    options: [
      "Transposition error",
      "Omission error",
      "Spelling error",
      "Decimal point error",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry error that can occur when entering text data with special characters?",
    options: [
      "Encoding error",
      "Spelling error",
      "Omission error",
      "Transposition error",
    ],
    ans: "A",
  },
  {
    ques: " What is a data entry operator?",
    options: [
      "A person who enters data into a computer system",
      "A software program that automatically enters data",
      "A machine that records data",
      "An algorithm that analyzes data",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between data entry and data processing?",
    options: [
      "Data entry involves entering data into a computer system, while data processing involves analyzing and manipulating data",
      "Data entry involves analyzing and manipulating data, while data processing involves entering data into a computer system",
      "Data entry and data processing are the same thing",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a common software program used for data entry?",
    options: [
      "Microsoft Access",
      "Google Sheets",
      "QuickBooks",
      "Adobe Photoshop",
    ],
    ans: "D",
  },
  {
    ques: " What is the importance of accuracy in data entry?",
    options: [
      "It ensures that the data is reliable and can be used for decision-making",
      "It saves time by minimizing the need for double-checking and correcting errors",
      "It improves the aesthetics of the data output",
      "It allows for more complex data entry tasks to be performed",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of speed in data entry?",
    options: [
      "It allows for more data to be entered in a shorter amount of time",
      "It minimizes the risk of errors due to the data entry operator getting tired or distracted",
      "It improves the accuracy of the data entered",
      "It is not important in data entry",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a common task performed by a data entry operator?",
    options: [
      "Entering customer contact information",
      "Transcribing audio recordings",
      "Analyzing data trends",
      "Inputting survey responses",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common industry that employs data entry operators?",
    options: [
      "Healthcare",
      "Retail",
      "Finance",
      "Law",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common qualification for a data entry job?",
    options: [
      "Attention to detail",
      "Customer service skills",
      "Mathematical proficiency",
      "Both B and C",
    ],
    ans: "D",
  },
  {
    ques: " What is a data entry form?",
    options: [
      "A paper document used to collect data",
      "A software program that facilitates data entry by presenting a structured interface",
      "A data analysis tool that helps identify trends in data",
      "A type of database used for storing and organizing data",
    ],
    ans: "B",
  },
  {
    ques: " What is a data entry clerk's role in quality assurance?",
    options: [
      "To identify and correct errors in data entry",
      "To design and implement quality control measures for data entry",
      "To analyze data trends and identify areas for improvement",
      "To communicate data entry results to management and other stakeholders",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of data validation that checks if a value falls within a certain range?",
    options: [
      "Range validation",
      "Format validation",
      "Existence validation",
      "Completeness validation",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a type of data validation that checks if a value is in the correct format?",
    options: [
      "Range validation",
      "Format validation",
      "Existence validation",
      "Completeness validation",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a type of data validation that checks if a value exists in a list of acceptable values?",
    options: [
      "Range validation",
      "Format validation",
      "Existence validation",
      "Completeness validation",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a type of data validation that checks if all required fields have been completed?",
    options: [
      "Range validation",
      "Format validation",
      "Existence validation",
      "Completeness validation",
    ],
    ans: "D",
  },
  {
    ques: " What is the purpose of data cleaning in data entry?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To remove errors and inconsistencies from data that has already been entered",
      "To prevent unauthorized access to the data",
      "To analyze data trends and identify areas for improvement",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is not a common data cleaning task?",
    options: [
      "Removing duplicate records",
      "Correcting spelling errors",
      "Reformatting data to match a desired format",
      "Entering new data",
    ],
    ans: "D",
  },
  {
    ques: " What is the purpose of data analysis in data entry?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To remove errors and inconsistencies from data that has already been entered",
      "To analyze data trends and identify areas for improvement",
      "To prevent unauthorized access to the data",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a common data analysis tool used in data entry?",
    options: [
      "Microsoft Excel",
      "Microsoft Word",
      "Microsoft PowerPoint",
      "Microsoft Access",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a common data analysis task?",
    options: [
      "Identifying trends in data",
      "Generating reports",
      "Correcting errors in data entry",
      "Creating graphs and charts",
    ],
    ans: "C",
  },
  {
    ques: " What is the purpose of data storage in data entry?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To remove errors and inconsistencies from data that has already been entered",
      "To analyze data trends and identify areas for improvement",
      "To store and organize data for future use",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common database management system used for data entry?",
    options: [
      "Microsoft Excel",
      "Microsoft Word",
      "Microsoft PowerPoint",
      "Microsoft Access",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common data storage format used in data entry?",
    options: [
      "CSV",
      "XML",
      "PDF",
      "JSON",
    ],
    ans: "C",
  },
  {
    ques: " What is the importance of data security in data entry?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To prevent unauthorized access to the data",
      "To analyze data trends and identify areas for improvement",
      "To store and organize data for future use",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a common data security measure used in data entry?",
    options: [
      "Password protection",
      "Data encryption",
      "Firewalls",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common software program used for data entry?",
    options: [
      "Microsoft Excel",
      "Google Sheets",
      "Adobe Photoshop",
      "QuickBooks",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common skill required for a data entry internship?",
    options: [
      "Attention to detail",
      "Fast typing speed",
      "Ability to work independently",
      "Proficiency in a specific programming language",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not a common task assigned to a data entry intern?",
    options: [
      "Entering customer information into a database",
      "Creating graphs and charts based on data trends",
      "Cleaning up data to remove errors and inconsistencies",
      "Generating reports based on data analysis",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a common format for data entry?",
    options: [
      "PDF",
      "HTML",
      "Text file",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common source of data for data entry?",
    options: [
      "Customer surveys",
      "Sales reports",
      "Social media posts",
      "Weather forecasts",
    ],
    ans: "D",
  },
  {
    ques: " What is the purpose of data entry accuracy checks?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To remove errors and inconsistencies from data that has already been entered",
      "To analyze data trends and identify areas for improvement",
      "To store and organize data for future use",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a common data entry accuracy check?",
    options: [
      "Double-entry verification",
      "Range validation",
      "Format validation",
      "Completeness validation",
    ],
    ans: "B",
  },
  {
    ques: " What is the purpose of data entry speed tests?",
    options: [
      "To ensure that data is entered accurately and consistently",
      "To remove errors and inconsistencies from data that has already been entered",
      "To evaluate the typing speed and accuracy of the data entry intern",
      "To store and organize data for future use",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a common data entry speed test?",
    options: [
      "Typing test",
      "10-key test",
      "Dictation test",
      "Microsoft Office proficiency test",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a common data entry best practice?",
    options: [
      "Entering data as quickly as possible to meet a deadline",
      "Using abbreviations and acronyms to save time",
      "Taking breaks to rest your eyes and hands",
      "Skipping accuracy checks to save time",
    ],
    ans: "C",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  ]

  export default data_entry