const email_markrting = [
  {
    quizname: "Email Marketing",
    imgLink :"https://alphanumericideas.com/images/careers/email-marketing.png",
    ques: " What is the full form of email",
    options: [
      "Electric mail",
      "Electronic mail",
      "Electromagnetic Mail",
      "Electricity mail",
    ],
    ans: "B",
  },
  {
    ques: " Which sign was used to link the user name with a destination server for sending email?",
    options: [
      "&",
      "@",
      "*",
      "#",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is a correct format of email address?",
    options: [
      "Name@website@info",
      "Name@website.info",
      "www.nameofebsite.com",
      "name.website.com",
    ],
    ans: "B",
  },
  {
    ques: " To communicate with the email server the mail reader uses special languages, called_____",
    options: [
      "web server",
      "DNS",
      "Topology",
      "Protocals",
    ],
    ans: "D",
  },
  {
    ques: " In computing, the __________is an application-layer internet standard protocol used by e-mail clients to retreive e mail from a mail server.",
    options: [
      "POP ( post office protocol)",
      "SMTP",
      "IMAP",
      "name.website.com",
    ],
    ans: "A",
  },
  {
    ques: " Sending an e-mail is similar to",
    options: [
      "Picturing an event",
      "Narrating a story",
      "Writing a letter",
      "Creating a drawing",
    ],
    ans: "C",
  },
  {
    ques: " ________ allows you to access your email from anywhere?",
    options: [
      "Forum",
      "webmail Interface",
      "Massage Board",
      "Weblog",
    ],
    ans: "B",
  },
  {
    ques: " An e-mail account includes a storage area, often called a?",
    options: [
      "Attachment",
      "Hyperlink",
      "Mailbox",
      "IP address",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a term pertaining to e-mail?",
    options: [
      "Inbox",
      "Sender",
      "Power point",
      "Receiver",
    ],
    ans: "C",
  },
  {
    ques: " While sending an e-mail _________line describe the contents of the message",
    options: [
      "To",
      "Subject",
      "Content",
      "CC",
    ],
    ans: "B",
  },
  {
    ques: " Gmail belongs to",
    options: [
      "Great mail",
      "Yahoo mail",
      "Google mail",
      "Gopher mail",
    ],
    ans: "C",
  },
  {
    ques: " What is the full form of BCC?",
    options: [
      "Brown Cold coffee",
      "Big Carbon copy",
      "Blind carbon copy",
      "Casual copy",
    ],
    ans: "C",
  },
  {
    ques: " Junk e-mail is also called?",
    options: [
      "Spam",
      "Copple crumbs",
      "Sniffer script",
      "None",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is required in the e-mail opening form to  open a new e-mail id",
    options: [
      "Name and address",
      "Date of birth and father name",
      "Alternate email id if necessary",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the purpose of providing a draft folder in an email system?",
    options: [
      "To save unsent emails",
      "To save a copy of sent emails",
      "To store deleted emails",
      "To store spam emails",
    ],
    ans: "A",
  },
  {
    ques: " Which medium is not suitable for E-mail",
    options: [
      "Paper",
      "Internet",
      "Intranet",
      "Extranet",
    ],
    ans: "A",
  },
  {
    ques: " Which term avoided in email?",
    options: [
      "Wrong e-mail address",
      "Re-reading",
      "Subject line",
      "Smileys",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is used by internet mail",
    options: [
      "FTP",
      "HTTP",
      "TCP/IP",
      "UTP",
    ],
    ans: "B",
  },
  {
    ques: " To send a received mail to another person, you need to____the message",
    options: [
      "Copy ",
      "Forward",
      "Reply to",
      "Reply all",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is mandatory for sending an email",
    options: [
      "Body",
      "Subject",
      "Attachment",
      "Sender mail ID",
    ],
    ans: "D",
  },
  {
    ques: " What is included in an E-mail address",
    options: [
      "User's name followed by domain name",
      "Domain name followed by user name",
      "User's name followed by street adress",
      "User's name follwed by postel address",
    ],
    ans: "A",
  },
  {
    ques: " Which email messages sent by an unknown sender, usually for business purposes?",
    options: [
      "Virus",
      "Trojan",
      "Important",
      "Junk",
    ],
    ans: "D",
  },
  {
    ques: " A block of text automatically added to the end of an outgoing email is called",
    options: [
      "Footer",
      "Signature",
      "Attachment",
      "Encryption",
    ],
    ans: "B",
  },
  {
    ques: " Need of email media marketing",
    options: [
      "Limitation of social media",
      "Customization",
      "Target Audience",
      "Reporting and analytics",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following comes under email marketing?",
    options: [
      "Email newsletters",
      "Lead Nurturing",
      "Digests",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not an advantage of email newsletters?",
    options: [
      "Email newsletters spread your brand awareness",
      "Leverage the temporary content",
      "Freedom to include different types of content",
      "Leverage the existing content",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is most important metric to track email marketing?",
    options: [
      "CTR",
      "Open rate",
      "Click rate",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " what technique is used by legitimate marketers to customize offerings for specific customers?",
    options: [
      "Personalization",
      "Spamming",
      "Spoofing",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Select the features of a lead nurturing platform?",
    options: [
      "A/B testing",
      "Landing page creation",
      "Campaigning",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " This is a term for the number of times that user click on links in a message or on a website : it is much higher for legitimate marketing emails that for spam.",
    options: [
      "Personalization",
      "Spamming",
      "CTR",
      "Spoofing",
    ],
    ans: "C",
  },
  {
    ques: " Identify the factor that enables tracking of good and bad reputation?",
    options: [
      "IP address",
      "User engagement",
      "Fequency",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " How important is the authentication process in email marketing?",
    options: [
      "Not Important",
      "Can be skipped",
      "Very Important",
      "Depend on individual",
    ],
    ans: "C",
  },
  {
    ques: " In which type of email campaige, advertisment are sent to target group of customer?",
    options: [
      "Direct email marketing",
      "Indirect email marketing",
      "Spamming",
      "Spoofing",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is correct size of email template before executing a campaign?",
    options: [
      "15KB",
      "18KB",
      "20KB",
      "15MB",
    ],
    ans: "B",
  },
  {
    ques: " In a marketing meeting, you suggested that the company should put more emphasis on email marketing. What is the most compelling data statistic you should cite?",
    options: [
      "It is a cheap marketing tool.",
      "It generates more ROI than other marketing channels.",
      "People like emails.",
      "Email can be integrated into social media.",
    ],
    ans: "B",
  },
  {
    ques: " In developing your next email campaign, you have to make a case to develop a responsive email. What is the key factor of responsive design to persuade your team?",
    options: [
      "People open emails on smartphones more than any other device",
      "Responsive design means designing for iPhones",
      "People open emails multiple times on a variety of devices. So, the email needs to render properly regardless.",
      "Responsive design means mobile-first thinking.",
    ],
    ans: "C",
  },
  {
    ques: " Using white space around an object will:",
    options: [
      "Waste space that could be used for marketing",
      "Decrease readability",
      "Enhance the object and draw attention to it",
      "Make a border",
    ],
    ans: "C",
  },
  {
    ques: " How important is consistency in branding and subject lines?",
    options: [
      "The email becomes an extension of the brand and the format becomes recognizable",
      "Everything needs to be the same.",
      "It isn't important.",
      "Subject lines can be ignored.",
    ],
    ans: "A",
  },
  {
    ques: " A member of your team proposes to cap the subject line at 30 characters to increase open rate and click rate. What points would you emphasize as the primary factors of open rate and click rate?",
    options: [
      "Word length and frequency",
      "Specific recipients and personalized message",
      "List size and offer",
      "Targeted industry and the day of the week",
    ],
    ans: "B",
  },
  {
    ques: " Emails containing videos have higher open rates and click through rates.",
    options: [
      "TRUE",
      "FALSE",
      "Can be true or false",
      "Can not say",
    ],
    ans: "A",
  },
  {
    ques: " Open rates are more influenced by _____.",
    options: [
      "Industry",
      "Word choice",
      "Personalization",
      "The day email is sent",
    ],
    ans: "C",
  },
  {
    ques: " What are the goals that marketers should strive for in email marketing?",
    options: [
      "Spend less time on email marketing.",
      "Increase open rates and click rates.",
      "Increase spam rates.",
      "Decrease deliverability rates.",
    ],
    ans: "B",
  },
  {
    ques: " What is a best practice for sending an email communication from a brand?",
    options: [
      "Including only the employee name.",
      "Including employee's first name.",
      "Sending from multiple employee accounts.",
      "Identifying the brand name.",
    ],
    ans: "D",
  },
  {
    ques: " What are the goals that marketers should strive for in email marketing?",
    options: [
      "Spend less time on email marketing.",
      "Increase spam rates.",
      "Decrease deliverability rates",
      "Increase open rates and click rates.",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is not an advantage of email newsletters?",
    options: [
      "Email newsletters spread your brand awareness",
      "Leverage the temporary content",
      "Freedom to include different types of content",
      "Leverage the existing content",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is the most important metric to track email marketing?",
    options: [
      "CTR",
      "Open rate",
      "Click rate",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What technique is used by legitimate marketers to customize offerings for specific customers?",
    options: [
      "Personalization",
      "Spamming",
      "Spoofing",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Select the features of a lead nurturing platform?",
    options: [
      "A/B testing",
      "Landing page creation",
      "Campaigning",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " This is a term for the number of times that a user clicks on links in a message or on a website: it is much higher for legitimate marketing emails than for spam",
    options: [
      "Personalization",
      "Spamming",
      "CTR",
      "Spoofing",
    ],
    ans: "C",
  },
  {
    ques: " How important is the authentication process in email marketing?",
    options: [
      "Not Important",
      "Can be skipped",
      "Very Important",
      "Depends on individual",
    ],
    ans: "C",
  },
  {
    ques: " In which type of email campaign advertisement is sent to the target group of customers?",
    options: [
      "Direct email marketing",
      "Indirect email marketing",
      "Spamming",
      "Spoofing",
    ],
    ans: "A",
  },
  {
    ques: " What are the goals that marketers should strive for in email marketing?",
    options: [
      "Spend less time on email marketing.",
      "Increase spam rates",
      "Decrease deliverability rates",
      "Increase open rates and click rates",
    ],
    ans: "D",
  },
  {
    ques: " What is a best practice for sending an email communication from a brand?",
    options: [
      "Including only the employee name",
      "Including employee’s first name.",
      "Sending from multiple employee accounts.",
      "Identifying the brand name",
    ],
    ans: "D",
  },
  {
    ques: " Emails containing videos have higher open rates and click-through rates.",
    options: [
      "TRUE",
      "FALSE",
      "Can be true or false",
      "Can not say",
    ],
    ans: "C",
  },
  {
    ques: " How important is consistency in branding and subject lines?",
    options: [
      "The email becomes an extension of the brand and the format becomes recognizable.",
      "Everything needs to be the same",
      "It isn’t important.",
      "Subject lines can be ignored",
    ],
    ans: "A",
  },
  {
    ques: " Using white space around an object will:",
    options: [
      "Waste space that could be used for marketing",
      "Decrease readability",
      "Enhance the object and draw attention to it",
      "Make a border",
    ],
    ans: "C",
  },
  {
    ques: " In a marketing meeting, you suggested that the company should put more emphasis on email marketing. What is the most compelling data statistic you should cite?",
    options: [
      "It is a cheap marketing tool.",
      "It generates more ROI than other marketing channels",
      "People like emails.",
      "Email can be integrated into social media.",
    ],
    ans: "B",
  },
  {
    ques: " In developing your next email campaign, you have to make a case to develop a responsive email. What is the key factor of responsive design to persuade your team?",
    options: [
      "People open emails on smartphones more than on any other device",
      "Responsive design means designing for iPhones.",
      "People open emails multiple times on a variety of devices. So, the email needs to render properly regardless.",
      "Responsive design means mobile-first thinking.",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not an advantage of email newsletters?",
    options: [
      "Email newsletters spread your brand awareness",
      "Leverage the temporary content",
      "Freedom to include different types of content",
      "Leverage the existing content",
    ],
    ans: "B",
  },
  {
    ques: " What technique is used by legitimate marketers to customize offerings for specific customers?",
    options: [
      "Personalization",
      "Spamming",
      "Spoofing",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " How important is consistency in branding and subject lines?",
    options: [
      "The email becomes an extension of the brand and the format becomes recognizable.",
      "Everything needs to be the same.",
      "It isn’t important.",
      "Subject lines can be ignored.",
    ],
    ans: "A",
  },
  {
    ques: " The sequence of a sales process is_________",
    options: [
      "Lead generation, call, presentation & sale",
      "Sale, presentation, Lead generation & call",
      "Lead generation, Call, Sale & Presentation",
      "There is no sequence required",
    ],
    ans: "A",
  },
  {
    ques: " Value -added services means___________",
    options: [
      "Additional services",
      "Better value at a premium",
      "Costlier services",
      "Better value at a discount",
    ],
    ans: "A",
  },
  {
    ques: " To ‘Close a Call’ means__________",
    options: [
      "To end the conversation",
      "To clinch the sale",
      "To put the phone down",
      " To close the doors",
    ],
    ans: "B",
  },
  {
    ques: " ‘Customization’ means_________",
    options: [
      "Tailor-made products for each staff",
      "Tailor-made products for each customer",
      "Customers selling goods",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " A ‘Call’ in marketing language means__________",
    options: [
      "Calling on a salesperson",
      "Calling on a customer",
      "Telemarketing",
      "None of these",
    ],
    ans: "D",
  },
  {
    ques: " A marketing survey is required for____________",
    options: [
      "Deciding marketing strategies",
      "Deciding product strategies",
      "Deciding pricing strategies",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Cross-selling means___________",
    options: [
      "Selling with a cross face",
      "Cross country marketing",
      "Selling other products to existing customers",
      "Selling to friends",
    ],
    ans: "C",
  },
  {
    ques: " Marketing segmentation is useful for________",
    options: [
      "Preferential marketing",
      "Targeting existing clients",
      "Identifying prospects and Knowing customers’ tastes",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Market information means_____________",
    options: [
      "Knowledge of customer’s tastes",
      "Knowledge of industries",
      "Knowledge of households",
      "All of these",
    ],
    ans: "A",
  },
  {
    ques: " Market segmentation means_____________",
    options: [
      "Segmenting the employees",
      "Segmentation the customers as per their needs",
      "Segmenting the products",
      "All of these",
    ],
    ans: "B",
  },
  {
    ques: " A ‘Target Group’ means___________",
    options: [
      "A group of sellers",
      "A group of products",
      "A group of person to whom sales should be focused",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " Marketing is the art of___________",
    options: [
      "Buying more",
      "Paying more",
      "Selling more",
      "Talking more",
    ],
    ans: "C",
  },
  {
    ques: " Marketing of services is resorted in___________",
    options: [
      "Manufacturing concerns",
      "Insurance business",
      "Hostels",
      "Only 2 and 3",
    ],
    ans: "D",
  },
  {
    ques: " Marketing is a__________",
    options: [
      "One day effort",
      "Team effort",
      "One man effort",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " Service marketing is the same as___________",
    options: [
      "Transaction marketing",
      "Relationship marketing",
      "Internal marketing",
      "All the above",
    ],
    ans: "B",
  },
  {
    ques: " Good marketing requires one of the following",
    options: [
      "Proper planning",
      "Good team work",
      "Good communication skills",
      "All of these",
    ],
    ans: "B",
  },
  {
    ques: " Marketing in banks is a necessity today, due to",
    options: [
      "Liberalisation",
      "Nationalisation",
      "Fashion",
      "Urbanisation",
    ],
    ans: "A",
  },
  {
    ques: " Marketing is influenced by__________",
    options: [
      "Product demand",
      "Public taste",
      "Buyer behaviour",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Market share means__________",
    options: [
      "Share of business among peers",
      "Share market",
      "Share prices",
      "IPOs",
    ],
    ans: "A",
  },
  {
    ques: " Marketing helps in_______",
    options: [
      "Boosting production",
      "Getting new clients",
      "Interacting with strangers",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " A DSA helps in________",
    options: [
      "Boosting direct sales",
      "Boosting sales through the net",
      "Strengthening indirect marketing",
      "Strengthening telemarketing",
    ],
    ans: "A",
  },
  {
    ques: " A DSA (Direct Selling Agent) is one___________",
    options: [
      "Who sells through the internet",
      "Who works on the bank counters",
      "Who works in bank office setup",
      "Who sells direct to the client",
    ],
    ans: "D",
  },
  {
    ques: " Selling is_________",
    options: [
      "Different from marketing",
      "A sub-function of marketing",
      "Same as marketing",
      "None of these",
    ],
    ans: "D",
  },
  {
    ques: " A ‘Lead’ in marketing jargon, means__________",
    options: [
      "A likely consumer",
      "A metal",
      "A leash",
      "A team leader",
    ],
    ans: "A",
  },
  {
    ques: " Good selling skills involve__________",
    options: [
      "Patience",
      "Presence",
      "Empathy",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " A true marketing mindset requires________",
    options: [
      "Control mindset",
      "Command mindset",
      "Passive mindset",
      "Active mindset",
    ],
    ans: "D",
  },
  {
    ques: " Innovation means________",
    options: [
      "Inspiration",
      "Enthusiasm",
      "Creativity",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " Internal marketing means__________",
    options: [
      "Marketing to self",
      "Marketing to family members",
      "Marketing to the staff members",
      "Marketing inside India",
    ],
    ans: "C",
  },
  {
    ques: " Modern styles of marketing are____________",
    options: [
      "Telemarketing",
      "Web marketing",
      "Advertisement on the net",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " A good and effective DSA should_____________",
    options: [
      "Be more effective than the competitor company",
      "Copy the competitor company",
      "Be passive",
      "Criticize the competitor company",
    ],
    ans: "A",
  },
  {
    ques: " Prior to the Industrial Revolution, selling was no problem and no sales team is required because",
    options: [
      "People don’t want to sell anything",
      "Small-scale enterprises dominated the economic scene",
      "Marketing was not a subject then",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " The long term objective of marketing is______",
    options: [
      "Customer Satisfaction",
      "Profit Maximisation",
      "Profit Maximisation with customer",
      "Cost cutting",
    ],
    ans: "C",
  },
  {
    ques: " Which among the following is statistical indicator for equality in income distribution?",
    options: [
      "Gini Coefficient",
      "Price Indices",
      "GNP",
      "GDP",
    ],
    ans: "A",
  },
  {
    ques: " Short term planning focuses on",
    options: [
      "Functional Plans",
      "Long term objectives",
      "Specific Goals",
      "Both (1) and (3)",
    ],
    ans: "D",
  },
  {
    ques: " In marketing terms, Attitude can best be defined as a",
    options: [
      "Rude behaviour of salesperson",
      "Rude behaviour of Consumer",
      "Mental state of consumer",
      "Ego of the marketing executive",
    ],
    ans: "C",
  },
  {
    ques: " The nominal scale used for marketing research, refers to",
    options: [
      "Population characteristics based on age or sex or ownership of a specific consumer durable",
      "Ordering of scale",
      "Both 1 and 2",
      "Neither 1 nor 2",
    ],
    ans: "A",
  },
  {
    ques: " A situation where by market is expanded by developing new products to satisfy new consumer needs is called",
    options: [
      "Market development",
      "Diversification",
      "Both land 2",
      "Neither 1 nor 2",
    ],
    ans: "B",
  },
  {
    ques: " The level of commitment that consumers feel toward a given brand is called",
    options: [
      "Brand equity",
      "Brand name",
      "Brand loyalty",
      "Brand utility",
    ],
    ans: "C",
  },
  {
    ques: " Assigning specific jobs and work places to the selected candidates is known as____________",
    options: [
      "Placement",
      "Recruitment",
      "Transfer ",
      "Promotion",
    ],
    ans: "A",
  },
  {
    ques: " Which among the following is an example of tele-conferencing?",
    options: [
      "Computer conferencing",
      "Audio conferencing",
      "Video conferencing",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " In online marketing, there is________",
    options: [
      "No exchange",
      "Only warranty",
      "Guarantee",
      "Exchange is the core of marketing",
    ],
    ans: "B",
  },
  {
    ques: " EMI is marketing tool if",
    options: [
      "It is very high",
      "It is very low",
      "It is increasing",
      "It is fluctuating",
    ],
    ans: "B",
  },
  {
    ques: " Delivery Channels means:",
    options: [
      "Sales outlets",
      "Purchased goods",
      "Product shelf-life",
      "Courier person",
    ],
    ans: "A",
  },
  {
    ques: " Standard marketing practices include",
    options: [
      "Poaching",
      "Unhealthy competition",
      "Lowering the selling price",
      "Unhealthy discount scheme",
    ],
    ans: "C",
  },
  {
    ques: " Marketing of service is adopted in",
    options: [
      "Grocery stores",
      "Garments trade",
      "Medicine shop",
      "Hotels",
    ],
    ans: "D",
  },
  {
    ques: " Selling is ___________",
    options: [
      "Same as marketing",
      "More than marketing",
      "Offering discounts",
      "A sub-function of marketing",
    ],
    ans: "D",
  },
  {
    ques: " Indirect marketing is the same as",
    options: [
      "Onsite selling",
      "Using a smart-phone",
      "Viral marketing",
      "Advertisements",
    ],
    ans: "D",
  },
  {
    ques: " To close a call’ means",
    options: [
      "To look for a new clients",
      "Telemarketing",
      "To clinch the sale deal",
      "Online marketing",
    ],
    ans: "C",
  },
  {
    ques: " Market size also means",
    options: [
      "Marketing planning",
      "Market pricing",
      "Market distribution",
      "Market space",
    ],
    ans: "C",
  },
  {
    ques: " The sole aim of marketing is to",
    options: [
      "improve the balance sheet figures",
      "increase recruitments",
      "increase profits",
      "increase production",
    ],
    ans: "C",
  },
  {
    ques: " Efficient marketing style requires",
    options: [
      "Proper planning",
      "Good debating skills",
      "Arrogant staff",
      "Knowledge of many languages",
    ],
    ans: "B",
  },
  {
    ques: " Target group means",
    options: [
      "All employers",
      "All sales persons",
      "Intended buyers",
      "All industries",
    ],
    ans: "C",
  },
  {
    ques: " ‘Push’ marketing style requires",
    options: [
      "Collective effort",
      "Good pushing strength",
      "Lengthy talks",
      "Ability to identify the leads",
    ],
    ans: "A",
  },
  {
    ques: " Web marketing involves",
    options: [
      "Selling websites",
      "Door-to-door marketing",
      "E-mail chatting",
      "Web advertisements",
    ],
    ans: "D",
  },
  {
    ques: " The task of marketing involves",
    options: [
      "Opening new branches",
      "Buying a company",
      "Selling a company",
      "Selling products and services of a company",
    ],
    ans: "D",
  },
  {
    ques: " Market space means",
    options: [
      "Place where goods are sold",
      "Trade fairs and meals",
      "Scope available for selling",
      "Road shows",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is a part of marketing?",
    options: [
      "The Process of Creating Pricing",
      "Distribution",
      "Promotion",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Marketing involves ",
    options: [
      "Product",
      "Programme",
      "Problem",
      "All of these",
    ],
    ans: "A",
  },
  {
    ques: " Which is the essential of marketing?",
    options: [
      "Marketing is a human activity",
      "Marketing is a Product",
      "Marketing is a knowledge",
      "All of these",
    ],
    ans: "A",
  },
  {
    ques: " Marketing includes",
    options: [
      "Consumer-oriented Process",
      "Certain market",
      "Socio-economic activity",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Which is/are the functions of marketing?",
    options: [
      "Pricing of goods and services",
      "Promotion",
      "Marketing Research",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " What is the basis of marketing?",
    options: [
      "Exchange",
      "Net Sales and Net Profit",
      "Profit",
      "Packaging",
    ],
    ans: "A",
  },
  {
    ques: " Which is the subject matter of Marketing?",
    options: [
      "Machine, man and money",
      "Product or Service",
      "Capital",
      "Brand",
    ],
    ans: "B",
  },
  {
    ques: " Marketing is:",
    options: [
      "A day to day function",
      "A one-off affair",
      "A one man show",
      "A collective process",
    ],
    ans: "D",
  },
  {
    ques: " Which is the subject Matter of marketing?",
    options: [
      "Man",
      "Goods and Services",
      "Labour",
      "Planning",
    ],
    ans: "B",
  },
  {
    ques: " Rural Marketing is related to-",
    options: [
      "Book",
      "Car",
      "Rural product like-Agricultural tools",
      "Mobile",
    ],
    ans: "C",
  },
  {
    ques: " Lead generation means",
    options: [
      "Tips for selling tactics",
      "Tips for better production",
      "Generating leaders",
      "Likely sources for prospective clients",
    ],
    ans: "D",
  },
  {
    ques: " Marketing Channel means",
    options: [
      "Delivery period",
      "Delivery time",
      "Delivery place",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " E-marketing is same as",
    options: [
      "Virtual Marketing",
      "Digital Marketing",
      "Real time marketing",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Effective Marketing Helps in:",
    options: [
      "Boosting the purchases",
      "Boosting the sales",
      "Diversified Business",
      "Realization of Dreams",
    ],
    ans: "B",
  },
  {
    ques: " A “Buyer’s Market” means:",
    options: [
      "Supply exceeds demand",
      "Buyers are also sellers",
      "Sellers are also buyers",
      "Demand exceeds supply",
    ],
    ans: "A",
  },
  {
    ques: " The sequence of sales process is",
    options: [
      "A call, a lead, presentation, sale",
      "A lead, a call, presentation, sale",
      "Presentation, sale, lead, call",
      "Presentation, lead, sale, call",
    ],
    ans: "B",
  },
  {
    ques: " A presentation means",
    options: [
      "Display of products",
      "A gift",
      "Explaining the utility of products",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " Customer retention means:",
    options: [
      "Retaining the customer at the bank for the full day",
      "Quick disposal",
      "Customer dealing with the same bank for a long time",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " Niche market means:",
    options: [
      "A free market",
      "A social market",
      "Equity market",
      "A specified market for the target group",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is target customer?",
    options: [
      "Structure",
      "Factors",
      "Future customer",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Marketing is a mixture of mixture” this statement is related to:",
    options: [
      "Marketing mix",
      "Product planning",
      "Price determination",
      "Cost",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is the starting point of marketing?",
    options: [
      "Market",
      "Production place",
      "Buyer’s house",
      "All of these",
    ],
    ans: "A",
  },
  {
    ques: " For customer oriented company, customer satisfaction is:",
    options: [
      "Target",
      "Marketing tool",
      "Enterprise of profit",
      "Both land 2",
    ],
    ans: "D",
  },
  {
    ques: " Promotion mix contain(s):",
    options: [
      "Advertisement",
      "Personnel sale",
      "Sales promotion",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Demand consists:",
    options: [
      "Want or need",
      "Willingness to spend",
      "Resource to purchase",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " All the market efforts starts with the discovery of:",
    options: [
      "Product",
      "Needs & wants of consumers",
      "Corruption",
      "All of the Above",
    ],
    ans: "B",
  },
  {
    ques: " What type of relation is there between company and suppliers?",
    options: [
      "Direct",
      "Indirect",
      "Multilevel",
      "Social",
    ],
    ans: "A",
  },
  {
    ques: " What decision is taken under Planning?",
    options: [
      "What to do?",
      "When to do?",
      "How to do?",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Out of the following_____________ is NOT one of the three levels of a product.",
    options: [
      "Core",
      "Augmented",
      "Actual",
      "Fragmented",
    ],
    ans: "D",
  },
  {
    ques: " Out of the following______________ is NOT an objective of pricing.",
    options: [
      "Profit",
      "Stabilising demand and sales of the product",
      "Improvement in product quality",
      "Expansion of business",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following pricing methods is NOT based on competitors pricing?",
    options: [
      "English Auction",
      "Sealed-Bid Auction",
      "Group Pricing",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " Goods that are typically bought by a consumer based on a comparison of suitability, quality, price & style are called___________ goods.",
    options: [
      "Industrial",
      "Specialty",
      "Shopping",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " The Traditional Marketing style involves:",
    options: [
      "Telemarketing",
      "Digital Marketing",
      "Indirect Marketing",
      "Direct Marketing",
    ],
    ans: "D",
  },
  {
    ques: " Modern Method of Marketing include:",
    options: [
      "Publicity on the net",
      "Advertisement on the net",
      "Soliciting business through e-mails",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " A true marketing requires:",
    options: [
      "Command and other mindset",
      "Control Mindset",
      "Passive mindset",
      "Active mindset",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following sentences is TRUE?",
    options: [
      "Marketing is not required in a Buyers’ Market",
      "Marketing is not required in a Sellers’s market",
      "Marketing is not required due to competition",
      "Marketing is not required due to liberalization",
    ],
    ans: "B",
  },
  {
    ques: " Market information means:",
    options: [
      "Knowledge of shops and bazaars",
      "Knowledge of shopping malls",
      "Knowledge of customer profile and product mix",
      "Knowledge of various languages",
    ],
    ans: "C",
  },
  {
    ques: " Market Research is needed for:",
    options: [
      "Checking the market area",
      "Checking the right product to be sold",
      "Making proper marketing decisions",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " For effective marketing the salesmen should have which of these qualities?",
    options: [
      "Creativity",
      "Team spirit",
      "Motivation",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " In market skimming pricing strategy:",
    options: [
      "Initially price is higher and then it is reduced",
      "Initially price is lower and then it is increased",
      "Initial price is high and is maintained high",
      "Initial price is low and is maintained low",
    ],
    ans: "B",
  },
  {
    ques: " Marketing plan helps in:",
    options: [
      "Better lead generation",
      "Better systems",
      "Better results",
      "Improved balance sheet",
    ],
    ans: "C",
  },
  {
    ques: " If Marketing is done effectively which of the following is NOT required?",
    options: [
      "Publicity",
      "Advertisement",
      "Market Research",
      "Market Segmentation",
    ],
    ans: "A",
  },
  {
    ques: " What is Motivation?",
    options: [
      "Better communication skills",
      "Sales Coaching",
      "Market Research",
      "Inspiring the employees to perform better",
    ],
    ans: "D",
  },
  {
    ques: " In a Selling Process in today’s world:",
    options: [
      "Only standard products are sold",
      "The seller should aim at customer satisfaction",
      "The seller need not have product knowledge",
      "No customization required",
    ],
    ans: "B",
  },
  {
    ques: " Find the TRUE statement:",
    options: [
      "Marketing involves team work",
      "Marketing involves additional work",
      "Marketing is not required in India due to its vast population",
      "Marketing is a waste of the employees’ time",
    ],
    ans: "A",
  },
  {
    ques: " A Target market is:",
    options: [
      "Entire country",
      "Entire city",
      "That which consists of customers who need the identified product",
      "Entire globe",
    ],
    ans: "C",
  },
  {
    ques: " Sales forecasting involves:",
    options: [
      "Sales Planning",
      "Sales Pricing",
      "Distribution Channels",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " The following offering is NOT a service:",
    options: [
      "Fixed deposit receipt",
      "Postage stamp",
      "Gift coupon of a chain store",
      "All of these",
    ],
    ans: "C",
  },
  {
    ques: " The act of obtaining a desired object from someone by offering something in return is called as a(n):",
    options: [
      "Transaction",
      "Exchange",
      "Relationship",
      "Value",
    ],
    ans: "B",
  },
  {
    ques: " Economists use the term_____________ to refer to a collection of buyers and sellers who transact in a particular product class.",
    options: [
      "Customer",
      "Market",
      "Experience",
      "All of these",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following pricing strategies is NOT ideal for new products?",
    options: [
      "Market-Skimming",
      "Discriminatory Pricing",
      "Promotional Pricing",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " A_________ discount is offered by the seller to intermediary who performs functions like selling, storing and record Keeping.",
    options: [
      "Quantity",
      "Trade",
      "Cash",
      "Seasonal",
    ],
    ans: "B",
  },
  {
    ques: " With___________ pricing, the products are priced below list price (or even below cost) for a temporary period to create buying urgency.",
    options: [
      "Reference",
      "By-product",
      "Promotional",
      "Market penetration",
    ],
    ans: "C",
  },
  {
    ques: " Difference between Direct and Indirect Marketing is:",
    options: [
      "Direct marketing is to employees of the concerned company, while Indirect is to outsiders",
      "Direct marketing is to employees of the concerned company, while Indirect is to outsiders",
      "Direct Marketing is to Bank’s owner, Insiders is to outsiders",
      "Direct Marketing is to other Bank’s employees, Indirect outsiders",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is the most logical budget setting method?",
    options: [
      "Affordable method",
      "Percentage-of-Sales method",
      "Competitive-parity method",
      "Objective-and-task method",
    ],
    ans: "D",
  },
  {
    ques: " One common misuse of marketing research findings in contemporary business is the tendency for marketing research to:",
    options: [
      "Become a vehicle for pitching the sponsor’s products",
      "Become a vehicle for discriminating in the marketplace",
      "Become a means for raising prices",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: "  A ‘Buyers’ Market’ means:",
    options: [
      "Buyers are also sellers",
      "Sellers are also buyers",
      "There are no sellers",
      "Supply exceeds demand",
    ],
    ans: "D",
  },
  {
    ques: " Market share analysis helps to track:",
    options: [
      "Share price movements",
      "Percentage share of business",
      "Pricing strategy for share values",
      "All of these",
    ],
    ans: "B",
  },
  {
    ques: " Marketing in Banks is required for:",
    options: [
      "Getting new customers",
      "Retaining existing customers",
      "Lending",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " The advantages of audience selectivity, no ad competition and personalization apply to which type of media?",
    options: [
      "Newspapers",
      "Television",
      "Direct Mail",
      "Radio",
    ],
    ans: "C",
  },
  {
    ques: " Costs that do not vary with production or sales levels are called:",
    options: [
      "Fixed costs",
      "Variable costs",
      "Standard costs",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " If a company were to make a product such as a suit of clothes and sold that product to a retailer, the company would have sold to the _______ market.",
    options: [
      "Reseller",
      "Business",
      "Government",
      "Service",
    ],
    ans: "A",
  },
  {
    ques: " The shrinking of distances due to technological advance such as computer and fax connections by telephone, are one characteristic of what new challenge to marketing?",
    options: [
      "Rapid globalization",
      "The changing world economy",
      "The call for more socially responsible marketing",
      "The micro-chip revolution",
    ],
    ans: "A",
  },
  {
    ques: " The goal of the marketing logistics system should be to provide",
    options: [
      "A targeted level of promotional support",
      "A targeted level of customer service at the least cost",
      "A targeted level of transportation expense ratio",
      "A targeted level of field support",
    ],
    ans: "B",
  },
  {
    ques: " The practice of going after a large share of a smaller market or subsets of a few markets is called ____________.",
    options: [
      "Undifferentiated marketing",
      "Differentiated marketing",
      "Concentrated marketing",
      "Turbo marketing",
    ],
    ans: "C",
  },
  {
    ques: " What are the 4 P’s in Marketing?",
    options: [
      "Purpose, place, product and promotion",
      "Person, place, product and promotion",
      "Price, place, product and promotion",
      "Person, price, product and promotion",
    ],
    ans: "D",
  },
  {
    ques: " Relationship selling means:",
    options: [
      "Cross selling",
      "Selling to relatives",
      "Telemarketing",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " Marketing is successful when:",
    options: [
      "Demand exceeds supply",
      "Salesman are effectively trained",
      "Supply exceeds demand",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " A prospect means:",
    options: [
      "A person who is working in a bank/ financial institution",
      "Details of a company",
      "A likely buyer",
      "Head of an financial institution",
    ],
    ans: "C",
  },
  {
    ques: " NRI is an easy target for effective marketing because ______",
    options: [
      "He likes Indian goods",
      "He is easily approachable",
      "It is cheaper to contact NRISs",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Study of marketing environment means",
    options: [
      "Study of company’s markets, customers and competitors",
      "Study of economic environment",
      "Study of current trends",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " A Market plan includes:",
    options: [
      "Market objectives",
      "Market research",
      "Pricing strategy",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Customization is useful for:",
    options: [
      "Motivating the sales force",
      "To add variety in marketing",
      "Value added service",
      "Pleasing target customers",
    ],
    ans: "B",
  },
  {
    ques: " Telemarketing campaigns are resorted to:",
    options: [
      "Avoid cold calls",
      "Avoid buyer resistance",
      "Avoid seller inhibitions",
      "Reaching a wider audience",
    ],
    ans: "D",
  },
  {
    ques: " The Traditional Marketing style involves",
    options: [
      "Telemarketing",
      "Direct Marketing",
      "Indirect Marketing",
      "Digital Marketing",
    ],
    ans: "C",
  },
  {
    ques: " Which is the technique used for effective marketing planning?",
    options: [
      "Psychological tools for marketing",
      "Goal oriented",
      "Marketing research",
      "All the above",
    ],
    ans: "D",
  },
  {
    ques: " The sales process begins with",
    options: [
      "Customer identification",
      "Sales presentation",
      "Lead generation",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Service marketing is the same as__________",
    options: [
      "Transaction marketing",
      "Relationship marketing",
      "Internal marketing",
      "Outdoor marketing",
    ],
    ans: "B",
  },
  {
    ques: " Market share means_________",
    options: [
      "Share market",
      "Scope for marketing",
      "IPOs",
      "Share of business among peers",
    ],
    ans: "D",
  },
  {
    ques: " Marketing helps in_______",
    options: [
      "Boosting production",
      "Getting new clients",
      "Interacting with strangers",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Promotion in Marketing means:",
    options: [
      "Passing an examination",
      "Selling the product in specific areas",
      "Selling the products through various means",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " In ‘Double-win’ strategy:",
    options: [
      "Customer gets an additional benefit",
      "Customer gets price debate",
      "Both customs and the sales person come out with sense of satisfaction",
      "Both (1) and (2)",
    ],
    ans: "B",
  },
  {
    ques: " Which among the following is NOT an example of Direct marketing?",
    options: [
      "Tele-marketing",
      "Sales on internet",
      "Mail order sales",
      "Retail stores",
    ],
    ans: "D",
  },
  ]

  export default email_markrting