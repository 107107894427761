const digital_marketing = [
  {
    quizname: "Digital Marketing",
    imgLink :"https://alphanumericideas.com/images/careers/digital-marketing.png",
    ques: " What does PPC stand for?",
    options: [
      "Pay Per Click",
      "Pay Per Cost",
      "Pay Per Conversion",
      "Pay Per Campaign",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is NOT a type of PPC advertising?",
    options: [
      "Google Ads",
      "Bing Ads",
      "Instagram Ads",
      "Print Ads",
    ],
    ans: "D",
  },
  {
    ques: " What is the main goal of a PPC campaign?",
    options: [
      "Increase brand awareness",
      "Increase website traffic",
      "Increase conversions",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is a landing page in the context of a PPC campaign?",
    options: [
      "The page where a user is directed after clicking an ad",
      "The page where an ad is displayed",
      "The page where the user's payment is processed",
      "The page where the user's personal information is collected",
    ],
    ans: "A",
  },
  {
    ques: " What is a Quality Score in the context of a Google Ads campaign?",
    options: [
      "A rating given to an ad for its relevance and usefulness",
      "A rating given to a website for its usability and design",
      "A rating given to a company for its customer service",
      "A rating given to a keyword for its search volume",
    ],
    ans: "A",
  },
  {
    ques: " What is a negative keyword in the context of a PPC campaign?",
    options: [
      "A keyword that is excluded from targeting",
      "A keyword that is targeted specifically",
      "A keyword that is used in the ad copy",
      "A keyword that is used in the website's meta tags",
    ],
    ans: "A",
  },
  {
    ques: " What is a conversion in the context of a PPC campaign?",
    options: [
      "A click on an ad",
      "A visit to a website",
      "A desired action taken by a user on a website",
      "A sale made through an ad",
    ],
    ans: "C",
  },
  {
    ques: " What is a CPA in the context of a PPC campaign?",
    options: [
      "Cost per acquisition",
      "Cost per action",
      "Cost per advertisement",
      "Cost per click",
    ],
    ans: "B",
  },
  {
    ques: " What is a CPM in the context of a PPC campaign?",
    options: [
      "Cost per acquisition",
      "Cost per action",
      "Cost per thousand impressions",
      "Cost per click",
    ],
    ans: "C",
  },
  {
    ques: " What is a CPC in the context of a PPC campaign?",
    options: [
      "Cost per acquisition",
      "Cost per action",
      "Cost per thousand impressions",
      "Cost per click",
    ],
    ans: "D",
  },
  {
    ques: " What is an ad group in the context of a PPC campaign?",
    options: [
      "A group of ads that target a specific audience",
      "A group of keywords that trigger an ad",
      "A group of landing pages that an ad directs to",
      "A group of conversion tracking codes",
    ],
    ans: "B",
  },
  {
    ques: " What is a remarketing campaign in the context of a PPC campaign?",
    options: [
      "A campaign that targets users who have previously visited a website",
      "A campaign that targets users who have previously clicked an ad",
      "A campaign that targets users who have previously made a purchase",
      "A campaign that targets users who have previously subscribed to a newsletter",
    ],
    ans: "A",
  },
  {
    ques: " What is the main goal of PPC advertising?",
    options: [
      "to increase brand awareness",
      "to generate leads",
      "to drive website traffic",
      "to increase sales",
    ],
    ans: "C",
  },
  {
    ques: " Which platform is considered to be the largest PPC advertising platform?",
    options: [
      "Bing Ads",
      "Google Ads",
      "Facebook Ads",
      "Instagram Ads",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between PPC and SEO?",
    options: [
      "PPC is paid and SEO is free",
      "PPC is short-term and SEO is long-term",
      "PPC is for branding and SEO is for conversion",
      "PPC is for display ads and SEO is for search engine results",
    ],
    ans: "A",
  },
  {
    ques: " What are the three main types of PPC advertising?",
    options: [
      "search ads, display ads, and video ads",
      "search ads, social media ads, and email ads",
      "search ads, display ads, and shopping ads",
      "search ads, video ads, and shopping ads",
    ],
    ans: "C",
  },
  {
    ques: " What is the purpose of A/B testing in PPC advertising?",
    options: [
      "to determine the most effective ad copy",
      "to determine the best targeting options",
      "to determine the optimal budget",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between CPC and CPM in PPC advertising?",
    options: [
      "CPC is cost per click and CPM is cost per impression",
      "CPC is cost per conversion and CPM is cost per mile",
      "CPC is cost per campaign and CPM is cost per month",
      "CPC is cost per customer and CPM is cost per acquisition",
    ],
    ans: "A",
  },
  {
    ques: " What is the role of keywords in PPC advertising?",
    options: [
      "to determine the target audience",
      "to determine the ad placement",
      "to match ads to relevant searches",
      "to determine the budget",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between a broad match and a phrase match in PPC advertising?",
    options: [
      "a broad match will trigger your ad for any search containing your keywords, while a  phrase match will trigger your ad only for searches that match the exact phrase",
      "a broad match will trigger your ad for any search containing your keywords, while a phrase match will trigger your ad for any search containing any of the keywords",
      "a broad match will trigger your ad for any search containing your keywords, while a phrase match will trigger your ad for any search containing your keywords in any order",
      "a broad match will trigger your ad for any search containing your keywords, while a phrase match will trigger your ad for any search containing your keywords in reverse order",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is the correct depiction of Digital Marketing?",
    options: [
      "E-mail Marketing",
      "Social Media Marketing",
      "Web Marketing",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " _________ doesn't fall under the category of digital marketing.",
    options: [
      "TV",
      "Billboard",
      "Radio",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is incorrect about digital marketing?",
    options: [
      "Digital marketing can only be done offline",
      "Digital marketing cannot be done offline.",
      "Digital marketing requires electronic devices for promoting goods and services.",
      "In general, digital marketing can be understood as online marketing, web marketing, and e-mail marketing.",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following factors are responsible for leaving an impact on the Google PageRank?",
    options: [
      "The text depicting inbound links to a page of a website.",
      "A total number of inbound links to a website's page.",
      "The subject matter of the site providing the inbound link to a page of a website.",
      "The number of outbound links on the page that contains the inbound link to a page of a website.",
    ],
    ans: "B",
  },
  {
    ques: " What is meant by Impression Share?",
    options: [
      "The percentage of times your ad was displayed divided by all possible impressions for that search term.",
      "The percentage of times your ad was displayed when it was eligible to be displayed.",
      "The percentage of times your ad was displayed higher than your primary competitor.",
      "The percentage of impressions you lost due to a low ad rank",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following formulas is used by Pay-per-click?",
    options: [
      "Ads clicked (#) / Advertising cost ($)",
      "Advertising cost ($) + Ads clicked (#)",
      "Advertising cost ($) / Ads clicked (#)",
      "Ads clicked (#) * Advertising cost ($)",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following factors leads to a Successful PPC Advertisement?",
    options: [
      "Selecting Relevant Keywords",
      "Improved Landing Page Quality",
      "Quality Score",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " The correct formula for determining an actual CPC as when an advertisement is clicked can be mathematically derived as _________",
    options: [
      "Position * QS",
      "Impressions * Clicks",
      "Clicks / Impressions",
      "QS * Max CPC (bid)",
    ],
    ans: "D",
  },
  {
    ques: " The affinity audience can be defined as _______",
    options: [
      "A user similar to that of your remarketing list",
      "A marketing user who is willing to make a purchase",
      "A user showing a continuous interest in the matter",
      "A user similar to that of your customer list",
    ],
    ans: "C",
  },
  {
    ques: " What is the correct abbreviation of SERP?",
    options: [
      "Search Engine Result Page",
      "System Engine Random Page",
      "Search Estimate Result Page",
      "System Estimate Random Page",
    ],
    ans: "A",
  },
  {
    ques: " The main advantage of online advertisement is ______",
    options: [
      "Low-cost promotional strategy",
      "Online advertising is promotional as well as informational",
      "Trackable",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Where do we use keywords?",
    options: [
      "Tags",
      "Title",
      "Description",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " The correct abbreviation of ROI is _______",
    options: [
      "Risk on investment",
      "Return on income",
      "Risk on income",
      "Return on investment",
    ],
    ans: "D",
  },
  {
    ques: " ___ are used in digital marketing.",
    options: [
      "Electronic devices",
      "Internet",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " ____  is/are the main component(s) of digital marketing",
    options: [
      "SEO",
      "SMO",
      "SEM",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the full form of SEO?",
    options: [
      "Search Engine Optimal",
      "Social Engine Optimization",
      "Search Engine Optimization",
      "Social Engine Optimal",
    ],
    ans: "C",
  },
  {
    ques: " What is the full form of SEM?",
    options: [
      "Social Engine Marketing",
      "Search Engine Marketing",
      "Search Engine Management",
      "Social Engine Management",
    ],
    ans: "B",
  },
  {
    ques: " What is the full form of SMO?",
    options: [
      "Social Media Optimal",
      "Search Media Optimal",
      "Search Media Optimization",
      "Social Media Optimization",
    ],
    ans: "D",
  },
  {
    ques: " In recent years, digital marketing has also become more reliant on ____",
    options: [
      "Email",
      "Affiliate",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Using ____, you improve your site's structure and content and conduct promotional activities to enhance your ranking on search engines",
    options: [
      "Search Engine Optimization",
      "Social Media Marketing",
      "Social Media Optimization",
      "Search Engine Marketing",
    ],
    ans: "A",
  },
  {
    ques: " ____ is/are part(s) of SEO.",
    options: [
      "Off-Page",
      "On-Page",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " In On-Page SEO, website owners use various methods and measures within their own websites to improve their website's ____ on search engines.",
    options: [
      "Pages",
      "Ranking",
      "Portal",
      "Data",
    ],
    ans: "B",
  },
  {
    ques: " An on-page search engine optimization strategy involves dealing with elements of SEO within a website, such as ____, etc.",
    options: [
      "Meta Tags",
      "Technical Tags",
      "Content Quality",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " ____ contain meta tags that contain information about their content.",
    options: [
      "Pages",
      "Sites",
      "Portals",
      "Browsers",
    ],
    ans: "A",
  },
  {
    ques: " As Meta Tags help search engines ____ the page, they are extremely important in terms of SEO",
    options: [
      "Understand",
      "Index",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " ____ and Google+ are all social media sites that are utilized in Social Media Optimization to increase traffic and, consequently, ranking for websites.",
    options: [
      "Facebook",
      "Twitter",
      "LinkedIn",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " The ____ Marketing strategy differs from SEO and SMO in that you have to pay search engines like Google to promote your products and services on SERPs.",
    options: [
      "Email",
      "Search Engine",
      "Affiliate",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " A search engine marketing strategy uses paid advertisements such as ____  ads that appear above organic results in search engine results pages",
    options: [
      "Page per click",
      "Point per click",
      "Pay per click",
      "None",
    ],
    ans: "C",
  },
  {
    ques: " Unlike paid marketing where you pay for clicks and traffic, ____ gives you a free slot in the search results based on the relevance and quality of your content",
    options: [
      "SEO",
      "SMM",
      "SEM",
      "SMO",
    ],
    ans: "A",
  },
  {
    ques: " SEM consists of -",
    options: [
      "Display ads",
      "Search ads",
      "Shopping ads",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Google ads are also known as -",
    options: [
      "PPC advertising",
      "Pay-per-click advertising",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Google ads provide PPC advertising, which includes -",
    options: [
      "Banners",
      "Texts",
      "Rich Media Ads",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is/are a/the network in Google ads?",
    options: [
      "Google search",
      "Display",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Advertisements appear on ____ results pages in Google's search network",
    options: [
      "Websites",
      "Browsers",
      "Webpages",
      "Search Engine",
    ],
    ans: "D",
  },
  {
    ques: " The search engine results pages display your ad above organic results when users search for related ____",
    options: [
      "Tags",
      "Meta tags",
      "Keywords",
      "Pages",
    ],
    ans: "Keywords",
  },
  {
    ques: " What should be remembered before creating a PPC campaign?",
    options: [
      "Determine your goals",
      "Set a budget",
      "Make a keyword list",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " When a customer clicks on your PPC ad, they arrive on your ____ page.",
    options: [
      "Home",
      "About",
      "Landing",
      "Contact",
    ],
    ans: "C",
  },
  {
    ques: " Ads are displayed on websites that have given space to Google for advertising in the ____ network.",
    options: [
      "Search Engine",
      "Display",
      "Both A and B",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is NOT a Social Media Platform?",
    options: [
      "Facebook",
      "Twitter",
      "Instagram",
      "Google",
    ],
    ans: "D",
  },
  {
    ques: " SMM helps improve -",
    options: [
      "Brand Awareness",
      "Product Visibility",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not a type of digital marketing?",
    options: [
      "Search Engine Optimization",
      "Television Advertising",
      "Email Marketing",
      "Influencer Marketing",
    ],
    ans: "B",
  },
  {
    ques: " What is the main goal of Search Engine Optimization (SEO)?",
    options: [
      "To increase brand awareness",
      "To increase website traffic",
      "To increase sales",
      "To increase social media followers",
    ],
    ans: "B",
  },
  {
    ques: " In the context of digital marketing, what is a landing page?",
    options: [
      "A website's homepage",
      "A page on a website where a user is directed after clicking on an advertisement",
      "A page on a website that lists all of the website's products or services",
      "A page on a website that is only accessible to registered users",
    ],
    ans: "B",
  },
  {
    ques: " What is the purpose of A/B testing in digital marketing?",
    options: [
      "To determine which version of a website or ad performs better",
      "To determine which social media platform is most effective for a particular campaign",
      "To determine the best time to send an email marketing campaign",
      "To determine the best keywords to use in a search engine optimization campaign",
    ],
    ans: "A",
  },
  {
    ques: ' In the context of digital marketing, what is a "conversion"?',
    options: [
      "A sale or completed lead form",
      "A click on an advertisement",
      "A website visitor",
      "A social media follower",
    ],
    ans: "A",
  },
  {
    ques: " What is the main goal of a pay-per-click (PPC) advertising campaign?",
    options: [
      "To increase brand awareness",
      "To drive website traffic",
      "To increase sales",
      "To generate leads",
    ],
    ans: "B",
  },
  {
    ques: " What is the main difference between search engine optimization (SEO) and pay-per-click (PPC) advertising?",
    options: [
      "SEO is free while PPC is not",
      "SEO is focused on organic search results while PPC is focused on paid search results",
      "SEO is focused on social media while PPC is focused on search engines",
      "SEO is focused on website design while PPC is focused on ad copy",
    ],
    ans: "B",
  },
  {
    ques: " What is the most popular social media platform for B2C companies?",
    options: [
      "LinkedIn",
      "Twitter",
      "Facebook",
      "Instagram",
    ],
    ans: "C",
  },
  {
    ques: " What is the most popular social media platform for B2B companies?",
    options: [
      "LinkedIn",
      "Twitter",
      "Facebook",
      "Instagram",
    ],
    ans: "A",
  },
  {
    ques: " What is the most popular social media platform for visual content?",
    options: [
      "LinkedIn",
      "Twitter",
      "Facebook",
      "Instagram",
    ],
    ans: "D",
  },
  {
    ques: " What is the most popular social media platform for short-form content?",
    options: [
      "LinkedIn",
      "Twitter",
      "Facebook",
      "Instagram",
    ],
    ans: "B",
  },
  {
    ques: " What is the most popular social media platform for video content?",
    options: [
      "LinkedIn",
      "Twitter",
      "Facebook",
      "YouTube",
    ],
    ans: "D",
  },
  {
    ques: " What is the primary goal of a content marketing strategy?",
    options: [
      "To increase website traffic",
      "To increase brand awareness",
      "To generate leads",
      "To increase sales",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is not considered a digital marketing channel?",
    options: [
      "Social media",
      "Television advertising",
      "Email marketing",
      "Referral marketing",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a soft lead and a hard lead in digital marketing?",
    options: [
      "A soft lead is someone who has shown interest in a product or service, while a hard lead is someone who has made a purchase",
      "A soft lead is someone who has made a purchase, while a hard lead is someone who has shown interest in a product or service.",
      "A soft lead is a potential customer, while a hard lead is a confirmed customer",
      "A soft lead is someone who has filled out a form, while a hard lead is someone who has made a phone call.",
    ],
    ans: "B",
  },
  {
    ques: " What is the primary goal of email marketing?",
    options: [
      "To increase website traffic",
      "To increase brand awareness",
      "To generate leads",
      "To increase sales",
    ],
    ans: "D",
  },
  {
    ques: " What is remarketing in digital marketing?",
    options: [
      "A strategy for targeting customers who have shown interest in a product or service",
      "A strategy for targeting customers who have made a purchase",
      "A strategy for targeting customers who have left items in their online shopping cart",
      "A strategy for targeting customers who have unsubscribed from an email list",
    ],
    ans: "A",
  },
  {
    ques: " What is the primary goal of a social media marketing strategy?",
    options: [
      "To increase website traffic",
      "To increase brand awareness",
      "To generate leads",
      "To increase sales",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is not a type of paid search advertising?",
    options: [
      "PPC",
      "CPM",
      "CPA",
      "CPC",
    ],
    ans: "B",
  },
  {
    ques: " What is the primary purpose of a landing page?",
    options: [
      "To provide information about a company",
      "To generate leads or sales",
      "To provide customer support",
      "To share company news",
    ],
    ans: "B",
  },
  {
    ques: " In terms of SEO, what is the purpose of backlinks?",
    options: [
      "To increase website traffic",
      "To improve website design",
      "To boost a website's search engine ranking",
      "To increase website security",
    ],
    ans: "C",
  },
  {
    ques: " What is the primary benefit of using social media for marketing?",
    options: [
      "It allows companies to reach a large audience at a low cost",
      "It allows companies to gather valuable customer data",
      "It allows companies to build strong relationships with customers",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is remarketing?",
    options: [
      "A type of search engine advertising",
      "A way to target customers who have previously shown interest in a product or service",
      "A way to target customers based on their browsing history",
      "Both B and C",
    ],
    ans: "D",
  },
  {
    ques: " What is the primary benefit of using video marketing?",
    options: [
      "It allows companies to tell a story and convey emotion",
      "It allows companies to reach a large audience at a low cost",
      "It allows companies to gather valuable customer data",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the primary benefit of using podcasts for marketing?",
    options: [
      "It allows companies to reach a large audience at a low cost",
      "It allows companies to build strong relationships with customers",
      "It allows companies to establish themselves as thought leaders in their industry",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the primary benefit of using customer reviews for marketing?",
    options: [
      "It allows companies to reach a large audience at a low cost",
      "It allows companies to build trust and credibility with potential customers",
      "It allows companies to gather valuable customer feedback",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the primary benefit of using Instagram for marketing?",
    options: [
      "It allows companies to reach a large, engaged audience",
      "It allows companies to gather valuable customer data",
      "It allows companies to improve the customer experience",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the primary goal of retargeting?",
    options: [
      "To increase brand awareness",
      "To drive sales",
      "To target customers who have previously shown interest in a product or service",
      "All of the above",
    ],
    ans: "C",
  },
  {
    ques: " What is the most common platform for PPC advertising?",
    options: [
      "Bing Ads",
      "Facebook Ads",
      "Google Ads (Correct Answer)",
      "Twitter Ads",
    ],
    ans: "C",
  },
  {
    ques: " What are keywords in PPC advertising?",
    options: [
      "The words or phrases that users enter into a search engine when looking for information.",
      "The group of ads that share the same theme or topic",
      "The text of an ad",
      "The number of times a user clicks on an ad",
    ],
    ans: "A",
  },
  {
    ques: " What is the landing page in PPC advertising?",
    options: [
      "The page that a user is taken to after clicking on an ad",
      "The number of times an ad is shown to users",
      "The measure of the relevance and quality of an ad",
      "The percentage of users who click on an ad and then complete a desired action",
    ],
    ans: "A",
  },
  {
    ques: " What is the Quality score in PPC advertising?",
    options: [
      "A measure of the relevance and quality of an ad, as determined by the advertising platform.",
      "The number of clicks an ad receives divided by the number of impressions it receives",
      "The amount an advertiser pays each time a user clicks on their ad",
      "The group of ads that share the same theme or topic",
    ],
    ans: "A",
  },
  {
    ques: " What is the formula for calculating click-through rate (CTR)?",
    options: [
      "Number of clicks an ad receives / Number of impressions it receives",
      "Number of impressions an ad receives / Number of clicks it receives",
      "Number of conversions an ad receives / Number of clicks it receives",
      "Number of clicks an ad receives / Number of conversions it receives",
    ],
    ans: "A",
  },
  {
    ques: " What is the main benefit of having a high quality score in PPC advertising?",
    options: [
      "Lower costs",
      "Better ad placement",
      "More impressions",
      "More clicks",
    ],
    ans: "A",
  },
  {
    ques: " What is the primary platform for PPC advertising on Google?",
    options: [
      "Google Search",
      "Google Display Network",
      "Google Shopping",
      "Google Maps",
    ],
    ans: "A",
  },
  {
    ques: " What is the function of ad groups within a Google Ads campaign?",
    options: [
      "They group similar keywords together",
      "They group similar ads together",
      "They group similar audiences together",
      "They group similar placements together",
    ],
    ans: "B",
  },
  {
    ques: " What is a Quality Score in Google Ads?",
    options: [
      "A measure of the relevance and quality of an ad, as determined by Google Ads",
      "A measure of the budget allocated to an ad",
      "A measure of the number of clicks an ad receives",
      "A measure of the number of conversions an ad receives",
    ],
    ans: "A",
  },
  {
    ques: " What is the purpose of ad extensions in Google Ads?",
    options: [
      "To provide additional information about a product or service",
      "To increase the number of ad impressions",
      "To increase the click-through rate (CTR)",
      "To decrease the cost-per-click (CPC)",
    ],
    ans: "A",
  },
  {
    ques: " What is a negative keyword in Google Ads?",
    options: [
      "A keyword that you do not want your ad to show up for",
      "A keyword that you want your ad to show up for",
      "A keyword that increases your ad's Quality Score",
      "A keyword that decreases your ad's Quality Score",
    ],
    ans: "A",
  },
  {
    ques: " What is a Smart Campaign in Google Ads?",
    options: [
      "A campaign that utilizes machine learning to optimize ad delivery",
      "A campaign that is focused on remarketing",
      "A campaign that is focused on mobile advertising",
      "A campaign that is focused on shopping ads",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a broad match and a phrase match keyword in Google Ads?",
    options: [
      "Broad match will show your ad for any search containing your keywords, while phrase match will only show your ad for searches containing your exact keywords",
      "Phrase match will show your ad for any search containing your keywords, while broad match will only show your ad for searches containing your exact keywords",
      "Broad match and phrase match are the same in Google Ads",
      "Broad match is for shopping ads, while phrase match is for search ads",
    ],
    ans: "A",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  ]

  export default digital_marketing