const content_writing = [
    {
      quizname: "Content Writing",
      imgLink :"https://alphanumericideas.com/images/careers/content-writing.png",
      ques: " What is the primary goal of content writing?",
      options: [
        "To inform",
        "To entertain",
        "To persuade",
        "To educate",
      ],
      ans: "C",
    },
    {
      ques: " What is the most important aspect of SEO writing?",
      options: [
        "Using keywords effectively",
        "Writing in a conversational tone",
        "Creating visually appealing content",
        "Using humor in the content",
      ],
      ans: "A",
    },
    {
      ques: " What is the best way to optimize a blog post for search engines?",
      options: [
        "Using multiple H1 tags",
        "Writing long-form content",
        "Including internal and external links",
        "Using a lot of images",
      ],
      ans: "C",
    },
    {
      ques: " How long should a blog post be to be considered effective?",
      options: [
        "500 words or less",
        "1000 words or less",
        "1500 words or less",
        "2000 words or more",
      ],
      ans: "D",
    },
    {
      ques: " What is the primary benefit of creating a content strategy?",
      options: [
        "It helps to increase traffic to a website",
        "It helps to improve the overall design of a website",
        "It helps to keep the content consistent",
        "It helps to create a more engaging user experience",
      ],
      ans: "C",
    },
    {
      ques: " What is the purpose of a headline in content writing?",
      options: [
        "To summarize the content",
        "To grab the reader's attention",
        "To provide background information",
        "To provide a call to action",
      ],
      ans: "B",
    },
    {
      ques: " Which of the following is an example of a good headline?",
      options: [
        "10 Tips for Writing Effective Blog Posts",
        "The Importance of Writing Quality Content",
        "How to Write Content That Will Get You More Traffic",
        "Why Content Writing is Important for Your Business",
      ],
      ans: "A",
    },
    {
      ques: " What is the best way to research keywords for a blog post?",
      options: [
        "Using Google's Keyword Planner",
        "Asking friends and family what they would search for",
        "Using a thesaurus",
        "Guessing",
      ],
      ans: "A",
    },
    {
      ques: " What is the best way to organize the content of a blog post?",
      options: [
        "In a random order",
        "Chronologically",
        "In a logical order using headings and subheadings",
        "Alphabetically",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to create a call to action in a blog post?",
      options: [
        "Asking the reader to share the post on social media",
        "Including a link to a landing page",
        "Asking the reader to leave a comment",
        "All of the above",
      ],
      ans: "D",
    },
    {
      ques: " What is the primary goal of a landing page?",
      options: [
        "To provide information about a product or service",
        "To increase the number of email subscribers",
        "To generate leads",
        "To increase sales",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to write a meta description?",
      options: [
        "Including the primary keyword",
        "Keeping it under 160 characters",
        "Writing it in the form of a question",
        "Both 1 and 2",
      ],
      ans: "D",
    },
    {
      ques: " What is the primary benefit of using header tags?",
      options: [
        "They help to improve the overall design of a website",
        "They make the content easier to read",
        "They help search engines understand the structure of a webpage",
        "They help to increase the click-through rate",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to write a title tag?",
      options: [
        "Including the primary keyword",
        "Keeping it under 60 characters",
        "Writing it in the form of a question",
        "Both 1 and 2",
      ],
      ans: "D",
    },
    {
      ques: " What is the best way to format a blog post?",
      options: [
        "Including a lot of images and videos",
        "Using a lot of headings and subheadings",
        "Using short paragraphs",
        "All of the above",
      ],
      ans: "D",
    },
    {
      ques: " What is the best way to include keywords in a blog post?",
      options: [
        "Stuffing as many keywords as possible in the content",
        "Using the primary keyword in the headline, meta description, and throughout the content",
        "Only using the primary keyword in the headline",
        "Only using the primary keyword in the meta description",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to create a unique voice in your content?",
      options: [
        "Using a lot of industry jargon",
        "Using a conversational tone",
        "Copying and pasting content from other sources",
        "Using a formal tone",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to create engaging content?",
      options: [
        "Writing in a dry, boring style",
        "Using humor and anecdotes",
        "Using a lot of statistics and data",
        "Writing in a formal, academic style",
      ],
      ans: "B",
    },
    {
      ques: " How often should a company blog?",
      options: [
        "Once a week",
        "Twice a week",
        "Once a month",
        "As often as possible",
      ],
      ans: "A",
    },
    {
      ques: " What is the primary benefit of guest blogging?",
      options: [
        "It helps to increase brand awareness",
        "It helps to generate leads",
        "It helps to improve search engine rankings",
        "It helps to build relationships with other bloggers and industry experts",
      ],
      ans: "D",
    },
    {
      ques: " What is the best way to use social media to promote a blog post?",
      options: [
        "Only sharing the post once",
        "Using a lot of hashtags",
        "Sharing the post multiple times and using a call to action",
        "Only sharing the post to a select group of people",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use internal links in a blog post?",
      options: [
        "Including a lot of links to other pages on the website",
        "Only linking to pages that are relevant to the content",
        "Only linking to the homepage",
        "Not including any internal links",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use external links in a blog post?",
      options: [
        "Including a lot of links to other websites",
        "Only linking to reputable sources",
        "Not including any external links",
        "Only linking to competitors' websites",
      ],
      ans: "B",
    },
    {
      ques: " What is the primary benefit of using infographics in content?",
      options: [
        "They make the content more visually appealing",
        "They help to increase social shares",
        "They make the content easier to understand",
        "They help to improve search engine rankings",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of using infographics in content?",
      options: [
        "They make the content more visually appealing",
        "They help to increase social shares",
        "They make the content easier to understand",
        "They help to improve search engine rankings",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a case study?",
      options: [
        "To provide information about a product or service",
        "To demonstrate the effectiveness of a product or service",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to write a white paper?",
      options: [
        "Writing it in a casual, conversational tone",
        "Including a lot of images and videos",
        "Writing it in a formal, academic tone",
        "Not including any data or statistics",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating an email newsletter?",
      options: [
        "It helps to increase brand awareness",
        "It helps to generate leads",
        "It helps to improve customer relationships",
        "It helps to increase sales",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use video in content?",
      options: [
        "Including a lot of text in the video",
        "Not including a transcript of the video",
        "Creating informative, engaging videos",
        "Creating videos that are only a few seconds long",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating a podcast?",
      options: [
        "It helps to increase brand awareness",
        "It helps to generate leads",
        "It helps to improve customer relationships",
        "It helps to increase sales",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use webinars in content marketing?",
      options: [
        "Not including a recording of the webinar",
        "Not including any interactive elements",
        "Creating informative, engaging webinars",
        "Creating webinars that are only a few minutes long",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a press release?",
      options: [
        "To provide information about a product or service",
        "To generate media coverage",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use social proof in content?",
      options: [
        "Not including any customer testimonials or reviews",
        "Using a lot of statistics and data",
        "Including customer testimonials or reviews",
        "Only including positive customer testimonials or reviews",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use storytelling in content?",
      options: [
        "Not including any storytelling elements",
        "Using a lot of statistics and data",
        "Including storytelling elements",
        "Only including happy endings in the stories",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a product review?",
      options: [
        "To provide information about a product or service",
        "To demonstrate the effectiveness of a product or service",
        "To generate leads",
        "To increase sales",
      ],
      ans: "A",
    },
    {
      ques: " What is the best way to use user-generated content in content marketing?",
      options: [
        "Not including any user-generated content",
        "Using a lot of statistics and data",
        "Including user-generated content",
        "Only including positive user-generated content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating a content calendar?",
      options: [
        "It helps to increase traffic to a website",
        "It helps to improve the overall design of a website",
        "It helps to keep the content consistent and organized",
        "It helps to create a more engaging user experience",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use interactive elements in content?",
      options: [
        "Not including any interactive elements",
        "Using a lot of statistics and data",
        "Including interactive elements",
        "Only including interactive elements that are not relevant to the content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a product tutorial?",
      options: [
        "To provide information about a product or service",
        "To demonstrate how to use a product or service",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use infographics in a blog post?",
      options: [
        "Not including any infographics",
        "Including a lot of infographics",
        "Including relevant infographics",
        "Only including infographics that are not relevant to the content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating a content marketing plan?",
      options: [
        "hello text markup languagIt helps to increase traffic to a website",
        "It helps to improve the overall design of a website",
        "It helps to keep the content consistent and organized",
        "It helps to create a more engaging user experience",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use data and statistics in a blog post?",
      options: [
        "Not including any data or statistics",
        "Including a lot of data and statistics",
        "Including relevant data and statistics",
        "Only including data and statistics that are not relevant to the content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a product comparison?",
      options: [
        "To provide information about a product or service",
        "To compare the features and benefits of different products or services",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use humor in content?",
      options: [
        "Not including any humor",
        "Using a lot of jokes",
        "Using relevant humor",
        "Only using offensive or insensitive humor",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating an ebook?",
      options: [
        "hIt helps to increase brand awareness",
        "It helps to generate leads",
        "It helps to improve customer relationships",
        "t helps to increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use images in a blog post?",
      options: [
        "Not including any images",
        "Including a lot of images",
        "Including relevant images",
        "Only including images that are not relevant to the content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a product demonstration?",
      options: [
        "To provide information about a product or service",
        "To demonstrate how a product or service works",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    {
      ques: " What is the best way to use testimonials in content?",
      options: [
        "Not including any testimonials",
        "Using a lot of testimonials",
        "Using relevant testimonials",
        "Only using testimonials from famous people",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary benefit of creating a content audit?",
      options: [
        "It helps to increase traffic to a website",
        "It helps to improve the overall design of a website",
        "It helps to identify gaps in content and areas for improvement",
        "It helps to create a more engaging user experience",
      ],
      ans: "C",
    },
    {
      ques: " What is the best way to use podcasts in content marketing?",
      options: [
        "Not including any podcasts",
        "Including a lot of podcasts",
        "Including relevant podcasts",
        "Only including podcasts that are not relevant to the content",
      ],
      ans: "C",
    },
    {
      ques: " What is the primary goal of a product tutorial video?",
      options: [
        "To provide information about a product or service",
        "To demonstrate how to use a product or service",
        "To generate leads",
        "To increase sales",
      ],
      ans: "B",
    },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   quizname: "Content Writing",
    //   ques: " What is the full form of HTML",
    //   options: [
    //     "hello text markup language",
    //     "Helo text markup language",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "D",
    // },
    // {
    //   ques: " What is the full form of Pqeas",
    //   options: [
    //     "hello text markup language",
    //     "Pqes",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Api",
    //   options: [
    //     "hello text markup language",
    //     "Aplication programming interface",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Jcb",
    //   options: [
    //     "hello text markup language",
    //     "Jcb",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Mla",
    //   options: [
    //     "hello text markup language",
    //     "Mla",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of Pen",
    //   options: [
    //     "hello text markup language",
    //     "Pen",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
    // {
    //   ques: " What is the full form of ins",
    //   options: [
    //     "hello text markup language",
    //     "ins",
    //     "Hyper text markup language",
    //     "Hyper text markup language",
    //   ],
    //   ans: "B",
    // },
  ]

  export default content_writing