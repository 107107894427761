const CardData = [
  {
    title: "Accounts",
    link: "/quiz",
    description:
      "An internship in accounts at Alphanumeric Ideas, a digital marketing agency, can provide a unique and valuable learning experience for those interested in combining their interests in finance and marketing. The company's focus on digital innovation and technology can offer interns an exciting opportunity to gain hands-on experience in various accounting functions within the fast-paced and ever-changing world of digital marketing.",
    description2:
      "During the internship, interns can expect to work closely with the accounts team and assist with budgeting, financial reporting, and financial analysis. They may also reconcile accounts, process invoices, and prepare financial statements. Additionally, they may have the opportunity to work on projects related to digital marketing campaigns, providing insight into the financial aspects of the marketing process.",
    enable: true,
    imgLink: "https://alphanumericideas.com/images/careers/icons/accounts.png",
    minimum: [
      "Currently enrolled in a degree program in finance, accounting, or a related field.",
      "Strong analytical and problem-solving skills.",
      "Proficiency in Microsoft Excel and other accounting software.",
      "Knowledge of basic accounting principles and practices.",
      "Strong organizational and time management skills.",
    ],

    responsibilities: [
      "Assisting with accounts payable and accounts receivable functions, such as processing invoices, payments, and receipts.",
      "Supporting the preparation of financial statements, including balance sheets, income statements, and cash flow statements.",
      "Conducting financial analysis and preparing reports on financial performance and key performance indicators.",
      "Assisting with budgeting and forecasting processes, including gathering data and performing analysis to support budget preparation.",
      "Performing reconciliations of accounts, such as bank accounts, credit cards, and vendor accounts.",
    ],
  },
  {
    title: "Digital Marketing",
    link: "/quiz",
    description:
      "An internship in digital marketing at Alphanumeric Ideas, can provide a valuable learning experience for those interested in pursuing a career in digital marketing. The company's focus on innovation and technology can offer interns an exciting opportunity to gain hands-on experience in various digital marketing functions within the context of the fast-paced and ever-changing world of digital marketing.",
    description2:
      "During the internship, interns can expect to work closely with the digital marketing team and assist with tasks such as search engine optimization (SEO), pay-per-click (PPC) advertising, email marketing, social media management, and website optimization. They may also be involved in developing digital marketing strategies, analyzing data, and evaluating campaign performance.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/digital-marketing.png",
    minimum: [
      "Currently enrolled in a degree program or a recent graduate (within the past 12 months) with a focus on marketing, digital marketing, or a related field.",
      "Familiarity with digital marketing concepts, such as SEO, PPC, social media marketing, and email marketing.",
      "Basic understanding of marketing analytics and the ability to analyze data to draw conclusions.",
      "Strong communication skills, including written and verbal communication.",
      "Good organizational skills, including the ability to manage time effectively and prioritize tasks.",
    ],
    responsibilities: [
      "Assisting with the development of digital marketing strategies and campaigns.",
      "Conducting research and analysis on industry trends, competitor strategies, and target audience behaviors.",
      "Helping to create content for various digital marketing channels, including email marketing, social media, and blog posts.",
      "Supporting the management of social media accounts by creating and scheduling posts, monitoring engagement, and analyzing performance metrics.",
      "Assisting with the creation of landing pages and other website optimizations to improve conversions.",
    ],
  },
  {
    title: "Marketing",
    link: "/quiz",
    description:
      "An internship in marketing at Alphanumeric Ideas can provide a valuable learning experience for those interested in pursuing a career in marketing. The company's focus on digital innovation and technology can offer interns an exciting opportunity to gain hands-on experience in various marketing functions within the fast-paced and ever-changing world of digital marketing.",
    description2:
      "Throughout the internship, interns can expect to receive training and guidance from experienced professionals in the field. They may participate in team meetings and discussions to gain further insight into the operations of the marketing department and have the opportunity to contribute to the development of marketing campaigns.",
    enable: true,
    imgLink: "https://alphanumericideas.com/images/careers/icons/marketing.png",
    minimum: [
      "Currently enrolled in a degree program in marketing, communications, business, or a related field.",
      "Strong written and verbal communication skills.",
      "Knowledge of basic marketing principles and practices.",
      "Proficiency in Microsoft Office and other digital tools.",
      "Excellent organizational and time management skills.",
    ],
    responsibilities: [
      "Assist in developing marketing plans and strategies: You will be expected to assist the marketing team in developing marketing plans and strategies. This includes conducting research, analyzing data, and creating reports.",
      "Support social media and content marketing efforts: You may be asked to help manage social media accounts and create content for blog posts, emails, and other marketing materials.",
      "Help with market research: You will be expected to assist in conducting market research to gather information about potential customers, competitors, and industry trends.",
      "Analyze and report on marketing campaigns: You will be expected to track and analyze the results of marketing campaigns and create reports that summarize key findings and recommendations.",
      "Coordinate events and promotions: You may be asked to help coordinate events and promotional activities, such as product launches, trade shows, or community outreach programs.",
    ],
  },
  {
    title: "Human Resources",
    link: "/quiz",
    description:
      "As a Human Resources intern at Alphanumeric Ideas, a digital marketing agency, you will gain valuable experience in the fast-paced world of business and marketing. During your internship, you will work closely with the HR team to support their various initiatives, including recruitment, employee relations, and performance management.",
    description2:
      "Throughout your internship, you will gain exposure to various HR functions, including compensation and benefits, employee engagement, and talent development. You will work alongside experienced HR professionals and learn best practices for managing people in a dynamic and ever-changing industry.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/human-resources.png",
    minimum: [
      "Currently pursuing a degree in Human Resources, Business Administration, or a related field.",
      "Strong communication and interpersonal skills.",
      "Basic knowledge of HR principles and practices.",
      "Proficient in Microsoft Office (Word, Excel, PowerPoint).",
      "Ability to work independently and as part of a team.",
    ],
    responsibilities: [
      "Assist with recruitment and staffing: This includes posting job ads, reviewing resumes, conducting initial phone screens, scheduling interviews, and participating in the interview process.",
      "Support employee relations initiatives: This includes managing employee records, responding to employee inquiries, and providing general support to employees.",
      "Assist with performance management: This includes creating and distributing performance evaluation forms, tracking employee progress, and assisting with employee development plans.",
      "Provide support for HR administrative tasks: This includes organizing employee files, maintaining HR databases, and updating employee information.",
      "Participate in HR meetings and events: This includes attending agency-wide meetings and events, as well as HR-specific meetings and events.",
    ],
  },
  {
    title: "Business Development",
    link: "/quiz",
    enable: true,
    description:
      "As a Business Development intern at Alphanumeric Ideas, a digital marketing agency, you will have the opportunity to gain valuable experience in the fast-paced world of business and marketing. During your internship, you will work closely with the Business Development team to support their various initiatives, including lead generation, sales, and marketing.",
    description2:
      "Throughout your internship, you will gain exposure to various aspects of business development, including market research, sales strategy, and client relationship management. You will work alongside experienced professionals and learn best practices for managing client relationships and growing a business.",
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/business-development.png",
    minimum: [
      "Currently pursuing a Bachelor's or Master's degree in Business Administration, Marketing, or a related field.",
      "Excellent communication skills, both verbal and written.",
      "Strong organizational skills, attention to detail, and ability to multitask.",
      "Basic knowledge of sales and marketing principles and practices.",
      "Proficiency in Microsoft Office (Word, Excel, PowerPoint).",
    ],
    responsibilities: [
      "Assist with lead generation: This includes conducting research to identify potential clients, reaching out to leads via email or phone, and tracking leads through a CRM system.",
      "Support the sales process: This includes participating in client meetings and presentations, preparing proposals and contracts, and helping to manage client relationships.",
      "Collaborate with the Business Development team: This includes working alongside experienced business development professionals and collaborating with other interns to complete projects.",
      "Conduct market research: This includes researching industry trends, identifying new market opportunities, and analyzing data to support sales and marketing initiatives.",
      "Support marketing efforts: This includes creating and executing targeted marketing campaigns to attract new clients, and contributing to the development of marketing materials.",
    ],
  },
  {
    title: "Content Writing",
    link: "/quiz",
    description:
      "As a Content Writing intern at Alphanumeric Ideas, a digital marketing agency, you will have the opportunity to gain valuable experience in the field of content marketing and copywriting. During your internship, you will work closely with the Content team to support their various initiatives, including content creation, social media management, and email marketing.",
    description2:
      "Throughout your internship, you will gain exposure to various aspects of content marketing and copywriting, including writing for different platforms and audiences, developing a content strategy, and analyzing content performance. You will work alongside experienced professionals and learn best practices for creating effective content that engages audiences and drives results.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/content-writing.png",
    minimum: [
      "Currently pursuing a Bachelor's or Master's degree in English, Communications, Journalism, Marketing, or a related field.",
      "Experience with content creation, such as writing blog articles, social media posts, or email marketing campaigns.",
      "Familiarity with social media platforms and social media management tools.",
      "Strong research skills, with the ability to conduct thorough research on a variety of topics.",
      "Knowledge of SEO best practices and ability to write content optimized for search engines.",
    ],
    responsibilities: [
      "Assist with content creation: This includes researching and writing blog articles, creating social media posts, developing email marketing campaigns, and editing and proofreading content.",
      "Support social media management: This includes participating in the development and execution of social media strategies, managing social media accounts, and monitoring social media analytics.",
      "Conduct research: This includes researching industry trends, analyzing competitors, and gathering information to support content marketing initiatives.",
      "Participate in brainstorming sessions: This includes collaborating with the Content team to generate ideas for content and marketing campaigns.",
      "Attend meetings and events: This includes attending agency-wide meetings and events, as well as Content team-specific meetings and events.",
    ],
  },
  {
    title: "Data Analyst",
    link: "/quiz",
    description:
      "As a Data Analyst intern at Alphanumeric Ideas, a digital marketing agency, you will have the opportunity to gain hands-on experience in the field of data analysis and marketing analytics. During your internship, you will work closely with the Analytics team to support their various initiatives, including data collection, data analysis, and reporting.",
    description2:
      "Throughout your internship, you will gain exposure to various aspects of marketing analytics, including web analytics, social media analytics, and digital marketing analytics. You will work alongside experienced professionals and learn best practices for using data to inform marketing decisions and drive business outcomes.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-analyst.png",
    minimum: [
      "Currently pursuing a Bachelor's or Master's degree in Statistics, Mathematics, Computer Science, Information Technology, Marketing, or a related field.",
      "Strong analytical and critical thinking skills, with the ability to analyze complex data sets and extract meaningful insights.",
      "Proficiency in data analysis tools such as Microsoft Excel, SQL, R, Python, or other statistical analysis tools.",
      "Familiarity with web analytics platforms such as Google Analytics and social media analytics tools such as Facebook Insights.",
      "Experience with data visualization tools such as Tableau, Power BI, or D3.js.",
    ],
    responsibilities: [
      "Data collection and management: You will assist with collecting data from various sources, cleaning and organizing data, and ensuring its accuracy and completeness.",
      "Data analysis: You will work with the Analytics team to develop and execute data analysis strategies, analyze complex data sets, and extract meaningful insights.",
      "Reporting: You will assist with creating reports and visualizations to communicate insights to both technical and non-technical stakeholders.",
      "Supporting data-driven decision-making: You will provide data-driven insights to inform marketing and business decisions.",
      "Research: You will conduct research on industry trends and competitors to support the Analytics team's initiatives.",
    ],
  },
  {
    title: "Data Entry",
    link: "/quiz",
    description:
      "As a Data Entry intern at Alphanumeric Ideas, a digital marketing agency, you will have the opportunity to gain hands-on experience in data entry and management. During your internship, you will work closely with the Operations team to support their various initiatives, including data entry, data management, and reporting.",
    description2:
      "Throughout your internship, you will gain exposure to various aspects of digital marketing, including email marketing, social media marketing, and search engine optimization. You will work alongside experienced professionals and learn best practices for using data to inform marketing decisions and drive business outcomes.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-entry.png",
    minimum: [
      "Education: Candidates should typically be enrolled in a degree program in a relevant field such as business, marketing, or data analytics. A high school diploma or equivalent may also be acceptable.",
      "Technical skills: Candidates should have basic computer skills and be comfortable working with Microsoft Office applications such as Excel, Word, and PowerPoint. Familiarity with data entry software and tools may also be preferred.",
      "Attention to detail: Data entry requires a high degree of accuracy and attention to detail, so candidates should have excellent organizational and data management skills.",
      "Analytical skills: Candidates should be able to analyze data and identify trends and patterns to support the Operations team in their data management and reporting activities.",
      "Communication skills: Candidates should have strong written and verbal communication skills, as they will be working closely with the Operations team and may be responsible for creating reports and communicating insights to internal and external stakeholders.",
    ],
    responsibilities: [
      "Data entry: You will be responsible for entering data accurately and efficiently into spreadsheets or databases using various data entry tools and software.",
      "Data cleaning and organization: You will be responsible for organizing and cleaning data to ensure data accuracy and completeness. This may include verifying data, removing duplicates, and ensuring data consistency.",
      "Data documentation and metadata: You will be responsible for maintaining data documentation and metadata to support the Operations team in their reporting activities.",
      "Data analysis: You may be responsible for analyzing data and identifying trends and patterns to support the Operations team in their data management and reporting activities.",
      "Reporting: You may be responsible for creating reports and visualizations to communicate insights to internal and external stakeholders.",
    ],
  },
  {
    title: "Data Research",
    link: "/quiz",
    description:
      "The data research internship at Alphanumeric Ideas is a hands-on program that allows interns to work with real-world data and gain practical experience in data analysis, research, and reporting. Interns work closely with experienced professionals in the agency's data research team and are exposed to a wide range of projects, including market research, consumer behavior analysis, and campaign performance tracking.",
    description2:
      "During the internship, interns are trained in various data analysis tools, such as Excel, Google Analytics, and other industry-specific software. They learn how to collect and analyze data, interpret the results, and develop insights and recommendations based on the findings.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/data-research.png",
    minimum: [
      "Education: Typically, applicants should be pursuing or have completed a degree in a related field such as marketing, business administration, economics, statistics, or another relevant field.",
      "Skills: Strong analytical skills, attention to detail, and proficiency in using data analysis tools like Excel, Google Analytics, and other industry-specific software are essential for the role.",
      "Communication: Strong written and verbal communication skills are necessary to effectively present research findings and communicate with clients and colleagues.",
      "Learning mindset: A willingness to learn and adapt to new challenges is crucial as the internship requires interns to work on various projects and tasks.",
      "Time management: The ability to manage time effectively and meet deadlines is critical as interns will be working on multiple projects simultaneously.",
    ],
    responsibilities: [
      "Conducting research: One of the primary responsibilities of the data research intern is to conduct research on various topics related to digital marketing, consumer behavior, and other areas relevant to the agency's clients. This may involve researching trends, analyzing data, and compiling reports based on the findings.",
      "Data analysis: The intern would assist the data research team in analyzing data from various sources, such as surveys, social media, and other online platforms. This involves using data analysis tools like Excel and Google Analytics to organize and interpret data and prepare reports based on the findings.",
      "Reporting: As an intern, you would be expected to assist in preparing reports based on the research and data analysis conducted. This may involve presenting findings in a clear and concise manner using data visualization tools like charts and graphs.",
      "Collaboration: You would be expected to work collaboratively with the data research team and other colleagues in the agency to ensure that project timelines are met, and tasks are completed accurately and efficiently.",
      "Learning: The data research internship at Alphanumeric Ideas is designed to provide you with a learning experience, so you would be expected to ask questions, seek guidance and be willing to learn new skills and techniques related to data analysis and digital marketing.",
    ],
  },
  {
    title: "Email Marketing",
    link: "/quiz",
    description:
      "The email marketing internship at Alphanumeric Ideas is a hands-on program that allows interns to work with real-world email marketing campaigns and gain practical experience in email marketing strategy, campaign management, and performance analysis. Interns work closely with experienced professionals in the agency's email marketing team and are exposed to a wide range of projects, including email campaign design, content creation, segmentation, and reporting.",
    description2:
      "During the internship, interns are trained in various email marketing tools such as Mailchimp, Constant Contact, and other industry-specific software. They learn how to create and manage email campaigns, develop compelling content, segment email lists, and analyze campaign performance.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/email-marketing.png",
    minimum: [
      "Education: Typically, applicants should be pursuing or have completed a degree in a related field such as marketing, communications, business administration, or another relevant field.",
      "Skills: Strong written and verbal communication skills, attention to detail, and proficiency in using email marketing tools such as Mailchimp, Constant Contact, or other industry-specific software are essential for the role.",
      "Creativity: A creative mindset is an asset for the internship as interns would be expected to contribute to email content creation and campaign design.",
      "Learning mindset: A willingness to learn and adapt to new challenges is crucial as the internship requires interns to work on various projects and tasks.",
      "Time management: The ability to manage time effectively and meet deadlines is critical as interns will be working on multiple projects simultaneously.",
    ],
    responsibilities: [
      "Campaign Management: One of the primary responsibilities of the email marketing intern is to assist the email marketing team in managing email campaigns for various clients. This may involve creating email lists, developing email content, testing and sending emails, and analyzing campaign performance.",
      "Content creation: The intern would assist in creating email content and subject lines that are engaging and effective in getting recipients to open and interact with the email. They will work with the team to ensure that all content aligns with the client's goals and messaging.",
      "Reporting: As an intern, you would be expected to assist in preparing reports on the performance of email campaigns. This may involve analyzing data, presenting findings in a clear and concise manner using data visualization tools like charts and graphs.",
      "Collaboration: You would be expected to work collaboratively with the email marketing team and other colleagues in the agency to ensure that project timelines are met, and tasks are completed accurately and efficiently.",
      "Learning: The email marketing internship at Alphanumeric Ideas is designed to provide you with a learning experience, so you would be expected to ask questions, seek guidance and be willing to learn new skills and techniques related to email marketing.",
    ],
  },
  {
    title: "Graphic Design",
    link: "/quiz",
    description:
      "Alphanumeric Ideas, a digital marketing agency, is currently offering a Graphic Designer internship for individuals looking to gain valuable experience in the design industry. This internship is an excellent opportunity for aspiring designers to work with experienced professionals, create unique and engaging designs, and contribute to real-world projects.",
    description2:
      "The Graphic Designer intern will be responsible for working alongside the agency's creative team to design various visual materials such as logos, graphics, and social media content for clients. The intern will also collaborate with the agency's marketing team to develop visual campaigns that meet the client's needs and objectives.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/graphic-design.png",
    minimum: [
      "Education: Most companies require interns pursuing a Graphic Designer role to have at least a high school diploma or equivalent. Some organizations may prefer applicants with a college degree in Graphic Design, Fine Arts, or a related field.",
      "Design skills: Strong design skills are essential for this role. Interns should have experience using design software such as Adobe Creative Suite, be proficient in design principles, typography, and color theory, and have a good understanding of layout and composition.",
      "Portfolio: It is important for applicants to have a portfolio of previous design work that demonstrates their skills and experience. A good portfolio should showcase the applicant's ability to create engaging designs, communicate ideas visually, and work collaboratively with others.",
      "Creativity: A strong sense of creativity and a willingness to push boundaries and explore new design ideas is important for a Graphic Designer intern.",
      "Attention to detail: An eye for detail is crucial for this role. Interns should have a strong attention to detail and be able to produce high-quality designs with accuracy.",
    ],
    responsibilities: [
      "Collaborate with the creative team: Graphic Designer interns will be expected to work alongside the creative team to develop design concepts and execute design projects.",
      "Create visual materials: Interns will be responsible for creating various visual materials such as logos, graphics, brochures, social media content, and other marketing materials.",
      "Work on multiple projects: Interns will typically work on multiple design projects simultaneously and will need to manage their time effectively to ensure deadlines are met.",
      "Stay up-to-date with design trends: Graphic Designer interns should be aware of current design trends and best practices and be willing to incorporate them into their work.",
      "Attend team meetings: Interns should attend team meetings and participate in discussions to contribute their ideas and stay informed about ongoing projects.",
    ],
  },
  {
    title: "Online Bidding",
    link: "/quiz",
    description:
      "Alphanumeric Ideas, a digital marketing agency, offers an Online Bidding internship program for students interested in gaining experience in the field of online advertising. The internship provides a unique opportunity for interns to learn about online bidding and its role in digital marketing. During the internship program, interns will be introduced to the concept of online bidding and how it works. They will learn about different types of online advertising platforms, such as Google Ads and Facebook Ads, and the bidding process that takes place on these platforms. Interns will also learn about keyword research, ad copywriting, and other essential skills that are necessary for successful online advertising.",
    description2:
      "The Online Bidding internship program at Alphanumeric Ideas is designed to provide interns with hands-on experience. Interns will work closely with experienced professionals and be given the opportunity to participate in real projects. They will learn how to analyze data and make data-driven decisions to optimize online advertising campaigns. Additionally, interns will learn how to communicate effectively with clients and provide them with regular updates on their campaigns' performance.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/online-bidding.png",
    minimum: [
      "Most Online Bidding internships require a candidate to be pursuing or have completed a degree in Marketing, Advertising, Business Administration, or a related field. However, some internships may also consider candidates from different educational backgrounds.",
      "A candidate for an Online Bidding internship should have a basic understanding of digital marketing concepts, such as SEO, SEM, PPC, social media marketing, and online advertising.",
      "An intern should possess strong analytical skills to analyze data, make data-driven decisions, and optimize online advertising campaigns.",
      "An intern should have a keen eye for detail to identify areas of improvement and ensure that the online advertising campaigns are optimized for maximum performance.",
      "A candidate should have a basic understanding of Microsoft Excel and other relevant tools used in digital marketing, such as Google Ads, Google Analytics, and Facebook Ads Manager.",
    ],
    responsibilities: [
      "Assist with creating and managing online advertising campaigns: You will help create and manage online advertising campaigns across different platforms such as Google Ads, Facebook Ads, and LinkedIn Ads. This includes performing keyword research, writing ad copy, setting up targeting parameters, and monitoring the performance of the campaigns.",
      "Analyze data and provide insights: You will use data analysis tools to analyze the performance of online advertising campaigns and provide insights to the team. This includes identifying trends, creating reports, and making data-driven recommendations to optimize campaigns.",
      "Research industry trends and best practices: You will stay up-to-date on industry trends and best practices for online advertising, including new ad formats, targeting options, and bidding strategies. You will share your findings with the team to help improve campaign performance.",
      "Communicate with clients and stakeholders: You will communicate with clients and stakeholders to provide regular updates on campaign performance, answer questions, and provide recommendations for improvement.",
      "Collaborate with the team: You will work closely with other team members to ensure that campaigns are running smoothly, tasks are completed on time, and client expectations are met.",
    ],
  },
  {
    title: "Web Development",
    link: "/quiz",
    description:
      "As an intern at Alphanumeric Ideas, the focus of the internship would be on web development. The internship would provide an opportunity to work on various web development projects under the guidance of experienced developers. The internship would be a great learning experience for anyone interested in pursuing a career in web development.",
    description2:
      "The internship program would involve working on both front-end and back-end development. The front-end development would include working with HTML, CSS, and JavaScript to create visually appealing and responsive web pages. The back-end development would involve working with programming languages like PHP, NodeJS, or Python to create the logic behind the web pages.",
    enable: true,
    imgLink:
      "https://alphanumericideas.com/images/careers/icons/web-development.png",
    minimum: [
      "Educational Qualification: Applicants should have completed or currently pursuing a degree in Computer Science, Information Technology, Web Development, or a related field. Some internships may require a specific level of education, such as a bachelor's or master's degree.",
      "Technical Skills: Applicants should have a strong foundation in programming languages such as HTML, CSS, JavaScript, and at least one server-side language like PHP, NodeJS, or Python. Experience with web development frameworks, version control systems, and databases like MySQL or MongoDB may also be preferred.",
      "Communication Skills: Strong communication skills are essential, as interns will be expected to work collaboratively with other team members, communicate with clients, and take direction from supervisors.",
      "Problem-Solving Skills: Applicants should possess strong analytical and problem-solving skills, as they will be responsible for identifying and solving complex web development problems.",
      "Passion for Web Development: Applicants should have a genuine interest and passion for web development, digital marketing.",
    ],
    responsibilities: [
      "Collaborating with team members: Work closely with other developers, designers, and project managers to complete assigned projects and tasks.",
      "Writing clean, efficient, and maintainable code: Write code that is easy to understand, maintain, and modify, and adheres to industry best practices.",
      "Testing and debugging: Test and debug code to identify and resolve errors and ensure that the final product meets the client's requirements.",
      "Participating in code reviews: Participate in code reviews with other team members to identify potential issues and make suggestions for improvements.",
      "Researching and learning new technologies: Stay up-to-date with the latest industry trends and technologies, and research new tools and frameworks that could improve the development process.",
    ],
  },

  // {
  //   title: "Flutter",
  //   link: "/quiz",
  //   description:
  //     "Are you a tech-savvy individual with a passion for mobile app development and a flair for creativity? Look no further! Alphanumeric Ideas, a leading digital marketing agency, is excited to offer an exceptional internship opportunity for a Flutter Developer.",
  //   description2:
  //     "Alphanumeric Ideas is a cutting-edge digital marketing agency that specializes in delivering innovative solutions to clients worldwide. With a focus on excellence and creativity, we strive to make a meaningful impact in the digital landscape. Our team consists of highly skilled professionals from various backgrounds, creating a collaborative and inspiring work environment.",
  //   enable: true,
  //   imgLink: "https://alphanumericideas.com/images/careers/icons/marketing.png",
  //   minimum: [
  //     "Enthusiasm and passion for mobile app development, with a particular interest in Flutter and cross-platform development.",
  //     "Knowledge of programming languages like Dart, Java, or Kotlin.",
  //     "Familiarity with mobile app development frameworks and tools.",
  //     "Creativity and an eye for design to create visually appealing app interfaces.",
  //     "Strong communication skills and the ability to work collaboratively in a team setting.",
  //   ],
  //   responsibilities: [
  //     "Collaborating with cross-functional teams to understand client requirements and design app interfaces that align with their brand identity and marketing goals.",
  //     "Writing clean, efficient, and maintainable code using Flutter, Dart, and other relevant technologies to build responsive mobile applications for Android and iOS platforms.",
  //     "Assisting in debugging, testing, and troubleshooting app functionalities to ensure smooth and bug-free user experiences.",
  //     "Keeping up-to-date with the latest Flutter advancements and mobile app development best practices to contribute fresh ideas and innovative solutions.",
  //     "Participating in code reviews and discussions, providing valuable feedback to improve overall app quality.",
  //   ],
  // },
];

export default CardData;
