const business_developement = [
  {
    quizname: "Business Developement",
    imgLink:
      "https://alphanumericideas.com/images/careers/business-development.png",
    ques: " What is the primary goal of business development?",
    options: [
      "Sales",
      "Marketing",
      "Identifying and pursuing new business opportunities",
      "Customer service",
    ],
    ans: "C",
  },
  {
    ques: " What is a common strategy used in business development to identify new opportunities?",
    options: [
      "Market research",
      "Advertising",
      "Product development",
      "Direct mail",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is a key skill needed for a business development job?",
    options: [
      "Project management",
      "Graphic design",
      "Sales",
      "Technical writing",
    ],
    ans: "C",
  },
  {
    ques: " What are the stages of the business development process?",
    options: [
      "Identifying opportunities, evaluating opportunities, implementing opportunities",
      "Identifying opportunities, implementing opportunities, evaluating opportunities",
      "Evaluating opportunities, identifying opportunities, implementing opportunities",
      "Implementing opportunities, evaluating opportunities, identifying opportunities",
    ],
    ans: "A",
  },
  {
    ques: " What is the role of a business development professional in a company?",
    options: [
      "To create and implement sales strategies",
      "To identify new business opportunities and develop relationships",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between business development and sales?",
    options: [
      "Business development is focused on identifying new opportunities, while sales is focused on closing deals.",
      "Business development is focused on managing existing customers, while sales is focused on acquiring new customers.",
      "Business development is focused on product development, while sales is focused on market research",
      "Business development is focused on advertising, while sales is focused on customer service.",
    ],
    ans: "A",
  },
  {
    ques: " What is the main focus of a business development plan?",
    options: [
      "Increasing revenue",
      "Reducing costs",
      "Improving customer satisfaction",
      "Identifying and pursuing new business opportunities",
    ],
    ans: "D",
  },
  {
    ques: " What is the importance of market research in business development?",
    options: [
      "Helps to understand customer needs and preferences",
      "Helps to identify new business opportunities",
      "Helps to evaluate the potential of new opportunities",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the purpose of a business development proposal?",
    options: [
      "To convince a potential client to do business with the company",
      "To outline the company's marketing strategy",
      "To present the company's financials",
      "To show the company's product development progress",
    ],
    ans: "A",
  },
  {
    ques: " What is a key aspect of building relationships in business development?",
    options: [
      "Networking",
      "Cold calling",
      "Spamming",
      "Ignoring customer feedback",
    ],
    ans: "A",
  },
  {
    ques: " What is the main goal of a business development team?",
    options: [
      "To increase company revenue",
      "To reduce company expenses",
      "To improve company reputation",
      "To identify and pursue new business opportunities",
    ],
    ans: "D",
  },
  {
    ques: " What is a common way to measure the success of a business development strategy?",
    options: [
      "Number of new customers acquired",
      "Increase in company stock price",
      "Reduction in employee turnover",
      "Increase in company revenue",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development manager?",
    options: [
      "To manage the company's finances",
      "To handle customer service and support",
      "To lead a team responsible for identifying and pursuing new business opportunities",
      "To create and implement sales strategies",
    ],
    ans: "C",
  },
  {
    ques: " What is the first step in the business development process?",
    options: [
      "Identifying opportunities",
      "Evaluating opportunities",
      "Implementing opportunities",
      "Researching competitors",
    ],
    ans: "A",
  },
  {
    ques: " What is a common way to identify new business opportunities?",
    options: [
      "Surveying current customers",
      "Monitoring the competition",
      "Analyzing industry trends",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a sales pitch and a business development pitch?",
    options: [
      "A sales pitch is focused on closing a deal, while a business development pitch is focused on building a relationship",
      "A sales pitch is focused on a specific product or service, while a business development pitch is focused on the company as a whole",
      "A sales pitch is focused on the short-term, while a business development pitch is focused on the long-term",
      "A sales pitch is focused on reducing costs, while a business development pitch is focused on increasing revenue",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of networking in business development?",
    options: [
      "It helps to build relationships with potential clients and partners",
      "It helps to learn about new business opportunities",
      "It helps to stay informed about industry trends and developments",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a business development strategy and a marketing strategy?",
    options: [
      "A business development strategy is focused on identifying new business opportunities, while a marketing strategy is focused on promoting products or services",
      " A business development strategy is focused on increasing revenue, while a marketing strategy is focused on reducing costs",
      " A business development strategy is focused on building relationships, while a marketing strategy is focused on closing deals",
      "A business development strategy is focused on improving company reputation, while a marketing strategy is focused on increasing brand awareness",
    ],
    ans: "A",
  },
  {
    ques: " What is the role of a business development executive?",
    options: [
      "To create and implement sales strategies",
      "To identify new business opportunities and develop relationships",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "B",
  },
  {
    ques: " What is the role of a business development consultant?",
    options: [
      "To provide advice and guidance on identifying and pursuing new business opportunities",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between business development and product development?",
    options: [
      "Business development is focused on identifying new opportunities, while product development is focused on creating new products or services",
      "Business development is focused on increasing revenue, while product development is focused on reducing costs",
      "Business development is focused on building relationships, while product development is focused on improving existing products or services",
      "Business development is focused on marketing, while product development is focused on research and development",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong value proposition in business development?",
    options: [
      "It helps to differentiate the company's products or services from competitors",
      "It helps to communicate the benefits of the products or services to potential clients",
      "It helps to build trust and credibility with potential clients",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is a common business development tool used to analyze market opportunities?",
    options: [
      "SWOT analysis",
      "PEST analysis",
      "Five Forces analysis",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is a key aspect of business development in international markets?",
    options: [
      "Understanding cultural differences",
      "Adapting products or services to local market conditions",
      "Establishing partnerships with local businesses",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the importance of a business development budget?",
    options: [
      "It helps to allocate resources effectively",
      "It helps to measure the success of business development efforts",
      "It helps to identify potential cost savings",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a business development department and a sales department?",
    options: [
      "A business development department is focused on identifying new opportunities, while a sales department is focused on closing deals",
      "A business development department is focused on managing existing customers, while a sales department is focused on acquiring new customers",
      "A business development department is focused on product development, while a sales department is focused on market research",
      "A business development department is focused on advertising, while a sales department is focused on customer service",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a business development plan?",
    options: [
      "It helps to set clear goals and objectives",
      "It helps to identify potential risks and challenges",
      "It helps to allocate resources effectively",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development analyst?",
    options: [
      "To collect and analyze data on market trends and opportunities",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong business development team?",
    options: [
      "It helps to identify new opportunities effectively",
      "It helps to build strong relationships with clients and partners",
      "It helps to drive company growth and revenue",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What are the common metrics used to measure the success of a business development strategy?",
    options: [
      "Number of new business opportunities identified, revenue generated from new opportunities, return on investment",
      "Number of new customers acquired, increase in company stock price, reduction in employee turnover",
      "Number of new products or services developed, increase in company reputation, reduction in costs",
      "Number of new partnerships formed, increase in brand awareness, customer satisfaction levels",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong business development strategy in a company?",
    options: [
      "It helps to identify and capitalize on new growth opportunities",
      "It helps to increase company revenue and profitability",
      "It helps to build a competitive advantage in the market",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between business development and mergers and acquisitions?",
    options: [
      "Business development is focused on identifying new opportunities, while mergers and acquisitions are focused on acquiring existing companies",
      "Business development is focused on increasing revenue, while mergers and acquisitions are focused on reducing costs",
      "Business development is focused on building relationships, while mergers and acquisitions are focused on acquiring assets",
      "Business development is focused on creating new products or services, while mergers and acquisitions are focused on integrating existing products or services",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong business development pipeline?",
    options: [
      "It helps to ensure a steady flow of new business opportunities",
      "It helps to prioritize and allocate resources effectively",
      "It helps to measure the success of business development efforts",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development representative?",
    options: [
      "To identify new business opportunities and develop relationships with potential clients",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a clear value proposition in business development?",
    options: [
      "It helps to communicate the unique benefits of the company's products or services to potential clients",
      "It helps to differentiate the company from competitors",
      "It helps to build trust and credibility with potential clients",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development coordinator?",
    options: [
      "To assist a business development team with administrative tasks and support",
      "To identify new business opportunities and develop relationships with potential clients",
      "To create and implement sales strategies",
      "To manage the company's finances",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong brand in business development?",
    options: [
      "It helps to increase company visibility and credibility in the market",
      "It helps to differentiate the company from competitors",
      "It helps to build trust and loyalty with customers",
      "All of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between business development and corporate development?",
    options: [
      "Business development is focused on identifying new opportunities in existing markets, while corporate development is focused on strategic growth through mergers, acquisitions, and partnerships",
      "Business development is focused on increasing revenue, while corporate development is focused on reducing costs",
      "Business development is focused on building relationships, while corporate development is focused on creating a long-term vision and strategy for the company",
      "Business development is focused on product development, while corporate development is focused on organizational development",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong online presence in business development?",
    options: [
      "It helps to increase visibility and credibility in the digital market",
      "It helps to gather valuable customer insights through data analytics",
      "It helps to build relationships and interact with potential clients through social media and digital channels",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development intern?",
    options: [
      "To assist a business development team with research, data analysis, and administrative tasks",
      "To identify new business opportunities and develop relationships with potential clients",
      "To create and implement sales strategies",
      "To manage the company's finances",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between business development and business management?",
    options: [
      "Business development is focused on identifying new opportunities, while business management is focused on the day-to-day operations of a company",
      "Business development is focused on increasing revenue, while business management is focused on reducing costs",
      "Business development is focused on building relationships, while business management is focused on managing resources and personnel",
      "Business development is focused on creating new products or services, while business management is focused on improving existing products or services",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong CRM system in business development?",
    options: [
      "It helps to track and manage interactions with potential and existing clients",
      "It helps to gather and analyze customer data",
      "It helps to improve the efficiency and effectiveness of business development efforts",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development executive in a startup company?",
    options: [
      "To identify new business opportunities and develop relationships with potential investors and partners",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong business development plan for a startup company?",
    options: [
      "It helps to identify and capitalize on new growth opportunities",
      "It helps to secure funding from investors and partners",
      "It helps to build a competitive advantage in the market",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development director?",
    options: [
      "To lead a team responsible for identifying and pursuing new business opportunities",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  {
    ques: " What is the importance of a strong business development team in a startup company?",
    options: [
      "It helps to identify new opportunities effectively",
      "It helps to secure funding from investors and partners",
      "It helps to drive company growth and revenue",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between business development and venture capital?",
    options: [
      "Business development is focused on identifying new opportunities, while venture capital is focused on providing financial resources to startup companies",
      "Business development is focused on increasing revenue, while venture capital is focused on reducing costs",
      "Business development is focused on building relationships, while venture capital is focused on investing in companies with high growth potential",
      "Business development is focused on creating new products or services, while venture capital is focused on supporting the development and scaling of existing products or services",
    ],
    ans: "C",
  },
  {
    ques: " What is the importance of a strong business development strategy in a startup company?",
    options: [
      "It helps to identify and capitalize on new growth opportunities",
      "It helps to secure funding from investors and partners",
      "It helps to build a competitive advantage in the market",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " What is the role of a business development advisor?",
    options: [
      "To provide advice and guidance on identifying and pursuing new business opportunities",
      "To create and implement sales strategies",
      "To manage the company's finances",
      "To handle customer service and support",
    ],
    ans: "A",
  },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
  // {
  //   ques: " What is the full form of Pqeas",
  //   options: [
  //     "hello text markup language",
  //     "Pqes",
  //     "Hyper text markup language",
  //     "Hyper text markup language",
  //   ],
  //   ans: "B",
  // },
];
export default business_developement;
