import React from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/esm/Image";
import Logo from "../images/logo.png";
import Fb from "../images/facebookIcon.png";
import Insta from "../images/instagramIcon.png";
import Twitter from "../images/twitterIcon.png";
import Linkdin from "../images/linkedinIcon.png";
import pinterest from "../images/pinterestIcon.png";

const Footer = () => {
  return (
    <>
      <Container fluid className="footorColor pt-5 pb-5 mt-5">
        <div className="row ps-5 mainRow">
          <div className="col-lg-7 fCol">
            <Image fluid src={Logo} className="ftrLogo" />
            <div className="row">
              <div className="col-lg-8">
                <div className="firstBoxText">
                  REDEFINING SYMBOLS TO <br />
                  MEMORIES
                </div>
              </div>
              <div className="col-lg-4 firstSecondBox">
                <div className="text-center">
                  <div className="mb-2">Social Links</div>
                  <a
                    href="https://www.facebook.com/AlphanumericIdeas"
                    target="_blank"
                  >
                    <Image fluid src={Fb} className="mb-2 scIcn"></Image>
                  </a>
                  <a
                    href="https://www.instagram.com/alphanumericideas/"
                    className="ps-1"
                    target="_blank"
                  >
                    <Image fluid src={Insta} className="mb-2 scIcn"></Image>
                  </a>
                  <a
                    href="https://twitter.com/AiAlphanumeric"
                    className="ps-1"
                    target="_blank"
                  >
                    <Image fluid src={Twitter} className="mb-2 scIcn"></Image>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/alphanumeric-ideas"
                    className="ps-1"
                    target="_blank"
                  >
                    <Image fluid src={Linkdin} className="mb-2 scIcn"></Image>
                  </a>
                  <a
                    href="https://in.pinterest.com/AlphanumericIdeas/"
                    className="ps-1"
                    target="_blank"
                  >
                    <Image fluid src={pinterest} className="mb-2 scIcn"></Image>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5">
            <div className="footerHeading mb-5 fCol">
              Discover the Future Together with Alphanumeric Ideas
            </div>
            <div className="row justify-content-around">
              <div className="col-lg-5 col-sm-6 fCol">
                <div className="subFooterHeading">Let's Talk</div> <br />
                <a href="tel:18008901188" className="fLink">
                  1800 890 1188 (Toll Free) <br />
                </a>
                <div className="mt-3">
                  <a
                    href="https://wa.me/917696855855"
                    className="fLink"
                    target="_blank"
                  >
                    WhatsApp Us!
                  </a>
                </div>
                <div className="subFooterHeading mt-5 mb-4">Office</div>
                <div className="footerText">
                  India — <br /> Greater Mohali <br /> Punjab, India
                </div>
              </div>

              <div className="col-lg-3 col-sm-6 fCol">
                <div className="subFooterHeading cMargin">Company</div> <br />
                <div className="mt-3 hMargin">
                  <a className="fLink" href="https://alphanumericideas.com/">
                    Home
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/about"
                  >
                    About Us
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/our-services"
                  >
                    Our Services
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/our-work"
                  >
                    Our Work
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/careers"
                  >
                    Careers
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/blog"
                  >
                    Blog
                  </a>
                  <br />
                  <a
                    className="fLink"
                    href="https://alphanumericideas.com/contact-us"
                  >
                    Contact Us
                  </a>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row ps-5 mt-5 mainRow">
          <div className="col-lg-9 mb-2">
            <div className="fCol">
              <div className="endText pe-3 d-inline newDisplay">
                ©2022 Alphanumeric Ideas Pvt. Ltd. All Rights Reserved.
              </div>
              <a
                href="https://alphanumericideas.com/privacy-policy"
                className="fLink endText ps-5 pe-4 newDisplay"
              >
                Privacy Policy
              </a>
              <a
                href="https://alphanumericideas.com/tandc"
                className="fLink endText ps-5 newDisplay"
              >
                T&C
              </a>
            </div>
          </div>
          <div className="col-lg-3 fCol">
            <div className="endText mt-1">Made in India, With Proud.</div>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Footer;
