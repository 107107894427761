const graphic_design = [
  {
    quizname: "Graphic Design",
    imgLink :"https://alphanumericideas.com/images/careers/graphic-designer.png",
    ques: " What is the most common file format for saving images for the web?",
    options: [
      "JPEG",
      "PNG",
      "BMP",
      "GIF",
    ],
    ans: "A",
  },
  {
    ques: " What is the resolution of a standard web image?",
    options: [
      "72 dpi",
      "150 dpi",
      "300 dpi",
      "600 dpi",
    ],
    ans: "A",
  },
  {
    ques: " What is the primary color used in the CMYK color model?",
    options: [
      "Red",
      "Blue",
      "Cyan",
      "Yellow",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of reducing the size of a file without losing quality?",
    options: [
      "Compression",
      "Rasterizing",
      "Vectorizing",
      "Resampling",
    ],
    ans: "A",
  },
  {
    ques: " What is the most popular software used for vector graphics?",
    options: [
      "Adobe Photoshop",
      "Adobe Illustrator",
      "CorelDRAW",
      "Inkscape",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe the process of adding text to an image?",
    options: [
      "Typography",
      "Texturing",
      "Rasterizing",
      "Vectorizing",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of adjusting the brightness and contrast of an image?",
    options: [
      "Color correction",
      "Color grading",
      "Tone correction",
      "Tone grading",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe the process of adjusting the saturation of an image?",
    options: [
      "Color correction",
      "Color grading",
      "Tone correction",
      "Tone grading",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of adjusting the size and shape of an image?",
    options: [
      "Cropping",
      "Scaling",
      "Re-sampling",
      "Rasterizing",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a website?",
    options: [
      "Web design",
      "UI design",
      "UX design",
      "Graphic design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a mobile app?",
    options: [
      "Mobile design",
      "UI design",
      "UX design",
      "Graphic design",
    ],
    ans: "B",
  },
  {
    ques: " Three-dimensional means…………..",
    options: [
      "Height, width, and depth",
      "Height, and width",
      "Height, and depth",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " ______ Colors are groups of colors that consist of purples, greens, and blues",
    options: [
      "Warm",
      "Cool",
      "Blue",
      "Pale",
    ],
    ans: "B",
  },
  {
    ques: " Concept of a work of art is called……",
    options: [
      "Illustration",
      "Design",
      "Space",
      "Value",
    ],
    ans: "B",
  },
  {
    ques: " ………is a Text matter for a design",
    options: [
      "Content",
      "Panel",
      "Layer",
      "Layout",
    ],
    ans: "A",
  },
  {
    ques: " What is the recommended resolution for images used on the internet?",
    options: [
      "150 dpi",
      "300 dpi",
      "72 dpi",
      "600 dpi",
    ],
    ans: "C",
  },
  {
    ques: " Which graphic formats will allow you to make use of transparency?",
    options: [
      "Iff",
      "Tiff",
      "Bmp",
      "Jpg",
    ],
    ans: "B",
  },
  {
    ques: " Bending of a line is called…",
    options: [
      "Curve",
      "Width",
      "Length",
      "Angle",
    ],
    ans: "A",
  },
  {
    ques: " What is Two-dimensional?",
    options: [
      "Height, width, and depth",
      "Height, and width",
      "Height, and depth",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " What is Two-dimensional?",
    options: [
      "Height, width, and depth",
      "Height, and width",
      "Height, and depth",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " ………………Represent the regions that can contain printable art works.",
    options: [
      "Art board",
      "Application bar",
      "Tool panel",
      "Control panel",
    ],
    ans: "A",
  },
  {
    ques: " Which tool should you use to edit the individual Anchor points of an objects ?",
    options: [
      "Gradient tool",
      "Scale tool",
      "Direct selection tool",
      "Eraser tool",
    ],
    ans: "C",
  },
  {
    ques: " RGB stands for……………",
    options: [
      "Red green black",
      "Red gray black",
      "Red gray blue",
      "Red green blue",
    ],
    ans: "D",
  },
  {
    ques: " …………tool should be used to create a perfect circle",
    options: [
      "Spiral tool",
      "Elipse tool",
      "Rounded rectangle tool",
      "Blend tool",
    ],
    ans: "B",
  },
  {
    ques: " The perfect printing resolution is ____ Dpi",
    options: [
      "12",
      "150",
      "300",
      "360",
    ],
    ans: "C",
  },
  {
    ques: " Dpi stands for",
    options: [
      "Design per inch",
      "Depth per inch",
      "Dots per inch",
      "Dimensions per inch",
    ],
    ans: "C",
  },
  {
    ques: " ……………images does not lose quality when enlarged",
    options: [
      "Vector",
      "pixel",
      "Jpeg",
      "Png",
    ],
    ans: "A",
  },
  {
    ques: " …………Tool in the tool panel lets you select entire objects",
    options: [
      "Scale tool",
      "Selection tool",
      "Mesh tool",
      "Hand tool",
    ],
    ans: "B",
  },
  {
    ques: " ……………Displays options for the current selected objects",
    options: [
      "Control panel",
      "Tool panel",
      "Art board panel",
      "Document window",
    ],
    ans: "A",
  },
  {
    ques: " The ……………… contains tools for creating and editing images,artworks,page elements and more.",
    options: [
      "Art board panel",
      "Control panel",
      "Tools panel",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Which displays the file you are working on?",
    options: [
      "Application bar",
      "Tool panel",
      "Status bar",
      "Document window",
    ],
    ans: "D",
  },
  {
    ques: " A …………… Is a reusable art object stored in the symbols panel.",
    options: [
      "Brushes",
      "Symbols",
      "Switches",
      "Stroke",
    ],
    ans: "B",
  },
  {
    ques: " The …………………… is context- sensitive, meaning that it offers quick access to options, commands and other panels relevant to the current selected object.",
    options: [
      "Control panel",
      "Tool panel",
      "Art board panel",
      "Document window",
    ],
    ans: "A",
  },
  {
    ques: " In addition to the View menu options, you can use the ………….. tool to magnify and reduce the view of artwork to predefined magnification level.",
    options: [
      "Hand tool",
      "Zoom tool",
      "Slice tool",
      "Mesh tool",
    ],
    ans: "B",
  },
  {
    ques: " …………… tool Can use to pan to different areas of a document.",
    options: [
      "Hand tool",
      "Zoom tool",
      "Selection tool",
      "Pen tool",
    ],
    ans: "A",
  },
  {
    ques: " …………… Can help you accurately place and measure objects in your document",
    options: [
      "Rulers",
      "Navigation panel",
      "Groups",
      "Stroke width",
    ],
    ans: "A",
  },
  {
    ques: " The …………… lets you erase any area of your artwork, regardless of the structure.",
    options: [
      "Blend tool",
      "Eraser tool",
      "Selection tool",
      "Pen tool",
    ],
    ans: "B",
  },
  {
    ques: " ………… can be useful for turning a drawing into vector",
    options: [
      "Trace",
      "Blend",
      "Group",
      "Complain",
    ],
    ans: "A",
  },
  {
    ques: " ………… are the non-printing lines that help you align objects",
    options: [
      "Rulers",
      "Navigation panel",
      "Guides",
      "Stroke width",
    ],
    ans: "C",
  },
  {
    ques: " Which color mode is usually used for the printing process?",
    options: [
      "Gray scale",
      "Bitmap",
      "Cmyk",
      "Lab colour",
    ],
    ans: "C",
  },
  {
    ques: " Name the keyboard command for Zoom in",
    options: [
      "Ctrl + +",
      "Alt ++",
      "Ctrl + z",
      "Alt+ z",
    ],
    ans: "A",
  },
  {
    ques: " ……… are color blends of two or more colors that you can apply to the fill or stroke of artwork.",
    options: [
      "Artboard tool",
      "Column graph tool",
      "Eyedropper tool",
      "Gradient tool",
    ],
    ans: "D",
  },
  {
    ques: "Bitmap images are technically called",
    options: [
      "Pixels",
      "Vector graphics",
      "Raster images",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " The outline of a vector shape is",
    options: [
      "Pixels",
      "Grid",
      "Paths",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Raster images are based on a grid of dots known as",
    options: [
      "Paths",
      "Vector",
      "Pixels",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " To restore default preferences in photoshop in windows the shortcut used is",
    options: [
      "Ctrl+shift",
      "Ctrl+alt+shift",
      "Ctrl+q",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " To toggle between pen tool and free form pen tool the shortcut used is",
    options: [
      "P",
      "Alt+p",
      "Shift+p",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " _____ refers to the number of pixels that describe an image and establish its details",
    options: [
      "Layers",
      "Resolution",
      "Path",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Ppi stands for………",
    options: [
      "Points per inch",
      "Paths per inch",
      "Pixels per inch",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Smaller letters are usually called……..in typography",
    options: [
      "Uppercase",
      "Lowercase",
      "Sentence case",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " By default Photoshop is set for………….. color model",
    options: [
      "Grayscale",
      "Cmyk",
      "Rgb",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " CMYK stands for……",
    options: [
      "Cyan magenta yellow black",
      "Crimson magenta yellow black",
      "Cyan maroon yellow black",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following colors is also called additive colors?",
    options: [
      "Rgb",
      "Cmyk",
      "Grayscale",
      "Indexed",
    ],
    ans: "A",
  },
  {
    ques: " Bitmap images are technically called",
    options: [
      "Pixels",
      "Vector graphics",
      "Raster images",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " _____refers to the number of pixels that describe an image and establish its details",
    options: [
      "Layers",
      "Resolution",
      "Path",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " CMYK inks are also called",
    options: [
      "Printing colors",
      "Process colors",
      "Screen color",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " For print production the most appropriate color mode is",
    options: [
      "Cmyk",
      "Rgb",
      "Grayscale",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not a blending mode",
    options: [
      "Multiply",
      "Luminance",
      "Crop",
      "Overlay",
    ],
    ans: "C",
  },
  {
    ques: " New files in photoshop is generally created with",
    options: [
      "Normal layers",
      "Adjustment layers",
      "Background layer",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " The negative area of space surrounding the columns of type is called the …..area",
    options: [
      "Line",
      "Margin",
      "Grid",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Capital or larger letters of a type font are known as……",
    options: [
      "Uppercase",
      "Lower case",
      "Sentence case",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " ………is a graphical representation of a product, brand, company or group",
    options: [
      "Logo",
      "Ideogram",
      "Ideogram",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " ……… are useful for defining key alignments and intersecting points within a composition",
    options: [
      "Ruler",
      "Grid",
      "Margin",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " Which set of colors are subtractive colors?",
    options: [
      "Blue –red- green",
      "Yellow, yellow -orange, and orange",
      "Red –violet, red, and yellow –green",
      "Yellow-magenta-cyan",
    ],
    ans: "D",
  },
  {
    ques: " ____is a key element in logo design and plays an important role in brand differentiation.",
    options: [
      "Color",
      "Value",
      "Depth",
      "Brightness",
    ],
    ans: "A",
  },
  {
    ques: " The Coca-Cola logo was created by……………….in 1885",
    options: [
      "Rob janoff",
      "John pemberton",
      "Allan turing",
      "Frank mason robinson",
    ],
    ans: "D",
  },
  {
    ques: " _____is a short and striking or memorable phrase used in advertising",
    options: [
      "Logo",
      "Slogan",
      "Design",
      "Poster",
    ],
    ans: "B",
  },
  {
    ques: " …………………is the art and technique of arranging type in order to make language visible",
    options: [
      "Typography",
      "Symbol",
      "Tessellation",
      "Unity",
    ],
    ans: "A",
  },
  {
    ques: " ………….is the origination of new thing",
    options: [
      "Art",
      "Creativity",
      "Design",
      "Movie",
    ],
    ans: "B",
  },
  {
    ques: " Imagination means…………………",
    options: [
      "Ability of skill up gradation",
      "Ability to form new images",
      "Ability to learn new",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Brochure is a…………",
    options: [
      "Pamphlet",
      "Booklet",
      "Posters",
      "Slide",
    ],
    ans: "A",
  },
  {
    ques: " Thinking through visual processing is called……………",
    options: [
      "Visual design",
      "Visual thinking",
      "Graphics",
      "Film making",
    ],
    ans: "B",
  },
  {
    ques: " A4 size is………",
    options: [
      "20 x 19 cm",
      "29.7 cm x 21 cm",
      "28x22 cm",
      "30x20 cm",
    ],
    ans: "B",
  },
  {
    ques: " Not a part of design principles",
    options: [
      "form",
      "content",
      "unity",
      "ratio",
    ],
    ans: "D",
  },
  {
    ques: " Which is not included in the elements of design",
    options: [
      "Shape",
      "Line",
      "points",
      "texture",
    ],
    ans: "C",
  },
  {
    ques: " Which set of colors are analogous colors?",
    options: [
      "Blue, red, and green",
      "Yellow, yellow -orange, and orange",
      "Red –violet, red, and yellow –green",
      "Red, white, and blue",
    ],
    ans: "B",
  },
  {
    ques: " An image file such as a .jpeg is made up of lots of smaller units called……",
    options: [
      "Dots",
      "Fonts",
      "Points",
      "Pixels",
    ],
    ans: "D",
  },
  {
    ques: " …………is a vector drawing tool found in Illustrator.",
    options: [
      "Gradient tool",
      "Pen tool",
      "Eraser tool",
      "Select tool",
    ],
    ans: "B",
  },
  {
    ques: " A………………file is the most common picture file type found on the internet",
    options: [
      ".psd",
      ".jpg",
      ".doc",
      ".fla",
    ],
    ans: "B",
  },
  {
    ques: " Images and graphics on a website are usually created using …………",
    options: [
      "Photoshop",
      "Dreamweaver",
      "Indesign",
      "Paint",
    ],
    ans: "A",
  },
  {
    ques: " What are the 3 primary colors?",
    options: [
      "Red, white, and blue",
      "Green, red, and yellow –orange",
      "Red, blue, and yellow",
      "Green, orange, and purple",
    ],
    ans: "C",
  },
  {
    ques: " ………A consistent visual appearance or style of characters",
    options: [
      "Kerning",
      "Bullet",
      "Typeface",
      "Italics",
    ],
    ans: "C",
  },
  {
    ques: " ………is an imaginary line on which all letters in a line of type stand",
    options: [
      "Boldface",
      "Baseline",
      "Typestyle",
      "Serif",
    ],
    ans: "B",
  },
  {
    ques: " Anything with height or width is called………",
    options: [
      "Line",
      "Shape",
      "Mass",
      "Space",
    ],
    ans: "B",
  },
  {
    ques: " ………… is a form with width and length, but no depth.",
    options: [
      "Line",
      "Color",
      "Texture",
      "Mass",
    ],
    ans: "A",
  },
  {
    ques: " ……..can be defined as a figure or mass",
    options: [
      "Strength",
      "Color",
      "Shape",
      "Volume",
    ],
    ans: "C",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a user interface?",
    options: [
      "UI design",
      "UX design",
      "Interaction design",
      "Graphic design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for user experience?",
    options: [
      "UI design",
      "UX design",
      "Interaction design",
      "Graphic design",
    ],
    ans: "B",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a logo?",
    options: [
      "Logo design",
      "Branding",
      "Styling",
      "Conceptualizing",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a brochure?",
    options: [
      "Brochure design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a poster?",
    options: [
      "Poster design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a book cover?",
    options: [
      "Book cover design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a t-shirt?",
    options: [
      "T-shirt design",
      "Apparel design",
      "Fashion design",
      "Conceptualizing",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe creating a design for an advertisement?",
    options: [
      "Advertising design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a menu?",
    options: [
      "Menu design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a billboard?",
    options: [
      "Billboard design",
      "Advertising design",
      "Print design",
      "Publication design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a business card?",
    options: [
      "Business card design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the process of creating a design for a letterhead?",
    options: [
      "Letterhead design",
      "Print design",
      "Publication design",
      "Catalog design",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of adjusting the speed of a video called?",
    options: [
      "Time-lapse",
      "Slow motion",
      "Speed ramping",
      "Frame rate conversion",
    ],
    ans: "C",
  },
  {
    ques: " What is the process of adjusting the length of a video?",
    options: [
      "Trimming",
      "Splicing",
      "Cropping",
      "Transcoding",
    ],
    ans: "A",
  },
  {
    ques: " What is the process of adjusting the color temperature of a video called?",
    options: [
      "Color balance",
      "Hue adjustment",
      "Saturation adjustment",
      "White balance adjustment",
    ],
    ans: "D",
  },
  {
    ques: " _____is any plan for organizing colors",
    options: [
      "Color wheel",
      "Color scheme",
      "Composition",
      "Closure",
    ],
    ans: "B",
  },
  {
    ques: " _____ is parts of the artwork that appear farthest away from the viewer",
    options: [
      "Background",
      "Foreground",
      "Harmony",
      "Pictographs",
    ],
    ans: "A",
  },
  {
    ques: " _____ is white, any shade of gray and black are considered neutrals",
    options: [
      "Line",
      "Value",
      "Color",
      "None",
    ],
    ans: "C",
  },
  {
    ques: " Orange, green, violet are______?",
    options: [
      "Primary colors",
      "Tertiary colors",
      "Arbitrary color",
      "Secondary colors",
    ],
    ans: "D",
  },
  {
    ques: " What is the actual work of Photoshop?",
    options: [
      "Used for Programming",
      "Used for Animation",
      "Used for Graphics",
      "Used for Typing",
    ],
    ans: "D",
  },
  {
    ques: " Image Ready credit goes to Which company?",
    options: [
      "Cisco Systems",
      "Citrix Systems",
      "Adobe Systems",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Photoshop has a File Extension?",
    options: [
      "Bmp",
      "Tiff",
      "Txt",
      "Psd",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is the extension that supports Transparency in photoshop?",
    options: [
      "Jpeg",
      "Jpg",
      "Gif",
      "Png",
    ],
    ans: "D",
  },
  {
    ques: " GIF stands for?",
    options: [
      "Gray-scale Interchange file",
      "Graphics inter file",
      "Graphics Interchange Format",
      "Graphics Interchange file",
    ],
    ans: "C",
  },
  {
    ques: " The image quality Does not affect when the image is created with which of the following file type?",
    options: [
      "Gif",
      "Jpeg",
      "Png",
      "Tiff",
    ],
    ans: "D",
  },
  {
    ques: " Select the formats that are not an image format in photoshop?",
    options: [
      "WPG",
      "DPI",
      "EPS",
      "PCX",
    ],
    ans: "B",
  },
  {
    ques: " When we want to print an image, then ____ is helpful.",
    options: [
      "CMYK",
      "RGB",
      "BMP",
      "Gray Scale",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is the Example of Vector Image in photoshop?",
    options: [
      "JPG",
      "BMP",
      "EPS",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Red, yellow and blue are ___?",
    options: [
      "Complementary colors",
      "Secondary colors",
      "Tertiary colors",
      "Primary colors",
    ],
    ans: "D",
  },
  {
    ques: " The hierarchy of activities to create a new file in Photoshop is___?",
    options: [
      "Start > New File",
      "File > Open",
      "Create > New",
      "File > New",
    ],
    ans: "D",
  },
  {
    ques: " A duplicate layer of a layer in photoshop can be created with which shortcut key?",
    options: [
      "Ctrl+H",
      "Ctrl+D",
      "Ctrl+X",
      "Ctrl+J",
    ],
    ans: "D",
  },
  {
    ques: " What is the shortcut key to create a new file in Photoshop?",
    options: [
      "File, new",
      "Ctrl + N",
      "Ctrl+Shift+N",
      "Shift+N",
    ],
    ans: "B",
  },
  {
    ques: " Can a raster image be considered as _____image?",
    options: [
      "Color",
      "Curve",
      "Bitmap",
      "Graphic",
    ],
    ans: "C",
  },
  {
    ques: " We can move an image in Photoshop with which tool?",
    options: [
      "Move Tool",
      "Selection Tool",
      "Both a and b",
      "Slice Tool",
    ],
    ans: "A",
  },
  {
    ques: " We can fill a single color in the background of an image in photoshop with the help of which tool?",
    options: [
      "Gradient Tool",
      "Paint Bucket Tool",
      "Paint Tool",
      "Background Tool",
    ],
    ans: "C",
  },
  {
    ques: " Zoom Out in photoshop is done by which of the following shortcut key?",
    options: [
      "Ctrl + :",
      "Ctrl + J",
      "Ctrl + F",
      "Ctrl + –",
    ],
    ans: "D",
  },
  {
    ques: " Which features are in FILTER?",
    options: [
      "Liquefy",
      "Noise",
      "Blur",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " What is the use of Crop Tool?",
    options: [
      "Painting the image",
      "Moving the image",
      "Cutting the image",
      "Selecting the image",
    ],
    ans: "C",
  },
  {
    ques: " Can we remove the selection on the image with ____key?",
    options: [
      "Ctrl+D",
      "Ctrl+C",
      "Ctrl+V",
      "Ctrl+I",
    ],
    ans: "A",
  },
  {
    ques: " ____is called a painting tool.",
    options: [
      "Gradient Tool",
      "Pencil Tool",
      "Brush Tool",
      "All of these",
    ],
    ans: "D",
  },
  {
    ques: " Hat is the term used to describe the use of different font styles in design?",
    options: [
      "Typography",
      "Layout",
      "Vectorization",
      "Rasterization",
    ],
    ans: "A",
  },
  {
    ques: " What is the term used to describe the technique of overlaying colors to create a new color?",
    options: [
      "Gradient",
      "Masking",
      "Blending",
      "Layers",
    ],
    ans: "C",
  },
  {
    ques: " ____ editing software Photoshop uses raster-based images to edit photos.",
    options: [
      "Photo",
      "Video",
      "Both A and B",
      "None of the above",
    ],
    ans: "A",
  },
  {
    ques: " What does PSD stand for?",
    options: [
      "Photoshop Shopping document",
      "Photoshop Document",
      "Photoshop Digital",
      "Photoshop Shopping Digital",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is/are Photoshop's feature(s)?",
    options: [
      "Smart Objects",
      "Slice Tool",
      "Shapes",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Working with ____ allows you to create independent pieces of paper that can be edited independently and later added to",
    options: [
      "Layers",
      "Smart Objects",
      "Slice Tool",
      "Shapes",
    ],
    ans: "A",
  },
  {
    ques: " Photoshop's workspace represents its ____",
    options: [
      "Backend",
      "User Interface",
      "Website",
      "Space",
    ],
    ans: "B",
  },
  {
    ques: " Digital painting tasks can be carried out in the ____ workspace",
    options: [
      "Photography",
      "Painting",
      "Custom",
      "None",
    ],
    ans: "B",
  },
  {
    ques: " What is the shortcut key used in order to perform a free transformation?",
    options: [
      "Ctrl + A",
      "Ctrl + F",
      "Ctrl + T",
      "Ctrl + X",
    ],
    ans: "C",
  },
  {
    ques: " A Photoshop document can be searched using the ____ key combination.",
    options: [
      "Ctrl + S",
      "Ctrl + O",
      "Ctrl + F",
      "Ctrl + T",
    ],
    ans: "C",
  },
  {
    ques: " Shortcut key to cancel completely -",
    options: [
      "Delete",
      "Backspace",
      "Esc",
      "Tab",
    ],
    ans: "C",
  },
  {
    ques: " Select and mask workspaces are opened by pressing ____",
    options: [
      "Ctrl + R",
      "Ctrl + Alt",
      "Ctrl + Alt + R",
      "None",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following shape tools is supported by Photoshop?",
    options: [
      "Rectangle",
      "Polygon",
      "Line",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Which tool on Photoshop helps remove the background from the image?",
    options: [
      "Magic Wand Tool",
      "Magic Eraser Tool",
      "Both A and B",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following blending mode(s) is/are available on Photoshop?",
    options: [
      "Normal",
      "Dissolve",
      "Behind",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " Using the ___ tool, you can draw complex shapes and paths.",
    options: [
      "Draw",
      "Eraser",
      "Shape",
      "Pen",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following pen tool(s) is/are available on Photoshop?",
    options: [
      "Freeform Pen",
      "Add Anchor Point Tool",
      "Convert Point Tool",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " You can change the object color using -",
    options: [
      "Hue/Saturation",
      "Adding a Color layer",
      "Color replacement tool",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " We can resize a layer using -",
    options: [
      "Freeform Pen tool",
      "Resize tool",
      "Free Transform tool",
      "Layer Tool",
    ],
    ans: "C",
  },
  {
    ques: " Illustrator uses ___ (Dots per inch) as its vector image unit; Photoshop uses ___ (Parts per inch).",
    options: [
      "DPI, PPI",
      "PPI, DPI",
      "API, DPI",
      "DPI, API",
    ],
    ans: "A",
  },
  {
    ques: " How many layers do we need to create a clipping mask?",
    options: [
      "One",
      "Two",
      "Three",
      "Four",
    ],
    ans: "B",
  },
  {
    ques: " Layers or groups that contain ____ are clipped inside another layer or group.",
    options: [
      "Masks",
      "Clipping Masks",
      "Cluster Masks",
      "None",
    ],
    ans: "B",
  },
  {
    ques: " How to add images in photoshop?",
    options: [
      "Drag the image",
      "Use Open Feature",
      "Both 1 and 2",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " How to draw a line in photoshop?",
    options: [
      "Use Line Tool",
      "Use Pen Tool",
      "Use Brush Tool",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " How to do Multiple Steps Undo in Photoshop in Windows OS?",
    options: [
      "Ctrl + Z",
      "Ctrl + Shift + Z",
      "Ctrl + Alt + Z",
      "None of the above",
    ],
    ans: "B",
  },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  // {
  //   ques: " ",
  //   options: [
  //     "",
  //     "",
  //     "",
  //     "",
  //   ],
  //   ans: "",
  // },
  ]

  export default graphic_design