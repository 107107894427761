import React from "react";

const ResumeViewer = () => {
    const url = localStorage.getItem("url")
    localStorage.removeItem("url")
  return (
    <>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <iframe
          src={url}
          width="98%"
          height="600vh"
          frameborder="0"
        ></iframe>
      </div>
    </>
  );
};

export default ResumeViewer;
