const _marketing = [
  {
    quizname: "Marketing",
    imgLink :"https://alphanumericideas.com/images/careers/marketing-internship.png",
    ques: " What is the most important step in the marketing research process?",
    options: [
      "Defining the research problem",
      "Developing a research design",
      "Collecting data",
      "Analyzing data",
    ],
    ans: "A",
  },
  {
    ques: " What type of marketing focuses on building long-term relationships with customers?",
    options: [
      "Transactional marketing",
      "Relationship marketing",
      "Guerrilla marketing",
      "Event marketing",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is NOT one of the 4 Ps of marketing?",
    options: [
      "Product",
      "Price",
      "Promotion",
      "Salesforce",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a USP and a value proposition?",
    options: [
      "A USP is a unique selling point, while a value proposition is a promise of value",
      "A USP is a promise of value, while a value proposition is a unique selling point",
      "There is no difference, they mean the same thing",
      "A USP is a company's mission statement, while a value proposition is a statement of the company's values",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is NOT one of the traditional media channels?",
    options: [
      "Television",
      "Radio",
      "Print",
      "Social media",
    ],
    ans: "D",
  },
  {
    ques: " A target market is a specific group of consumers that a company _____",
    options: [
      "Wants to attract",
      "Wants to retain",
      "Wants to eliminate",
      "Wants to expand",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between guerrilla marketing and ambush marketing?",
    options: [
      "Guerrilla marketing is more aggressive than ambush marketing",
      "Ambush marketing is more aggressive than guerrilla marketing",
      "Guerrilla marketing is more subtle than ambush marketing",
      "Ambush marketing is more subtle than guerrilla marketing",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a brand and a logo?",
    options: [
      "A brand is a company's identity, while a logo is a symbol that represents that identity",
      "A logo is a company's identity, while a brand is a symbol that represents that identity",
      "There is no difference, they mean the same thing",
      "A brand is a company's mission statement, while a logo is a company's values",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between an influencer and a brand ambassador?",
    options: [
      "An influencer is someone with a large following on social media, while a brand ambassador is a paid spokesperson for a brand",
      "A brand ambassador is someone with a large following on social media, while an influencer is a paid spokesperson for a brand",
      "There is no difference, they mean the same thing",
      "An influencer is a company's mission statement, while a brand ambassador is a company's values",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between SEO and SEM?",
    options: [
      "SEO is the process of improving a website's visibility in organic search engine results, while SEM is the process of buying paid advertising on search engines",
      "SEM is the process of improving a website's visibility in organic search engine results, while SEO is the process of buying paid advertising on search engines",
      "There is no difference, they mean the same thing",
      "SEO is the process of optimizing a website for a specific set of keywords, while SEM is the process of optimizing a website for a specific set of phrases",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between branding and rebranding?",
    options: [
      "Branding is the process of creating a brand identity for a new product or company, while rebranding is the process of changing an existing brand identity",
      "Rebranding is the process of creating a brand identity for a new product or company, while branding is the process of changing an existing brand identity",
      "There is no difference, they mean the same thing",
      "Branding is the process of creating a company's mission statement, while rebranding is the process of changing a company's values",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a pull strategy and a push strategy in marketing?",
    options: [
      "A pull strategy is when a company uses advertising and promotions to pull customers towards its products, while a push strategy is when a company uses sales techniques to push its products onto customers",
      "A push strategy is when a company uses advertising and promotions to pull customers towards its products, while a pull strategy is when a company uses sales techniques to push its products onto customers",
      "There is no difference, they mean the same thing",
      "A pull strategy is when a company uses a direct approach to attract customers, while a push strategy is when a company uses an indirect approach to attract customers.",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between above the line and below the line advertising?",
    options: [
      "Above the line advertising refers to traditional forms of advertising such as TV, radio, and print, while below the line advertising refers to non-traditional forms of advertising such as event marketing and guerrilla marketing",
      "Below the line advertising refers to traditional forms of advertising such as TV, radio, and print, while above the line advertising refers to non-traditional forms of advertising such as event marketing and guerrilla marketing",
      "There is no difference, they mean the same thing",
      "Above the line advertising refers to advertising that is above a certain budget, while below the line advertising refers to advertising that is below a certain budget.",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a target market and a target audience?",
    options: [
      "A target market is a specific group of consumers that a company wants to sell its products to, while a target audience is a specific group of consumers that a company wants to communicate its message to",
      "A target audience is a specific group of consumers that a company wants to sell its products to, while a target market is a specific group of consumers that a company wants to communicate its message to",
      "There is no difference, they mean the same thing",
      "A target market is a company's ideal customers, while a target audience is the company's potential customers.",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a SWOT analysis and a PEST analysis?",
    options: [
      "A SWOT analysis is an internal analysis of a company's strengths, weaknesses, opportunities, and threats, while a PEST analysis is an external analysis of a company's political, economic, social, and technological environment.",
      "A PEST analysis is an internal analysis of a company's strengths, weaknesses, opportunities, and threats, while a SWOT analysis is an external analysis of a company's political, economic, social, and technological environment.",
      "There is no difference, they mean the same thing",
      "A SWOT analysis is a company's internal analysis of its mission and values, while a PEST analysis is a company's external analysis of its mission and values",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a product and a service?",
    options: [
      "A product is a physical item that can be bought and sold, while a service is an intangible activity that is performed for a customer",
      "A service is a physical item that can be bought and sold, while a product is an intangible activity that is performed for a customer",
      "There is no difference, they mean the same thing",
      "A product is a company's finished goods, while a service is a company's raw materials.",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between direct marketing and indirect marketing?",
    options: [
      "Direct marketing is a marketing method in which businesses directly contact customers through mail, email or phone, while indirect marketing is a marketing method in which businesses use third-party intermediaries to reach customers",
      "Indirect marketing is a marketing method in which businesses directly contact customers through mail, email or phone, while direct marketing is a marketing method in which businesses use third-party intermediaries to reach customers",
      "There is no difference, they mean the same thing",
      "Direct marketing is a company's internal marketing strategy, while indirect marketing is a company's external marketing strategy.",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between public relations and advertising?",
    options: [
      "Public relations is the practice of building relationships with the media and the public to enhance a company's reputation, while advertising is the practice of paying for space or time to promote a product or service",
      "Advertising is the practice of building relationships with the media and the public to enhance a company's reputation, while public relations is the practice of paying for space or time to promote a product or service",
      "There is no difference, they mean the same thing",
      "Public relations is a company's internal communication strategy, while advertising is a company's external communication strategy.",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between viral marketing and referral marketing?",
    options: [
      "Viral marketing is a marketing strategy in which a company creates a message that is designed to be passed along from person to person, while referral marketing is a marketing strategy in which a company encourages satisfied customers to refer others to its products or services.",
      "Referral marketing is a marketing strategy in which a company creates a message that is designed to be passed along from person to person, while viral marketing is a marketing strategy in which a company encourages satisfied customers to refer others to its products or services",
      "There is no difference, they mean the same thing",
      "Viral marketing is a company's internal marketing strategy, while referral marketing is a company's external marketing strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a brand loyalty and a customer loyalty?",
    options: [
      "Brand loyalty is when a customer consistently chooses to buy a certain brand of products, while customer loyalty is when a customer consistently chooses to buy from a certain company",
      "Customer loyalty is when a customer consistently chooses to buy a certain brand of products, while brand loyalty is when a customer consistently chooses to buy from a certain company",
      "There is no difference, they mean the same thing",
      "Brand loyalty is a company's internal strategy, while customer loyalty is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a B2B and a B2C marketing?",
    options: [
      "B2B marketing is when a business sells its products or services to other businesses, while B2C marketing is when a business sells its products or services to consumers",
      "B2C marketing is when a business sells its products or services to other businesses, while B2B marketing is when a business sells its products or services to consumers",
      "There is no difference, they mean the same thing",
      "B2B marketing is a company's internal strategy, while B2C marketing is a company's external strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a niche market and a mass market?",
    options: [
      "A niche market is a small, specialized market segment, while a mass market is a large, general market segment",
      "A mass market is a small, specialized market segment, while a niche market is a large, general market segment",
      "There is no difference, they mean the same thing",
      "A niche market is a company's target market, while a mass market is a company's target audience",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a primary research and a secondary research?",
    options: [
      "Primary research is research that is conducted by a company itself, usually through surveys, interviews, and focus groups, while secondary research is research that is conducted using existing data, such as data from government reports, industry associations, and the company's own internal data.",
      "Secondary research is research that is conducted by a company itself, usually through surveys, interviews, and focus groups, while primary research is research that is conducted using existing data, such as data from government reports, industry associations, and the company's own internal data.",
      "There is no difference, they mean the same thing",
      "Primary research is a company's internal research, while secondary research is a company's external research",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a push and a pull strategy in distribution?",
    options: [
      "A push strategy is when a company uses its sales force to push its products onto retailers, while a pull strategy is when a company uses advertising and promotions to pull consumers towards its products",
      "A pull strategy is when a company uses its sales force to push its products onto retailers, while a push strategy is when a company uses advertising and promotions to pull consumers towards its products",
      "There is no difference, they mean the same thing",
      "A push strategy is a company's internal distribution strategy, while a pull strategy is a company's external distribution strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a horizontal and a vertical integration?",
    options: [
      "Horizontal integration is when a company acquires or merges with other companies in the same industry, while vertical integration is when a company acquires or merges with companies in the supply chain of its industry",
      "Vertical integration is when a company acquires or merges with other companies in the same industry, while horizontal integration is when a company acquires or merges with companies in the supply chain of its industry",
      "There is no difference, they mean the same thing",
      "Horizontal integration is a company's internal strategy, while vertical integration is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a product positioning and a brand positioning?",
    options: [
      "Product positioning is the process of positioning a product in the minds of the consumers, while brand positioning is the process of positioning a brand in the minds of the consumers",
      "Brand positioning is the process of positioning a product in the minds of the consumers, while product positioning is the process of positioning a brand in the minds of the consumers",
      "There is no difference, they mean the same thing",
      "Product positioning is a company's internal strategy, while brand positioning is a company's external strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a direct and an indirect distribution channel?",
    options: [
      "A direct distribution channel is when a company sells its products directly to the consumers, while an indirect distribution channel is when a company uses intermediaries such as wholesalers or retailers to sell its products",
      "An indirect distribution channel is when a company sells its products directly to the consumers, while a direct distribution channel is when a company uses intermediaries such as wholesalers or retailers to sell its products",
      "There is no difference, they mean the same thing",
      "Direct distribution channel is a company's internal strategy, while indirect distribution channel is a company's external strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a sales promotion and a personal selling?",
    options: [
      "Sales promotion is a short-term incentive to encourage the purchase of a product or service, while personal selling is the process of persuading a potential customer to buy a product or service through face-to-face or telephone communication",
      "Personal selling is a short-term incentive to encourage the purchase of a product or service, while sales promotion is the process of persuading a potential customer to buy a product or service through face-to-face or telephone communication.",
      "There is no difference, they mean the same thing",
      "Sales promotion is a company's internal strategy, while personal selling is a company's external strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a market segmentation and a target market?",
    options: [
      "Market segmentation is the process of dividing a market into smaller groups of consumers with similar needs or characteristics, while a target market is a specific group of consumers that a company has decided to sell its products or services to",
      "Target market is the process of dividing a market into smaller groups of consumers with similar needs or characteristics, while a market segmentation is a specific group of consumers that a company has decided to sell its products or services to",
      "There is no difference, they mean the same thing",
      "Market segmentation is a company's internal strategy, while target market is a company's external strategy",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between a product life cycle and a market life cycle?",
    options: [
      "A market life cycle is the stages a product goes through from its introduction to its removal from the market, while a product life cycle is the stages a market goes through from its emergence to its decline.",
      "Product life cycle is a company's internal strategy, while market life cycle is a company's external strategy",
      "There is no difference, they mean the same thing",
      "A product life cycle is the stages a product goes through from its introduction to its removal from the market, while a market life cycle is the stages a market goes through from its emergence to its decline.",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between guerrilla marketing and ambush marketing?",
    options: [
      "Guerrilla marketing is a low-cost and unconventional marketing strategy that relies on surprise and creativity, while ambush marketing is a marketing strategy in which a company aligns itself with a major event or sponsorship without paying for the official sponsorship rights",
      "There is no difference, they mean the same thing",
      " Ambush marketing is a low-cost and unconventional marketing strategy that relies on surprise and creativity, while guerrilla marketing is a marketing strategy in which a company aligns itself with a major event or sponsorship without paying for the official sponsorship rights.",
      "Guerrilla marketing is a company's internal strategy, while ambush marketing is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between a brand ambassador and a spokesperson?",
    options: [
      "A spokesperson is a person who represents a brand in a positive light, typically through personal endorsements and interactions with customers, while a brand ambassador is a person who speaks on behalf of a brand or organization, typically through official statements and media appearances",
      "There is no difference, they mean the same thing",
      "A brand ambassador is a person who represents a brand in a positive light, typically through personal endorsements and interactions with customers, while a spokesperson is a person who speaks on behalf of a brand or organization, typically through official statements and media appearances.",
      "A brand ambassador is a company's internal strategy, while a spokesperson is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between a push and a pull strategy in advertising?",
    options: [
      "There is no difference, they mean the same thing",
      "A push strategy is when a company uses advertising and sales promotions to push its products onto retailers and ultimately to consumers, while a pull strategy is when a company uses advertising and promotions to pull consumers towards its products and ultimately drive demand to retailers.",
      "A push strategy is a company's internal advertising strategy, while a pull strategy is a company's external advertising strategy",
      "A pull strategy is when a company uses advertising and sales promotions to push its products onto retailers and ultimately to consumers, while a push strategy is when a company uses advertising and promotions to pull consumers towards its products and ultimately drive demand to retailers.",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a brand extension and a line extension?",
    options: [
      "A brand extension is a company's internal strategy, while a line extension is a company's external strategy",
      "There is no difference, they mean the same thing",
      "A line extension is when a company uses an existing brand name to launch a new product in a different category, while a brand extension is when a company launches a new product in the same category as existing products under the same brand name.",
      "A brand extension is when a company uses an existing brand name to launch a new product in a different category, while a line extension is when a company launches a new product in the same category as existing products under the same brand name.",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a loyalty program and a rewards program?",
    options: [
      "A rewards program is a program that rewards customers for their repeat business and encourages brand loyalty, while a loyalty program is a program that rewards customers for their purchases or actions with the brand",
      "There is no difference, they mean the same thing",
      "A loyalty program is a program that rewards customers for their repeat business and encourages brand loyalty, while a rewards program is a program that rewards customers for their purchases or actions with the brand",
      "A loyalty program is a company's internal strategy, while a rewards program is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between penetration pricing and skimming pricing?",
    options: [
      "Penetration pricing is a company's internal strategy, while skimming pricing is a company's external strategy",
      "There is no difference, they mean the same thing",
      "Skimming pricing is a pricing strategy where a company sets a low price for a new product to quickly gain market share, while penetration pricing is a pricing strategy where a company sets a high price for a new product and gradually lowers the price over time.",
      "Penetration pricing is a pricing strategy where a company sets a low price for a new product to quickly gain market share, while skimming pricing is a pricing strategy where a company sets a high price for a new product and gradually lowers the price over time.",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between relationship marketing and transactional marketing?",
    options: [
      "Transactional marketing is a marketing strategy that focuses on building long-term relationships with customers by providing personalized service and developing trust and loyalty, while relationship marketing is a marketing strategy that focuses on individual transactions and sales, rather than building long-term relationships with customers.",
      "There is no difference, they mean the same thing",
      "Relationship marketing is a marketing strategy that focuses on building long-term relationships with customers by providing personalized service and developing trust and loyalty, while transactional marketing is a marketing strategy that focuses on individual transactions and sales, rather than building long-term relationships with customers.",
      "Relationship marketing is a company's internal strategy, while transactional marketing is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between viral marketing and buzz marketing?",
    options: [
      "Buzz marketing is a marketing strategy that uses social networks and word-of-mouth to spread a message or product quickly, while viral marketing is a marketing strategy that generates excitement and interest in a product or brand through public relations, events and promotions.",
      "Viral marketing is a company's internal strategy, while buzz marketing is a company's external strategy",
      "There is no difference, they mean the same thing",
      "Viral marketing is a marketing strategy that uses social networks and word-of-mouth to spread a message or product quickly, while buzz marketing is a marketing strategy that generates excitement and interest in a product or brand through public relations, events and promotions.",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a brand identity and a brand image?",
    options: [
      "Brand image is the set of characteristics and attributes that define a brand and make it distinct from other brands, while brand identity is the perception or impression that consumers have of a brand in their minds",
      "Brand identity is the set of characteristics and attributes that define a brand and make it distinct from other brands, while brand image is the perception or impression that consumers have of a brand in their minds.",
      "There is no difference, they mean the same thing",
      "Brand identity is a company's internal strategy, while brand image is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a brand equity and a brand value?",
    options: [
      "Brand value is the value that a brand adds to a product or service, as perceived by the customer, while brand equity is the total worth of a brand, as determined by financial analysis.",
      "There is no difference, they mean the same thing",
      "Brand equity is the value that a brand adds to a product or service, as perceived by the customer, while brand value is the total worth of a brand, as determined by financial analysis.",
      "Brand equity is a company's internal strategy, while brand value is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between brand loyalty and brand awareness?",
    options: [
      "Brand loyalty is a company's internal strategy, while brand awareness is a company's external strategy",
      "Brand loyalty is the level of commitment and attachment that a customer has to a brand, while brand awareness is the extent to which a brand is recognized and remembered by the target market.",
      "Brand awareness is the level of commitment and attachment that a customer has to a brand, while brand loyalty is the extent to which a brand is recognized and remembered by the target market",
      "There is no difference, they mean the same thing",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between cause-related marketing and cause branding?",
    options: [
      "Cause branding is a type of corporate social responsibility in which a company associates itself with a specific cause or charity in order to improve its reputation, while cause-related marketing is the practice of incorporating a specific cause into a company's brand identity and positioning",
      "There is no difference, they mean the same thing",
      "Cause-related marketing is a type of corporate social responsibility in which a company associates itself with a specific cause or charity in order to improve its reputation, while cause branding is the practice of incorporating a specific cause into a company's brand identity and positioning",
      "Cause-related marketing is a company's internal strategy, while cause branding is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between online marketing and offline marketing?",
    options: [
      "Online marketing is the promotion of products or services through the internet, while offline marketing is the promotion of products or services through traditional means such as television, radio, and print.",
      "Offline marketing is the promotion of products or services through the internet, while online marketing is the promotion of products or services through traditional means such as television, radio, and print.",
      "Online marketing is a company's internal strategy, while offline marketing is a company's external strategy",
      "There is no difference, they mean the same thing",
    ],
    ans: "A",
  },
  {
    ques: " What is the difference between market research and market analysis?",
    options: [
      "Market research is a company's internal strategy, while market analysis is a company's external strategy",
      "Market analysis is the process of gathering and analyzing information about a market and its customers, while market research is the process of evaluating the market to identify opportunities and threats.",
      "There is no difference, they mean the same thing",
      "Market research is the process of gathering and analyzing information about a market and its customers, while market analysis is the process of evaluating the market to identify opportunities and threats.",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between direct marketing and indirect marketing?",
    options: [
      "There is no difference, they mean the same thing",
      "Indirect marketing is a form of advertising that reaches customers directly, through mail, email, telemarketing, or face-to-face communication, while direct marketing is a form of advertising that reaches customers through intermediaries such as retailers or wholesalers",
      "Direct marketing is a form of advertising that reaches customers directly, through mail , email, telemarketing, or face-to-face communication, while indirect marketing is a form of advertising that reaches customers through intermediaries such as retailers or wholesalers",
      "Direct marketing is a company's internal strategy, while indirect marketing is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between market positioning and market repositioning?",
    options: [
      "Market repositioning is the process of creating an image and identity for a product or service in the minds of the target market, while market positioning is the process of changing the image and identity of a product or service in the minds of the target market.",
      "Market positioning is the process of creating an image and identity for a product or service in the minds of the target market, while market repositioning is the process of changing the image and identity of a product or service in the minds of the target market.",
      "There is no difference, they mean the same thing",
      "Market positioning is a company's internal strategy, while market repositioning is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a sales forecast and a demand forecast?",
    options: [
      "A demand forecast is a prediction of future sales based on past sales and market trends, while a sales forecast is a prediction of the quantity of a product or service that consumers will purchase in the future",
      "There is no difference, they mean the same thing",
      "A sales forecast is a company's internal strategy, while a demand forecast is a company's external strategy",
      "A sales forecast is a prediction of future sales based on past sales and market trends, while a demand forecast is a prediction of the quantity of a product or service that consumers will purchase in the future",
    ],
    ans: "D",
  },
  {
    ques: " What is the difference between a product and service?",
    options: [
      "A service is a tangible item that can be bought and sold, while a product is an intangible action or benefit that is provided to customers.",
      "There is no difference, they mean the same thing",
      "A product is a tangible item that can be bought and sold, while a service is an intangible action or benefit that is provided to customers.",
      "A product is a company's internal strategy, while a service is a company's external strategy",
    ],
    ans: "C",
  },
  {
    ques: " What is the difference between a product differentiation and a price differentiation?",
    options: [
      "Price differentiation is the process of creating a unique and distinct image for a product in the minds of consumers, while product differentiation is the process of charging different prices for the same product based on factors such as location or market segment.",
      "Product differentiation is the process of creating a unique and distinct image for a product in the minds of consumers, while price differentiation is the process of charging different prices for the same product based on factors such as location or market segment",
      "There is no difference, they mean the same thing",
      "Product differentiation is a company's internal strategy, while price differentiation is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " What is the difference between a market leader and a market challenger?",
    options: [
      "A market challenger is a company that holds the largest market share in an industry, while a market leader is a company that is striving to gain market share and challenge the dominant company or companies in an industry.",
      "A market leader is a company that holds the largest market share in an industry, while a market challenger is a company that is striving to gain market share and challenge the dominant company or companies in an industry.",
      "There is no difference, they mean the same thing",
      "A market leader is a company's internal strategy, while a market challenger is a company's external strategy",
    ],
    ans: "B",
  },
  {
    ques: " Which of these is true about marketing?",
    options: [
      "Marketing is used to promote the product and services",
      "Marketing is concerned about the sales only",
      "Marketing is the activity, set of institutions, and processes for creating, communicating,",
      "Delivering, and exchanging offerings that have value for customers, clients, partners, and society at large.",
    ],
    ans: "C",
  },
  {
    ques: " Which one is not a part of the 4 Ps?",
    options: [
      "Product",
      "People",
      "Price",
      "Place",
    ],
    ans: "B",
  },
  {
    ques: " Who is the Father of Modern Marketing?",
    options: [
      "Philip Kotler",
      "Peter F Drucker",
      "Abraham Maslow",
      "Raymond Kroc",
    ],
    ans: "A",
  },
  {
    ques: " ___defined marketing as the science and art of exploring, creating, and delivering value to satisfy the needs of a target market at a profit?",
    options: [
      "Steve Jobs",
      "Philip Kotler",
      "Peter Drucker",
      "Abraham Maslow",
    ],
    ans: "B",
  },
  {
    ques: ' The term "Marketing" refers to?',
    options: [
      "Promotion of the product",
      "Focusing on sales and profit",
      "Strategizing and implementing the organization process",
      "Set of activities to deliver customer value and satisfaction",
    ],
    ans: "D",
  },
  {
    ques: " ___ is the key term in AMA's definition of marketing?",
    options: [
      "Sales",
      "Promotion",
      "Value",
      "Profit",
    ],
    ans: "C",
  },
  {
    ques: " Why must the marketers monitor the competitor's activities?",
    options: [
      "The competitor may destroy the organization",
      "The competitor may threaten the monopoly position of the company",
      "New offerings of a competitor may need alterations in one or more components of the company's marketing mix",
      "The competitor may be violating the law to gain an advantage",
    ],
    ans: "C",
  },
  {
    ques: " Different price points for a different level of quality for a company's related products is a part of which pricing strategy?",
    options: [
      "Product line pricing",
      "Incremental pricing",
      "Optional product pricing",
      "By-product pricing",
    ],
    ans: "A",
  },
  {
    ques: " In today's time marketing must be understood and developed as?",
    options: [
      "Getting the first mover’s advantage",
      "Creating value for the customers",
      "Pushing for higher sales and profits",
      "Creating innovative products",
    ],
    ans: "B",
  },
  {
    ques: ' Which one of these is an appropriate definition of "want"?',
    options: [
      "The desires of consumers",
      "Needs related to society",
      "Basic human needs",
      "Needs directed to the product",
    ],
    ans: "D",
  },
  {
    ques: " In the evolution of marketing the production period ended in ?",
    options: [
      "Late 1800s",
      "After the second world war",
      "In the 1920s",
      "Early 20th century",
    ],
    ans: "C",
  },
  {
    ques: " According to Philip Kotler marketing is____?",
    options: [
      "A science",
      "An art",
      "Both science and art",
      "None of the above",
    ],
    ans: "C",
  },
  {
    ques: " Which concept holds that consumers will favor the products that are easily available at an affordable price?",
    options: [
      "Production concept",
      "Product concept",
      "Marketing concept",
      "Production cost concept",
    ],
    ans: "B",
  },
  {
    ques: ' What does the term "marketing" refer to?',
    options: [
      "New product development",
      "Advertising and other promotional activities",
      "Achieving sales and profit targets",
      "Creating customer value and satisfaction",
    ],
    ans: "D",
  },
  {
    ques: " Which part of the consumer's income interests the marketers?",
    options: [
      "Gross Income",
      "Disposable Income",
      "Inflationary Income",
      "Discretionary Income",
    ],
    ans: "D",
  },
  {
    ques: " Catalogs, sponsored events, and digital media presence are closely associated with the marketing mix activity of?",
    options: [
      "Product development",
      "Pricing",
      "Promotion",
      "Sales",
    ],
    ans: "C",
  },
  {
    ques: " Early adopters and opinion leaders tend to?",
    options: [
      "Buy new products in their circles first and voice their opinion",
      "Buy tried and tested products",
      "Being from upper class, users from other classes are followers",
      "Don’t take risks easily",
    ],
    ans: "A",
  },
  {
    ques: " To achieve market expansion a company must?",
    options: [
      "Use distribution channels more effectively",
      "Use advertising campaigns more effectively",
      "Price their products more strategically",
      "All of the above",
    ],
    ans: "D",
  },
  {
    ques: " In an organization strategic marketing planning must begin with?",
    options: [
      "Hiring a senior planning consultant",
      "Establishing organizational goals and objectives",
      "Writing the mission statement",
      "Writing the vision statement",
    ],
    ans: "C",
  },
  {
    ques: " In the service industry how many Ps comprise the marketing mix?",
    options: [
      "4 Ps",
      "7 Ps",
      "10 Ps",
      "8 Ps",
    ],
    ans: "B",
  },
  {
    ques: ' Which period worked on the marketing philosophy of "A good product will sell itself"?',
    options: [
      "Marketing",
      "Production",
      "Sales",
      "Relationship",
    ],
    ans: "B",
  },
  {
    ques: ' Which of the following describes "diversification"?',
    options: [
      "New products in new markets",
      "New products in existing markets",
      "Existing products in an existing market",
      "Existing products in new markets",
    ],
    ans: "A",
  },
  {
    ques: " Which traits are desired of marketers today?",
    options: [
      "Only creativity",
      "Only critical thinking",
      "Both creativity and critical thinking",
      "Neither creativity nor critical thinking",
    ],
    ans: "C",
  },
  {
    ques: " What is motive development?",
    options: [
      "The imbalance between the customer's actual and desired state in which gap or problem-solving is recognized",
      "The gap between the customer's current state and past state",
      "The balance between the customer's actual and desired state",
      "The gap between the customer's and his colleague's current state",
    ],
    ans: "A",
  },
  {
    ques: " Producers, wholesalers, and retailers act as a unified system to form a?",
    options: [
      "Vertical marketing system",
      "Horizontal marketing system",
      "Traditional marketing system",
      "Hierarchical marketing system",
    ],
    ans: "A",
  },
  {
    ques: " Select the strategic marketing planning tool?",
    options: [
      "A BCG matrix",
      "Market analysis",
      "Consumer analysis and scanning model",
      "Market vulnerability/business opportunity analysis",
    ],
    ans: "A",
  },
  {
    ques: " Achieving strategic marketing objectives using marketing plans and strategies is called?",
    options: [
      "Target achievement",
      "Marketing implementation",
      "Market penetration",
      "Goal setting",
    ],
    ans: "B",
  },
  {
    ques: " Using a successful brand name to launch a new product in a different category is called?",
    options: [
      "Line extension",
      "Brand expansion",
      "Brand extension",
      "Branding",
    ],
    ans: "C",
  },
  {
    ques: " The marketer focuses on product awareness, trial, and expansion in this stage of the product life cycle?",
    options: [
      "Growth Stage",
      "Decline Stage",
      "Maturity stage",
      "Introduction stage",
    ],
    ans: "D",
  },
  {
    ques: " The major objective of any marketing activity is to?",
    options: [
      "Sell",
      "Promote",
      "Create",
      "Increase awareness",
    ],
    ans: "A",
  },
  {
    ques: " When a firm believes that competitive edge depends purely on its ability to innovate, it is following?",
    options: [
      "Competitive model",
      "Conventional model",
      "Out-of-the-box thinking",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is important for the success of a differentiated product with unique customer benefits?",
    options: [
      "Early recognition of the need",
      "Large scale promotion",
      "Product superiority",
      "Detailed analysis",
    ],
    ans: "C",
  },
  {
    ques: " Choose the correct sequence of activities for brand building?",
    options: [
      "Selecting brand elements > Leveraging secondary associations > Developing a marketing program",
      "Developing marketing program > Selecting brand elements > Leveraging secondary associations",
      "Leveraging secondary associations > Developing marketing program > Selecting brand elements",
      "Selecting brand elements > Developing marketing program > Leveraging secondary associations",
    ],
    ans: "D",
  },
  {
    ques: " Which marketing channel makes the products available to end users without intermediaries?",
    options: [
      "Indirect channel",
      "Direct channel",
      "Dynamic channel",
      "Flexible channel",
    ],
    ans: "B",
  },
  {
    ques: " One company adjusts its marketing budget by observing another company's budget. This process is called?",
    options: [
      "Objective and task method",
      "Competitor analysis",
      "Competitive parity method",
      "Affordable budget allocation",
    ],
    ans: "C",
  },
  {
    ques: " At which stage of the product cycle is the profit typically negative?",
    options: [
      "Decline stage",
      "Growth stage",
      "Maturity stage",
      "Growth stage",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following facts defines the break-even point?",
    options: [
      "Total revenue is less than the total cost",
      "Total revenue is the same as the total cost",
      "Total profit is more than the total cost",
      "The total cost is less than the total cost",
    ],
    ans: "B",
  },
  {
    ques: " The expenses paid to workers for overtime are considered as?",
    options: [
      "Fixed cost",
      "Variable cost",
      "Dependent cost",
      "Total cost",
    ],
    ans: "B",
  },
  {
    ques: " During the societal marketing period what was firms' main concern for marketing?",
    options: [
      "Producing high-quality products",
      "Social and ethical concerns in marketing",
      "Achieving sales target",
      "Understanding consumer insights",
    ],
    ans: "B",
  },
  {
    ques: " ____is achieved when customer expectations are met regarding the quality of products and services along with the value-based price?",
    options: [
      "Quality excellence",
      "Customer satisfaction",
      "Value proposition",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Dividing the market into homogeneous groups is called?",
    options: [
      "Segmentation",
      "Classification",
      "Analysis",
      "Grouping",
    ],
    ans: "A",
  },
  {
    ques: " Good marketing is a result of careful planning and ____?",
    options: [
      "Research",
      "Sales",
      "Execution",
      "Strategies",
    ],
    ans: "C",
  },
  {
    ques: " Logistics management is mainly concerned about?",
    options: [
      "Promotion",
      "Production",
      "Supply chain management",
      "Sales",
    ],
    ans: "C",
  },
  {
    ques: " What do you understand about Marketing buzz?",
    options: [
      "Social marketing",
      "Traditional marketing",
      "Digital marketing",
      "Viral marketing",
    ],
    ans: "D",
  },
  {
    ques: " Which markets buy goods or services to further process them or use them in the production process?",
    options: [
      "Secondary markets",
      "Consumer markets",
      "Government markets",
      "Business markets",
    ],
    ans: "D",
  },
  {
    ques: " What do you understand about brand loyalty?",
    options: [
      "Consumer's emotional attachment to the brand",
      "Consumer's social attachment toward the brand",
      "Fulfillment of consumers needs",
      "Feel good factor when using a brand’s product",
    ],
    ans: "A",
  },
  {
    ques: " A description of people and their place in society is covered under?",
    options: [
      "Geographic segmentation",
      "Demographic segmentation",
      "Income group",
      "Age group",
    ],
    ans: "B",
  },
  {
    ques: " When a firm sets high initial prices which fall with entry of competitors in the market, it is called?",
    options: [
      "Competitive pricing",
      "Price skimming",
      "Entry barrier",
      "Monopolistic competition",
    ],
    ans: "B",
  },
  {
    ques: " Who gave the concept of Five forces?",
    options: [
      "Seth Godin",
      "Michael Porter",
      "Tim Ferriss",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is not a part of Barriers to Entry?",
    options: [
      "Government policies",
      "Patents",
      "High capital requirements",
      "New Entrants",
    ],
    ans: "D",
  },
  {
    ques: " In the buying process, when a customer recognizes a need or a problem, what is the stage called?",
    options: [
      "Information search",
      "Alternative evaluation",
      "Purchase",
      "Need recognition",
    ],
    ans: "D",
  },
  {
    ques: " At which stage of the product life cycle do the sales slow down and profits level off or decline?",
    options: [
      "Introduction",
      "Growth",
      "Maturity",
      "Decline",
    ],
    ans: "D",
  },
  {
    ques: " When adopting a new product, the customer does not go through which of the following?",
    options: [
      "Interest",
      "Comparison",
      "Culture",
      "Awareness",
    ],
    ans: "C",
  },
  {
    ques: " The evaluation of the difference between total benefits and total costs of one marketing offer in comparison to competitive offers is known as?",
    options: [
      "Customer satisfaction",
      "Customer perceived value",
      "Customer expectation",
      "Customer relationship",
    ],
    ans: "B",
  },
  {
    ques: " Which company created Great Value for the customer using technology innovation and became a game changer?",
    options: [
      "Apple",
      "Nokia",
      "Samsung",
      "Oneplus",
    ],
    ans: "A",
  },
  {
    ques: " In general political campaigns are examples of ?",
    options: [
      "Digital marketing",
      "Social marketing",
      "Personal marketing",
      "Conventional marketing",
    ],
    ans: "C",
  },
  {
    ques: " What is the major factor that differentiates a service from a product?",
    options: [
      "Availability",
      "Intangibility",
      "Price",
      "Experience",
    ],
    ans: "B",
  },
  {
    ques: ' "Everyone likes a Mercedes car but few can buy it."This is an example of?',
    options: [
      "Demand",
      "Desire",
      "Want",
      "Need",
    ],
    ans: "A",
  },
  {
    ques: " In which technique does a company use the social network on the internet to create the brand image?",
    options: [
      "Community outreach",
      "Viral marketing",
      "Social Marketing",
      "Traditional Marketing",
    ],
    ans: "B",
  },
  {
    ques: " Remarketing is used for creating demand for which of the following?",
    options: [
      "Non-usable product",
      "Reusable products",
      "The renewed use of existing products",
      "New Products",
    ],
    ans: "C",
  },
  {
    ques: " What does demographic segmentation stand for?",
    options: [
      "Geographic locations",
      "Description of people and their place in society",
      "People living in diversified areas",
      "Location of target customers",
    ],
    ans: "B",
  },
  {
    ques: " Choose the factor which is not included as a part of the physical supply?",
    options: [
      "Storage",
      "Standardization",
      "Transport",
      "Packaging",
    ],
    ans: "B",
  },
  {
    ques: " In demographic segmentation which of these factors is not considered?",
    options: [
      "Age",
      "Income",
      "Interests",
      "Gender",
    ],
    ans: "C",
  },
  {
    ques: " When a customer buys a product without logical thinking, his decision is?",
    options: [
      "Emotional",
      "Rational",
      "Patronage",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " Charging low prices from the introduction stage to gain growth in the market is?",
    options: [
      "Premium",
      "Penetration",
      "Skimming",
      "Rational pricing",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is also called a supermarket?",
    options: [
      "Self-service store",
      "Retail store",
      "Hypermarket",
      "All of the above",
    ],
    ans: "A",
  },
  {
    ques: " What is the full form of MPR?",
    options: [
      "Marketing Public Relations",
      "Monitoring Public Relations",
      "Management of Public Relations",
      "Measurement of Public Relations",
    ],
    ans: "A",
  },
  {
    ques: " Who used the term Meta Marketing for the first time?",
    options: [
      "Philip Kotler",
      "Eugene J Kelly",
      "Robert Kiyosaki",
      "None of the above",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following is the best distribution channel for vacuum cleaners?",
    options: [
      "Telemarketing",
      "Retail Stores",
      "Direct Marketing",
      "All of above",
    ],
    ans: "C",
  },
  {
    ques: " E-marketing is a part of which of the following?",
    options: [
      "E-payment",
      "E-commerce",
      "Email marketing",
      "Traditional marketing",
    ],
    ans: "B",
  },
  {
    ques: " Which of the following statements is correct?",
    options: [
      "Marketing is the term used to refer only to the sales function within a firm",
      "Marketing managers usually don't get involved in production or distribution decisions",
      "Marketing is an activity that considers only the needs of the organization, not the needs of society as a whole",
      "Marketing is the activity, set of institutions, and processes for creating, communicating, delivering, and exchanging offerings that have value for customers, clients, partners, and society at large",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is NOT an element of the marketing mix?",
    options: [
      "Distribution",
      "Product",
      "Target market",
      "Pricing",
    ],
    ans: "C",
  },
  {
    ques: " Marketing decision makers in a firm must constantly monitor competitors' activities-their products, prices, distribution, and promotional efforts-because",
    options: [
      "The competitors may be violating the law and can be reported to the authorities",
      "The actions of competitors may threaten the monopoly position of the firm in its industry",
      "The actions of competitors may create an oligopoly within an industry",
      "New product offerings by a competitor with the resulting competitive variations may require adjustments to one or more components of the firm's marketing mix",
    ],
    ans: "D",
  },
  {
    ques: " Political campaigns are generally examples of---",
    options: [
      "Cause marketing",
      "Organization marketing",
      "Event marketing",
      "Person marketing",
    ],
    ans: "D",
  },
  {
    ques: " ____________ is the collection and interpretation of information about forces, events, and relationships that may affect the organization.",
    options: [
      "Environmental scanning",
      "Stakeholder analysis",
      "Market sampling",
      "Opportunity analysis",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is typically NOT a result of recognizing the importance of ethnic groups by marketers?",
    options: [
      "Use of an undifferentiated one-size-fits-all marketing strategy",
      "Different pricing strategies for different groups",
      "Variations in product offerings to suit the wants of a particular group",
      "Study of ethnic buying habits to isolate market segments",
    ],
    ans: "A",
  },
  {
    ques: " Strategic marketing planning establishes the--",
    options: [
      "Resource base provided by the firm's strategy",
      "Economic impact of additional sales",
      "Tactical plans that must be implemented by the entire organization",
      "Basis for any marketing strategy",
    ],
    ans: "D",
  },
  {
    ques: " These objectives are often the most suitable when firms operate in a market dominated by a major competitor and where their financial resources are limited",
    options: [
      "Niche",
      "Hold",
      "Harvest",
      "Divest",
    ],
    ans: "A",
  },
  {
    ques: " When companies make marketing decisions by considering consumers' wants and the long-run interests of the company, consumer, and the general population, they are practicing which of the following principles?",
    options: [
      "Innovative marketing",
      "Consumer-oriented marketing",
      "Value marketing",
      "Societal marketing",
    ],
    ans: "D",
  },
  {
    ques: " The use of price points for reference to different levels of quality for a company's related products is typical of which product-mix pricing strategy?",
    options: [
      "Optional-product pricing",
      "Captive-product pricing",
      "By-product pricing",
      "Product line pricing",
    ],
    ans: "D",
  },
  {
    ques: " ___________ is the concept under which a company carefully integrates and coordinates its many communications channels to deliver a clear, consistent, and compelling message about the organization and its products",
    options: [
      "The promotion mix",
      "Integrated international affairs",
      "Integrated marketing communications",
      "Integrated demand characteristics",
    ],
    ans: "C",
  },
  {
    ques: " The ______________ holds that consumers will favor products that are available and highly affordable (therefore, work on improving production and distribution efficiency).",
    options: [
      "Product concept",
      "Production concept",
      "Production cost expansion concept",
      "Marketing concept",
    ],
    ans: "A",
  },
  {
    ques: " The term marketing refers to:",
    options: [
      "New product concepts and improvements",
      "Advertising and promotion activities",
      "A philosophy that stresses customer value and satisfaction",
      "Planning sales campaigns",
    ],
    ans: "C",
  },
  {
    ques: ' The term "marketing mix" describes:',
    options: [
      "A composite analysis of all environmental factors inside and outside the firm",
      "A series of business decisions that aid in selling a product",
      "The relationship between a firm's marketing strengths and its business weaknesses",
      "A blending of four strategic elements to satisfy specific target markets",
    ],
    ans: "D",
  },
  {
    ques: " When looking at consumer income, marketers are most interested in",
    options: [
      "Discretionary income",
      "Deferred income",
      "Inflationary income",
      "Disposable income",
    ],
    ans: "A",
  },
  {
    ques: " Newsletters, catalogs, and invitations to organization-sponsored events are most closely associated with the marketing mix activity of--",
    options: [
      "Pricing",
      "Distribution",
      "Product development",
      "Promotion",
    ],
    ans: "D",
  },
  {
    ques: " Assume you are in charge of the politically-mandated process of converting the economy of a developing African nation from state-controlled to market-driven business ventures. Your ability to control–––––– will most likely determine the future success of the country and its government",
    options: [
      "The culture",
      "Marketing",
      "Technology",
      "Competitive environment",
    ],
    ans: "B",
  },
  {
    ques: " Early adopters of which opinion leaders are largely comprised of and tend to be ________",
    options: [
      "Generalized; that is, they tend to lead the group on most issues",
      "More likely to buy new products before their friends do and voice their opinions about them",
      "From the upper class; people from other classes are more likely to be followers",
      "Quiet, withdrawn people who don't make fashion statements or take risks easily",
    ],
    ans: "B",
  },
  {
    ques: " The strategic marketing planning process begins with _______",
    options: [
      "The writing of the mission statement",
      "The establishment of organizational objectives",
      "The formulation of a marketing plan",
      "The formulation of a marketing plan",
    ],
    ans: "A",
  },
  {
    ques: " Market expansion is usually achieved by:",
    options: [
      "More effective use of distribution",
      "More effective use of advertising",
      "By cutting prices",
      "All of the above are suitable tactics",
    ],
    ans: "D",
  },
  {
    ques: ' A marketing philosophy summarized by the phrase "a good product will sell itself" is characteristic of the ______________ period.',
    options: [
      "Production",
      "Sales",
      "Marketing",
      "Relationship",
    ],
    ans: "A",
  },
  {
    ques: " Diversification is best described as which of the following?",
    options: [
      "Existing products in new markets",
      "Existing products in existing markets",
      "New products for new markets",
      "New products for existing markets",
    ],
    ans: "C",
  },
  {
    ques: " Today's marketers need ___________",
    options: [
      "Neither creativity nor critical thinking skills",
      "Both creativity and critical thinking skills",
      "Critical thinking skills but not creativity",
      "Creativity but not critical thinking skills",
    ],
    ans: "B",
  },
  {
    ques: " An imbalance between a consumer's actual and desired state in which recognition that a gap or problem needs resolving is called _____________",
    options: [
      "Motive development",
      "An attitudes",
      "A self-concept",
      "Product Evolutions",
    ],
    ans: "A",
  },
  {
    ques: " When producers, wholesalers, and retailers act as a unified system, they comprise a ______________",
    options: [
      "Conventional marketing system.",
      "Power-based marketing system",
      "Horizontal marketing system.",
      "Vertical marketing system",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following is a strategic marketing planning tool?",
    options: [
      "The market share/market growth (BCG) matrix",
      "The consumer scanning model",
      "A market vulnerability/business opportunity matrix",
      "Market sheet analysis",
    ],
    ans: "A",
  },
  {
    ques: " Diversification is best described as which of the following?",
    options: [
      "Existing products in new markets",
      "Existing products in existing markets",
      "New products for new markets",
      "New products for existing markets",
    ],
    ans: "C",
  },
  {
    ques: " The process that turns marketing strategies and plans into marketing actions in order to accomplish strategic marketing objectives is called __________",
    options: [
      "Marketing strategy",
      "Marketing control",
      "Marketing analysis",
      "Marketing implementation",
    ],
    ans: "D",
  },
  {
    ques: " __________ is a strategy of using a successful brand name to launch a new or modified product in a new category.",
    options: [
      "Duo-branding",
      "Line extension",
      "Brand extension",
      "Multi-branding",
    ],
    ans: "C",
  },
  {
    ques: " The stage is the product life cycle that focuses on expanding market and creating product awareness and trial is the:",
    options: [
      "Decline stage",
      "Introduction stage",
      "Growth stage",
      "Maturity stage",
    ],
    ans: "B",
  },
  {
    ques: " _______ is a critical success factor of products at project level which is indicated by a differentiated product with unique customer benefits.",
    options: [
      "Solid up front homework",
      "High quality marketing activities",
      "Sharp early product definition",
      "Product superiority",
    ],
    ans: "D",
  },
  {
    ques: " Which of the following represents the correct sequence of tasks for building a brand (from left to right)?",
    options: [
      "Developing marketing programs, Leverage of secondary associations, Choosing brand elements",
      "Choosing brand elements, Developing marketing programs, Leverage of secondary associations",
      "Choosing brand elements, Leverage of secondary associations, Developing marketing programs",
      "Leverage of secondary associations, Choosing brand elements, Developing marketing programs",
    ],
    ans: "B",
  },
  {
    ques: " Company A fixes its marketing communication budget by observing the budget set by Company B. This is an example of establishing budget by:",
    options: [
      "Affordable method",
      "Percentage of sales method",
      "Competitive parity method",
      "Objective and task method",
    ],
    ans: "C",
  },
  {
    ques: " Marketing channel that involves no intermediaries to made their products available to final buyers is classified as",
    options: [
      "Direct channel",
      "Indirect channel",
      "Static channel",
      "Flexible channel",
    ],
    ans: "A",
  },
  {
    ques: " Typically profit is negative in which stage of the product life cycle?",
    options: [
      "Growth",
      "Maturity",
      "Introduction",
      "Decline",
    ],
    ans: "C",
  },
  {
    ques: " The Economic Order Quantity (EOQ) represents the order quantity for which:",
    options: [
      "Inventory carrying cost per unit is lowest",
      "Order processing cost per unit is lowest",
      "Total inventory and order cost per unit is lowest",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following statement is true regarding break-even point:",
    options: [
      "The profit at break-even point is 0",
      "Total revenue is more than total cost at break-even point",
      "Total cost is more than total profit at break-even point",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " Cost of setting up a warehouse is considered as ____________ cost and cost of overtime paid to workers is considered as a ______________ cost.",
    options: [
      "Fixed, fixed",
      "Variable, fixed",
      "Fixed, variable",
      "Variable, Variable",
    ],
    ans: "C",
  },
  {
    ques: " Identify the incorrect statement about multiple channels:",
    options: [
      "Internet banking is an example of low cost channel that is offered by banks",
      "Customers usually use only one channel for all transactions even if multiple channels are offered",
      "Service sensitive customers prefer the full service channels",
      "Economic considerations should not be the only criteria for deciding what combination of channels to be used",
    ],
    ans: "B",
  },
  {
    ques: " Segmentation is the process of:",
    options: [
      "Dividing the market into homogeneous groups",
      "Selecting one group of consumers among several other groups",
      "Creating a unique space in the minds of the target consumer",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " Good marketing is no accident, but a result of careful planning and ________",
    options: [
      "Execution",
      "Selling",
      "Research",
      "Strategies",
    ],
    ans: "A",
  },
  {
    ques: " The ______________ function of marketing makes the products available in different geographic regions",
    options: [
      "Production",
      "Selling",
      "Distribution",
      "Promotion",
    ],
    ans: "C",
  },
  {
    ques: " The traditional view of marketing is that the firm makes something and then ______________ it.",
    options: [
      "Markets",
      "Sells",
      "Prices",
      "Services",
    ],
    ans: "B",
  },
  {
    ques: " __________ is referred to as segmentation.",
    options: [
      "Mass marketing",
      "Niche marketing",
      "Differentiated marketing.",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " Groups that have a direct or indirect influence on a persons attitudes or behavior is known as __________",
    options: [
      "Reference groups",
      "Family",
      "Roles",
      "Status",
    ],
    ans: "A",
  },
  {
    ques: " Augmented product contains ___________",
    options: [
      "Basic needs.",
      "Functional characteristics",
      "Additional benefits",
      "Expected features",
    ],
    ans: "C",
  },
  {
    ques: " The emotional attachment of a customer towards a brand is known as __________",
    options: [
      "Brand associations",
      "Perceived quality",
      "Brand loyalty",
      "Brand awareness",
    ],
    ans: "C",
  },
  {
    ques: " Logistics means _________",
    options: [
      "Production",
      "Flow of goods",
      "Consumption",
      "Marketing channel",
    ],
    ans: "B",
  },
  {
    ques: " Logistics management is a part of __________",
    options: [
      "Production",
      "Marketing channel",
      "Supply chain management",
      "Consumption",
    ],
    ans: "C",
  },
  {
    ques: " Marketing buzz means __________",
    options: [
      "Viral marketing",
      "Virtual marketing",
      "De-marketing",
      "Social marketing",
    ],
    ans: "A",
  },
  {
    ques: " Re-marketing is related to creating demand for ________",
    options: [
      "Fresh products.",
      "Non-usable products",
      "Low quality products",
      "Renewed use of products",
    ],
    ans: "D",
  },
  {
    ques: " _____________ is aimed at encouraging renewed use of a product in which market interest has declined.",
    options: [
      "De-marketing",
      "Re-marketing.",
      "Synch rod",
      "Telemarketing.",
    ],
    ans: "B",
  },
  {
    ques: " Demographic segmentation refers to.",
    options: [
      "The description of the people and the place in society",
      "The description of the people's purchasing behavior",
      "The location where people live",
      "Geographic regions",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following reflects the marketing concept philosophy?",
    options: [
      "You won't find a better deal anywhere",
      "When it's profits versus customers' needs, profits will always win out",
      "We are in the business of making and selling superior products",
      "We won't have a marketing department, we have a customer department",
    ],
    ans: "D",
  },
  {
    ques: " What is price skimming?",
    options: [
      "Setting an initially high price which falls as competitors enter the market",
      "Setting a high price which consumers perceive as indicating high quality",
      'Setting a low price to "Skim off" a large number of consumers.',
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " In the selling concept, maximization of projects of the firm is done through _________",
    options: [
      "Sales volume",
      "Increasing production",
      "Quality",
      "Services",
    ],
    ans: "A",
  },
  {
    ques: " ____________ motives are those which determine where or from whom products are purchased",
    options: [
      "Product",
      "Patronage",
      "Emotional",
      "Rational",
    ],
    ans: "B",
  },
  {
    ques: " In ______________ segmentation , buyers are divided into different groups on the basis of lifestyle or personality and values",
    options: [
      "Geographic",
      "Demographic",
      "Psychographic",
      "Behavioral",
    ],
    ans: "C",
  },
  {
    ques: " The technique of using the social network on the internet to create the brand image is called as _____________",
    options: [
      "Social marketing",
      "Re-marketing",
      "Viral-marketing",
      "Synchronic marketing",
    ],
    ans: "C",
  },
  {
    ques: " Which of the following is not included in the function of physical supply:",
    options: [
      "Standardization",
      "Storage",
      "Packaging",
      "Transportation",
    ],
    ans: "A",
  },
  {
    ques: " Which of the following is not part of demographic segmentation?",
    options: [
      "Age",
      "Income",
      "Education",
      "Interest",
    ],
    ans: "D",
  },
  {
    ques: " When a consumer decides to buy without much logical thinking, his decision is said to be _____________",
    options: [
      "Patronage",
      "Emotional",
      "Rational",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " __________ price refers to the high initial price charged when a new product is introduced in the market",
    options: [
      "Premium",
      "Penetration",
      "Skimming",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " ___________ is the practice of charging a low price right down from the beginning to stimulate the growth of the market",
    options: [
      "Skimming",
      "Penetration",
      "Premium",
      "None of these",
    ],
    ans: "B",
  },
  {
    ques: " Supermarket is also known as ________",
    options: [
      "Self service store",
      "Hyper market",
      "co-operative societies",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " The best channel of distribution for vacuum cleaners is __________",
    options: [
      "Direct marketing",
      "Telemarketing",
      "Retail chains",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " M P R stands for:",
    options: [
      "Managing public relations",
      "Measuring public relations",
      "Marketing public relations",
      "Monitoring public relations",
    ],
    ans: "C",
  },
  {
    ques: " Being ______________ one cannot taste, touch, see, hear, smell or use services like physical products",
    options: [
      "Intangible",
      "Tangible",
      "Vaporous",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " The term Meta marketing was first used by ______________",
    options: [
      "Eugene .J .Kelly",
      "N. H. Borden",
      "Wendell",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " E- marketing is a part of __________",
    options: [
      "E-commerce",
      "E-cash",
      "E-Payment",
      "E-mail",
    ],
    ans: "A",
  },
  {
    ques: " __________ advertisement is a small, graphic link placed on a web page.",
    options: [
      "Banner",
      "Buttons",
      "Website",
      "E-mail",
    ],
    ans: "A",
  },
  {
    ques: " Social marketing is used as an instrument to achieve the goals of __________",
    options: [
      "Marketer",
      "Seller",
      "Society",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " _________ marketing means serving a small market not Served by competitions.",
    options: [
      "Niche",
      "Mega",
      "Meta",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: " These are ______________ elements of promotion mix",
    options: [
      "Four",
      "Six",
      "Five",
      "Two",
    ],
    ans: "B",
  },
  {
    ques: " ___________ advertisement is used at the time of introducing a new product in the market",
    options: [
      "Selective",
      "Reminder",
      "Primary",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " _____________ is the oral presentation in a conversation with one or more prospective buyers for the purpose of making sales",
    options: [
      "Advertising",
      "Branding",
      "Personal selling",
      "None of these",
    ],
    ans: "C",
  },
  {
    ques: " The process of introducing higher quality products by a manufacturer is called _________",
    options: [
      "Product line expansion",
      "Product line contraction",
      "Trading down",
      "Trading up",
    ],
    ans: "D",
  },
  {
    ques: " _____________ is the marketing and financial value associated with a brand's strength in a market",
    options: [
      "Brand equity",
      "Brand loyalty",
      "Branding",
      "None of these",
    ],
    ans: "A",
  },
  {
    ques: ' Marketing is both an "art" and a "science" there is constant tension between the formulated side of marketing and the ______________ side',
    options: [
      "Creative",
      "Management",
      "Selling",
      "Behavior",
    ],
    ans: "A",
  },
  {
    ques: " Transportation belongs to ______________ function of marketing",
    options: [
      "Research",
      "Exchange",
      "Physical supply",
      "Facilitating",
    ],
    ans: "C",
  },
  ]

  export default _marketing